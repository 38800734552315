import { gql } from '@apollo/client';

export const ARTICLE_BY_ID = gql`
  query knowledgeBaseArticle($id: String!, $countView: Boolean) {
    knowledgeBaseArticle(id: $id, countView: $countView) {
      _id
      title
      contents
      tags
      portalSection {
        name
        externalSectionId
      }
      attachments {
        filename
        url
      }
    }
  }
`;
