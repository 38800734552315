import styled from 'styled-components';

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -18px;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
  margin-bottom: 0;
`;

export const Subtitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #1890ff;
  cursor: pointer;

  & > svg {
    position: relative;
    top: 1px;
  }
`;

export const AvatarsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .rcs-inner-container {
  }
  .rcs-custom-scroll {
    padding-bottom: 20px;
  }
  .rcs-inner-handle {
    background-color: #e4e4e4;
  }
  .rcs-custom-scrollbar {
    width: 4px;
    right: 0;
  }
`;

export const ContentContainer = styled.div`
  padding: 24px;
  padding-bottom: 0;
  width: 100%;
`;

export const AllAvatarsContentContainer = styled.div`
  padding: 24px 12px 0 24px;
  width: 100%;
`;

export const AvatarContentContainer = styled(ContentContainer)`
  padding-bottom: 24px;
`;

export const AvatarsList = styled.ul`
  overflow: hidden;
  list-style: none;
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-columns: repeat(12, 1fr);
  padding-left: 0;
  margin-right: 12px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const AvatarsListItem = styled.li`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  cursor: pointer;
  img {
    width: 41px;
    height: 41px;
  }
`;

export const PreviewAvatarImage = styled.img`
  width: 238px;
  height: 238px;
  border-radius: 50%;
  margin-bottom: 100px;
`;

export const AvatarMainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
