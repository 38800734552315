import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Image } from 'antd';
import {
  StyledModal,
  ModalMainContainer,
  AvatarContainer,
  StyledAvatar,
  Header,
  StyledCloseIcon,
  ProgressMainContainer,
  Percent,
  StyledProgress,
  StatusElement,
  StatusElements,
  CancelButton,
  ButtonsContainer,
  ToProfileButton,
} from './styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { IUserModel } from 'modules/common/gql/models/user.model';
import { statusElements } from './const';
import { ReactComponent as Checked } from 'modules/common/assets/images/completenessProfile/checked.svg';
import { ReactComponent as NotChecked } from 'modules/common/assets/images/completenessProfile/notChecked.svg';

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  user: IUserModel;
}

const CompletenessProfileModal: FC<IProps> = ({ isOpen, closeHandler, user }) => {
  const { profileCompleteness } = user;
  const { total } = profileCompleteness;
  const history = useHistory();

  const toMyProfile = () => {
    history.push('/profile');
    closeHandler();
  };

  return (
    <StyledModal visible={isOpen} footer={null} closeIcon={null} onCancel={closeHandler} width={556}>
      <ModalMainContainer>
        {user && (
          <AvatarContainer>
            <StyledAvatar src={<Image preview={false} src={user.photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
          </AvatarContainer>
        )}
        <Header>
          Your Profile is {total}% Complete
          <StyledCloseIcon onClick={closeHandler} />
        </Header>
        <ProgressMainContainer>
          <Percent>{total}% of 100%</Percent>
          <StyledProgress percent={total} showInfo={false} />
        </ProgressMainContainer>
        <StatusElements>
          {statusElements.map((elem) => {
            const { label, value } = elem;
            return (
              <StatusElement>
                {profileCompleteness[value] ? <Checked /> : <NotChecked />}
                <p>{label}</p>
              </StatusElement>
            );
          })}
        </StatusElements>
        <ButtonsContainer>
          <CancelButton onClick={closeHandler}>I will do it later</CancelButton>
          <ToProfileButton onClick={toMyProfile}>Go to My Profile</ToProfileButton>
        </ButtonsContainer>
      </ModalMainContainer>
    </StyledModal>
  );
};

export default CompletenessProfileModal;
