import React, { useEffect, useState, useMemo, Fragment, FC, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useLazyQuery, useQuery } from '@apollo/client';
import { message } from 'antd';
import { useSelector } from 'react-redux';

import {
  MainContainer,
  Header,
  StyledTitle,
  SearchTypesContainer,
  SearchType,
  CardsContainer,
  PaginationElement,
  PaginationMainContainer,
  NextPaginationControlButton,
  PrevPaginationControlButton,
  EmptyContainer,
  Content,
  SpinnerContainer,
  HeaderTitle,
  CreateSupportButton,
  CreateSupportContainer,
  MainCreateSupportContainer,
  SearchMainContainer,
} from './styles';
import Cards from './components/CardsContainer';
import { GLOBAL_SEARCH_USERS, GLOBAL_SEARCH_POSTS, GLOBAL_SEARCH_ARTICLES } from 'modules/common/gql/query';
import { IUserResult, IPostResult } from 'modules/common/components/Header/components/GlobalSearch/GlobalSearch';
import { ReactComponent as EmptyGlobalSearchPeople } from 'modules/profile/assets/images/emptyGlobalSearchPeople.svg';
import { ReactComponent as EmptyGlobalSearchPost } from 'modules/profile/assets/images/emptyGlobalSearchPost.svg';
import Spinner from 'modules/common/components/Spinner';
import SearchContainer from 'modules/common/components/GlobalSearchInput';
import Footer from 'modules/common/components/Footer';
import useDebounce from 'modules/common/hooks/useDebounce';
import { emitSearchGlobalQuery, emitSearchKBQuery } from 'utils/mixpanel';
import { RootState } from 'store/rootReducer';
import { GET_NEWS_FEED_ALL_BADGES } from 'modules/common/gql/query';
import { IUploadedFile } from 'modules/common/gql/models/newsFeedPost.model';

export enum SEARCH_TYPES {
  PEOPLE = 'PEOPLE',
  POST = 'POST',
  ARTICLE = 'ARTICLE',
}

const searchTypes: {
  title: string;
  value: SEARCH_TYPES;
}[] = [
  {
    title: 'People',
    value: SEARCH_TYPES.PEOPLE,
  },
  {
    title: 'Post',
    value: SEARCH_TYPES.POST,
  },
  // {
  //   title: 'Articles',
  //   value: SEARCH_TYPES.ARTICLE,
  // },
];

export interface IPreviewPost {
  user_id: string;
  post_id: string;
  post_type: string;
}

export interface IAddress {
  id: string;
  countryCode: string;
  usageType: string;
  municipality: string;
}

export interface IPostUser {
  first_name: string;
  last_name: string;
  user_id: string;
  full_name: string;
  photo_url: string | null;
}

export interface IPost {
  post_id: string;
  post_type: string;
  headline: string;
  text: string;
  author: IPostUser;
  mentioned_users: IPostUser[] | null;
  publishing_date: string;
  fileUrls?: string[] | null;
  files?: IUploadedFile[] | null;
}
export interface IArticle {
  tags: string[];
  article_id: number;
  title: string;
  contents: string;
  section_name: string;
  image_url?: string;
  external_section_id: string;
  slug: string;
}

export interface IArticlesResult {
  articlesBySearchFilter: {
    data: IArticle[];
    pagination: {
      total: number;
    };
  };
}

export interface IBadge {
  _id: string;
  name: string;
  description: string;
  iconUrl: string;
}

export interface INewsFeedBadgeResult {
  newsFeedAllBadges: IBadge[];
}

type Filter = { [key: string]: any };

interface IProps {
  filtersData: Filter;
}

const getElementsPerPage = (size: number) => {
  if (size > 1300) {
    return 4;
  } else if (size <= 1300 && size > 900) {
    return 3;
  }
  return 8;
};

const ELEMENTS_PER_PAGE_ARTICLES = 4;

const GlobalSearch: FC<IProps> = () => {
  const postsLoaderRef = useRef<any>(null);
  const usersLoaderRef = useRef<any>(null);
  const [isFetchMoreBlock, setFetchMoreBlock] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const debouncedScreenWidth = useDebounce(`${screenWidth}`, 200);
  const [isMountedComponent, setMountedComponent] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const { search } = useLocation();
  const [inputSearchValue, setInputSearchValue] = useState<string>('');
  const parsedSearch = useMemo(() => qs.parse(location.search.slice(1)), [location.search]);
  const [elementsPerPageUsers, setElementsPerPageUsers] = useState<number>(getElementsPerPage(+debouncedScreenWidth));
  const { data: badgesData } = useQuery<INewsFeedBadgeResult>(GET_NEWS_FEED_ALL_BADGES, {
    onError: (err: Error) => message.error(err),
  });
  const filtersData: Filter = useMemo(() => {
    const filters: Filter = {};
    const splitedSearchData = qs.parse(search.slice(1));

    Object.keys(splitedSearchData).map((elem) => {
      const value = splitedSearchData[elem];
      if (typeof elem === 'string' && (typeof value === 'string' || Array.isArray(value))) {
        filters[elem] = value;
      }
    });

    return filters;
  }, [search]);

  const paginationPage = +filtersData.page || 1;

  const isSupportPage = filtersData.from === 'NAVBAR' && filtersData.type === SEARCH_TYPES.ARTICLE;

  const elementsPerPage = 8;

  const onError = (err: Error) => message.error(err);
  const [searchUsers, usersQuery] = useLazyQuery<IUserResult>(GLOBAL_SEARCH_USERS, {
    variables: {
      text: filtersData.search,
      offset: 0,
      limit: elementsPerPage,
    },
    onError,
  });
  const { data: users, loading: usersLoading } = usersQuery;

  const [searchPosts, postsQuery] = useLazyQuery<IPostResult>(GLOBAL_SEARCH_POSTS, {
    variables: { text: filtersData.search, offset: 0, limit: elementsPerPage },
    onError,
  });

  const { data: posts, loading: postsLoading } = postsQuery;
  const { data: articles, loading: articlesLoading } = useQuery<IArticlesResult>(GLOBAL_SEARCH_ARTICLES, {
    variables: {
      searchText: filtersData.search?.length > 0 ? filtersData.search : undefined,
      offset: (paginationPage - 1) * ELEMENTS_PER_PAGE_ARTICLES,
      limit: ELEMENTS_PER_PAGE_ARTICLES,
    },
    onError,
  });

  const currentQuery = useMemo(
    () => (filtersData.type === SEARCH_TYPES.PEOPLE ? usersQuery : postsQuery),
    [filtersData, usersQuery, postsQuery],
  );

  const totalUsers = useMemo(() => users?.searchUsersGlobal.pagination.total, [users]);
  const totalPosts = useMemo(() => posts?.searchNewsFeedPostsGlobal.pagination.total, [posts]);

  const paginationButtons = useMemo(() => {
    const totalArticles = articles?.articlesBySearchFilter.pagination.total;
    if (!totalArticles) {
      return [];
    }

    return [...Array(Math.ceil(totalArticles / ELEMENTS_PER_PAGE_ARTICLES))].map((_, i) => i + 1);
  }, [articles, filtersData, elementsPerPageUsers]);

  const searchData = useMemo(() => {
    switch (filtersData.type) {
      case SEARCH_TYPES.PEOPLE:
        return users?.searchUsersGlobal.data || [];
      case SEARCH_TYPES.POST:
        return posts?.searchNewsFeedPostsGlobal.data || [];
      case SEARCH_TYPES.ARTICLE:
        return articles?.articlesBySearchFilter.data || [];
      default:
        return [];
    }
  }, [filtersData, users, posts, articles]);

  const previewModalOpen = (id: string) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...parsedSearch,
        changedFeedId: id,
      }),
    });
  };

  const setFilter = (filter: Filter) => {
    history.push({
      pathname: '/profile/global-search',
      search: qs.stringify(filter),
    });
  };

  const setPaginationPage = (page: number) => {
    setFilter({
      ...filtersData,
      page,
    });
  };

  const prevButtonHandler = () => {
    if (paginationPage > 1) {
      setPaginationPage(paginationPage - 1);
    }
  };

  const nextButtonHandler = () => {
    if (paginationPage < paginationButtons.length) {
      setPaginationPage(paginationPage + 1);
    }
  };

  const searchHandler = () => {
    setFilter({
      ...filtersData,
      section: undefined,
      tags: undefined,
      page: 1,
      search: inputSearchValue?.length ? inputSearchValue : undefined,
    });
  };

  const changeTypeHandler = (type: string) => {
    setFilter({
      ...filtersData,
      page: 1,
      section: undefined,
      tags: undefined,
      type,
    });
  };

  const resizeHandler = () => setScreenWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    setElementsPerPageUsers(getElementsPerPage(+debouncedScreenWidth));
    if (isMountedComponent) {
      setPaginationPage(1);
    }
  }, [debouncedScreenWidth]);

  useEffect(() => {
    setMountedComponent(true);
  }, []);

  useEffect(() => {
    const { search } = filtersData;
    if (search && paginationPage > 0) {
      searchUsers();
      searchPosts();
      const mixpanelCallback = isSupportPage ? emitSearchKBQuery : emitSearchGlobalQuery;
      mixpanelCallback({ query: search });
    }
  }, [filtersData, searchUsers, searchPosts, paginationPage]);

  useEffect(() => {
    setInputSearchValue(filtersData.search || '');
  }, [filtersData]);

  const callback = (entries) => {
    const [entry] = entries;
    const query: any = currentQuery;
    if (!entry.isIntersecting || !query || !query?.fetchMore) {
      return;
    }
    const queryData =
      filtersData.type === SEARCH_TYPES.PEOPLE ? query?.data?.searchUsersGlobal : query?.data?.searchNewsFeedPostsGlobal;
    const { data, pagination } = queryData;

    if (!entry.isIntersecting || isFetchMoreBlock || !pagination.total || pagination.total === data.length) {
      return;
    }

    setFetchMoreBlock(true);
    query.fetchMore({
      variables: {
        limit: elementsPerPage,
        offset: pagination.offset + elementsPerPage,
        text: filtersData.search,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setFetchMoreBlock(false);

        if (!fetchMoreResult) {
          return previousResult;
        }

        if (filtersData.type === SEARCH_TYPES.PEOPLE) {
          const initialData = previousResult?.searchUsersGlobal?.data || [];
          return Object.assign({}, previousResult, {
            searchUsersGlobal: {
              ...previousResult.searchUsersGlobal,
              data: [...initialData, ...fetchMoreResult.searchUsersGlobal.data],
              pagination: fetchMoreResult.searchUsersGlobal.pagination,
            },
          });
        } else if (filtersData.type === SEARCH_TYPES.POST) {
          const initialData = previousResult?.searchNewsFeedPostsGlobal?.data || [];
          return Object.assign({}, previousResult, {
            searchNewsFeedPostsGlobal: {
              ...previousResult.searchNewsFeedPostsGlobal,
              data: [...initialData, ...fetchMoreResult.searchNewsFeedPostsGlobal.data],
              pagination: fetchMoreResult.searchNewsFeedPostsGlobal.pagination,
            },
          });
        }
      },
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(callback, options);

    if (usersLoaderRef.current) {
      observer.unobserve(usersLoaderRef.current);
      observer.observe(usersLoaderRef.current);
    } else if (postsLoaderRef.current) {
      observer.unobserve(postsLoaderRef.current);
      observer.observe(postsLoaderRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [usersLoaderRef.current, currentQuery, filtersData, elementsPerPageUsers, postsLoaderRef]);

  return (
    <>
      <MainContainer>
        <Content>
          <HeaderTitle>
            {isSupportPage ? 'Support' : filtersData?.search ? `Search Results for "${filtersData?.search}"` : 'Search Results'}
          </HeaderTitle>
          <Header>{isSupportPage && <StyledTitle>Welcome to Astreya Support. How can we help ?</StyledTitle>}</Header>
          <SearchMainContainer>
            <SearchContainer
              placeholder={'Search (ex. holidays, password reset, or OneLogin)'}
              setInputSearchValue={setInputSearchValue}
              searchValue={inputSearchValue}
              searchHandler={searchHandler}
              isArticleType={filtersData.from === 'NAVBAR' && filtersData.type === SEARCH_TYPES.ARTICLE}
            />
          </SearchMainContainer>

          {filtersData.from !== 'NAVBAR' && (
            <SearchTypesContainer>
              {searchTypes.map((elem, key) => {
                const { title, value } = elem;
                return (
                  <SearchType $isActive={value === filtersData.type} onClick={() => changeTypeHandler(value)} key={key}>
                    {title}
                  </SearchType>
                );
              })}
            </SearchTypesContainer>
          )}
          {searchData.length > 0 ? (
            <Fragment>
              <CardsContainer>
                <Cards
                  badgesData={badgesData?.newsFeedAllBadges || []}
                  currentUserId={user.id || ''}
                  dataType={filtersData.type}
                  data={searchData}
                  previewModalOpen={previewModalOpen}
                  postsLoaderRef={postsLoaderRef}
                  usersLoaderRef={usersLoaderRef}
                  totalPosts={totalPosts}
                  totalUsers={totalUsers}
                />
              </CardsContainer>
              {paginationButtons.length > 0 && filtersData.type === SEARCH_TYPES.ARTICLE && (
                <PaginationMainContainer
                  isArticleType={filtersData.from === 'NAVBAR' && filtersData.type === SEARCH_TYPES.ARTICLE}
                >
                  <PrevPaginationControlButton onClick={prevButtonHandler}>Previous</PrevPaginationControlButton>
                  {paginationButtons
                    .slice(paginationPage - 5 >= 0 ? paginationPage - 5 : 0, paginationPage - 1 + 5)
                    .map((elem, key) => (
                      <PaginationElement isChanged={elem === paginationPage} onClick={() => setPaginationPage(elem)} key={key}>
                        {elem}
                      </PaginationElement>
                    ))}
                  <NextPaginationControlButton onClick={nextButtonHandler}>Next</NextPaginationControlButton>
                </PaginationMainContainer>
              )}
              {filtersData.type === SEARCH_TYPES.ARTICLE && (
                <MainCreateSupportContainer>
                  <CreateSupportContainer>
                    <h2>Can’t find what you’re looking for?</h2>
                    <CreateSupportButton href={'https://support.astreya.com/new/'} target={'_blank'}>
                      Create a Support Ticket
                    </CreateSupportButton>
                  </CreateSupportContainer>
                </MainCreateSupportContainer>
              )}
            </Fragment>
          ) : usersLoading || postsLoading || articlesLoading ? (
            <SpinnerContainer>
              <Spinner width={60} height={60} />
            </SpinnerContainer>
          ) : (
            <EmptyContainer>
              {filtersData.type === SEARCH_TYPES.PEOPLE && <EmptyGlobalSearchPeople />}
              {filtersData.type === SEARCH_TYPES.POST && <EmptyGlobalSearchPost />}
              <p>We didn't find anything on your request</p>
            </EmptyContainer>
          )}
        </Content>
      </MainContainer>
      <Footer />
    </>
  );
};

export default GlobalSearch;
