import { useEffect, useState, useMemo, RefObject } from 'react';
import pdfjs from '@bundled-es-modules/pdfjs-dist';

interface IArguments {
  parentContainerRef: RefObject<HTMLDivElement>;
  file: string;
  scale?: number;
  rotate?: number;
  page?: number;
  workerSrc?: string;
  withCredentials?: boolean;
  autoScale?: boolean;
}

type TypeUsePdf = (args: IArguments) => [boolean, number];

const getScale = (width: number) => {
  if (width > 1400) {
    return 0.4;
  } else if (width > 800) {
    return 0.6;
  }
  return 1;
};

const usePdf: TypeUsePdf = ({
  parentContainerRef,
  file,
  scale = 1,
  rotate = 0,
  page,
  workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
  withCredentials = false,
  autoScale = false,
}) => {
  const [pdf, setPdf] = useState<any>();
  const loading = useMemo(() => !pdf, [pdf]);
  const numPages = useMemo(() => (pdf ? pdf._pdfInfo.numPages : null), [pdf]);

  const drawPDF = (page) => {
    const rotation = rotate === 0 ? page.rotate : page.rotate + rotate;
    let dpRatio = 1;
    dpRatio = window.devicePixelRatio;

    const originalViewport = page.getViewport({
      scale: 1 * dpRatio,
      rotation,
    });

    const adjustedScale = autoScale ? getScale(originalViewport.width / dpRatio) : scale * dpRatio;

    const viewport = page.getViewport({
      scale: adjustedScale,
      rotation,
    });
    const canvas = document.createElement('canvas');

    if (!canvas) {
      return;
    }

    const canvasContext = canvas.getContext('2d');
    canvas.style.width = `${viewport.width / dpRatio}px`;
    canvas.style.height = `${viewport.height / dpRatio}px`;
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = {
      canvasContext,
      viewport,
    };
    page.render(renderContext);

    if (parentContainerRef.current) {
      parentContainerRef.current.appendChild(canvas);
    }
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
  }, []);

  useEffect(() => {
    const config = {
      url: file,
      withCredentials,
    };

    pdfjs.getDocument(config).promise.then(setPdf);
  }, [file, withCredentials]);

  useEffect(() => {
    if (parentContainerRef.current) {
      parentContainerRef.current.innerHTML = '';
    }
    if (!pdf) {
      return;
    }
    if (page) {
      pdf.getPage(page).then((res) => drawPDF(res));
      return;
    }
    const pagesNumber = pdf._pdfInfo.numPages;
    const allPages = [...Array(Math.ceil(pagesNumber))].map((_, i) => i + 1);
    const pagesPromises = allPages.map((pageNumber) => pdf.getPage(pageNumber));
    Promise.all(pagesPromises).then((res) => {
      res.map((el) => drawPDF(el));
    });
  }, [pdf, page, scale, rotate, parentContainerRef]);

  return [loading, numPages];
};

export default usePdf;
