import { gql } from '@apollo/client';

export const TOP_COMMENT_FRAGMENT = `
  _id
  postId
  text
  plainText
  parentCommentId
  mentions {
    userId
    fullName
    photoUrl
  }
  changed
  reactions {
    LIKE
    CELEBRATE
    SUPPORT
    LOVE
    INSIGHTFUL
    CURIOUS
  }
  author {
    fullName
    photoUrl
    id
    firstName
    lastName
  }
  createdAt
  reactions {
    LIKE
    CELEBRATE
    SUPPORT
    LOVE
    INSIGHTFUL
    CURIOUS
  }
  comments(pagination: {limit: 1000}) {
    data {
      _id
      postId
      text
      plainText
      parentCommentId
      mentions {
        userId
        fullName
        photoUrl
      }
      changed
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
      author {
        fullName
        photoUrl
        id
        firstName
        lastName
      }
      createdAt
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
    }
  }
`;

export const NEWS_FEED_COMMON_FRAGMENT = `
  _id
  userId
  author {
    id
    photoUrl
    fullName
    firstName
    lastName
  }
  text
  plainText
  files {
    fileId
    fileName
    fileUrl
    fileType
    previewImageUrl
  }
  tags
  mentions {
    userId
    fullName
    photoUrl
    positionBusinessTitle
  }
  publishingDate
  createdAt
  updatedAt
  type
  isHidden  
  commentsCount
  reactions {
    LIKE
    CELEBRATE
    SUPPORT
    LOVE
    INSIGHTFUL
    CURIOUS
  }
  mostRepliedComment {
    ${TOP_COMMENT_FRAGMENT}
  }
  mostReactedComment {
    ${TOP_COMMENT_FRAGMENT}
  }
  directCommentsCount
`;

export const NEWS_FEED_COMMENT_FRAGMENT = `
  comments(pagination: {limit:5}) {
    data {
      _id
      postId
      text
      plainText
      parentCommentId
      mentions {
        userId
        fullName
        photoUrl
      }
      changed
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
      author {
        fullName
        photoUrl
        id
        firstName
        lastName
      }
      createdAt
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
      comments(pagination: {limit: 1000}) {
        data {
          _id
          postId
          text
          plainText
          parentCommentId
          mentions {
            userId
            fullName
            photoUrl
          }
          changed
          reactions {
            LIKE
            CELEBRATE
            SUPPORT
            LOVE
            INSIGHTFUL
            CURIOUS
          }
          author {
            fullName
            photoUrl
            id
            firstName
            lastName
          }
          createdAt
          reactions {
            LIKE
            CELEBRATE
            SUPPORT
            LOVE
            INSIGHTFUL
            CURIOUS
          }
        }
      }
    }
    pagination {
      nextCursor
      limit
      pageCount
    }
  }
`;

export const GET_ALL_COMMENTS_IDS_BY_ID = gql`
  query newsFeedPostComments($postId: String!) {
    newsFeedPostComments(postId: $postId, pagination: { limit: 0 }) {
      data {
        _id
      }
    }
  }
`;

export const GET_COMMENTS_BY_ID = gql`
  query newsFeedPostComments($postId: String!, $limit: Int!, $nextCursor: String) {
    newsFeedPostComments(postId: $postId, pagination: { limit: $limit, nextCursor: $nextCursor }) {
      data {
        _id
        postId
        text
        plainText
        parentCommentId
        mentions {
          userId
          fullName
          photoUrl
        }
        changed
        reactions {
          LIKE
          CELEBRATE
          SUPPORT
          LOVE
          INSIGHTFUL
          CURIOUS
        }
        author {
          fullName
          photoUrl
          id
          firstName
          lastName
        }
        createdAt
        comments(pagination: { limit: 1000 }) {
          data {
            _id
            postId
            text
            plainText
            parentCommentId
            mentions {
              userId
              fullName
              photoUrl
            }
            changed
            reactions {
              LIKE
              CELEBRATE
              SUPPORT
              LOVE
              INSIGHTFUL
              CURIOUS
            }
            author {
              fullName
              photoUrl
              id
              firstName
              lastName
            }
            createdAt
          }
        }
      }
      pagination {
        nextCursor
        limit
        pageCount
      }
    }
  }
`;

export const GET_NEWS_FEED_POST_BY_ID = gql`
  query NewsFeedPostById($postId: String!) {
    newsFeedPostById(postId: $postId) {
      ... on NewsFeedIndividualPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
      }
      ... on NewsFeedCompanyNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        publishAt
        headline
        isFeatured
        featuredExpAt
      }
      ... on NewsFeedTeamNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        publishAt
        costCenterId
        isFeatured
        headline
        featuredExpAt
        costCenter {
          id
          name
        }
      }
      ... on NewsFeedHrBirthdayWishesPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrNewHiresPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrPromotionPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrWorkAnniversaryPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          hireDate
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedThanksPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedKudosPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
      }
      ... on NewsFeedRecognitionPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            isProfilePhotoAvatar
            avatarBgColor
          }
          badge
        }
    }
  }
`;

export const GET_COST_CENTERS = gql`
  query costCenters {
    costCenters {
      data {
        id
        name
      }
    }
  }
`;

export const GET_NEWS_FEED_POST_REACTIONS = gql`
  query NewsFeedPostReactions($postId: String!) {
    newsFeedPostReactions(postId: $postId) {
      _id
      postId
      commentId
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
      userInfo {
        reactionType
        userProxies {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          followedByMeStatus
        }
      }
    }
  }
`;

export const namedGetNewsFeedPosts = (postfix: string) => gql`
query GetNewsFeedPosts_${postfix}($limit: Int!, $nextCursor: String, $postTypes: [NF_POST_TYPES!]!) {
    newsFeedPosts(pagination: {
      limit: $limit,
      nextCursor: $nextCursor,
    }, postTypes: $postTypes) {
      data {
        ... on NewsFeedIndividualPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
        }
        ... on NewsFeedCompanyNewsPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          publishAt
          headline
          isFeatured
          featuredExpAt
        }
        ... on NewsFeedTeamNewsPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          publishAt
          costCenterId
          isFeatured
          headline
          featuredExpAt
          costCenter {
            id
            name
          }
        }
        ... on NewsFeedHrBirthdayWishesPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            isProfilePhotoAvatar
            avatarBgColor
          }
        }
        ... on NewsFeedHrNewHiresPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            isProfilePhotoAvatar
            avatarBgColor
          }
        }
        ... on NewsFeedHrPromotionPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            isProfilePhotoAvatar
            avatarBgColor
          }
        }
        ... on NewsFeedHrWorkAnniversaryPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            hireDate
            isProfilePhotoAvatar
            avatarBgColor
          }
        }
        ... on NewsFeedThanksPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            isProfilePhotoAvatar
            avatarBgColor
          }
        }
        ... on NewsFeedKudosPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
        }
        ... on NewsFeedRecognitionPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            isProfilePhotoAvatar
            avatarBgColor
          }
          badge
        }
      },
      pagination {
        nextCursor,
        pageCount, 
      },
    }
  }
`;

export const namedGetNewsFeedMultiplePostsReactions = (postfix: string) => gql`
  query NewsFeedReactionsByPostIds_${postfix}($postIds: [String!]!) {
    newsFeedReactionsByPostIds(postIds: $postIds) {
      _id
      postId
      commentId
      reactions {
    LIKE
    CELEBRATE
    SUPPORT
    LOVE
    INSIGHTFUL
    CURIOUS
  }
      userInfo {
        reactionType
        userProxies {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          followedByMeStatus
        }
      }
    }
  }
`;

export const GET_NEWS_FEED_POST_REACTION = gql`
  query newsFeedPostReactions($postId: String!) {
    newsFeedPostReactions(postId: $postId) {
      _id
      postId
      commentId
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
      userInfo {
        reactionType
        userProxies {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          followedByMeStatus
        }
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers($offset: Int!, $limit: Int!, $nameLike: String!) {
    searchUsers(pagination: { offset: $offset, limit: $limit }, nameLike: $nameLike) {
      data {
        id
        photoUrl
        fullName
        firstName
        lastName
      }
    }
  }
`;

export const GET_COMMENTS_REACTIONS = gql`
  query newsFeedCommentReactions($commentId: String!, $postId: String!) {
    newsFeedCommentReactions(commentId: $commentId, postId: $postId) {
      _id
      postId
      commentId
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
      userInfo {
        reactionType
        userProxies {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          followedByMeStatus
        }
      }
    }
  }
`;

export const GET_AVATARS = gql`
  query avatars {
    avatars {
      _id
      createdAt
      updatedAt
      deletedAt
      photoFileName
      bgColor
      photoUrl
    }
  }
`;

export const GET_AVATAR_BY_ID = gql`
  query avatar($id: String!) {
    avatar(id: $id) {
      _id
      createdAt
      updatedAt
      deletedAt
      photoFileName
      bgColor
      photoUrl
    }
  }
`;
