import React, { FC, useState, useRef, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { message } from 'antd';

import {
  StyledModal,
  MainHeaderContainer,
  HeaderContainer,
  StyledCloseIcon,
  ContentContainer,
  StyledInput,
  StyledLabel,
  InputContainer,
  GlobalInputLabel,
  MainInputContainer,
  Or,
  AvatarsContainer,
  AvatarsList,
  AvatarsListItem,
  SeeMoreButton,
} from './styles';
import AllAvatarsModal from './components/AllAvatars';
import SelectedFileModal from './components/SelectedFile';
import SelectedAvatarModal from './components/SelectedAvatar';
import { ReactComponent as CloudFile } from 'modules/profile/assets/images/avatarEditor/cloudFile.svg';
import { SET_PHOTO, SET_AVATAR } from 'modules/profile/gql/mutation';
import { GET_AVATARS } from 'modules/news-feed/gql/query';
import { IAvatarModel } from 'modules/common/gql/models/avatar.model';
import { emitProfileUpdated } from 'utils/mixpanel';

interface IProps {
  isOpened: boolean;
  closeHandler: () => void;
  refetchUser: () => void;
  userId: string;
}

interface IGetAvatarsResult {
  avatars: IAvatarModel[];
}

const AvatarModal: FC<IProps> = ({ isOpened, closeHandler, refetchUser, userId }) => {
  const editorRef = useRef<any>(null);
  const [scale, setScale] = useState(1);
  const [changedFile, setChangedFile] = useState<File | null>(null);
  const [changedAvatar, setChangedAvatar] = useState<IAvatarModel | null>(null);
  const { data: avatarsData } = useQuery<IGetAvatarsResult>(GET_AVATARS);
  const [showAllAvatars, setShowAllAvatars] = useState(false);
  const avatarsList = useMemo(
    () => (!showAllAvatars ? avatarsData?.avatars.slice(0, 35) : avatarsData?.avatars),
    [avatarsData, showAllAvatars],
  );

  const onClose = () => {
    setChangedAvatar(null);
    setChangedFile(null);
    setShowAllAvatars(false);
    closeHandler();
  };

  const onSelectAvatarClose = () => {
    setChangedAvatar(null);
    setChangedFile(null);
    setShowAllAvatars(true);
    closeHandler();
  };

  const onSuccessCb = () => {
    refetchUser();
    onClose();
    emitProfileUpdated({ section: 'profile picture' });
  };
  const onErrorCb = (err) => message.error(err);
  const [setPhoto] = useMutation(SET_PHOTO, { onCompleted: onSuccessCb, onError: onErrorCb });
  const [setAvatar] = useMutation(SET_AVATAR, { onCompleted: onSuccessCb, onError: onErrorCb });

  const onSave = async () => {
    if (editorRef.current) {
      const resultFilePromise = new Promise((resolve) => {
        editorRef.current.getImageScaledToCanvas().toBlob(
          (blob) => {
            const file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });

            resolve(file);
          },
          'image/jpeg',
          0.95,
        );
      });
      const result = await resultFilePromise;

      if (result) {
        setPhoto({
          variables: {
            file: result,
            id: userId,
          },
        });
      }
      return;
    }
    if (changedAvatar) {
      setAvatar({
        variables: { avatarId: changedAvatar._id, id: userId },
      });
    }
  };

  const clearChangedFile = () => {
    setChangedFile(() => null);
    setScale(() => 1);
  };

  const dropFilesHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/bmp')) {
      setChangedFile(file);
    }
  };

  if (!avatarsList?.length) {
    return null;
  }

  if (changedAvatar) {
    return (
      <SelectedAvatarModal avatar={changedAvatar} onSave={onSave} cancelHandler={onSelectAvatarClose} closeHandler={onClose} />
    );
  }

  if (!!changedFile) {
    return (
      <SelectedFileModal
        editorRef={editorRef}
        closeHandler={onClose}
        file={changedFile}
        clearChangedFile={clearChangedFile}
        scale={scale}
        setScale={setScale}
        onSave={onSave}
      />
    );
  }

  if (showAllAvatars) {
    return (
      <AllAvatarsModal
        avatars={avatarsList}
        goBack={() => {
          setShowAllAvatars(false);
          setChangedAvatar(null);
        }}
        setAvatar={(avatar: IAvatarModel) => setChangedAvatar(avatar)}
      />
    );
  }

  return (
    <StyledModal $withAvatars={!!avatarsList?.length && !changedFile} visible={isOpened} footer={null}>
      <MainHeaderContainer>
        <HeaderContainer>
          Profile Photo
          <StyledCloseIcon onClick={closeHandler} />
        </HeaderContainer>
      </MainHeaderContainer>
      <ContentContainer>
        <MainInputContainer>
          <GlobalInputLabel htmlFor={'avatarInput'}>
            <InputContainer
              onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onDrop={dropFilesHandler}
            >
              <CloudFile />
              <p>Drag and drop your images here</p>
              <StyledLabel htmlFor={'avatarInput'}>Upload a photo</StyledLabel>
              <StyledInput
                type={'file'}
                id={'avatarInput'}
                name={'avatarInput'}
                onChange={(e) => setChangedFile((e.target.files || [null])[0])}
                accept="image/png, image/jpeg, image/jpg, .svg"
              />
            </InputContainer>
          </GlobalInputLabel>
          <Or>or</Or>
          <AvatarsContainer>
            <AvatarsList>
              {avatarsList.map((el, i) => (
                <AvatarsListItem onClick={() => setChangedAvatar(el)} key={i}>
                  <img src={el.photoUrl} />
                </AvatarsListItem>
              ))}
            </AvatarsList>
            {!showAllAvatars && <SeeMoreButton onClick={() => setShowAllAvatars(true)}>See All Avatars</SeeMoreButton>}
          </AvatarsContainer>
        </MainInputContainer>
      </ContentContainer>
    </StyledModal>
  );
};

export default AvatarModal;
