import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { Image } from 'antd';

import {
  MainContainer,
  Avatar,
  InfoContainer,
  UserContainer,
  MessageContainer,
  MessageText,
  MainInfoContainer,
  PostTextContainer,
  ReadIndicator,
  UserText,
} from '../../component.styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { richTextToPlain } from 'utils/parsers';
import { NOTIFICATION_TYPES } from '../../constants';
import { ICommentMentionNotification } from '../../interfaces';
import PostAuthor from '../PostAuthor';

interface IProps {
  data: ICommentMentionNotification;
  changeNotify: (id: string) => void;
}

const CommentMention: FC<IProps> = ({ data, changeNotify }) => {
  const { createdAt, status, relatedEntity, id, userId } = data;
  const { commentUser, postUser, comment, postType } = relatedEntity;
  const { text = '' } = comment || { text: '' };
  const parsedText = useMemo(() => richTextToPlain(text), [text]);

  if (!relatedEntity || Object.keys(relatedEntity).length <= 0) {
    return null;
  }

  return (
    <MainContainer onClick={() => changeNotify(id)}>
      <UserContainer>
        <Avatar src={<Image preview={false} src={commentUser?.photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
        <MainInfoContainer>
          <InfoContainer>
            <MessageContainer>
              <UserText>{commentUser?.fullName}</UserText>
              <MessageText>mentioned you in a comment to</MessageText>
              <PostAuthor userId={userId} postUser={postUser} postType={postType} />
              <MessageText>post</MessageText>
            </MessageContainer>
            <MessageContainer>{moment(createdAt).format('MMM DD')}</MessageContainer>
          </InfoContainer>
          <PostTextContainer dangerouslySetInnerHTML={{ __html: parsedText }} />
        </MainInfoContainer>
      </UserContainer>
      {(status === NOTIFICATION_TYPES.SEEN || status === NOTIFICATION_TYPES.NEW) && <ReadIndicator />}
    </MainContainer>
  );
};

export default CommentMention;
