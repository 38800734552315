import styled from 'styled-components';
import { Drawer } from 'antd';

import { ReactComponent as CaretRight } from './assets/caretRight.svg';
import { ReactComponent as MenuTabletIcon } from './assets/menuTablet.svg';

export const MobileMenuHeader = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const MobileMenuHeaderLink = styled.span`
  margin-top: 2px;
  cursor: pointer;
`;

export const MobileMenuIcon = styled(MenuTabletIcon)`
  display: block;
  margin-top: -2px;
`;

export const MobileHeaderContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(28, 37, 46, 0.121569);
  position: relative;
  z-index: 99999999;
`;

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 8px 106px;
  display: flex;
  justify-content: space-between;
`;

export const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileDrawer = styled(Drawer)`
  z-index: 9999;
  top: 40px;
  .ant-drawer-body {
    padding: 20px 0;
  }
  h2 {
    padding-left: 40px;
  }
`;

export const MobileMenuElement = styled.span`
  fill: #4a4a4a;
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 16px;
  padding: 15px 0;
  margin: 0 50px;
  border-bottom: 1px solid #eff0f1;
  justify-content: space-between;
  cursor: pointer;
  & p {
    margin: 0;
  }
  :hover {
    color: #4a4a4a;
  }
`;

export const MobileMenuProfile = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  padding-bottom: 20px;
  box-shadow: 0px 5px 6px rgba(28, 37, 46, 0.121569);
`;

export const MobileProfileTitle = styled.div`
  margin-left: 20px;

  & div {
    font-weight: 600;
    font-size: 16px;
  }
  & span {
    cursor: pointer;
    font-weight: 600;
    color: #1890ff;
  }
`;

export const ArrowRight = styled(CaretRight)`
  position: relative;
  top: 3px;
`;

export const AppsTitle = styled.h3`
  font-size: 12px;
  color: #6b778c;
  margin-top: 20px;
  padding-left: 50px;
`;

export const MobileApps = styled.ul`
  list-style: none;
  display: grid;
  padding: 20px 50px;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  & li {
    min-height: 100px;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff8e10;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const NotificationsDrawer = styled(Drawer)`
  z-index: 99999999999999;
  .ant-drawer-body > div {
    width: 100%;
    & > .rcs-custom-scroll {
      height: 85vh !important;
      padding-bottom: 100px !important;
    }
  }
`;
