import React, { FC, ReactElement } from 'react';
import { StyledInput, StyledLabel, FormItem } from '../styles';

export interface IProps {
  label?: string;
  value?: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  prefix?: ReactElement;
  suffix?: ReactElement;
  style?: any;
}

const Input: FC<IProps> = ({ value, onChange, placeholder, label, prefix: Prefix, style, suffix }) => (
  <FormItem style={style}>
    <StyledLabel hasValue={!!value}>{label}</StyledLabel>
    <StyledInput value={value} onChange={onChange} placeholder={placeholder || ''} prefix={Prefix} suffix={suffix} />
  </FormItem>
);

export default Input;
