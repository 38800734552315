import React, { FC } from 'react';
import { Popover } from 'antd';
import { useHistory } from 'react-router-dom';

import { StyledBreadcrumbs, BreadCrumbElement, MenuContainer, MenuElement, OtherUsersContainer } from '../../styles';
import { IUserReports } from '../../Profile';

interface IProps {
  data: IUserReports[];
}

const BreadCrumbs: FC<IProps> = ({ data }) => {
  const reversedData = [...data].reverse();
  const [user] = reversedData.splice(reversedData.length - 1);
  const slicedData = reversedData.slice(0, 2);
  const otherUsers = reversedData.slice(2, reversedData.length);
  const history = useHistory();

  return (
    <StyledBreadcrumbs>
      {slicedData.map((elem) => {
        const { id, job, fullName: preferredName } = elem;
        const { positionTitle } = job;

        return (
          <BreadCrumbElement key={id} onClick={() => history.push(`/profile/${id}`)}>
            {preferredName} ({positionTitle})
          </BreadCrumbElement>
        );
      })}
      {reversedData.length > 2 && (
        <BreadCrumbElement>
          <Popover
            placement="bottom"
            content={() => {
              return (
                <MenuContainer>
                  {otherUsers.map((elem) => (
                      <MenuElement key={elem.id} onClick={() => history.push(`/profile/${elem.id}`)}>
                        {elem.fullName}
                      </MenuElement>
                    ))}
                </MenuContainer>
              );
            }}
            overlayClassName={'followPopover'}
          >
            <OtherUsersContainer>...</OtherUsersContainer>
          </Popover>
        </BreadCrumbElement>
      )}
      <BreadCrumbElement key={user.id} onClick={() => history.push(`/profile/${user.id}`)}>
        {user.fullName} ({user?.job.positionTitle})
      </BreadCrumbElement>
    </StyledBreadcrumbs>
  );
};

export default BreadCrumbs;
