import React from 'react';
import { StyledFooterText, StyledLogo, Container, ColoredLink, StyledRow, StyledCol } from './styles';

const Footer = () => (
  <Container>
    <StyledRow align="middle" justify="space-between">
      <StyledCol span={12}>
        <StyledFooterText>
          {`©  ${new Date().getFullYear()} `}
          <ColoredLink href={'https://astreya.com'} target={'_blank'}>
            Astreya
          </ColoredLink>
          {`. All Rights Reserved.`}
          <ColoredLink href={'https://astreya.com/privacy-policy/'} target={'_blank'}>
            Privacy Policy
          </ColoredLink>
        </StyledFooterText>
      </StyledCol>
      <StyledCol span={12}>
        <StyledLogo />
      </StyledCol>
    </StyledRow>
  </Container>
);

export default Footer;
