import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import apolloClient from 'app/apolloClient';
import Router from 'app/Router';
import store from 'store';
import ErrorBoundary from 'modules/common/components/Error/ErrorBoundary';
import MainWrapper from 'modules/common/components/MainWrapper';

const App = () => (
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <ErrorBoundary>
          <MainWrapper>
            <Router />
          </MainWrapper>
        </ErrorBoundary>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>
);

export default App;
