import React, { FC, useMemo } from 'react';
import moment, { Moment } from 'moment';
import {
  MainModalContainer,
  PictureContainer,
  PictureFileLabel,
  PictureInput,
  SubmitButton,
  SubmitContainer,
  UploadTitleContainer,
  LabelContainer,
  DatePickerContainer,
  CompanyNewsDatePickersContainer,
  CostCentersSelectorItem,
  UploadPictureContainer,
  UploadPictureElementContainer,
  UploadPictureElement,
  StyledDeletePhotoImage,
  SmallPictureFileLabel,
  UploadTitleSmallContainer,
  WarningText,
  CostCentersSelectorContainer,
  CompanyNewsInput as HeadlineInput,
  FeaturedContainer,
  StyledSwitch,
  SelectorImage,
  UploadPdfElement,
  StyledAttachmentImage,
} from '../styled';
import { IEditableData } from '../MainContent';
import { IUserModel } from 'modules/common/gql/models/user.model';
import CostCenterIcon from '../../../assets/createPostModal/teamIcon.png';
import { ICostCenter } from '../../../Feed';
import { ReactComponent as UploadImage } from 'modules/news-feed/pages/Feed/assets/createPostModal/uploadImage.svg';
import DatePicker, { disablePreviousDate } from 'modules/common/components/forms/Datepicker';
import Editor from 'modules/common/components/forms/RichTextEditor';
import { FilesType } from '../MainContent';
import Spinner from 'modules/common/components/Spinner';
import { NF_PERMISSIONS } from 'modules/auth/constants';
import { getFileExtension } from 'modules/news-feed/pages/Feed/utils';
import Pdf from './PdfContainer';

interface IProps {
  editableValue: IEditableData;
  changeFileHandler: (file: FileList | null) => void;
  submitHandler: () => void;
  user: IUserModel;
  setValueHandler: (value: string | boolean, fieldName: string) => void;
  setDateTimeHandler: (value: Moment | string | null, fieldName: string) => void;
  costCenters: ICostCenter[];
  deleteNotUploadedImage: (index: number) => void;
  prepareMentionsData: (query: string, cb: Function) => void;
  onChangeTextNewsFeed: (value: string, editor?: any) => void;
  deleteUploadedImage: (fileId: string) => void;
  isImagesListInvalid: boolean;
  isBlockedSubmit: boolean;
  submitButtonBlock: () => void;
  filteredImages: FilesType[];
  isPdfListInvalid: boolean;
  isInValidImage: boolean;
  isInValidPdf: boolean;
}

const TeamNews: FC<IProps> = ({
  costCenters,
  changeFileHandler,
  setDateTimeHandler,
  editableValue,
  setValueHandler,
  submitHandler,
  user,
  deleteNotUploadedImage,
  prepareMentionsData,
  onChangeTextNewsFeed,
  deleteUploadedImage,
  isImagesListInvalid,
  isBlockedSubmit,
  submitButtonBlock,
  filteredImages,
  isPdfListInvalid,
  isInValidImage,
  isInValidPdf,
}) => {
  const { permissionIds = [] } = user;
  const { text, publishAt, costCenterId, headline, isFeatured, featuredExpAt } = editableValue;

  const isDataValid = useMemo(() => {
    const withContent = (text.length || filteredImages.length > 0) && !!headline.length;
    const isFeaturedValid = (isFeatured && featuredExpAt) || !isFeatured;
    return withContent && isFeaturedValid;
  }, [editableValue, filteredImages]);

  const setEmptyValue = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element: any = event.target;
    element.value = '';
  };

  if (!costCenters.length) {
    return null;
  }

  return (
    <MainModalContainer>
      {costCenters.length > 1 ? (
        <CostCentersSelectorContainer>
          {costCenters.map((elem, i) => {
            const { id, name } = elem;
            return (
              <>
                <CostCentersSelectorItem
                  key={i}
                  $active={costCenterId === id}
                  onClick={() => setValueHandler(id, 'costCenterId')}
                >
                  <SelectorImage src={CostCenterIcon} /> {name}
                </CostCentersSelectorItem>
              </>
            );
          })}
        </CostCentersSelectorContainer>
      ) : null}
      <HeadlineInput placeholder={'Headline'} value={headline} onChange={(e) => setValueHandler(e.target.value, 'headline')} />
      <Editor
        value={text}
        onChange={onChangeTextNewsFeed}
        hasMarkupPermission={permissionIds.includes(NF_PERMISSIONS.RICH_MARKUP)}
      />
      <FeaturedContainer>
        <LabelContainer>
          Featured Post
          <StyledSwitch checked={isFeatured} onChange={(isChecked) => setValueHandler(isChecked, 'isFeatured')} />
        </LabelContainer>
        {isFeatured && (
          <DatePickerContainer>
            <DatePicker
              placeholder={'Featured Expiration'}
              style={{ width: '100%', marginTop: '0' }}
              showTime={true}
              onChange={(value) => setDateTimeHandler(value, 'featuredExpAt')}
              value={featuredExpAt}
              allowClear={true}
              disabledDate={disablePreviousDate}
              defaultValue={moment().add(7, 'day')}
              useLocalTimezone={true}
            />
          </DatePickerContainer>
        )}
      </FeaturedContainer>
      <CompanyNewsDatePickersContainer>
        <DatePickerContainer>
          <DatePicker
            label={'Schedule a Post'}
            placeholder={'Schedule a Post'}
            style={{ width: '100%', marginTop: '0' }}
            showTime
            value={publishAt}
            onChange={(value) => setDateTimeHandler(value, 'publishAt')}
            allowClear={true}
            disabledDate={disablePreviousDate}
          />
        </DatePickerContainer>
      </CompanyNewsDatePickersContainer>
      <PictureContainer>
        {filteredImages.length > 0 ? (
          <UploadPictureContainer>
            {filteredImages.map((elem, key) => {
              const extension = getFileExtension((elem instanceof File ? elem.name : elem?.fileName) || 'png');

              if (extension === 'pdf') {
                return (
                  <UploadPictureElementContainer key={key}>
                    <UploadPdfElement>
                      <StyledDeletePhotoImage
                        onClick={() => (elem instanceof File ? deleteNotUploadedImage(key) : deleteUploadedImage(elem.fileId))}
                      />
                      <StyledAttachmentImage />
                      <Pdf src={elem} scale={0.1} page={1} />
                    </UploadPdfElement>
                  </UploadPictureElementContainer>
                );
              }

              return (
                <UploadPictureElementContainer key={key}>
                  <UploadPictureElement>
                    <StyledDeletePhotoImage
                      onClick={() => (elem instanceof File ? deleteNotUploadedImage(key) : deleteUploadedImage(elem.fileId))}
                    />
                    <img src={elem instanceof File ? URL.createObjectURL(elem) : elem.fileUrl} />
                  </UploadPictureElement>
                </UploadPictureElementContainer>
              );
            })}
            <SmallPictureFileLabel htmlFor={'pictureUploaded'}>
              <UploadTitleSmallContainer>
                <UploadImage />
                <p>Add photos or PDFs</p>
              </UploadTitleSmallContainer>
            </SmallPictureFileLabel>
          </UploadPictureContainer>
        ) : (
          <PictureFileLabel htmlFor={'pictureUploaded'}>
            <UploadTitleContainer>
              <UploadImage />
              <p>Add photos or PDFs</p>
            </UploadTitleContainer>
          </PictureFileLabel>
        )}
        <PictureInput
          accept="image/x-png,image/gif,image/jpeg,application/pdf"
          multiple={true}
          type={'file'}
          id={'pictureUploaded'}
          onChange={(e) => changeFileHandler(e.target.files)}
          onClick={setEmptyValue}
        />
      </PictureContainer>
      <SubmitContainer>
        <SubmitButton
          type="primary"
          onClick={() => {
            submitButtonBlock();
            submitHandler();
          }}
          disabled={!isDataValid || isInValidImage || isInValidPdf || isImagesListInvalid || isBlockedSubmit}
        >
          {isBlockedSubmit ? <Spinner color={'#1890FF'} /> : 'Post'}
        </SubmitButton>
      </SubmitContainer>
      {isInValidImage && <WarningText>You can upload JPEG, PNG or GIF files. File size limit is 5 Mb.</WarningText>}
      {isInValidPdf && <WarningText>Pdf file size limit is 10 Mb.</WarningText>}
      {isImagesListInvalid && <WarningText>Maximum images per post - 10 images. Please delete redundant images.</WarningText>}
      {isPdfListInvalid && <WarningText>Maximum pdf files per post - 5. Please delete redundant files.</WarningText>}
    </MainModalContainer>
  );
};

export default TeamNews;
