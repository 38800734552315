import styled from 'styled-components';

import { ReactComponent as SearchIcon } from 'modules/profile/assets/images/search.svg';

interface IHeaderCard {
  isArticleType?: boolean;
}

export const HeaderCard = styled.div<IHeaderCard>`
  width: 900px;
  padding: 14px 20px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
  border-radius: 40px;
`;

export const InputContainer = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #111111;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SearchInput = styled.input`
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  border: none;
  outline: none;
  width: 90%;
  height: 30px;

  ::placeholder {
    color: #6b778c;
  }
`;

export const SearchButton = styled.div`
  background: #1890ff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  color: #fff;
  user-select: none;
  cursor: pointer;
  width: 100px;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 10px;
`;
