import React, { Fragment, FC, useEffect, useMemo, useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { message, Collapse } from 'antd';
import { useHistory } from 'react-router-dom';
import pluralize from 'pluralize';

import PostCard from '../PostCard';
import { INewsFeedPostModel } from 'modules/common/gql/models/newsFeedPost.model';
import { INewsFeedPostResult } from '../../Feed';
import { IAuthorUser } from '../MainContent/MainContent';
import { namedGetNewsFeedPosts } from 'modules/news-feed/gql/query';
import { POST_TYPES } from '../../constants';
import {
  namedNewsFeedPostsAddedSubscription,
  namedNewsFeedPostsUpdatedSubscription,
  namedNewsFeedPostsDeletedSubscription,
  ON_COMMENT_UPDATED,
  ON_COMMENT_ADDED,
  ON_COMMENT_DELETED,
} from 'modules/news-feed/gql/subscription';
import { SpinnerContainer } from '../../styles';
import Spinner from 'modules/common/components/Spinner';
import { HIDE_POST, UNHIDE_POST } from '../../../../gql/mutation';
import {
  HeaderFeature,
  MainContainerHeaderFeature,
  HideButton,
  StyledFeaturedPostsIcon,
  CaretCollapsedIcon,
  CollapseItem,
} from '../../styles';
import { IBadge } from '../MainContent/MainContent';
import { emitViewPost, mapPostTypes, emitHidePost } from 'utils/mixpanel';

export interface IProps {
  editHandler: (id: string, posts: any) => void;
  followedUsers: IAuthorUser[];
  followHandler: (id: string) => void;
  unfollowHandler: (id: string) => void;
  userId: string;
  photoUrl: string;
  postTypes: POST_TYPES[];
  badgesData: IBadge[];
}

const SocialFeed: FC<IProps> = ({
  editHandler,
  followedUsers,
  followHandler,
  unfollowHandler,
  userId,
  photoUrl,
  postTypes,
  badgesData,
}) => {
  const history = useHistory();
  const [isFetchMoreBlock, setFetchMoreBlock] = useState(false);
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [scrollToPostId, setScrollToPostId] = useState('');
  const loadingDivRef = useRef<HTMLDivElement>(null);
  const [changedIdFollowPopover, setChangedIdFollowPopover] = useState<string | null>(null);
  const newsFeedQuery = useQuery<INewsFeedPostResult>(namedGetNewsFeedPosts('social_news'), {
    variables: {
      limit: 5,
      postTypes,
    },
    onError: (err: Error) => message.error(err),
  });
  const [hidePost] = useMutation(HIDE_POST, {
    onError: (err) => message.error(err),
  });

  const [unhidePostMutation] = useMutation(UNHIDE_POST, {
    onError: (err) => message.error(err),
  });

  const data = newsFeedQuery?.data?.newsFeedPosts?.data;
  const pagination = newsFeedQuery?.data?.newsFeedPosts?.pagination;

  const hiddenPosts = useMemo(() => data?.filter((elem) => elem.isHidden) || [], [data]);

  useEffect(() => {
    newsFeedQuery.refetch();
  }, []);

  const callback = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting || !pagination || !pagination.nextCursor || isFetchMoreBlock) {
      return;
    }
    setFetchMoreBlock(true);
    newsFeedQuery.fetchMore({
      variables: {
        limit: 5,
        nextCursor: pagination.nextCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setFetchMoreBlock(false);
        if (!fetchMoreResult) {
          return previousResult;
        }
        const initialData = previousResult?.newsFeedPosts?.data || [];
        return Object.assign({}, previousResult, {
          newsFeedPosts: {
            ...previousResult.newsFeedPosts,
            data: [...initialData, ...fetchMoreResult.newsFeedPosts.data],
            pagination: fetchMoreResult.newsFeedPosts.pagination,
          },
        });
      },
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(callback, options);

    if (loadingDivRef.current) {
      observer.unobserve(loadingDivRef.current);
      observer.observe(loadingDivRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [loadingDivRef.current, newsFeedQuery, isFetchMoreBlock]);

  useEffect(() => {
    // ADDING POST SUBSCRIPTION
    newsFeedQuery.subscribeToMore({
      document: namedNewsFeedPostsAddedSubscription('social_news'),
      updateQuery: (prev, { subscriptionData }) => {
        const isValidRes = subscriptionData?.data && postTypes.includes(subscriptionData.data.onNewsFeedPostAdded.type);
        if (!isValidRes) {
          return prev;
        }
        const newFeedItem = subscriptionData.data.onNewsFeedPostAdded;

        return Object.assign({}, prev, {
          newsFeedPosts: {
            ...prev.newsFeedPosts,
            data: [newFeedItem, ...(prev?.newsFeedPosts?.data || [])],
          },
        });
      },
    });
    // UPDATE POST SUBSCRIPTION
    newsFeedQuery.subscribeToMore({
      document: namedNewsFeedPostsUpdatedSubscription('social_news'),
      updateQuery: (prev, { subscriptionData }) => {
        const isValidRes = subscriptionData?.data && postTypes.includes(subscriptionData.data.onNewsFeedPostUpdated.type);
        if (!isValidRes) {
          return prev;
        }
        const newFeedItem = subscriptionData.data.onNewsFeedPostUpdated;
        const { _id } = newFeedItem;
        const updatedNewsFeedData = JSON.parse(JSON.stringify([...prev.newsFeedPosts.data]));
        const updatedIndex = updatedNewsFeedData.findIndex((elem) => elem._id === _id);

        if (updatedIndex !== -1) {
          updatedNewsFeedData[updatedIndex] = newFeedItem;
          return Object.assign({}, prev, {
            newsFeedPosts: {
              ...prev.newsFeedPosts,
              data: updatedNewsFeedData,
            },
          });
        }
        return prev;
      },
    });

    // REMOVE POST SUBSCRIPTION
    newsFeedQuery.subscribeToMore({
      document: namedNewsFeedPostsDeletedSubscription('social_news'),
      updateQuery: (prev, { subscriptionData }) => {
        const isValidRes = subscriptionData?.data && postTypes.includes(subscriptionData.data.onNewsFeedPostDeleted.type);
        if (!isValidRes) {
          return prev;
        }
        const newFeedItem = subscriptionData.data.onNewsFeedPostDeleted;
        const { _id } = newFeedItem;
        const updatedNewsFeedData = JSON.parse(JSON.stringify([...(prev?.newsFeedPosts?.data || [])]));
        const indexOfDeleteItem = updatedNewsFeedData.findIndex((elem) => elem._id === _id);

        if (indexOfDeleteItem !== -1) {
          updatedNewsFeedData.splice(indexOfDeleteItem, 1);

          return Object.assign({}, prev, {
            newsFeedPosts: {
              ...prev.newsFeedPosts,
              data: updatedNewsFeedData,
            },
          });
        }
        return prev;
      },
    });
    // COMMENT ADD SUBSCRIPTION
    newsFeedQuery.subscribeToMore({
      document: ON_COMMENT_ADDED,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedComment = subscriptionData?.data?.onNewsFeedCommentAdded;
        let feedDataClone = JSON.parse(JSON.stringify(prev));

        if (!updatedComment) {
          return prev;
        }
        const { postId, parentCommentId } = updatedComment;

        const changedFeed = feedDataClone.newsFeedPosts.data.find((elem) => elem._id === postId);

        if (changedFeed) {
          if (parentCommentId) {
            const changedParentComment = changedFeed.comments.data.find((elem) => elem._id === parentCommentId);
            const changedComment = changedParentComment.comments.data.find((elem) => elem._id === updatedComment._id);

            if (changedComment) {
              return prev;
            }

            if (changedParentComment) {
              changedParentComment.comments.data.push(updatedComment);
            }
          } else {
            const changedComment = changedFeed.comments.data.find((elem) => elem._id === updatedComment._id);

            if (changedComment) {
              return prev;
            }

            changedFeed.comments.data.push(updatedComment);
          }

          return Object.assign({}, feedDataClone);
        }

        return prev;
      },
    });
    // COMMENT UPDATE SUBSCRIPTION
    newsFeedQuery.subscribeToMore({
      document: ON_COMMENT_UPDATED,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedComment = subscriptionData?.data?.onNewsFeedCommentUpdated;
        const feedDataClone = JSON.parse(JSON.stringify(prev));

        if (!updatedComment) {
          return prev;
        }
        const { postId, _id, parentCommentId } = updatedComment;

        const changedFeed = feedDataClone.newsFeedPosts.data.find((elem) => elem._id === postId);

        if (changedFeed) {
          const changedCommentIndex = changedFeed.comments.data.findIndex((elem) => elem._id === _id);

          if (changedCommentIndex !== -1) {
            changedFeed.comments.data.splice(changedCommentIndex, 1, updatedComment);
          } else {
            const changedParentComment = changedFeed.comments.data.find((elem) => elem._id === parentCommentId);

            if (changedParentComment) {
              const changedCommentIndex = changedParentComment.comments.data.findIndex((elem) => elem._id === _id);

              changedParentComment.comments.data.splice(changedCommentIndex, 1, updatedComment);
            }
          }

          return Object.assign({}, feedDataClone);
        }

        return prev;
      },
    });
    // COMMENT DELETE SUBSCRIPTION
    newsFeedQuery.subscribeToMore({
      document: ON_COMMENT_DELETED,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedComment = subscriptionData?.data?.onNewsFeedCommentDeleted;
        const feedDataClone = JSON.parse(JSON.stringify(prev));

        if (!updatedComment) {
          return prev;
        }
        const { postId, _id, parentCommentId } = updatedComment;

        const changedFeed = feedDataClone.newsFeedPosts.data.find((elem) => elem._id === postId);

        if (changedFeed) {
          const changedCommentIndex = changedFeed.comments.data.findIndex((elem) => elem._id === _id);

          if (changedCommentIndex !== -1) {
            changedFeed.comments.data.splice(changedCommentIndex, 1);
          } else {
            const changedParentComment = changedFeed.comments.data.find((elem) => elem._id === parentCommentId);

            if (changedParentComment) {
              const changedCommentIndex = changedParentComment.comments.data.findIndex((elem) => elem._id === _id);

              changedParentComment.comments.data.splice(changedCommentIndex, 1);
            }
          }

          return Object.assign({}, feedDataClone);
        }

        return prev;
      },
    });
  }, []);

  useEffect(() => {
    if (scrollToPostId) {
      setTimeout(() => {
        scrollToRef?.current?.scrollIntoView({ behavior: 'smooth' });
        setScrollToPostId('');
      }, 500);
    }
  }, [scrollToPostId, scrollToRef.current]);

  const onChangeFeedId = (id: string, key: number | null, isOpenedText?: boolean) => {
    const data = newsFeedQuery.data?.newsFeedPosts.data || [];
    const post = data.find((e) => e._id === id);
    if (post?.type) {
      emitViewPost({ postType: mapPostTypes(post.type) });
    }
    if (isOpenedText) {
      history.push(`news-feed?changedFeedId=${id}&previewModalIsOpenedText=true`);
      return;
    }

    history.push(`news-feed?changedFeedId=${id}`);
  };

  const closeFollowPopover = () => {
    setChangedIdFollowPopover(() => null);
  };

  const openFollowPopover = (id: string) => {
    setChangedIdFollowPopover(() => id);
  };

  const hidePostHandler = (id: string, type: string) => {
    hidePost({
      variables: {
        postId: id,
      },
    });
    emitHidePost({ postType: mapPostTypes(type) });
  };

  const unhidePost = (id: string) => {
    unhidePostMutation({
      variables: {
        postId: id,
      },
    });
    setScrollToPostId(id);
  };

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      {!!hiddenPosts.length && (
        <MainContainerHeaderFeature ghost>
          <Collapse.Panel
            header={
              <HeaderFeature>
                <StyledFeaturedPostsIcon /> {pluralize('Collapsed Featured Post', hiddenPosts.length, true)}
              </HeaderFeature>
            }
            key="1"
            showArrow={false}
            extra={<CaretCollapsedIcon />}
          >
            {hiddenPosts.map((post) => (
              <CollapseItem key={post._id}>
                <HeaderFeature>
                  <StyledFeaturedPostsIcon /> <p>{`${post.headline}`}</p>
                  <HideButton onClick={() => unhidePost(post._id)}>Show</HideButton>
                </HeaderFeature>
              </CollapseItem>
            ))}
          </Collapse.Panel>
        </MainContainerHeaderFeature>
      )}
      {data.map((post: INewsFeedPostModel) => {
        const {
          _id,
          files,
          text,
          plainText,
          createdAt,
          isFeatured,
          mentions,
          publishingDate,
          headline,
          featuredExpAt,
          type,
          targetEmployees,
          isHidden,
          badge,
          costCenter,
          comments,
          author,
          commentsCount,
          reactions,
          mostReactedComment,
          mostRepliedComment,
          directCommentsCount,
        } = post;
        const isAuthor = author?.id === userId;

        if (isHidden) {
          return null;
        }
        const ref = scrollToPostId === _id ? scrollToRef : null;
        return (
          <PostCard
            postRef={ref}
            isAuthor={isAuthor}
            key={_id}
            plainText={plainText || ''}
            text={text || ''}
            type={type}
            author={author}
            files={files}
            mentions={mentions || null}
            reactions={reactions || {}}
            id={_id || ''}
            commentsData={comments}
            createdAt={createdAt || ''}
            editHandler={(id) => editHandler(id, data)}
            onChangeFeedId={onChangeFeedId}
            isFeatured={isFeatured}
            headline={[POST_TYPES.COMPANY_NEWS, POST_TYPES.TEAM_NEWS].includes(type) ? headline : undefined}
            changedIdFollowPopover={changedIdFollowPopover}
            closeFollowPopover={closeFollowPopover}
            openFollowPopover={openFollowPopover}
            followedUsers={followedUsers}
            followHandler={followHandler}
            unfollowHandler={unfollowHandler}
            userId={userId}
            publishingDate={publishingDate}
            photoUrl={photoUrl}
            featuredExpAt={[POST_TYPES.COMPANY_NEWS, POST_TYPES.TEAM_NEWS].includes(type) ? featuredExpAt : undefined}
            hidePost={hidePostHandler}
            targetEmployees={targetEmployees || []}
            badge={badge}
            costCenter={costCenter}
            badgesData={badgesData}
            commentsCount={commentsCount}
            newsFeedQuery={newsFeedQuery}
            mostReactedComment={mostReactedComment}
            mostRepliedComment={mostRepliedComment}
            directCommentsCount={directCommentsCount}
          />
        );
      })}
      <SpinnerContainer ref={loadingDivRef} isLoadedAllData={!pagination || !pagination.nextCursor}>
        {pagination && pagination.nextCursor && <Spinner width={30} height={30} />}
      </SpinnerContainer>
    </Fragment>
  );
};

export default SocialFeed;
