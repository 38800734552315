import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($accessToken: String!) {
    loginSso(accessToken: $accessToken) {
      accessToken
      refreshToken
      refreshExpires
      user {
        id
        permissionIds
        createdAt
        roles {
          permissions {
            id
            name
          }
        }
      }
    }
  }
`;

export const LOGIN_BY_EMAIL_MUTATION = gql`
  mutation LoginByEmail($email: String!) {
    loginByEmail(email: $email) {
      accessToken
      refreshToken
      refreshExpires
      user {
        id
        permissionIds
        createdAt
        roles {
          permissions {
            id
            name
          }
        }
      }
    }
  }
`;

export const REFRESH_AUTH_TOKEN_MUTATION = gql`
  mutation RefreshAuthToken($refreshToken: String!) {
    refreshAuthToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      refreshExpires
    }
  }
`;
