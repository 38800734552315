import { joinPath } from 'utils/path';
import appRoutes from 'app/routes';

export const footerElements = [
  {
    title: 'Powered by OneLogin',
    link: '/',
  },
  {
    title: 'Terms',
    link: '/',
  },
  {
    title: 'Privacy Policy',
    link: '/',
  },
];

export enum NF_PERMISSIONS {
  MANAGE_COMPANY_NEWS = 'nf_manage_company_news',
  MANAGE_TEAM_NEWS = 'nf_manage_team_news',
  MANAGE_ALL_TEAM_NEWS = 'nf_manage_all_team_news',
  MANAGE_HR_NEW_HIRES = 'nf_manage_hr_new_hires',
  MANAGE_HR_BIRTHDAY_WISHES = 'nf_manage_hr_birthday_wishes',
  MANAGE_HR_PROMOTION = 'nf_manage_hr_promotion',
  MANAGE_HR_WORK_ANNIVERSARY = 'nf_manage_hr_work_anniversary',
  MANAGE_INDIVIDUAL_POSTS = 'nf_manage_individual_posts',
  MANAGE_THANKS = 'nf_manage_thanks',
  MANAGE_COMMENTS = 'nf_manage_comments',
  RICH_MARKUP = 'nf_use_rich_markup',
  NAVIGATE_TO_BACK_OFFICE = 'navigate_to_back_office',
  NAVIGATE_TO_CRM = 'navigate_to_crm',
  NAVIGATE_TO_PEAKON = 'navigate_to_peakon',
}

export const NF_PERMISSIONS_TO_SKIP = [NF_PERMISSIONS.MANAGE_INDIVIDUAL_POSTS, NF_PERMISSIONS.MANAGE_THANKS];

export enum EMP_PROF_PERMISSIONS {
  MANAGE_WITHIN_OWN_COST_CENTER = 'emp_prof_edit_within_own_cost_c',
  MANAGE_BEYOND_OWN_COST_CENTER = 'emp_prof_edit_beyond_own_cost_c',
}

export const ADMIN_ACCESS_PERMISSION = NF_PERMISSIONS.NAVIGATE_TO_BACK_OFFICE;

export const authRoutes = {
  login: joinPath(appRoutes.auth, 'login'),
  loginRedirect: joinPath(appRoutes.auth, 'login-redirect'),
  impersonalize: joinPath(appRoutes.auth, 'impersonalize'),
};
