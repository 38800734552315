import React, { useCallback, useMemo, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { loginRedirectSuccess, loginRedirectFailure } from 'store/domains/auth/auth.actions';

interface IParams {
  access_token?: string;
}

const LoginRedirect: React.FC = () => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const history = useHistory();
  const parseHash = useCallback(() => {
    const splitedHash = hash.substr(1).split('&');
    return splitedHash.reduce((acc, item) => {
      const res: any = acc;
      const parts = item.split('=');
      const [key, value] = parts;
      res[key] = value;
      return res;
    }, {});
  }, [hash]);

  const hashObject: IParams = useMemo(() => parseHash(), [hash]);
  const { access_token } = hashObject;

  useEffect(() => {
    if (access_token) {
      dispatch(loginRedirectSuccess(access_token, history));
    } else {
      message.error('Login failed, please try again.');
      dispatch(loginRedirectFailure());
    }
  }, [access_token, dispatch]);

  return <div>Redirecting...</div>;
};

export default LoginRedirect;
