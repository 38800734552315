import React, { FC, useMemo, useState, useCallback, FunctionComponent, Fragment, Ref, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { message, Image } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery, QueryResult, OperationVariables } from '@apollo/client';
import CustomScroll from 'react-custom-scroll';
import 'react-custom-scroll/dist/customScroll.css';
import pluralize from 'pluralize';

import {
  Header,
  HeaderContainer,
  StyledFeaturedPostsIcon,
  InfoContainer,
  UserContainer,
  UserNameContainer,
  Name,
  SubName,
  DescriptionContainer,
  Card,
  Container,
  MainReactionsContainer,
  ReactionContainer,
  ReactionsContainer,
  Button,
  ButtonsContainer,
  StyledDropdown,
  HeadlineContainer,
  StyledAvatar,
  StyledFollowPopover,
  ViewReactionsButton,
  Menu,
  MainDescriptionContainer,
  SliceButton,
  NameContainer,
  MainContainerHeader,
  HideButton,
  BehalfContainer,
  ThanksClap,
  IconContainer,
  ThanksDisclaimer,
  RecognitionBody,
  RecognitionCard,
  BehalfTeamNewsContainer,
  BehalfHeaderContainer,
  BehalfTeamNewsHeaderContainer,
  CostCenterImage,
  Comma,
  StyledAnd,
  CommentInputContainer,
  CommentsContainer,
  CommentsMainContainer,
  StyledLike,
  ShowMoreButton,
} from './styles';
import { company } from '../../constants';
import { POST_TYPES, reactionsTypes } from 'modules/common/const';
import { profileRoutes } from 'modules/profile/config/Profile.router';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import ReactionsPopover from './components/ReactionsPopover';
import EditPopover from './components/EditPopover';
import ImagesContainer from './components/ImagesContainer';
import FollowPopover from 'modules/common/components/popovers/FollowPopover';
import DeleteModal from 'modules/common/components/DeleteModal';
import ViewReactionsModal from '../modals/ViewReactionsModal';
import {
  DELETE_NEWS_FEED_POST,
  SET_NEWS_FEED_POST_REACTION,
  UNSET_NEWS_FEED_POST_REACTION,
} from 'modules/news-feed/gql/mutation';
import { postTypes } from 'modules/news-feed/pages/Feed/constants';
import { IUploadedFile, IPostAuthor, ICommentModel } from 'modules/common/gql/models/newsFeedPost.model';
import { IAuthorUser } from '../MainContent/MainContent';
import { IMentionModel } from 'modules/common/gql/models/mention.model';
import { EDITOR_CONFIG } from 'app/tinymce';
import { RootState } from 'store/rootReducer';
import { UserState } from 'store/domains/user/user.types';
import { ReactComponent as HidePostIcon } from '../../assets/hidePost.svg';
import HrPostCard from '../HrPostCard';
import TargetEmployees from './components/TargetEmployees';
import { ITargetEmployeesModel } from 'modules/common/gql/models/targetEmployees.model';
import { ICostCenter } from 'modules/common/gql/models/newsFeedPost.model';
import CostCenterIcon from 'modules/news-feed/pages/Feed/assets/createPostModal/teamIcon.png';
import { IBadge } from '../MainContent/MainContent';
import { ReactComponent as Comment } from '../../assets/comment.svg';
import Comments from '../Comments';
import { IComment } from 'modules/common/gql/models/newsFeedPost.model';
import CommentsInput from '../MainContent/components/CommentsInput';
import { emitReactPost, mapPostTypes, emitViewPost } from 'utils/mixpanel';
import { CommentsControlContainer, Point, CancelButton } from '../MainContent/components/PreviewModal/styles';
import { GET_USER_BY_ID } from 'modules/profile/gql/query';
import { IUser } from 'modules/team/pages/ClubView/ClubView';
import { INewsFeedPostResult } from 'modules/news-feed/pages/Feed/Feed';
import { parseDaysToWeeks } from '../../utils';
import { IReaction as IReactionFeed } from 'modules/common/gql/models/newsFeedPost.model';
import { getPostReactions } from '../Comments/utils';
import apolloClient from 'app/apolloClient';
import { GET_COMMENTS_BY_ID } from '../../../../gql/query';

export interface IReaction {
  type: string;
  count: number;
}

interface IProps {
  text: string;
  plainText: string;
  author: IPostAuthor;
  files: IUploadedFile[];
  mentions: IMentionModel[] | null;
  targetEmployees: ITargetEmployeesModel[];
  id: string;
  type: string;
  commentsData: ICommentModel;
  createdAt: string;
  editHandler: (id: string) => void;
  onChangeFeedId: (id: string, key: number | null, isOpenedText?: boolean) => void;
  reactions: IReactionFeed;
  isFeatured?: boolean;
  headline?: string;
  changedIdFollowPopover: string | null;
  closeFollowPopover: () => void;
  openFollowPopover: (id: string) => void;
  followedUsers: IAuthorUser[];
  followHandler: (id: string) => void;
  unfollowHandler: (id: string) => void;
  userId: string;
  publishingDate: string;
  photoUrl: string;
  featuredExpAt?: string;
  isAuthor: boolean;
  hidePost: (id: string, type: string) => void;
  badge?: string;
  costCenter?: ICostCenter;
  badgesData: IBadge[];
  postRef: Ref<HTMLDivElement> | null;
  commentsCount: number;
  newsFeedQuery: QueryResult<INewsFeedPostResult, OperationVariables>;
  mostReactedComment: IComment | null;
  mostRepliedComment: IComment | null;
  directCommentsCount: number;
}

interface IReactionData {
  background: string;
  image: FunctionComponent;
  type: string;
}

interface IUserByIdResult {
  user: IUser;
}

const LONG_TEXT_LENGTH = 148;

const PostCard: FC<IProps> = ({
  text,
  plainText,
  author,
  files,
  reactions,
  id,
  type,
  editHandler,
  onChangeFeedId,
  isFeatured,
  headline,
  followedUsers,
  followHandler,
  unfollowHandler,
  userId,
  publishingDate,
  featuredExpAt,
  isAuthor,
  hidePost,
  targetEmployees,
  badge,
  costCenter,
  badgesData,
  photoUrl,
  commentsData,
  postRef,
  commentsCount,
  newsFeedQuery,
  mentions,
  mostReactedComment,
  mostRepliedComment,
  directCommentsCount,
}) => {
  const changedCommentRef = useRef<HTMLDivElement | null>(null);
  const [changedCommentForScroll, setChangedCommentForScroll] = useState<string | null>(null);
  const [scrollToComments, setScrollToComments] = useState<number | undefined>(undefined);
  const { data: comments } = commentsData;
  const [getProfileUser, { data: userById }] = useLazyQuery<IUserByIdResult>(GET_USER_BY_ID, {
    onError: (err: Error) => message.error(err),
    fetchPolicy: 'network-only',
  });
  const targetEmployeesLength = targetEmployees?.length;
  const editorRef = useRef<any>(null);
  const daysDifference = useMemo(() => moment().diff(moment(publishingDate), 'days'), [publishingDate]);

  const filteredComments = useMemo(() => {
    if (!comments) {
      return [];
    }

    let commentsClone = [...comments];

    if (mostReactedComment) {
      commentsClone = [mostReactedComment, ...commentsClone];
    }
    if (mostRepliedComment) {
      commentsClone = [mostRepliedComment, ...commentsClone];
    }

    const uniqueComments: IComment[] = [];

    commentsClone.forEach((elem) => {
      if (!uniqueComments.some((comment) => comment._id === elem._id)) {
        uniqueComments.push(elem);
      }
    });

    return uniqueComments;
  }, [mostReactedComment, mostRepliedComment, comments]);

  const showMoreCommentsCount = useMemo(() => directCommentsCount - comments.length, [directCommentsCount, comments]);

  const parsedTargetEmployees = useMemo(() => {
    if (!targetEmployees?.length) {
      return null;
    }
    const targetEmployeesClone = [...targetEmployees];

    const index = targetEmployeesClone.findIndex((elem) => elem.userId === userId);

    if (index !== -1) {
      const [deletedElement] = targetEmployeesClone.splice(index, 1);
      targetEmployeesClone.unshift(deletedElement);
    }

    return targetEmployeesClone;
  }, [targetEmployees]);
  const badgeElement = useMemo(() => {
    const badgeElement = badgesData.find((elem) => elem._id === badge);
    return badgeElement;
  }, [badge, badgesData]);
  const isFuturePostStatus = useMemo(
    () => isFeatured && (featuredExpAt ? moment().isBefore(featuredExpAt) : true),
    [isFeatured, featuredExpAt],
  );
  const [changedCommentId, setChangedCommentId] = useState<null | string>(null);
  const [replyCommentId, setReplyCommentId] = useState<null | string>(null);
  //TEMPORARY!
  const [isOpenedComments, setOpenedComments] = useState<boolean>(true);
  const isLongText = useMemo(() => {
    return plainText.length > LONG_TEXT_LENGTH;
  }, [plainText]);
  const addMentionsToText = (text) => {
    let res = text;

    if (mentions?.length) {
      mentions.forEach((m) => {
        res = res.replace(
          new RegExp(m.fullName, 'g'),
          `<span class="post_mention" contenteditable="false" data-mention-name="${m.fullName}" data-mention-id="${m.userId}">${m.fullName}</span>`,
        );
      });
    }

    return res;
  };
  const htmlToShow = useMemo(() => {
    let res = text;
    if (isLongText) {
      let subText = plainText.substring(0, LONG_TEXT_LENGTH);
      subText = addMentionsToText(subText) + '...';
      res = `<span>${subText}</span>`;
    }

    return res;
  }, [isLongText, plainText, text]);
  const history = useHistory();
  const [isOpenCancelPopover, setOpenCancelPopover] = useState(false);
  const [deletePost] = useMutation(DELETE_NEWS_FEED_POST, {
    onError: (err) => message.error(err),
  });
  const [setReaction] = useMutation(SET_NEWS_FEED_POST_REACTION, {
    onError: () => message.error('Error during setting reaction'),
  });
  const [unsetReaction] = useMutation(UNSET_NEWS_FEED_POST_REACTION, {
    onError: () => message.error('Error during setting reaction'),
  });
  const [isVisibleReactionsModal, setIsVisibleReactionsModal] = useState(false);

  const getProfileSubmit = () => {
    getProfileUser({
      variables: {
        id: author.id,
      },
    });
  };

  const getProfileCancel = () => {
    getProfileUser({
      variables: {
        id: null,
      },
    });
  };

  const postReactions: IReaction[] = useMemo(() => getPostReactions(reactions), [reactions]);

  const bestReactions = useMemo(
    () => postReactions.sort((firstElem, secondElem) => secondElem.count - firstElem.count).slice(0, 3),
    [postReactions],
  );
  const allReactions = useMemo(() => {
    return postReactions.reduce((acc, current) => acc + current.count, 0);
  }, [postReactions]);
  const [isVisibleOverlay, setIsVisibleOverlay] = useState(false);
  const handleVisibleChange = useCallback((flag) => setIsVisibleOverlay(flag), [isVisibleOverlay, setIsVisibleOverlay]);
  const closeReactionsDropdown = useCallback(() => setIsVisibleOverlay(false), [setIsVisibleOverlay]);
  const deletePostHandler = useCallback(() => {
    deletePost({ variables: { postId: id } });
  }, [id]);
  const setReactionHandler = useCallback(
    (reactionType: string) => {
      setReaction({ variables: { postId: id, type: reactionType } });
      emitReactPost({ postType: mapPostTypes(type), reactionType: reactionType.toLowerCase() });
    },
    [id],
  );
  const unSetReactionHandler = useCallback(
    (type: string) => {
      unsetReaction({ variables: { postId: id, type } });
    },
    [id],
  );
  const changedReaction: IReactionData | null = useMemo(() => {
    if (reactions) {
      const key = Object.keys(reactions).find((elem) => reactions[elem]?.includes(userId));

      if (key) {
        const reaction = reactionsTypes[key];

        if (reaction) {
          return reaction;
        }
      }
      return null;
    }
  }, [reactions, userId]);

  const changedReplyComment = useMemo(
    () => (comments || []).find((elem) => elem._id === replyCommentId),
    [comments, replyCommentId],
  );
  const ChangedReactionImage = changedReaction?.image;
  /* CHECKING THE PERMISSIONS */
  const userFromStore: UserState = useSelector((state: RootState) => state?.user);
  const isAbleToEdit = useMemo(() => {
    const permissionIds = userFromStore.permissionIds || [];
    const neededPostType = postTypes.find((pt) => pt.value === type);
    const neededPermission = neededPostType?.permission || null;

    const doHavePermission = permissionIds.some((pId) => {
      return Array.isArray(neededPermission) ? neededPermission.some((el) => el === pId) : neededPermission === pId;
    });

    if (neededPostType?.skip) {
      return isAuthor;
    } else {
      return doHavePermission;
    }
  }, [userFromStore]);
  const isHRPostType =
    type === POST_TYPES.HR_BIRTHDAY_WISHES ||
    type === POST_TYPES.HR_NEW_HIRES ||
    type === POST_TYPES.HR_PROMOTION ||
    type === POST_TYPES.HR_WORK_ANNIVERSARY;
  const isThanksPostType = type === POST_TYPES.THANKS;
  const isRecognitionPostType = type === POST_TYPES.RECOGNITION;
  const mentionsClickHandler = (e) => {
    const targetElement: any = e.target;
    if (targetElement?.className === EDITOR_CONFIG.class) {
      const userId = targetElement.getAttribute(EDITOR_CONFIG.dataIdentifier);
      history.push(`${profileRoutes.profile}${userId}`);
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const seeMoreClickHandler = () => {
    onChangeFeedId(id, null, true);
  };
  const composeTargetEmployeesText = useCallback(() => {
    if (!parsedTargetEmployees?.length) {
      return null;
    }
    const targetEmployeesClone = [...parsedTargetEmployees];

    return targetEmployeesClone.slice(0, 21).map((cur, i) => {
      const { fullName, userId } = cur;
      const isLastTarget = i === targetEmployeesClone.length - 1;
      const isMoreElement = i === 20;
      let mentionText = '';
      const goToProfile = () => history.push(`${profileRoutes.profile}${userId}`);
      const openFeed = () => onChangeFeedId(id, null);
      const onClick = () => (isMoreElement ? openFeed() : goToProfile());

      if (i >= 0 && i <= 19) {
        mentionText = fullName;
      } else if (isMoreElement) {
        const othersCount = targetEmployeesClone.length - i;
        mentionText = `${othersCount} ${othersCount > 1 ? 'others' : 'other'}`;
      }
      return (
        <Fragment key={userId}>
          {i !== 0 && !isMoreElement && (isLastTarget ? <StyledAnd>and</StyledAnd> : <Comma>,</Comma>)}
          {isMoreElement && <StyledAnd>and</StyledAnd>}
          <span onClick={onClick} className="thanks_mention">
            {mentionText}
          </span>
        </Fragment>
      );
    });
  }, [parsedTargetEmployees]);

  const editorFocus = () => {
    editorRef?.current?.editor?.focus();
  };

  const editCommentHandler = (id: string) => {
    setReplyCommentId(null);
    setChangedCommentId(id);
    editorFocus();
  };

  const cancelCommentHandler = () => {
    setChangedCommentId(null);
  };

  const replyCommentHandler = (id: string) => {
    setChangedCommentId(null);
    setReplyCommentId(id);
    editorFocus();
  };

  const cancelReplyHandler = () => {
    setReplyCommentId(null);
  };

  const fetchMoreComments = async () => {
    const changedFeed = newsFeedQuery?.data?.newsFeedPosts.data.find((elem) => elem._id === id);

    if (!changedFeed || !changedFeed.comments.pagination.nextCursor) {
      return;
    }

    const comments = await apolloClient.query({
      query: GET_COMMENTS_BY_ID,
      variables: {
        limit: 10,
        nextCursor: changedFeed.comments.pagination.nextCursor,
        postId: id,
      },
    });

    const fetchMorePromise = new Promise<boolean>((resolve) => {
      newsFeedQuery.updateQuery((prevResult) => {
        const prevResultClone = JSON.parse(JSON.stringify(prevResult));
        const changedFeed = prevResultClone?.newsFeedPosts.data.find((elem) => elem._id === id);

        if (!changedFeed || !comments.data) {
          return Object.assign({}, prevResultClone);
        }

        changedFeed.comments.data = [...changedFeed.comments.data, ...comments.data.newsFeedPostComments.data];
        changedFeed.comments.pagination = comments.data.newsFeedPostComments.pagination;
        return Object.assign({}, prevResultClone);
      });

      setTimeout(() => resolve(true), 400);
    });

    const res = await fetchMorePromise;

    if (res) {
      setScrollToComments(999999);
    }
  };

  const setChangedCommentRef = (id: string, ref: HTMLDivElement | null) => {
    if (id === changedCommentForScroll && ref) {
      changedCommentRef.current = ref;
    }
  };

  useLayoutEffect(() => {
    if (changedCommentRef.current && changedCommentForScroll) {
      const rect = changedCommentRef.current;
      setScrollToComments(rect.offsetTop - (rect?.parentElement?.offsetTop || 0) + rect.offsetHeight - 350);
    }
  }, [changedCommentRef, changedCommentForScroll]);

  const changeCommentForScroll = (id: string) => {
    setChangedCommentForScroll(id);
  };

  return (
    <Card>
      {postRef && <div ref={postRef} />}
      <DeleteModal
        closeHandler={() => setOpenCancelPopover(false)}
        discardHandler={() => setOpenCancelPopover(false)}
        isOpen={isOpenCancelPopover}
        isVisibleCloseIcon={true}
        onDeleteHandler={() => {
          setOpenCancelPopover(false);
          deletePostHandler();
        }}
        title={'Are you sure you want to delete this post?'}
        subtitle={`If you continue now, this post will be completely deleted.`}
        titleCancelButton={'Cancel'}
        titleConfirmButton={'Delete'}
        type={'DELETE'}
      />
      {id && isVisibleReactionsModal && (
        <ViewReactionsModal isVisible={isVisibleReactionsModal} onClose={() => setIsVisibleReactionsModal(false)} postId={id} />
      )}
      {isHRPostType && (
        <HrPostCard
          targetEmployees={targetEmployees}
          type={type}
          onChangeFeedId={onChangeFeedId}
          id={id}
          postAge={daysDifference < 7 ? moment(publishingDate).fromNow() : moment(publishingDate).format('DD MMM')}
        />
      )}
      {!isHRPostType ? (
        <Fragment>
          <HeaderContainer>
            {(type === POST_TYPES.COMPANY_NEWS || type === POST_TYPES.TEAM_NEWS || isFuturePostStatus) && (
              <MainContainerHeader>
                {isFuturePostStatus ? (
                  <Header>
                    <StyledFeaturedPostsIcon />
                    Featured
                  </Header>
                ) : type === POST_TYPES.COMPANY_NEWS ? (
                  <BehalfHeaderContainer>
                    <img src={company.logo} />
                    <p>Posted for {company.name}</p>
                  </BehalfHeaderContainer>
                ) : (
                  <BehalfTeamNewsHeaderContainer>
                    <CostCenterImage src={CostCenterIcon} />

                    <p>Posted for {costCenter?.name} team</p>
                  </BehalfTeamNewsHeaderContainer>
                )}
                {isFuturePostStatus && (
                  <HideButton onClick={() => hidePost(id, type)}>
                    <HidePostIcon />
                    <p>Collapse</p>
                  </HideButton>
                )}
              </MainContainerHeader>
            )}
            {type === POST_TYPES.COMPANY_NEWS && isFuturePostStatus && (
              <BehalfContainer>
                <img src={company.logo} />
                <p>Posted for {company.name}</p>
              </BehalfContainer>
            )}
            {type === POST_TYPES.TEAM_NEWS && isFuturePostStatus && (
              <BehalfTeamNewsContainer>
                <CostCenterImage src={CostCenterIcon} />
                <p>Posted for {costCenter?.name} team</p>
              </BehalfTeamNewsContainer>
            )}
            <InfoContainer>
              {userById?.user ? (
                <StyledFollowPopover
                  onVisibleChange={(val) => !val && getProfileCancel()}
                  trigger={['click']}
                  content={() =>
                    userById?.user && (
                      <FollowPopover
                        followHandler={followHandler}
                        unfollowHandler={unfollowHandler}
                        userData={userById?.user}
                        followedUsers={followedUsers}
                      />
                    )
                  }
                  visible={Boolean(userById?.user)}
                  overlayClassName={'followPopover'}
                  placement={'topLeft'}
                  destroyTooltipOnHide={true}
                >
                  <UserContainer onClick={getProfileSubmit}>
                    <StyledAvatar src={<Image preview={false} src={author?.photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
                    <UserNameContainer>
                      <NameContainer isChanged={Boolean(userById?.user)}>
                        <Name level={5}>{author.fullName}</Name>
                      </NameContainer>
                      <SubName>
                        {daysDifference < 1 ? moment(publishingDate).fromNow() : parseDaysToWeeks(daysDifference)}
                      </SubName>
                    </UserNameContainer>
                  </UserContainer>
                </StyledFollowPopover>
              ) : (
                <UserContainer onClick={getProfileSubmit}>
                  <StyledAvatar src={<Image preview={false} src={author?.photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
                  <UserNameContainer>
                    <NameContainer isChanged={Boolean(userById?.user)}>
                      <Name level={5}>{author?.fullName}</Name>
                    </NameContainer>
                    <SubName>{daysDifference < 1 ? moment(publishingDate).fromNow() : parseDaysToWeeks(daysDifference)}</SubName>
                  </UserNameContainer>
                </UserContainer>
              )}
              <StyledDropdown
                overlay={<EditPopover deleteHandler={() => setOpenCancelPopover(true)} editHandler={() => editHandler(id)} />}
                placement="bottomRight"
                overlayStyle={{ zIndex: 999 }}
              >
                <Menu $isEditable={isAbleToEdit} />
              </StyledDropdown>
            </InfoContainer>
            {headline && <HeadlineContainer>{headline}</HeadlineContainer>}
            <MainDescriptionContainer>
              {isThanksPostType && (
                <ThanksDisclaimer>
                  {composeTargetEmployeesText()}
                  <span>{`${pluralize('was', targetEmployeesLength)} thanked`}</span>
                </ThanksDisclaimer>
              )}
              {isRecognitionPostType && (
                <ThanksDisclaimer>
                  {composeTargetEmployeesText()}
                  <span>{`${pluralize('was', targetEmployeesLength)} recognized for`}</span>
                </ThanksDisclaimer>
              )}
            </MainDescriptionContainer>
          </HeaderContainer>
          {isThanksPostType && (
            <Fragment>
              <IconContainer>
                <ThanksClap />
              </IconContainer>
              <TargetEmployees onClickHandler={() => onChangeFeedId(id, null)} employees={parsedTargetEmployees} />
            </Fragment>
          )}
          {isRecognitionPostType && (
            <Fragment>
              <IconContainer>
                <RecognitionBody>
                  <h2>{badgeElement?.name}</h2>
                  <RecognitionCard>
                    <img src={badgeElement?.iconUrl} />
                  </RecognitionCard>
                </RecognitionBody>
              </IconContainer>
              <TargetEmployees onClickHandler={() => onChangeFeedId(id, null)} employees={parsedTargetEmployees} />
            </Fragment>
          )}
          <Container $withHeadline={!!headline}>
            {!isLongText && (
              <DescriptionContainer
                onClick={mentionsClickHandler}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
            {isLongText && (
              <DescriptionContainer onClick={mentionsClickHandler}>
                <span dangerouslySetInnerHTML={{ __html: htmlToShow }} />
                <SliceButton onClick={seeMoreClickHandler}>See more</SliceButton>
              </DescriptionContainer>
            )}
          </Container>
          {!!files?.length && <ImagesContainer files={files} onChangeFeedId={(key: number) => onChangeFeedId(id, key)} />}
        </Fragment>
      ) : null}
      <Container>
        <CommentsContainer>
          <MainReactionsContainer
            onClick={() => {
              if (allReactions > 0 && !isVisibleReactionsModal) {
                setIsVisibleReactionsModal(true);
                emitViewPost({ postType: mapPostTypes(type) });
              }
            }}
          >
            <ReactionsContainer>
              {bestReactions &&
                bestReactions.map((elem, i) => {
                  const { type } = elem;
                  const { image: ReactionSVG, background } = reactionsTypes[type] || {};
                  return (
                    <ReactionContainer key={`${i}_${type}`} style={{ background }}>
                      <ReactionSVG />
                    </ReactionContainer>
                  );
                })}
            </ReactionsContainer>
            <ViewReactionsButton>{pluralize('reaction', allReactions, true)}</ViewReactionsButton>
          </MainReactionsContainer>
          <p
            onClick={() => {
              if (!isOpenedComments) {
                emitViewPost({ postType: mapPostTypes(type) });
              }
              setOpenedComments(!isOpenedComments);
            }}
          >
            {pluralize('comment', commentsCount ?? 0, true)}
          </p>
        </CommentsContainer>
        <ButtonsContainer>
          <StyledDropdown
            onVisibleChange={handleVisibleChange}
            visible={isVisibleOverlay}
            overlay={<ReactionsPopover setReaction={setReactionHandler} closeReactionsDropdown={closeReactionsDropdown} />}
            placement="topCenter"
            trigger={['hover']}
          >
            {ChangedReactionImage ? (
              <Button
                isChangedReaction={true}
                onClick={() => changedReaction?.type && unSetReactionHandler(changedReaction.type)}
              >
                <ChangedReactionImage />
                <p>{changedReaction?.type.toLowerCase()}</p>
              </Button>
            ) : (
              <Button onClick={() => setReactionHandler('LIKE')}>
                <StyledLike />
                <p>Like</p>
              </Button>
            )}
          </StyledDropdown>
          <Button
            onClick={() => {
              setOpenedComments(true);
              editorFocus();
            }}
          >
            <Comment />
            <p>Comment</p>
          </Button>
        </ButtonsContainer>
      </Container>
      <CommentInputContainer>
        <CommentsMainContainer>
          {changedCommentId && (
            <CommentsControlContainer>
              <p>Editing comment</p> <Point /> <CancelButton onClick={cancelCommentHandler}>Cancel</CancelButton>
            </CommentsControlContainer>
          )}
          {replyCommentId && (
            <CommentsControlContainer>
              <p>
                Replying to
                <span>{changedReplyComment?.author.fullName}</span>
              </p>
              <Point /> <CancelButton onClick={cancelReplyHandler}>Cancel</CancelButton>
            </CommentsControlContainer>
          )}
          <CommentsInput
            postType={type}
            postId={id}
            key={id}
            changedCommentId={changedCommentId}
            replyCommentId={replyCommentId}
            comments={comments || []}
            cancelCommentHandler={cancelCommentHandler}
            cancelReplyHandler={cancelReplyHandler}
            userAvatar={photoUrl}
            editorRef={editorRef}
          />
        </CommentsMainContainer>
        {showMoreCommentsCount > 0 && (
          <ShowMoreButton onClick={fetchMoreComments}>Show {showMoreCommentsCount} comments</ShowMoreButton>
        )}
        {isOpenedComments &&
          ((comments || []).length > 3 ? (
            <CustomScroll heightRelativeToParent={'350px'} allowOuterScroll={true} scrollTo={scrollToComments}>
              <Comments
                commentsData={filteredComments || []}
                isOpened={isOpenedComments}
                onReply={replyCommentHandler}
                editHandler={editCommentHandler}
                postId={id}
                mentionsClickHandler={mentionsClickHandler}
                setChangedCommentRef={setChangedCommentRef}
                changeCommentForScroll={changeCommentForScroll}
              />
            </CustomScroll>
          ) : (
            <Comments
              commentsData={filteredComments || []}
              isOpened={isOpenedComments}
              onReply={replyCommentHandler}
              editHandler={editCommentHandler}
              postId={id}
              mentionsClickHandler={mentionsClickHandler}
              changeCommentForScroll={changeCommentForScroll}
            />
          ))}
      </CommentInputContainer>
    </Card>
  );
};

export default PostCard;
