import React from 'react';
import CustomScroll from 'react-custom-scroll';
import { IAvatarModel } from 'modules/common/gql/models/avatar.model';
import { ReactComponent as BackCaret } from 'modules/profile/assets/images/avatarEditor/backCaret.svg';
import {
  StyledModal,
  MainHeaderContainer,
  HeaderContainer,
  StyledCloseIcon,
} from '../styles';
import {
  Title,
  Subtitle,
  TitleContent,
  AvatarsContainer,
  AvatarsList,
  AvatarsListItem,
  AllAvatarsContentContainer,
} from './styles';

interface IProps {
  avatars: IAvatarModel[];
  goBack: () => void;
  setAvatar: (avatar: IAvatarModel) => void;
}

const AllAvatars = ({ avatars, goBack, setAvatar }: IProps) => {
  return (
    <StyledModal $withAvatars={true} visible={true} footer={null}>
      <MainHeaderContainer>
        <HeaderContainer>
          <TitleContent>
            <Title>All Avatars</Title>
            <Subtitle onClick={goBack}><BackCaret /> Back to Profile Photo</Subtitle>
          </TitleContent>
          <StyledCloseIcon onClick={goBack} />
        </HeaderContainer>
      </MainHeaderContainer>
      <AllAvatarsContentContainer>
        <AvatarsContainer>
          <CustomScroll heightRelativeToParent="338px">
            <AvatarsList>
              {avatars.map((el, i) => (
                <AvatarsListItem onClick={() => setAvatar(el)} key={i}>
                  <img src={el.photoUrl} />
                </AvatarsListItem>
              ))}
            </AvatarsList>
          </CustomScroll>
        </AvatarsContainer>
      </AllAvatarsContentContainer>
    </StyledModal>
  );
}

export default AllAvatars;