import React, { FC, useMemo } from 'react';
import { Row, Avatar, Space, message, Image } from 'antd';
import { utc } from 'moment';
import { useMutation } from '@apollo/client';

import { StyledRow, StyledTitle, SectionTitle, StyledLink, StyledText, StyledCol } from './styles';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import { PositionTimeTypes } from 'modules/common/gql/models/job.model';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { TOGGLE_BIRTHDAY } from 'modules/profile/gql/mutation';
import BirthDay from './BirthDay';
import { IEntityData } from 'modules/team/pages/OrgChart/OrgChart';

export interface IProps {
  user: IUserModel;
  reportsTo?: IEntityData;
  goToProfile: (id: string) => void;
  isOwner: boolean;
  refetchUser: () => void;
}

const EmploymentInfo: FC<IProps> = ({ user, reportsTo, goToProfile, isOwner, refetchUser }) => {
  if (!user) {
    return null;
  }

  const {
    birthDate,
    job,
    employment: { hireDate, tenure },
    costCenter,
    birthDateHidden,
  } = user;
  const hireDateParsed = useMemo(() => utc(hireDate).format('ll'), [hireDate]);
  const birthDateParsed = useMemo(() => utc(birthDate).format('MMM D'), [birthDate]);
  const jobTitle = useMemo(() => {
    return job?.positionBusinessTitle || null;
  }, [job]);
  const employmentType = useMemo(() => {
    const PART_TIME = 'Part time';
    const FULL_TIME = 'Full time';
    const { positionTimeType } = job || {};
    if (positionTimeType === PositionTimeTypes.PartTime) {
      return PART_TIME;
    } else if (positionTimeType === PositionTimeTypes.FullTime) {
      return FULL_TIME;
    }
    return null;
  }, [job]);
  const costCenterName = useMemo(() => costCenter?.name, [costCenter]);

  const [toggleField] = useMutation(TOGGLE_BIRTHDAY, {
    onCompleted: refetchUser,
    onError: (err) => message.error(err),
  });

  const toggleBirthday = () => {
    toggleField({
      variables: {
        hidden: !birthDateHidden,
        id: user.id,
      },
    });
  };

  return (
    <>
      <Row>
        <StyledCol span={24}>
          <SectionTitle>Employment Info</SectionTitle>
        </StyledCol>
      </Row>
      <StyledRow>
        <StyledCol span={6}>
          <Space direction="vertical">
            <StyledTitle disabled>Hire Date</StyledTitle>
            <StyledText>{hireDateParsed}</StyledText>
          </Space>
        </StyledCol>
        {jobTitle && (
          <StyledCol span={6}>
            <Space direction="vertical">
              <StyledTitle disabled>Job Title</StyledTitle>
              <StyledText>{jobTitle}</StyledText>
            </Space>
          </StyledCol>
        )}
        <StyledCol span={6}>
          <StyledLink onClick={() => goToProfile(reportsTo?.user?.id || '')}>
            <Space direction="vertical">
              <StyledTitle disabled>Reports To</StyledTitle>
              <Space direction="horizontal">
                <Avatar
                  size="small"
                  src={<Image preview={false} src={reportsTo?.user?.photoUrl || emptyAvatar} fallback={emptyAvatar} />}
                />
                <StyledText>{reportsTo?.user?.fullName ?? ''}</StyledText>
              </Space>
            </Space>
          </StyledLink>
        </StyledCol>
        <StyledCol span={6}>
          <Space direction="vertical">
            <StyledTitle disabled>Tenure</StyledTitle>
            {tenure ? <StyledText>{tenure} years</StyledText> : <StyledText>Less than a year</StyledText>}
          </Space>
        </StyledCol>
      </StyledRow>
      <StyledRow>
        {costCenterName && (
          <StyledCol span={6}>
            <Space direction="vertical">
              <StyledTitle disabled>Program</StyledTitle>
              <StyledText>{costCenterName}</StyledText>
            </Space>
          </StyledCol>
        )}
        {employmentType && (
          <StyledCol span={6}>
            <Space direction="vertical">
              <StyledTitle disabled>Employment Type</StyledTitle>
              <StyledText>{employmentType}</StyledText>
            </Space>
          </StyledCol>
        )}
        <BirthDay
          birthDateHidden={birthDateHidden}
          birthDateParsed={birthDateParsed}
          isOwner={isOwner}
          toggleBirthday={toggleBirthday}
          birthDate={birthDate}
        />
      </StyledRow>
    </>
  );
};

export default EmploymentInfo;
