import styled from 'styled-components';
import { Input } from 'antd';

export const EditContainer = styled.div`
  position: relative;
`;

export const StyledTextArea = styled(Input.TextArea)`
  resize: none;
`;
