import { applyMiddleware, createStore, } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();
const middlewareEnhancer = applyMiddleware(sagaMiddleware);

const store = createStore(
  rootReducer,
  composeWithDevTools(middlewareEnhancer),
);

sagaMiddleware.run(rootSaga)

export default store;
