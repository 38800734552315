import React, { FC, useState, useMemo, Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { Moment } from 'moment';
import { message } from 'antd';

import { StyledModal, TypeFeedContainer, StyledTabs } from '../styled';
import { NF_PERMISSIONS } from 'modules/auth/constants';
import { POST_TYPES } from '../../../constants';
import { ReactComponent as CloseIcon } from 'modules/news-feed/pages/Feed/assets/createPostModal/closeIcon.svg';
import { IEditableData, NewsFeedTypes } from '../MainContent';
import Individual from './Individual';
import CompanyNews from './CompanyNews';
import TeamNews from './TeamNews';
import DeleteModal from 'modules/common/components/DeleteModal';
import { ICostCenter } from '../../../Feed';
import { SEARCH_USERS_BY_NAME } from 'modules/common/gql/query';
import { IUserModel } from 'modules/common/gql/models/user.model';
import { IMentionModel } from 'modules/common/gql/models/mention.model';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { RootState } from 'store/rootReducer';
import { UserState } from 'store/domains/user/user.types';
import { IBadge } from '../MainContent';
import { GET_COST_CENTERS } from 'modules/news-feed/gql/query';
import { getFileExtension, getSizeFile } from 'modules/news-feed/pages/Feed/utils';
import { FilesType } from 'modules/news-feed/pages/Feed/components/MainContent/MainContent';

const { TabPane } = StyledTabs;

interface IProps {
  isOpen: boolean;
  isEditMode: boolean;
  closeHandler: () => void;
  editableValue: IEditableData;
  selectTypeHandler: (value: NewsFeedTypes) => void;
  user: IUserModel;
  changeFileHandler: (file: FileList | null) => void;
  submitHandler: () => void;
  onChangeTextNewsFeed: (value: string, editor?: any) => void;
  setValueHandler: (value: string | boolean, fieldName: string) => void;
  setDateTimeHandler: (value: Moment | string | null, fieldName: string) => void;
  setMentionsHandler: (value: IMentionModel[]) => void;
  deleteNotUploadedImage: (index: number) => void;
  deleteUploadedImage: (fileId: string) => void;
  filesForDelete: string[];
  badgesData: IBadge[];
  isBlockedSubmit: boolean;
  setBlockedSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ISearchUsersResponse {
  searchUsers: {
    data: IUserModel[];
  };
}

export interface IDOMMention {
  id: string;
  display: string;
}

export interface IMentionSuggestion {
  id: string;
  display: string;
  photoUrl: string | null;
}

export interface IThanksMessageSuggestionsResponse {
  newsFeedThanksMessageSuggestions: string[];
}

interface ICostCentersResult {
  costCenters: {
    data: ICostCenter[];
  };
}

const CreatePostModal: FC<IProps> = ({
  isOpen,
  isEditMode,
  closeHandler,
  editableValue,
  selectTypeHandler,
  user,
  changeFileHandler,
  submitHandler,
  onChangeTextNewsFeed,
  setValueHandler,
  setDateTimeHandler,
  deleteNotUploadedImage,
  deleteUploadedImage,
  filesForDelete,
  badgesData,
  isBlockedSubmit,
  setBlockedSubmit,
}) => {
  const { type } = editableValue;
  const [isOpenCancelPopover, setOpenCancelPopover] = useState(false);
  const userFromStore: UserState = useSelector((state: RootState) => state?.user);
  const permissionIds = userFromStore.permissionIds || [];
  const canCreateAllCostCenters = permissionIds.some((p) => p === NF_PERMISSIONS.MANAGE_ALL_TEAM_NEWS);
  const canCreateOwnCostCenter = permissionIds.some((p) => p === NF_PERMISSIONS.MANAGE_TEAM_NEWS);
  const canCreateCompanyPosts = permissionIds.some((p) => p === NF_PERMISSIONS.MANAGE_COMPANY_NEWS);
  const isSuperAdmin = canCreateCompanyPosts || canCreateAllCostCenters || canCreateOwnCostCenter;
  const filteredImages = useMemo(() => {
    const { files } = editableValue;
    return files.filter((elem) => {
      if (elem instanceof File) {
        return true;
      }

      return !filesForDelete.includes(elem.fileId);
    });
  }, [editableValue, filesForDelete]);

  const [getCostCenters, { data: costCenters }] = useLazyQuery<ICostCentersResult>(GET_COST_CENTERS, {
    onError: (err: Error) => message.error(err),
  });

  const costCentersData = costCenters?.costCenters?.data || [];

  const imagesAndFiles = useMemo(() => {
    const data: {
      images: FilesType[];
      pdfFiles: FilesType[];
    } = {
      images: [],
      pdfFiles: [],
    };

    filteredImages.forEach((elem) => {
      const extension = getFileExtension((elem instanceof File ? elem.name : elem?.fileName) || 'png');

      if (extension === 'pdf') {
        data.pdfFiles.push(elem);
        return;
      }
      data.images.push(elem);
    });
    return data;
  }, [filteredImages]);

  const isInValidImage = useMemo(
    () => Boolean(imagesAndFiles.images.some((elem) => elem instanceof File && getSizeFile(elem.size) >= 5)),
    [imagesAndFiles],
  );
  const isInValidPdf = useMemo(
    () => Boolean(imagesAndFiles.pdfFiles.some((elem) => elem instanceof File && getSizeFile(elem.size) >= 10)),
    [imagesAndFiles],
  );
  const isImagesListInvalid = useMemo(() => imagesAndFiles.images.length > 10, [imagesAndFiles]);
  const isPdfListInvalid = useMemo(() => imagesAndFiles.pdfFiles.length > 5, [imagesAndFiles]);

  /* WORKING WITH MENTIONS */

  // editor's callback to create a mention suggestions list
  const [successMentionsCallback, setSuccessMentionCallback] = useState(() => (data: any) => {});

  // triggred after graphql search query was executed
  const onMentionsLoadComplete = useCallback(
    (data) => {
      if (!successMentionsCallback) {
        return;
      }
      const suggestions =
        data?.searchUsers?.data.map((el) => ({ id: el.id, name: el.fullName, image: el.photoUrl || emptyAvatar })) || [];
      const currentMentions = editableValue.mentions || [];
      const filteredSuggestions = suggestions.filter(
        (el: any) => !currentMentions.some((curMention) => el.id === curMention.userId),
      );
      successMentionsCallback(filteredSuggestions);
    },
    [successMentionsCallback, setSuccessMentionCallback],
  );

  // graphql search query
  const [getUsersToMention] = useLazyQuery(SEARCH_USERS_BY_NAME, { onCompleted: onMentionsLoadComplete });

  // callback triggered on mentions change in the editor's input
  const prepareMentionsData = useCallback(
    (query, mentionsLoadedCallback) => {
      if (!query) {
        return;
      }
      setSuccessMentionCallback(() => mentionsLoadedCallback);
      getUsersToMention({ variables: { limit: 10, nameLike: query } });
    },
    [getUsersToMention, setSuccessMentionCallback],
  );

  const availableCostCenters = useMemo(() => {
    if (canCreateAllCostCenters) {
      return costCentersData;
    }
    if (canCreateOwnCostCenter) {
      return costCentersData.filter((cc) => cc.name === user?.costCenter?.name);
    }
    return [];
  }, [costCentersData, canCreateAllCostCenters, canCreateOwnCostCenter]);

  const submitButtonBlock = () => {
    setBlockedSubmit(true);
  };

  useEffect(() => {
    setBlockedSubmit(false);
  }, [isOpen]);

  const teamTab = useMemo(() => {
    if (availableCostCenters?.length) {
      return availableCostCenters.length > 1 ? 'Teams' : availableCostCenters[0].name;
    }
    return 'Teams';
  }, [availableCostCenters]);

  const individualKey = useMemo(() => {
    switch (type) {
      case POST_TYPES.THANKS:
        return POST_TYPES.THANKS;
      case POST_TYPES.RECOGNITION:
        return POST_TYPES.RECOGNITION;
      default:
        return POST_TYPES.INDIVIDUAL;
    }
  }, [type]);

  useEffect(() => {
    getCostCenters();
  }, []);

  return (
    <Fragment>
      <DeleteModal
        closeHandler={() => setOpenCancelPopover(false)}
        discardHandler={() => {
          setOpenCancelPopover(false);
          closeHandler();
        }}
        isOpen={isOpenCancelPopover}
        isVisibleCloseIcon={true}
        onDeleteHandler={() => setOpenCancelPopover(false)}
        title={'Do you want to discard or continue editing?'}
        subtitle={`If you discard now, you'll lose this post.`}
        titleCancelButton={'Discard'}
        titleConfirmButton={'Edit'}
        type={'CANCEL'}
      />
      <StyledModal
        title={`${isEditMode ? 'Edit' : 'Create'} a Post`}
        visible={isOpen}
        footer={null}
        onCancel={() => setOpenCancelPopover(true)}
        maskClosable={false}
        isBlockedClose={isBlockedSubmit}
        closeIcon={<CloseIcon />}
      >
        <TypeFeedContainer>
          {isSuperAdmin && (
            <StyledTabs activeKey={type} onChange={(activeKey: any) => !isEditMode && selectTypeHandler(activeKey)} centered>
              <TabPane disabled={isEditMode && type !== individualKey} tab="Followers" key={individualKey}>
                {[POST_TYPES.INDIVIDUAL, POST_TYPES.THANKS, POST_TYPES.RECOGNITION].includes(type) ? (
                  <Individual
                    isEditMode={isEditMode}
                    changeFileHandler={changeFileHandler}
                    editableValue={editableValue}
                    onChangeTextNewsFeed={onChangeTextNewsFeed}
                    submitHandler={submitHandler}
                    deleteNotUploadedImage={deleteNotUploadedImage}
                    isInValidImage={isInValidImage}
                    isInValidPdf={isInValidPdf}
                    deleteUploadedImage={deleteUploadedImage}
                    isImagesListInvalid={isImagesListInvalid}
                    isPdfListInvalid={isPdfListInvalid}
                    isBlockedSubmit={isBlockedSubmit}
                    submitButtonBlock={submitButtonBlock}
                    filteredImages={filteredImages}
                    type={type}
                    user={user}
                    setValueHandler={setValueHandler}
                    badgesData={badgesData}
                  />
                ) : null}
              </TabPane>
              {canCreateCompanyPosts && (
                <TabPane disabled={isEditMode && type !== POST_TYPES.COMPANY_NEWS} tab="Company" key={POST_TYPES.COMPANY_NEWS}>
                  {type === POST_TYPES.COMPANY_NEWS && (
                    <CompanyNews
                      editableValue={editableValue}
                      submitHandler={submitHandler}
                      changeFileHandler={changeFileHandler}
                      user={user}
                      setValueHandler={setValueHandler}
                      setDateTimeHandler={setDateTimeHandler}
                      isEditMode={isEditMode}
                      deleteNotUploadedImage={deleteNotUploadedImage}
                      prepareMentionsData={prepareMentionsData}
                      onChangeTextNewsFeed={onChangeTextNewsFeed}
                      isInValidImage={isInValidImage}
                      isInValidPdf={isInValidPdf}
                      isPdfListInvalid={isPdfListInvalid}
                      deleteUploadedImage={deleteUploadedImage}
                      isImagesListInvalid={isImagesListInvalid}
                      isBlockedSubmit={isBlockedSubmit}
                      submitButtonBlock={submitButtonBlock}
                      filteredImages={filteredImages}
                    />
                  )}
                </TabPane>
              )}
              {(canCreateAllCostCenters || canCreateOwnCostCenter) && (
                <TabPane disabled={isEditMode && type !== POST_TYPES.TEAM_NEWS} tab={teamTab} key={POST_TYPES.TEAM_NEWS}>
                  {type === POST_TYPES.TEAM_NEWS && (
                    <TeamNews
                      editableValue={editableValue}
                      submitHandler={submitHandler}
                      changeFileHandler={changeFileHandler}
                      user={user}
                      setValueHandler={setValueHandler}
                      setDateTimeHandler={setDateTimeHandler}
                      costCenters={availableCostCenters}
                      deleteNotUploadedImage={deleteNotUploadedImage}
                      prepareMentionsData={prepareMentionsData}
                      onChangeTextNewsFeed={onChangeTextNewsFeed}
                      isInValidImage={isInValidImage}
                      isInValidPdf={isInValidPdf}
                      isPdfListInvalid={isPdfListInvalid}
                      deleteUploadedImage={deleteUploadedImage}
                      isImagesListInvalid={isImagesListInvalid}
                      isBlockedSubmit={isBlockedSubmit}
                      submitButtonBlock={submitButtonBlock}
                      filteredImages={filteredImages}
                    />
                  )}
                </TabPane>
              )}
            </StyledTabs>
          )}
          {!isSuperAdmin && (
            <Individual
              isEditMode={isEditMode}
              changeFileHandler={changeFileHandler}
              editableValue={editableValue}
              onChangeTextNewsFeed={onChangeTextNewsFeed}
              submitHandler={submitHandler}
              deleteNotUploadedImage={deleteNotUploadedImage}
              isInValidImage={isInValidImage}
              isInValidPdf={isInValidPdf}
              isPdfListInvalid={isPdfListInvalid}
              deleteUploadedImage={deleteUploadedImage}
              isImagesListInvalid={isImagesListInvalid}
              isBlockedSubmit={isBlockedSubmit}
              submitButtonBlock={submitButtonBlock}
              filteredImages={filteredImages}
              type={type}
              user={user}
              setValueHandler={setValueHandler}
              badgesData={badgesData}
            />
          )}
        </TypeFeedContainer>
      </StyledModal>
    </Fragment>
  );
};

export default CreatePostModal;
