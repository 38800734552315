import apolloClient from 'app/apolloClient';
import { GLOBAL_SEARCH_USERS, GLOBAL_SEARCH_POSTS, GLOBAL_SEARCH_ARTICLES } from 'modules/common/gql/query';

/** Working with reach text editor */
export const parseEditorMentions = (editorMentions: Array<string>) => {
  return editorMentions.map((m) => ({ userId: m }));
};

/** Working with Global Search */
export const searchUsers = (search: string) =>
  apolloClient.query({
    query: GLOBAL_SEARCH_USERS,
    variables: {
      text: search,
      limit: 10,
      offset: 0,
    },
  });

export const searchPosts = (search: string) =>
  apolloClient.query({
    query: GLOBAL_SEARCH_POSTS,
    variables: {
      text: search,
      limit: 10,
      offset: 0,
    },
  });

export const searchArticles = (search: string) =>
  apolloClient.query({
    query: GLOBAL_SEARCH_ARTICLES,
    variables: {
      searchText: search,
      limit: 5,
      offset: 0,
    },
  });
