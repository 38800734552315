import styled, { css } from 'styled-components';
import { Row, Col, Card, Typography, Select, Avatar } from 'antd';

import { ReactComponent as Arrow } from 'modules/profile/assets/images/arrow.svg';
import { ReactComponent as StatusCheckedIcon } from 'modules/common/assets/images/status-checked-icon.svg';
import { ReactComponent as StatusGrayIcon } from 'modules/common/assets/images/status-leave-icon.svg';
import { ReactComponent as PhoneHome } from 'modules/common/assets/images/phone-home-icon.svg';
import { ReactComponent as PhoneWork } from 'modules/common/assets/images/phone-work-icon.svg';
import Pencil from 'modules/profile/assets/images/pencil.svg';
import { ReactComponent as Visible } from '../Timeline/assets/visible.svg';
import { ReactComponent as Hide } from '../Timeline/assets/hide.svg';
import { ReactComponent as Confirm } from 'modules/profile/assets/images/confirm.svg';
import { ReactComponent as Cancel } from 'modules/profile/assets/images/cancel.svg';
import { ReactComponent as Camera } from '../../../../assets/images/avatarEditor/camera.svg';

const { Title } = Typography;

interface IEditable {
  $isEditable?: boolean;
}

export const StyledCard = styled(Card)`
  box-shadow: 0px 2px 10px rgba(166, 169, 174, 0.219608);
  border-radius: 5px;
  @media (max-width: 1200px) {
    .ant-card-body {
      padding: 16px;
    }
  }
`;

export const CostCenter = styled(Typography.Text)`
  line-height: 24px;

  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const StyledRow = styled(Row)`
  padding-bottom: 12px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
`;

export const StyledCol = styled(Col)<IEditable>`
  margin-top: 16px;
  line-height: 24px;
  ${(props) =>
    props.$isEditable
      ? css`
          border-radius: 8px;
          &:hover {
            box-shadow: 0 0 0 5px #eff0f1;
            cursor: text !important;
            background-color: #eff0f1;
            background-repeat: no-repeat;
            background-position: 100% 0%;
            background-image: url(${Pencil});
          }
        `
      : css`
          background-color: #fff;
          box-shadow: none;
          background: none;
        `}
`;

export const StyledTitle = styled(Title)`
  display: inline-block;
  position: relative;
  line-height: 28px !important;
  font-size: 20px !important;
  font-weight: 600;
  color: #111;
  margin: 16px 0 4px 0 !important;

  @media (max-width: 1200px) {
    font-size: 16px !important;
  }
`;

export const StatusActiveIcon = styled(StatusCheckedIcon)`
  position: absolute;
  top: 12px;
  right: -26px;
`;

export const StatusLeaveIcon = styled(StatusGrayIcon)`
  position: absolute;
  top: 12px;
  right: -26px;
`;

export const StyledAvatar = styled(Avatar)`
  height: 120px;
  width: 120px;
  border-radius: 100%;
  overflow: hidden;
  background: #fff;
  
  .ant-image {
    width: 100%;
  }

  @media (max-width: 1200px) {
    width: 80px;
    height: 80px;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledInnerTitle = styled(Title)`
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-top: 0;
  cursor: auto !important;
  color: #8d8d8d !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px !important;
  line-height: 24px !important;
`;

export const HomePhoneIcon = styled(PhoneHome)`
  position: relative;
  top: 3px;
  right: -5px;
`;

export const WorkPhoneIcon = styled(PhoneWork)`
  position: relative;
  top: 3px;
  right: -5px;
`;

export const WorkingHoursInputs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WorkingHoursInputContainer = styled.div`
  position: relative;
`;

export const StyledSelectorTimes = styled(Select)`
  width: 150px;
  .ant-select-selector {
    padding-left: 30px !important;
  }
`;

export const SplitLine = styled.div`
  width: 16px;
  height: 1px;
  background: #111111;
`;

export const StyledArrow = styled(Arrow)`
  position: absolute;
  left: 10px;
  top: 13px;
  z-index: 999;
`;

export const WorkingHoursEditContainer = styled.div`
  position: relative;
`;

export const WorkingHoursControls = styled.div`
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 60px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
`;

export const StyledConfirm = styled(Confirm)`
  fill: #04aa77;
`;

export const StyledCancell = styled(Cancel)`
  fill: #f5222d;
`;

export const ControlButton = styled.div`
  border: 1px solid #eff0f1;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  background: #fff;
  transition: 0.4s;
  opacity: 1;

  ${({ isInvisible, isCanceButton }: { isInvisible?: boolean; isCanceButton?: boolean }) =>
    isInvisible
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : isCanceButton &&
        css`
          :hover {
            background: #f5222d !important;
            ${StyledCancell} {
              fill: #ffffff;
            }
          }
        `}

  :hover {
    background: #04aa77;
    ${StyledConfirm} {
      fill: #ffffff;
    }
  }
`;

export const StyledText = styled(Typography.Text)`
  display: block;
  min-height: 20px;
`;

export const FollowedContainer = styled.div`
  max-width: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FollowedElement = styled.div`
  display: flex;
  flex-direction: column;
  color: #4b4b4b;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`;

export const FollowerAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;

export const FollowedAvatarContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const FollowersCountContainer = styled.div`
  width: 40px;
  height: 40px;
  background: #ecf6ff;
  border: 3px solid #ffffff;
  color: #1890ff;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
  z-index: 99;
  font-weight: 600;
`;

export const MainFollowersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

interface IFollowbutton {
  isFollowed?: boolean;
  isPending?: boolean;
}

export const FollowButton = styled.div<IFollowbutton>`
  width: 100%;
  border: 1px solid #1890ff;
  border-radius: 4px;
  background: #1890ff;
  color: #ffffff;
  padding: 4px;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
  margin-top: 12px;
  font-weight: 600;

  ${({ isFollowed }) =>
    isFollowed &&
    css`
      background: #fff;
      color: #1890ff;
    `}

  ${({ isPending }) =>
    isPending &&
    css`
      cursor: auto;
      background: #ecf6ff;
      border-color: #ecf6ff;
      color: #1890ff;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const HideButton = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #1890ff;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const StyledVisible = styled(Visible)`
  margin-right: 10px;
`;

export const StyledHide = styled(Hide)`
  margin-right: 10px;
`;

export const StyledCamera = styled(Camera)`
  transition: 0.4s;
  opacity: 0;
`;

export const WrapperAvatar = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  z-index: 999;
  cursor: pointer;
  border-radius: 100%;
  background-color: rgb(17, 17, 17, 0);
`;

export const AvatarMainContainer = styled.div`
  position: relative;
  border-radius: 100%;
  overflow: hidden;

  :hover {
    ${WrapperAvatar} {
      background-color: rgb(17, 17, 17, 0.3);
    }
    ${StyledCamera} {
      opacity: 1;
    }
  }
`;

export const ProfileCol = styled(Col)`
  @media (max-width: 1200px) {
    padding: 16px !important;
  }
`;

export const BadgesContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

export const Badge = styled.img`
  margin: 0 2.5px;
  widht: 40px;
  height: 40px;
`;

export const BadgesTitle = styled.span`
  margin-top: 15px;
  display: block;
  text-align: center;
  color: #4B4B4B;
  line-height: 24px;
`;
