import React, { useMemo, useState, FC, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { Image } from 'antd';
import {
  MainContainer,
  StyledMentions,
  StyledAvatar,
  StyledOption,
  StyledElement,
  MentionsContainer,
  Mention,
  StyledClose,
  InputPlaceholder,
} from './styles';
import { SEARCH_USERS } from 'modules/news-feed/gql/query';
import { GET_MY_FOLLOWED_AUTHORS } from 'modules/profile/gql/query';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import useDebounce from 'modules/common/hooks/useDebounce';

interface IMentionedUser {
  id: string;
  photoUrl: string;
  fullName: string;
  firstName: string;
  lastName: string;
}

interface IOptionUser {
  id: string;
  photoUrl: string;
  fullName: string;
}

interface IUsersResult {
  searchUsers: {
    data: IMentionedUser[];
  };
}

interface IFollowedUsersResult {
  getMyFollowedAuthors: {
    data: {
      authorUser: IMentionedUser;
    }[];
  };
}

interface IProps {
  setTargetEmployees?: (data: IOptionUser[]) => void;
  preselectedUsers?: IOptionUser[];
}

const mentionsTextareaId = 'target_mentions';

const UsersMultiSelector: FC<IProps> = ({ setTargetEmployees, preselectedUsers }) => {
  const [selectedUsers, setSelectedUsers] = useState<IOptionUser[]>(preselectedUsers || []);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const searchValue = useDebounce(inputSearchValue, 500);
  const targetEmployeesRef = useRef<any>(null);
  const search = searchValue.slice(1);
  const { data: usersData } = useQuery<IUsersResult>(SEARCH_USERS, {
    variables: { offset: 0, limit: 10, nameLike: search },
    onError: (err) => console.log('Error during search users query', err),
  });
  const { data: followedUsers } = useQuery<IFollowedUsersResult>(GET_MY_FOLLOWED_AUTHORS, {
    variables: { limit: 9999, offset: 0 },
    skip: !!search.length,
    onError: (err) => console.log('Error during followed search users query', err),
  });
  const users = useMemo(() => {
    const allUsers = usersData?.searchUsers?.data || [];
    const followUsers = followedUsers?.getMyFollowedAuthors?.data.map((e) => e.authorUser) || [];
    const result = !search.length && followUsers.length ? followUsers : allUsers;

    return result;
  }, [search, followedUsers, usersData]);

  const filteredUsers = useMemo(
    () => users.filter((elem) => !selectedUsers.some((selectedUser) => selectedUser.id === elem.id)),
    [users, selectedUsers],
  );

  const onSearch = (value: string) => {
    setInputSearchValue(value);
  };

  const onSelect = ({ value }: any) => {
    const changedUser = users.find((elem) => elem.id === value);
    if (!changedUser) {
      return;
    }
    setSelectedUsers((oldValue) => [...oldValue, changedUser]);
    setInputSearchValue(() => '');
  };

  const onDelete = (id: string) => {
    const selectedUsersClone = [...selectedUsers];
    const changedUserIndex = selectedUsersClone.findIndex((elem) => elem.id === id);

    if (changedUserIndex !== -1) {
      selectedUsersClone.splice(changedUserIndex, 1);
      setSelectedUsers(selectedUsersClone);
    }
  };

  const getPopupContainer = () => {
    const el: any = document.getElementById('multiselect-container');
    return el;
  };

  useEffect(() => {
    setTargetEmployees && setTargetEmployees(selectedUsers);
  }, [selectedUsers]);

  useEffect(() => {
    const defaultValue = '@';
    const textarea: any = document.getElementById(mentionsTextareaId);
    const event = new Event('keyup', { bubbles: true });
    setInputSearchValue(defaultValue);
    setTimeout(() => textarea.dispatchEvent(event), 500);
  }, []);

  return (
    <MainContainer id="multiselect-container">
      <StyledMentions
        id={mentionsTextareaId}
        ref={targetEmployeesRef}
        autoFocus={true}
        filterOption={() => true}
        value={inputSearchValue}
        onChange={onSearch}
        onSelect={onSelect}
        placeholder={'@ to tag a colleague'}
        placement="top"
        split=""
        getPopupContainer={getPopupContainer}
      >
        {filteredUsers.map((elem) => {
          const { firstName, lastName, fullName, id, photoUrl } = elem;
          let mentionedText = `${firstName} ${lastName}`;
          if (fullName && fullName !== mentionedText) {
            mentionedText += ` (${fullName})`;
          }
          return (
            <StyledOption key={id} value={id}>
              <StyledElement>
                <StyledAvatar
                  src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />}
                  alt={'photo'}
                />
                <p>{mentionedText}</p>
              </StyledElement>
            </StyledOption>
          );
        })}
      </StyledMentions>
      <InputPlaceholder>You need to use @ to tag your colleague</InputPlaceholder>
      <MentionsContainer>
        {selectedUsers.map((elem) => {
          const { fullName, id, photoUrl } = elem;
          return (
            <Mention key={id}>
              <StyledAvatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} alt={'photo'} />
              <p>{fullName}</p>
              <StyledClose onClick={() => onDelete(id)} />
            </Mention>
          );
        })}
      </MentionsContainer>
    </MainContainer>
  );
};

export default UsersMultiSelector;
