import React, { FC, Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { message, Popover } from 'antd';
import 'react-custom-scroll/dist/customScroll.css';

import {
  MainCommentContainer,
  CommentsMainContainerContainer,
  CommentTextContainer,
  CommentContainer,
  RepliesControlContainer,
  RepliesControl,
  RepliesButton,
  StyledAvatar,
  NameContainer,
  StyledDropdown,
  Menu,
  StyledDescription,
  ReactionsContainer,
  AvatarContainer,
  MainReactionsContainer,
  ReactionContainer,
  ViewReactionsButton,
  Reactions,
  ReactionsMainContainer,
  ReactionElement,
  ReactionContainerForTooltip,
  MainNameContainer,
  Point,
  Edited,
  StyledFollowPopover,
  SeeMoreButton,
} from './styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { IComment } from 'modules/common/gql/models/newsFeedPost.model';
import { UserState } from 'store/domains/user/user.types';
import { RootState } from 'store/rootReducer';
import { DELETE_COMMENT, SET_COMMENT_REACTION, UNSET_COMMENT_REACTION } from '../../../../gql/mutation';
import { reactionsTypes } from 'modules/common/const';
import ReactionsPopover from 'modules/news-feed/pages/Feed/components/PostCard/components/ReactionsPopover';
import DeleteModal from 'modules/common/components/DeleteModal';
import { EditPopoverContainer, EditPopoverElement } from '../PostCard/styles';
import editImage from 'modules/news-feed/pages/Feed/assets/edit.svg';
import deleteImage from 'modules/news-feed/pages/Feed/assets/delete.svg';
import Replies from './components/Replies';
import { getPostReactions } from './utils';
import FollowPopover from 'modules/common/components/popovers/FollowPopover';
import { GET_FOLLOWED_USERS } from 'modules/team/gql/query';
import { IFollowedAuthorsResult, IUser } from 'modules/team/pages/ClubView/ClubView';
import { FOLLOW_AUTHOR, UNFOLLOW_AUTHOR } from 'modules/team/gql/mutation';
import { emitFollowRequest } from 'utils/mixpanel';
import { GET_USER_BY_ID } from 'modules/profile/gql/query';
import { richTextToPlain } from 'utils/parsers';
import ViewReactionsModal from 'modules/news-feed/pages/Feed/components/modals/ViewReactionsModal';
import { emitReactComment } from 'utils/mixpanel';

interface IProps {
  isOpened: boolean;
  onReply: (commentId: string) => void;
  isFullPost?: boolean;
  editHandler: (id: string) => void;
  postId: string | null;
  isFullModal?: boolean;
  mentionsClickHandler: (e: any) => void;
  setChangedCommentRef?: (id: string, ref: HTMLDivElement | null) => void;
  commentsData: IComment[];
  changeCommentForScroll: (id: string) => void;
}

interface IUserByIdResult {
  user: IUser;
}

export interface IFollowPopoverData {
  userId: string;
  commentId: string;
}

const Comments: FC<IProps> = ({
  isOpened,
  onReply,
  isFullPost,
  editHandler,
  postId,
  isFullModal,
  mentionsClickHandler,
  setChangedCommentRef,
  commentsData,
  changeCommentForScroll,
}) => {
  const { data: followedUsers } = useQuery<IFollowedAuthorsResult>(GET_FOLLOWED_USERS);
  const [followPopoverData, setFollowPopoverData] = useState<IFollowPopoverData | null>(null);
  const currentUser: UserState = useSelector((state: RootState) => state?.user);
  const [reactionPopoverId, setReactionPopoverId] = useState<string | null>(null);
  const [commentIdForDelete, setCommentIdForDelete] = useState<string | null>('');
  const [commentIdForReactions, setCommentIdForReactions] = useState<string | null>(null);
  const [showMorePosts, setShowMorePosts] = useState<string[]>([]);

  const [getUserData, { data: userByIdData }] = useLazyQuery<IUserByIdResult>(GET_USER_BY_ID);

  useEffect(() => {
    if (!followPopoverData?.userId) {
      return;
    }
    getUserData({
      variables: {
        id: followPopoverData?.userId,
      },
    });
  }, [followPopoverData]);

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    onError: (err) => message.error(err),
  });

  const [setCommentReaction] = useMutation(SET_COMMENT_REACTION, {
    onError: (err) => message.error(err),
  });

  const [unsetCommentReaction] = useMutation(UNSET_COMMENT_REACTION, {
    onError: (err) => message.error(err),
  });

  const deleteCommentHandler = (id: string) => {
    deleteComment({
      variables: {
        commentId: id,
      },
    });
    setCommentIdForDelete(null);
  };

  const setReactionHandler = (reactionType: string, id: string) => {
    if (postId) {
      setCommentReaction({
        variables: {
          type: reactionType,
          commentId: id,
          postId,
        },
      });
      emitReactComment({ reactionType: reactionType.toLowerCase() });
    }
  };

  const unsetReactionHandler = (reactionType: string, id: string) => {
    if (postId) {
      unsetCommentReaction({
        variables: {
          type: reactionType,
          commentId: id,
          postId,
        },
      });
    }
  };

  const handleVisibleReactionPopover = (id: string | null) => {
    setReactionPopoverId(id);
  };

  const closeFollowPopover = () => {
    setFollowPopoverData(() => null);
  };

  const [followUser] = useMutation(FOLLOW_AUTHOR, {
    onCompleted: () => {
      emitFollowRequest({ action: 'follow' });
    },
    onError: () => message.error('Error'),
  });

  const [unfollowUser] = useMutation(UNFOLLOW_AUTHOR, {
    onCompleted: () => {
      emitFollowRequest({ action: 'unfollow' });
    },
    onError: () => message.error('Error'),
  });

  const followHandler = (id: string) => {
    followUser({
      variables: {
        authorId: id,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWED_USERS,
        },
      ],
    });
  };

  const unfollowHandler = (id: string) => {
    unfollowUser({
      variables: {
        authorId: id,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWED_USERS,
        },
      ],
    });
  };
  const setPopoverData = (data: IFollowPopoverData | null) => {
    setFollowPopoverData(data);
  };

  const setMorePost = (id: string) => {
    setShowMorePosts((prevValue) => [...prevValue, id]);
  };

  const setIdForReactions = (id: string | null) => {
    setCommentIdForReactions(id);
  };

  if (!isOpened) {
    return null;
  }

  return (
    <CommentsMainContainerContainer>
      {postId && commentIdForReactions && (
        <ViewReactionsModal
          isVisible={Boolean(postId) && Boolean(commentIdForReactions)}
          onClose={() => setIdForReactions(null)}
          postId={postId}
          commentId={commentIdForReactions}
        />
      )}
      <DeleteModal
        type={'DELETE'}
        isOpen={Boolean(commentIdForDelete)}
        closeHandler={() => setCommentIdForDelete(null)}
        discardHandler={() => setCommentIdForDelete(null)}
        isVisibleCloseIcon={true}
        onDeleteHandler={() => commentIdForDelete && deleteCommentHandler(commentIdForDelete)}
        title={'Delete Comment'}
        subtitle={`Are you sure you want to delete this comment?`}
        titleCancelButton={'Cancel'}
        titleConfirmButton={'Delete'}
      />
      {commentsData &&
        commentsData.map((elem) => {
          const { text, createdAt, author, comments, _id, reactions, changed } = elem;
          const { data: replies } = comments;
          const { photoUrl, id: userId, fullName } = author;

          const currentReactions = getPostReactions(reactions);
          const bestReactions = currentReactions.sort((firstElem, secondElem) => secondElem.count - firstElem.count).slice(0, 3);
          const allReactions = currentReactions.reduce((acc, current) => acc + current.count, 0);
          const keyOfCurrentReaction =
            reactions && Object.keys(reactions).find((elem) => reactions[elem]?.includes(currentUser.id));
          const currentReaction = keyOfCurrentReaction ? reactionsTypes[keyOfCurrentReaction] : null;

          return (
            <MainCommentContainer ref={(ref) => setChangedCommentRef && setChangedCommentRef(_id, ref)} key={_id}>
              <CommentContainer>
                <StyledFollowPopover
                  onVisibleChange={(val) => !val && closeFollowPopover()}
                  trigger={['click']}
                  content={() =>
                    userByIdData && (
                      <FollowPopover
                        followHandler={followHandler}
                        unfollowHandler={unfollowHandler}
                        userData={userByIdData.user}
                        followedUsers={followedUsers?.getMyFollowedAuthors.data || []}
                      />
                    )
                  }
                  visible={userByIdData && followPopoverData?.commentId === _id}
                  overlayClassName={'followPopover'}
                  placement={'topRight'}
                >
                  <AvatarContainer
                    onClick={() =>
                      setFollowPopoverData({
                        commentId: _id,
                        userId,
                      })
                    }
                  >
                    <StyledAvatar
                      icon={<img src={photoUrl || emptyAvatar} alt={'logo'} />}
                      $isChanged={userByIdData && followPopoverData?.commentId === _id}
                    />
                  </AvatarContainer>
                </StyledFollowPopover>
                <RepliesControlContainer>
                  <CommentTextContainer style={isFullPost ? {} : { width: 400 }}>
                    <NameContainer>
                      <MainNameContainer>
                        <h3>{fullName}</h3>
                        {changed && (
                          <Fragment>
                            <Point />
                            <Edited>Edited</Edited>
                          </Fragment>
                        )}
                      </MainNameContainer>
                      <StyledDropdown
                        overlay={
                          <EditPopoverContainer>
                            <EditPopoverElement onClick={() => editHandler(_id)}>
                              <img src={editImage} />
                              Edit
                            </EditPopoverElement>
                            <EditPopoverElement onClick={() => setCommentIdForDelete(_id)}>
                              <img src={deleteImage} />
                              Delete
                            </EditPopoverElement>
                          </EditPopoverContainer>
                        }
                        placement="bottomRight"
                        overlayStyle={{ zIndex: 999999999 }}
                        trigger={['hover']}
                      >
                        <Menu $isEditable={currentUser.id === userId} />
                      </StyledDropdown>
                    </NameContainer>
                    <StyledDescription
                      onClick={mentionsClickHandler}
                      dangerouslySetInnerHTML={{ __html: text }}
                      isSliced={!showMorePosts.includes(_id)}
                    />
                    {!showMorePosts.includes(_id) && richTextToPlain(text).length > 120 && (
                      <SeeMoreButton
                        onClick={() => {
                          setMorePost(_id);
                        }}
                      >
                        See more
                      </SeeMoreButton>
                    )}
                  </CommentTextContainer>
                  <ReactionsContainer style={isFullPost ? {} : { width: 400 }} isReply={false}>
                    <RepliesControl>
                      <p>{moment(createdAt).fromNow()}</p>
                      <StyledDropdown
                        onVisibleChange={(flag) =>
                          flag ? handleVisibleReactionPopover(_id) : handleVisibleReactionPopover(null)
                        }
                        visible={reactionPopoverId === _id}
                        overlay={
                          <ReactionsPopover
                            setReaction={(type) => setReactionHandler(type, _id)}
                            closeReactionsDropdown={() => handleVisibleReactionPopover(null)}
                          />
                        }
                        placement="topCenter"
                        trigger={['hover']}
                      >
                        {currentReaction ? (
                          <RepliesButton
                            onClick={() => unsetReactionHandler(currentReaction?.type || '', _id)}
                            isChanged={currentReaction}
                          >
                            {currentReaction?.type.toLowerCase()}
                          </RepliesButton>
                        ) : (
                          <RepliesButton isChanged={currentReaction} onClick={() => setReactionHandler('LIKE', _id)}>
                            Like
                          </RepliesButton>
                        )}
                      </StyledDropdown>
                      <RepliesButton onClick={() => onReply(_id)}>Reply</RepliesButton>
                    </RepliesControl>
                    {allReactions > 0 && (
                      <MainReactionsContainer onClick={() => setCommentIdForReactions(_id)}>
                        <ViewReactionsButton>{allReactions}</ViewReactionsButton>
                        <Popover
                          content={() => (
                            <ReactionsMainContainer>
                              {currentReactions.map((elem, i) => {
                                const { type, count } = elem;
                                const { image: ReactionSVG, background } = reactionsTypes[type] || {};
                                return (
                                  <ReactionElement key={i}>
                                    <ReactionContainerForTooltip key={`${i}_${type}`} style={{ background }}>
                                      <ReactionSVG />
                                    </ReactionContainerForTooltip>
                                    <p>{count}</p>
                                  </ReactionElement>
                                );
                              })}
                            </ReactionsMainContainer>
                          )}
                          title={null}
                          overlayClassName={'commentReaction'}
                        >
                          <Reactions>
                            {bestReactions &&
                              bestReactions.map((elem, i) => {
                                const { type } = elem;
                                const { image: ReactionSVG, background } = reactionsTypes[type] || {};
                                return (
                                  <ReactionContainer key={`${i}_${type}`} style={{ background }}>
                                    <ReactionSVG />
                                  </ReactionContainer>
                                );
                              })}
                          </Reactions>
                        </Popover>
                      </MainReactionsContainer>
                    )}
                  </ReactionsContainer>
                  <Replies
                    mentionsClickHandler={mentionsClickHandler}
                    currentUserId={currentUser.id}
                    editHandler={editHandler}
                    handleVisibleReactionPopover={handleVisibleReactionPopover}
                    isFullPost={isFullPost}
                    reactionPopoverId={reactionPopoverId}
                    replies={replies}
                    setCommentIdForDelete={setCommentIdForDelete}
                    setReactionHandler={setReactionHandler}
                    unsetReactionHandler={unsetReactionHandler}
                    closeFollowPopover={closeFollowPopover}
                    followHandler={followHandler}
                    followPopoverData={followPopoverData}
                    followedUsers={followedUsers?.getMyFollowedAuthors.data || []}
                    setPopoverData={setPopoverData}
                    unfollowHandler={unfollowHandler}
                    userByIdData={userByIdData?.user}
                    showMorePosts={showMorePosts}
                    setMorePost={setMorePost}
                    setIdForReactions={setIdForReactions}
                    changeCommentForScroll={changeCommentForScroll}
                    commentId={_id}
                  />
                </RepliesControlContainer>
              </CommentContainer>
            </MainCommentContainer>
          );
        })}
    </CommentsMainContainerContainer>
  );
};

export default Comments;
