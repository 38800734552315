import React, { Fragment, FC } from 'react';

import { TeamPopoverContent, StyledTeamPopoverLink, TeamPopoverLine } from '../styles';

interface IElement {
  value: string;
  link: string;
}

interface IProps {
  elements: IElement[];
  pathname: string;
}

const TeamPopover: FC<IProps> = ({ elements, pathname }) => {
  const teamElements = elements || [];
  return (
    <TeamPopoverContent>
      {teamElements.map((el, i) => (
        <Fragment key={i}>
          <StyledTeamPopoverLink to={el.link} $isActive={pathname === el.link}>
            {el.value}
          </StyledTeamPopoverLink>
          {i !== teamElements.length - 1 && <TeamPopoverLine />}
        </Fragment>
      ))}
    </TeamPopoverContent>
  );
};

export default TeamPopover;
