import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import {
  MainContainer,
  Header,
  StyledTitle,
  ReportsToUser,
  OrgChartMainContainer,
  UserContainer,
  DirectReports,
  StyledCanvas,
  SupOrgContainer,
  StyledOrgChart,
  ReportsToContainer,
} from './styles';
import { GET_USER_ORG_CHART, GET_FOLLOWED_USERS } from '../../gql/query';
import { FOLLOW_AUTHOR, UNFOLLOW_AUTHOR } from '../../gql/mutation';
import { UserState } from 'store/domains/user/user.types';
import { RootState } from 'store/rootReducer';
import StyledCard from './components/OrgChartCard';
import Slider from './components/Slider';
import SubOrgSlider from './components/SubOrgSlider';
import { PhoneUsages } from 'modules/common/gql/models/phone.model';
import { IFollowStatus } from 'modules/common/gql/models/user.model';
import { emitFollowRequest } from 'utils/mixpanel';
import Footer from 'modules/common/components/Footer';

interface IAddress {
  id: string;
  countryCode: string;
  usageType: PhoneUsages.BUSINESS | PhoneUsages.HOME;
  municipality: string;
}

export interface IUser {
  id: string;
  fullName: string;
  photoUrl?: string;
  job: {
    positionTitle: string;
    addresses: IAddress[];
  };
  workingAddress: IAddress;
  costCenter: {
    name: string;
  };
  terminatedAt: string | null;
  isActive: boolean;
  manager?: {
    id: string;
  };
  directReportsCount: number;
}

export interface IOrganization {
  id: string;
  name: string;
}

export interface IEntityData {
  user?: IUser;
  organization?: IOrganization;
}

export interface IAuthorUser {
  status: IFollowStatus;
  authorUser: {
    id: string;
  };
}

export interface IOrgChart {
  subject: IEntityData;
  reportsTo: IEntityData;
  directReports: IEntityData[];
  supOrg: IEntityData[];
}

interface IResultOrgChart {
  userOrgChartMixed: IOrgChart;
}

export type PositionsTypes = [number, number] | null;

export interface IFollowedAuthorsResult {
  getMyFollowedAuthors: {
    data: IAuthorUser[];
  };
}

interface IFollowPopoverData {
  userId: string | null;
  cardId: string | null;
}

const OrgChart = () => {
  const history = useHistory();
  const userData: UserState = useSelector((state: RootState) => state?.user);
  const { id: userId } = userData;
  const [followPopoverData, setFollowPopoverData] = useState<IFollowPopoverData>({
    userId: null,
    cardId: null,
  });
  const userCardRef = useRef<HTMLDivElement>(null);
  const reportsToRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [positionsData, setPositionsData] = useState<PositionsTypes[]>([]);
  const location = useLocation();

  const orgChartQueryVariables = useMemo(() => {
    const parsedSearch = qs.parse(location.search.slice(1));

    if (parsedSearch.userId) {
      return {
        userId: parsedSearch.userId,
      };
    } else if (parsedSearch.organizationId) {
      return {
        organizationId: parsedSearch.organizationId,
      };
    }

    return {
      userId,
    };
  }, [location, userId]);

  const { data } = useQuery<IResultOrgChart>(GET_USER_ORG_CHART, {
    variables: orgChartQueryVariables,
    onError: (err: Error) => message.error(err),
  });

  const { data: followedUsers } = useQuery<IFollowedAuthorsResult>(GET_FOLLOWED_USERS);

  const [followUser] = useMutation(FOLLOW_AUTHOR, {
    onCompleted: () => {
      emitFollowRequest({ action: 'follow' });
    },
    onError: () => message.error('Error'),
  });
  const [unfollowUser] = useMutation(UNFOLLOW_AUTHOR, {
    onCompleted: () => {
      emitFollowRequest({ action: 'unfollow' });
    },
    onError: () => message.error('Error'),
  });

  const changeUserHandler = (id: string) => {
    history.push(`/team/org-chart?userId=${id}`);
  };
  const changeOrganizationHandler = (id: string) => {
    history.push(`/team/org-chart?organizationId=${id}`);
  };
  const toProfileHandler = (id: string) => {
    history.push(`/profile/${id}`);
  };

  const drawLines = (elem: [number, number] | null, key: number, cardX: number, cardY: number, ctx: CanvasRenderingContext2D) => {
    if (elem && cardX && cardY && canvasRef.current) {
      const [toX, toY] = elem;

      if (toX > canvasRef.current.offsetWidth || toX < 0) {
        return;
      }

      if (positionsData.length === 1) {
        ctx.moveTo(cardX, cardY);
        ctx.lineTo(toX, toY);
      } else {
        const middleLinePositionY = cardY + (toY - cardY) / 2;

        if (key === 0) {
          ctx.moveTo(cardX, cardY);
          ctx.lineTo(cardX, middleLinePositionY);
        }

        const prevElement = positionsData[key - 1];

        if (prevElement) {
          const [prevX] = prevElement;

          ctx.moveTo(toX, middleLinePositionY);
          ctx.lineTo(prevX, middleLinePositionY);
        }
        ctx.moveTo(toX, middleLinePositionY);
        ctx.lineTo(toX, toY);
      }
    }
  };

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    const userCardPositions = userCardRef.current;
    const reportsToPositions = reportsToRef.current;

    if (!ctx || !canvasRef.current) {
      return;
    }

    let reportsToX = reportsToPositions ? reportsToPositions.offsetLeft + reportsToPositions.offsetWidth / 2 : 0;
    let reportsToY = reportsToPositions ? reportsToPositions.offsetTop + reportsToPositions.offsetHeight : 0;

    ctx.canvas.width = canvasRef.current.offsetWidth;
    ctx.canvas.height = canvasRef.current.offsetHeight;
    ctx.strokeStyle = '#E0E0E0';
    ctx.lineWidth = 1;

    if (!userCardPositions) {
      if (!Boolean(reportsToX) || !Boolean(reportsToY)) {
        return;
      }
      positionsData.forEach((elem, key) => drawLines(elem, key, reportsToX, reportsToY, ctx));
      ctx.stroke();
      return;
    }

    let userCardX = userCardPositions.offsetLeft + userCardPositions.offsetWidth / 2;
    let userCardY = userCardPositions.offsetTop + userCardPositions.offsetHeight;
    let userCardXForReportsTo = userCardPositions.offsetLeft + userCardPositions.offsetWidth / 2;
    let userCardYForReportsTo = userCardPositions.offsetTop;

    ctx.moveTo(userCardXForReportsTo, userCardYForReportsTo);
    if (Boolean(reportsToX) && Boolean(reportsToY)) {
      ctx.bezierCurveTo(
        userCardXForReportsTo,
        userCardYForReportsTo,
        userCardXForReportsTo,
        userCardYForReportsTo,
        reportsToX,
        reportsToY,
      );
    }

    positionsData.forEach((elem, key) => drawLines(elem, key, userCardX, userCardY, ctx));

    ctx.stroke();
  }, [positionsData]);

  const followHandler = (id: string) => {
    followUser({
      variables: {
        authorId: id,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWED_USERS,
        },
      ],
    });
  };

  const unfollowHandler = (id: string) => {
    unfollowUser({
      variables: {
        authorId: id,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWED_USERS,
        },
      ],
    });
  };

  if (!data || !followedUsers) {
    return null;
  }

  const { directReports, reportsTo, subject, supOrg } = data?.userOrgChartMixed || {};

  return (
    <>
      <StyledOrgChart>
        <MainContainer>
          <Header>
            <StyledTitle level={3}>Organizational Chart</StyledTitle>
          </Header>
          <OrgChartMainContainer>
            <StyledCanvas ref={canvasRef} />
            {reportsTo && (
              <ReportsToUser>
                <ReportsToContainer ref={reportsToRef}>
                  <StyledCard
                    user={reportsTo?.user}
                    organization={reportsTo?.organization}
                    toProfileHandler={toProfileHandler}
                    isHaveTopLine={
                      reportsTo.user
                        ? Boolean(reportsTo?.user?.manager?.id)
                        : reportsTo?.organization?.name !== 'Astreya Partners, LLC'
                    }
                    followPopoverData={followPopoverData}
                    changeFollowPopoverData={(id, cardId) => {
                      setFollowPopoverData({
                        cardId,
                        userId: id,
                      });
                    }}
                    followHandler={followHandler}
                    unfollowHandler={unfollowHandler}
                    followedUsers={followedUsers}
                    managerId={reportsTo?.user?.manager?.id}
                    topButtonClickHandler={() =>
                      reportsTo?.user?.manager?.id ? changeUserHandler(reportsTo?.user?.manager?.id || '') : null
                    }
                  />
                </ReportsToContainer>
              </ReportsToUser>
            )}
            {supOrg && subject && (
              <UserContainer>
                <SupOrgContainer ref={userCardRef}>
                  <SubOrgSlider
                    data={supOrg}
                    changeOrganizationHandler={changeOrganizationHandler}
                    organization={subject.organization}
                    changeUserHandler={changeUserHandler}
                    toProfileHandler={toProfileHandler}
                    followPopoverData={followPopoverData}
                    changeFollowPopoverData={(id, cardId) => {
                      setFollowPopoverData({
                        cardId,
                        userId: id,
                      });
                    }}
                    followedUsers={followedUsers}
                    followHandler={followHandler}
                    unfollowHandler={unfollowHandler}
                    user={subject.user}
                    reportsToData={reportsTo}
                    key={1}
                  />
                </SupOrgContainer>
              </UserContainer>
            )}
            {directReports && (
              <DirectReports>
                <Slider
                  data={directReports}
                  toProfileHandler={toProfileHandler}
                  setPositionsData={setPositionsData}
                  followPopoverData={followPopoverData}
                  changeFollowPopoverData={(id, cardId) => {
                    setFollowPopoverData({
                      cardId,
                      userId: id,
                    });
                  }}
                  followedUsers={followedUsers}
                  followHandler={followHandler}
                  unfollowHandler={unfollowHandler}
                />
              </DirectReports>
            )}
          </OrgChartMainContainer>
        </MainContainer>
      </StyledOrgChart>
      <Footer />
    </>
  );
};

export default OrgChart;
