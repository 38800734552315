import { RelatedEntityType, textForMultipleUsers, textForSingleUser } from './const';

export const getMessageForNotify = (
  typeOfPost: RelatedEntityType,
  preferredName: string,
  targetEmployeesCount: number,
  workAnniversaryCount: number,
) => {
  if (targetEmployeesCount > 1) {
    const getNotifyText = textForMultipleUsers[typeOfPost];

    if (!getNotifyText) {
      return '';
    }
    return getNotifyText({
      employeesCount: targetEmployeesCount - 1,
      preferredName,
      workAnniversaryCount,
    });
  } else {
    const getNotifyText = textForSingleUser[typeOfPost];

    if (!getNotifyText) {
      return '';
    }

    return getNotifyText({
      preferredName,
      employeesCount: targetEmployeesCount - 1,
      workAnniversaryCount: workAnniversaryCount || 1,
    });
  }
};
