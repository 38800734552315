import styled, { css } from 'styled-components';
import { Select } from 'antd';

import { ReactComponent as Arrow } from 'modules/profile/assets/images/arrow.svg';

export const TimeEditContainer = styled.div`
  position: relative;
`;

export const TimeEditInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TimeInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledArrow = styled(Arrow)`
  position: absolute;
  left: 10px;
  top: 13px;
  z-index: 999;
`;

export const StyledSelector = styled(Select)`
  width: 100%;
  .ant-select-selector {
    padding-left: 30px !important;
  }

  .ant-select-selection-search {
    left: 30px !important;
  }
`;

export const TimeControls = styled.div`
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 60px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
`;

export const ControlButton = styled.div`
  border: 1px solid #eff0f1;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  background: #fff;
  transition: 0.4s opacity;
  opacity: 1;

  ${({ isInvisible }: { isInvisible?: boolean }) =>
    isInvisible &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`;
