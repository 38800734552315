import styled from 'styled-components';
import { Layout, Row, Col } from 'antd';
import { ReactComponent as FooterIcon } from '../../assets/images/shared/footerLogo.svg';

export const Container = styled(Layout.Footer)`
  padding: 24px 106px;
  border-top: 1px solid #d7dadf;
  background: #fafbfc;
  position: absolute;
  bottom: 0;
  width: 100%;

  @media (max-width: 1200px) {
    border-top: none;
    padding: 14px 44px;
  }
`;

export const StyledFooterText = styled.span`
  font-weight: 500;
  color: #4a4a4a;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const StyledLogo = styled(FooterIcon)`
  position: absolute;
  top: -30px;
  right: 0;
  @media (max-width: 1200px) {
    position: initial;
  }
`;

export const StyledCol = styled(Col)`
  @media (max-width: 1200px) {
    flex: 0;
  }
`;

export const ColoredSpan = styled.span`
  color: #ff8600;
`;

export const ColoredLink = styled.a`
  color: #ff8600;
  font-weight: 600;
  font-size: 14px;
  margin-left: 3px;

  :hover {
    color: #ff8600;
  }
`;

export const StyledRow = styled(Row)``;
