export enum PhoneUsages {
  HOME = 'HOME',
  BUSINESS = 'BUSINESS',
}

export enum PhoneDeviceTypes {
  MOBILE = 'Mobile',
  FAX = 'Fax',
  LANDLINE = 'Landline',
}

export interface IPhoneModel {
  id?: string;
  phoneNumber?: string;
  phoneDeviceType: PhoneDeviceTypes;
  usageType?: PhoneUsages.HOME | PhoneUsages.BUSINESS;
  hidden: boolean;
}
