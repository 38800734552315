import styled from 'styled-components';
import { Modal, Slider, Button } from 'antd';
import AvatarEditor from 'react-avatar-editor';
import { ReactComponent as CloseIcon } from 'modules/profile/assets/images/achievements/close.svg';
import { ReactComponent as MinImage } from '../../../../../../assets/images/avatarEditor/minImage.svg';
import { ReactComponent as MaxImage } from '../../../../../../assets/images/avatarEditor/maxImage.svg';
import { ReactComponent as ClearEditor } from '../../../../../../assets/images/avatarEditor/clearEditor.svg';

interface IModal {
  $withAvatars: boolean;
}

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

export const StyledModal = styled(Modal)<IModal>`
  width: ${(props) => (props.$withAvatars ? '672' : '336')}px !important;
  min-width: 336px;
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 16px;
  }
`;

export const StyledAvatarEditor = styled(AvatarEditor)`
  width: 100% !important;
  height: 100% !important;
`;

export const EditorMainContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  .ant-slider-handle {
    border: 1px solid #fafafa !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
  }
  .ant-slider-rail {
    background: #e4e4e4 !important;
  }
  .ant-slider-track {
    background: #1890ff !important;
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export const MainHeaderContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  border-bottom: 1px solid #eff0f1;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111111;
`;

export const ContentContainer = styled.div`
  padding: 24px 24px 70px 24px;
  width: 100%;

  @media (max-width: 800px) {
    padding: 24px;
  }
`;

export const StyledMinImage = styled(MinImage)`
  margin-right: 8px;
`;

export const StyledMaxImage = styled(MaxImage)`
  margin-left: 8px;
`;

export const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
`;

export const CancelButton = styled(Button)`
  width: 80px;
  border-radius: 4px;
  border: 1px solid #6b778c;
  color: #6b778c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-weight: 600;
  user-select: none;

  :hover {
    border: 1px solid #6b778c;
    color: #6b778c;
  }
`;

export const UploadButton = styled(CancelButton)`
  border: none;
  background: #1890ff;
  color: #fff !important;
  margin-left: 8px;
  :hover {
    background: #1890ff;
    color: #fff;
    border: none;
  }
`;

export const StyledClearEditor = styled(ClearEditor)``;

export const ClearEditorContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  :hover {
    background: #fafafa;
    border-radius: 2px;
  }
`;

export const GlobalInputLabel = styled.label`
  cursor: pointer;
  min-width: 160px;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 288px;
  content: '';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 12px;
    line-height: 16px;
    color: #6b778c;
    margin: 0;
    padding: 0;
    margin-top: 8px;
    max-width: 126px;
    text-align: center;
  }

  @media (max-width: 800px) {
    height: auto;
    margin-bottom: 20px;
  }
`;

export const StyledLabel = styled.label`
  margin-top: 8px;
  padding: 4px 12px;
  background: #edf6ff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: #1890ff;
  cursor: pointer;
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
`;

export const MainInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Or = styled.span`
  color: #6b778c;
  font-size: 12px;
  margin: 0 20px 0 30px;
`;

export const AvatarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

export const AvatarsList = styled.ul`
  overflow: hidden;
  list-style: none;
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(7, 1fr);

  @media (max-width: 800px) {
    padding: 0 !important;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const AvatarsListItem = styled.li`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  cursor: pointer;

  & img {
    width: 41px;
    height: 41px;
  }
`;

export const SeeMoreButton = styled.span`
  cursor: pointer;
  font-size: 14px;
  color: #1890ff;
  line-height: 24px;
  font-weight: 600;
  align-self: flex-end;
  margin-top: 14px;
  text-align: right;
`;
