import { gql } from '@apollo/client';

export const FOLLOW_AUTHOR = gql`
  mutation followAuthor($authorId: String!) {
    followAuthor(authorId: $authorId)
  }
`;

export const UNFOLLOW_AUTHOR = gql`
  mutation unfollowAuthor($authorId: String!) {
    unfollowAuthor(authorId: $authorId)
  }
`;
