import React, { FC } from 'react';
import { utc } from 'moment';

import {
  DateContainer,
  StyledTimelineItem,
  TimelineAvatar,
  TimeLine,
  Point,
  TransferInfoContainer,
  YearContainer,
} from '../../styles';
import { ITimelineEventModel } from 'modules/common/gql/models/timelineEvent.model';
import ach from '../../assets/ach.svg';

interface IProps {
  data: ITimelineEventModel;
  isVisibleYear: boolean;
}

const Promotion: FC<IProps> = ({ data, isVisibleYear }) => {
  const { date, relatedEntity: { positionBusinessTitle } } = data;
  return (
    <StyledTimelineItem>
      {isVisibleYear && <YearContainer>{utc(date).format('YYYY')}</YearContainer>}
      <TimelineAvatar background={'#FFF0E0'}>
        <img src={ach} />
      </TimelineAvatar>
      <TimeLine>
        <DateContainer background={'#FFF0E0'} color={'#FA8C16'}>
          Promotion <Point /> {utc(date).format('DD MMMM')}
        </DateContainer>
        <TransferInfoContainer>
          <h2>Promoted to {positionBusinessTitle}</h2>
        </TransferInfoContainer>
      </TimeLine>
    </StyledTimelineItem>
  );
};

export default Promotion;
