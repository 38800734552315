import React, { FC } from 'react';
import { ClubViewElement, StyledAvatar, AvatarContainer, StyledFollowPopover, StyledAvatarContainer } from '../styles';
import { IUser, IFollowPopoverData, IAuthorUser } from '../ClubView';
import { Image } from 'antd';
import FollowPopover from 'modules/common/components/popovers/FollowPopover';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';

interface IProps {
  groupName: string;
  users: IUser[];
  setFollowPopoverData: React.Dispatch<React.SetStateAction<IFollowPopoverData>>;
  closeFollowPopover: () => void;
  followHandler: (id: string) => void;
  unfollowHandler: (id: string) => void;
  followedUsers: IAuthorUser[];
  followPopoverData: IFollowPopoverData;
}

const DefaultElement: FC<IProps> = ({
  groupName,
  users,
  setFollowPopoverData,
  closeFollowPopover,
  followHandler,
  unfollowHandler,
  followedUsers,
  followPopoverData,
}) => {
  return (
    <ClubViewElement>
      {users &&
        users.map((elem, userKey) => {
          const { id, photoUrl } = elem;

          return (
            <AvatarContainer
              style={userKey === 0 ? { marginTop: 0 } : {}}
              key={id}
              onClick={(e) => {
                e.stopPropagation();
                setFollowPopoverData({
                  userId: id,
                  clubName: `${id}-${groupName}`,
                });
              }}
            >
              <StyledFollowPopover
                onVisibleChange={(val) => !val && closeFollowPopover()}
                trigger={['click']}
                content={
                  <FollowPopover
                    followHandler={followHandler}
                    unfollowHandler={unfollowHandler}
                    userData={elem}
                    followedUsers={followedUsers}
                  />
                }
                visible={followPopoverData.userId === id && followPopoverData.clubName === `${id}-${groupName}`}
                overlayClassName={'followPopover'}
                placement={'topRight'}
              >
                <StyledAvatarContainer
                  isChanged={followPopoverData.userId === id && followPopoverData.clubName === `${id}-${groupName}`}
                >
                  <StyledAvatar key={id} src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
                </StyledAvatarContainer>
              </StyledFollowPopover>
            </AvatarContainer>
          );
        })}
    </ClubViewElement>
  );
};

export default DefaultElement;
