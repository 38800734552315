import React, { FC, useMemo, Fragment } from 'react';
import { v4 as generateUniqueKey } from 'uuid';
import { Tooltip, Image } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  MainCardContainer,
  StyledAvatar,
  NameContainer,
  InfoElement,
  StyledButton,
  StyledArrow,
  ButtonContainer,
  StyledTopLine,
  StyledBottomLine,
  AvatarContainer,
  StyledFollowPopover,
  CountOfDirectReports,
  StyledAvatarContainer,
  ArrowBottomButton,
  StyledEmptyOrganization,
  StyledAstreyaLogo,
  LogoContainer,
} from '../styles';
import { IUser, IOrganization } from '../OrgChart';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import location from 'modules/common/assets/images/map-icon.svg';
import portfolio from 'modules/common/assets/images/briefcase-icon.svg';
import FollowPopover from 'modules/common/components/popovers/FollowPopover';
import { IFollowedAuthorsResult } from '../OrgChart';
import { ReactComponent as ArrowBottom } from '../../../assets/arrowBottom.svg';

interface IProps {
  user?: IUser;
  organization?: IOrganization;
  toProfileHandler: (id: string) => void;
  isHaveTopLine?: boolean;
  isHaveBottomLine?: boolean;
  isOpacity?: boolean;
  followPopoverData: {
    userId: string | null;
    cardId: string | null;
  };
  changeFollowPopoverData: (id: string | null, cardId: string | null) => void;
  followHandler: (id: string) => void;
  unfollowHandler: (id: string) => void;
  followedUsers: IFollowedAuthorsResult;
  managerId?: string;
  topButtonClickHandler?: () => void;
  isSubjectCard?: boolean;
}

const OrgChartCard: FC<IProps> = ({
  user,
  organization,
  toProfileHandler,
  isHaveBottomLine,
  isHaveTopLine,
  isOpacity,
  followPopoverData,
  changeFollowPopoverData,
  followHandler,
  unfollowHandler,
  followedUsers,
  managerId,
  topButtonClickHandler,
  isSubjectCard,
}) => {
  const history = useHistory();
  const uniqueKey = useMemo(() => generateUniqueKey(), []);

  const isOrganizationType = useMemo(() => !user && organization, [organization, user]);

  const bottomButtonClickHandler = (id?: string) => {
    if (!id) {
      return;
    }
    if (isOrganizationType) {
      history.push(`/team/org-chart?organizationId=${id}`);
      return;
    }
    history.push(`/team/org-chart?userId=${id}`);
  };

  const openCardHandler = () => {
    if ((isOrganizationType || user?.directReportsCount) && !isSubjectCard) {
      bottomButtonClickHandler(isOrganizationType ? organization?.id : user?.id);
    }
  };

  return (
    <div style={{ padding: '5px', position: 'relative' }}>
      {isHaveTopLine && <StyledTopLine />}
      {managerId && topButtonClickHandler && (
        <ArrowBottomButton onClick={topButtonClickHandler} isToManagerButton={true}>
          <ArrowBottom />
        </ArrowBottomButton>
      )}
      <MainCardContainer isOpacity={isOpacity} onClick={openCardHandler}>
        {isOrganizationType ? (
          <LogoContainer>
            {organization?.name === 'Astreya Partners, LLC' ? <StyledAstreyaLogo /> : <StyledEmptyOrganization />}
          </LogoContainer>
        ) : (
          user && (
            <AvatarContainer
              onClick={(e) => {
                e.stopPropagation();
                changeFollowPopoverData(user.id, uniqueKey);
              }}
            >
              {user?.directReportsCount ? <CountOfDirectReports>{user?.directReportsCount}</CountOfDirectReports> : null}
              <StyledFollowPopover
                onVisibleChange={(val) => !val && changeFollowPopoverData(null, null)}
                trigger={['click']}
                content={
                  <FollowPopover
                    followHandler={followHandler}
                    unfollowHandler={unfollowHandler}
                    userData={user}
                    followedUsers={followedUsers.getMyFollowedAuthors.data}
                  />
                }
                visible={followPopoverData.userId === user.id && followPopoverData.cardId === uniqueKey}
                overlayClassName={'followPopover'}
                placement={'topRight'}
              >
                <StyledAvatarContainer isChanged={followPopoverData.userId === user.id && followPopoverData.cardId === uniqueKey}>
                  <StyledAvatar src={<Image preview={false} src={user.photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
                </StyledAvatarContainer>
              </StyledFollowPopover>
            </AvatarContainer>
          )
        )}
        {isOrganizationType ? (
          <Tooltip title={<span>{organization?.name}</span>} overlayClassName={'followPopoverTooltip'}>
            <NameContainer>
              <p>{organization?.name}</p>
            </NameContainer>
          </Tooltip>
        ) : (
          user && (
            <Fragment>
              <Tooltip title={<span>{user.fullName}</span>} overlayClassName={'followPopoverTooltip'}>
                <NameContainer>
                  <p>{user.fullName}</p>
                </NameContainer>
              </Tooltip>
              <Tooltip title={<span>{user?.job?.positionTitle}</span>} overlayClassName={'followPopoverTooltip'}>
                <InfoElement>
                  <img src={portfolio} />
                  <p>{user?.job?.positionTitle}</p>
                </InfoElement>
              </Tooltip>
              {user.workingAddress ? (
                <Tooltip
                  title={<span>{`${user.workingAddress?.municipality}, ${user.workingAddress?.countryCode}`}</span>}
                  overlayClassName={'followPopoverTooltip'}
                >
                  <InfoElement>
                    <img src={location} />
                    <p>{`${user.workingAddress.municipality}, ${user.workingAddress.countryCode}`}</p>
                  </InfoElement>
                </Tooltip>
              ) : (
                <InfoElement>
                  <img src={location} />
                  <p>
                    <span>{'No date yet'}</span>
                  </p>
                </InfoElement>
              )}
            </Fragment>
          )
        )}

        {!isOrganizationType && user && (
          <ButtonContainer>
            <StyledButton
              type={'primary'}
              onClick={(e) => {
                e.stopPropagation();
                toProfileHandler(user.id);
              }}
            >
              View Profile
              <StyledArrow />
            </StyledButton>
          </ButtonContainer>
        )}
      </MainCardContainer>
      {isHaveBottomLine && <StyledBottomLine />}
      {(isOrganizationType || user?.directReportsCount) && !isSubjectCard ? (
        <ArrowBottomButton onClick={openCardHandler}>
          <ArrowBottom />
        </ArrowBottomButton>
      ) : null}
    </div>
  );
};

export default OrgChartCard;
