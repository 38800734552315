import React from 'react';

import EmploymentInfo from './components/EmploymentInfo/EmploymentInfo';
import DirectReports from './components/DirectReports/DirectReports';
import Interests from './components/Interests/Interests';
import { StyledCard, MainContainer } from './styles';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import { IOrgChart } from '../../../../../team/pages/OrgChart/OrgChart';

export interface IAboutProps {
  user: IUserModel;
  orgChart: IOrgChart;
  refetchUser: () => void;
  isOwner: boolean;
  goToProfile: (id: string) => void;
  isEditableProfile: boolean;
}

const About: React.FC<IAboutProps> = ({ user, refetchUser, isOwner, orgChart, goToProfile, isEditableProfile }) => {
  if (!user) {
    return null;
  }
  return (
    <MainContainer>
      <StyledCard>
        <EmploymentInfo
          goToProfile={goToProfile}
          reportsTo={orgChart?.reportsTo}
          user={user}
          isOwner={isOwner}
          refetchUser={refetchUser}
        />
      </StyledCard>
      {/* <StyledCard>
        <Achievements user={user} refetchUser={refetchUser} isOwner={isOwner} isEditableProfile={isEditableProfile} />
      </StyledCard> */}
      <StyledCard>
        <Interests user={user} refetchUser={refetchUser} isOwner={isOwner} isEditableProfile={isEditableProfile} />
      </StyledCard>
      {orgChart?.directReports && (
        <StyledCard>
          <DirectReports goToProfile={goToProfile} reports={orgChart?.directReports} user={user} isOwner={isOwner} />
        </StyledCard>
      )}
    </MainContainer>
  );
};

export default About;
