import React, { FC, useMemo } from 'react';
import { MainContainer, StyledCard, ContentContainer, CloseContainer, TextContainer, StyledTooltip } from './styles';
import { BadgeTypes } from '../../MainContent';
import { ReactComponent as Close } from '../../../../assets/badgesImages/close.svg';
import { IBadge } from '../../MainContent';

interface IProps {
  changedBadge: BadgeTypes | null;
  setBadge: (badge: string | null) => void;
  badgesData: IBadge[];
}

const BadgesContainer: FC<IProps> = ({ changedBadge, setBadge, badgesData }) => {
  const changedBadgeData = useMemo(() => badgesData.find((elem) => elem._id === changedBadge), [changedBadge, badgesData]);

  if (changedBadgeData) {
    const { description, iconUrl, name, _id } = changedBadgeData;

    return (
      <ContentContainer>
        <StyledCard onClick={() => setBadge(_id)} isChangedBadge={true}>
          <CloseContainer
            onClick={(e) => {
              e.stopPropagation();
              setBadge(null);
            }}
          >
            <Close />
          </CloseContainer>
          <img src={iconUrl} alt={'badge'} />
        </StyledCard>
        <TextContainer>
          <h2>{name}</h2>
          <p>{description}</p>
        </TextContainer>
      </ContentContainer>
    );
  }
  return (
    <MainContainer>
      {badgesData.map((elem) => {
        const { iconUrl, _id, name } = elem;

        return (
          <StyledTooltip
            title={name}
            key={_id}
            overlayInnerStyle={{ borderRadius: 17, padding: 15 }}
          >
            <StyledCard onClick={() => setBadge(_id)}>
              <img src={iconUrl} alt={'badge'} />
            </StyledCard>
          </StyledTooltip>
        );
      })}
    </MainContainer>
  );
};

export default BadgesContainer;
