import { gql } from '@apollo/client';

export const GET_HELPFUL_ARTICLES = gql`
  query helpfulArticles {
    articlesBySearchFilter(input: { tags: [] }, pagination: { limit: 4, offset: 0 }) {
      data {
        article_id
        external_id
        external_section_id
        original_external_section_id
        section_name
        title
        slug
        contents
        image_url
        tags
      }
      pagination {
        total
      }
    }
  }
`;

export const GET_POPULAR_ARTICLES = gql`
  query popularArticles {
    popularArticles(pagination: { limit: 4, offset: 0 }) {
      data {
        _id
        title
        slug
      }
      pagination {
        total
      }
    }
  }
`;
