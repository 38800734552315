import styled from 'styled-components';
import { Avatar as AvatarPic } from 'antd';

import { ReactComponent as ThanksClapIcon } from 'modules/news-feed/pages/Feed/assets/thanksClap.svg';

export const MainContainer = styled.div`
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  border-radius: 4px;

  :hover {
    background: #fafafa;
  }
`;

export const UserContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 10px;
`;

export const Avatar = styled(AvatarPic)`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 8px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MessageContainer = styled.p`
  color: #6b778c;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  margin: 0;
`;

export const UserText = styled.span`
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

export const MentiontText = styled.span`
  padding: 0;
  margin: 0;
  color: #1890ff;
`;

export const MessageText = styled.span`
  color: 4b4b4b;
  margin: 0 5px 0 5px !important;
`;

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PostTextContainer = styled.div`
  padding: 4px 8px;
  border: 1px solid #eff0f1;
  border-radius: 4px;
  background: #fafafa;
  font-weight: 400;
  font-size: 12px;
  color: #6b778c;
  word-break: break-word;
  margin-top: 8px;
  max-width: 256px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  span {
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: #6b778c;
  }

  p {
    margin: 0;
    padding: 0;
    max-width: 230px;
  }
`;

export const TargetPostTextContainer = styled(PostTextContainer)`
  max-width: 230px;
  max-height: 40px;
  overflow: hidden;
`;

export const ReadIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #ff8600;
  position: absolute;
  content: '';
  top: 50%;
  right: 10px;
`;

export const AvatarContainer = styled.div`
  position: relative;
`;

export const ClapContainer = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  border: 1px solid #ffffff;
  background: #ecf6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px;
  left: 0;
  border-radius: 100%;

  img {
    width: 100%;
  }
`;

export const StyledThanksClapIcon = styled(ThanksClapIcon)``;
