function embedIframe() {
  const iframe: any = document.createElement('iframe');
  iframe.src = 'https://astreya.onelogin.com/trust/saml2/http-post/sso/cc228ccd-7ef6-4eaf-87b8-e4a432cae8dc';
  iframe.style.position = 'absolute';
  iframe.style.display = 'none';
  iframe.id = 'smartRecruitesIframe';
  iframe.addEventListener(
    'load',
    function resizeIframe() {
      console.log('Onelogin for SmartRecruiters Iframe');
    },
    false
  );
  document.body.appendChild(iframe);
}

export default embedIframe;