import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { Popover, Typography, Button, Drawer, Input } from 'antd';

import { ReactComponent as MenuTabletIcon } from './assets/menuTablet.svg';
import { ReactComponent as CaretDownIcon } from './assets/globalSearch/caretDown.svg';
import { ReactComponent as Logo } from './assets/logo.svg';

export const HeaderContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(28, 37, 46, 0.121569);
  position: relative;
`;

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 8px 106px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    padding: 8px 40px;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ControlElement = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 10px;
  margin-bottom: -10px;
  position: relative;

  :last-child {
    margin-right: 0px;
  }
`;

export const NotifyCountContainer = styled.div`
  border: 1px solid #ffffff;
  border-radius: 8px;
  background: #fa8c16;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  left: 6px;
  padding: 2px 5px 0px 5px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 7px;
  font-weight: 600;
`;

export const NotifyContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchControlContainer = styled(ControlElement)`
  width: 250px;
  justify-content: flex-end;
  padding-right: 25px;
  cursor: auto;
  @media (min-width: 1025px) {
    width: auto;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 34px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MenuElement = styled(NavLink)`
  fill: #4a4a4a;
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 14px;
  margin-left: 30px;
  transition: 0.2s;

  p {
    margin: 0 0 0 10px;
  }

  :first-child {
    margin-left: 0;
  }

  :hover {
    color: #1890ff;
    fill: #1890ff;
  }
`;

interface IMenu {
  $isActive: boolean;
  activeStyles: { fill: string; color: string };
}

export const NoRedirectMenuElement = styled.span<IMenu>`
  fill: #4a4a4a;
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 14px;
  margin-left: 30px;
  cursor: pointer;
  transition: 0.2s;

  p {
    margin: 0 0 0 10px;
  }

  :hover {
    color: #1890ff;
    fill: #1890ff;
  }
  ${({ $isActive, activeStyles }) =>
    $isActive
      ? `
      color: #1890ff;
      fill: #1890ff;
    :hover {
      color: ${activeStyles.color};
    }
    svg > path {
      fill: ${activeStyles.fill};
    }
  `
      : ``}
`;

export const CaretDown = styled(CaretDownIcon)`
  position: relative;
  left: 4px;
`;

export const StyledLink = styled.a`
  fill: #4a4a4a;
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 14px;
  margin-left: 30px;
  transition: 0.2s;

  p {
    margin: 0 0 0 13px;
  }

  :first-child {
    margin-left: 0;
  }

  :hover {
    color: #1890ff;
    fill: #1890ff;
  }
`;

export const MenuTabletElement = styled(NavLink)`
  fill: #4a4a4a;
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 14px;
  width: 100%;
  padding: 15px 50px;

  p {
    margin: 0 0 0 13px;
  }

  :first-child {
    margin-top: 0;
  }

  :hover {
    color: #4a4a4a;
  }
`;

export const MenuTabletSubElement = styled(MenuTabletElement)`
  padding: 15px 50px;
  padding-left: 70px;
`;

export const TabletExternalLink = styled.a`
  fill: #4a4a4a;
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-size: 14px;
  width: 100%;
  padding: 15px 50px;

  p {
    margin: 0 0 0 13px;
  }

  :first-child {
    margin-top: 0;
  }

  :hover {
    color: #4a4a4a;
  }
`;

export const StyledPopover = styled(Popover)``;

export const TeamPopover = styled(Popover)``;

export const MainPopoverContainer = styled.div``;

export const ProfileInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ProfileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7px;
`;

export const Name = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  margin-top: 4px;
`;

export const PreferedName = styled(Typography.Text)`
  position: relative;
  left: 1px;
  color: #4a4a4a;
  font-size: 12px;
`;

export const SplitLine = styled.div`
  background: #eff0f1;
  width: 100%;
  height: 1px;
  margin-top: 16px;
`;

export const MenuElementProfile = styled(Typography.Text)`
  font-size: 12px;
  margin-top: 16px;
  cursor: pointer;
`;

export const MenuProfile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonLink = styled(Button)`
  margin-left: 0;
  padding-left: 0;
`;

export const StyledMenuTablet = styled(MenuTabletIcon)`
  display: none;
  margin-right: 20px;
  @media (max-width: 1024px) {
    display: block;
  }
`;

export const StyledDrawer = styled(Drawer)`
  z-index: 9999;
  .ant-drawer-body {
    padding: 0px;
  }
`;

export const MenuTablet = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 44px;
`;

export const TeamPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IStyledTeamPopoverLink {
  $isActive?: boolean;
}

export const StyledTeamPopoverLink = styled(Link)<IStyledTeamPopoverLink>`
  color: #4b4b4b;
  font-size: 12px;
  line-height: 16px;
  ${({ $isActive }) =>
    $isActive &&
    `
      color: #1890ff;
      fill: #1890ff;
  `}
`;

export const TeamPopoverLine = styled.div`
  background: #eff0f1;
  height: 1px;
  width: 100%;
  content: '';
  margin: 16px 0;
`;

export const StyledMenuPopover = styled(Popover)``;

export const StyledNotificationsPopover = styled(Popover)``;

export const MenuPopoverMainContainer = styled.div`
  width: 360px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const MenuPopoverElement = styled.a`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  border-radius: 8px;

  :hover {
    background: #fafafa;
  }

  & > span {
    color: #ff8600;
    font-weight: 600;
  }

  img {
    max-width: 100px;
  }
`;

export const GlobalSearchInput = styled(Input)`
  background: #fafafa;
  border-radius: 15px;
  border: none !important;
  box-shadow: none !important;
  width: 100%;
  margin-right: -11px;

  input {
    background: #fafafa;
  }
`;

export const StyledSearchPopover = styled(Popover)``;

export const StyledSearchContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
`;

export const StyledLogo = styled(Logo)`
  cursor: pointer;
`;
