import React from 'react';
import { useHistory } from 'react-router-dom';

import { MainContainer, ContentContainer, MainContentContainer, Title, SubTitle, Button } from './styles';
import Footer from 'modules/common/components/Footer';
import errorPageImage from 'modules/common/assets/images/errorPageImage.svg';
import router from 'app/routes';

const ErrorPage = () => {
  const history = useHistory();
  return (
    <MainContainer>
      <MainContentContainer>
        <ContentContainer>
          <img src={errorPageImage} />
          <Title>Something went wrong</Title>
          <SubTitle>Sorry, some error occured in the application.</SubTitle>
          <Button
            onClick={() => {
              history.push(router.home);
            }}
          >
            Go Home
          </Button>
        </ContentContainer>
      </MainContentContainer>
      <Footer />
    </MainContainer>
  );
};

export default ErrorPage;
