import styled from 'styled-components';
import { Typography, Space, List } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const SectionTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledLink = styled(Text)`
  font-weight: 600;
  color: #1890ff;
`;

export const StyledSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
`;

export const ListName = styled(Text)`
  margin-left: 23px;
`;

export const ArrowIcon = styled(RightOutlined)`
  position: relative;
  top: -1px;
  color: #1890ff;
  font-size: 8px;
  font-weight: 600;
`;

export const StyledList = styled(List)`
  margin-top: 15px;
  max-height: 250px;
  overflow: auto;
`;

export const ListItem = styled(List.Item)`
  &:first-child {
    border-top: 1px solid #f0f0f0;
  }

  &:last-child {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  min-width: 650px;
`;

export const EmptyContainer = styled.div`
  padding: 80px;
  text-align: center;
  color: #8d8d8d;
`;

export const ProfileLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .ant-avatar-image {
    min-width: 32px;
  }
`;
