import moment from 'moment';

export const getTimeList = () => {
  const timeList: string[] = [];

  new Array(24)
    .fill((value: any) => value)
    .forEach((acc, index) => {
      timeList.push(moment({ hour: index }).format('HH:mm:ss'));
    });

  return timeList;
};
