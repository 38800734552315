import React, { FC } from 'react';
import { Col, Typography, Tooltip } from 'antd';

import { StyledSubTitle, StyledTag, AddButton, Tags, StyledDeleteTagIcon, AddTagIcon, StyledRow } from '../styles';

export interface IPropTypes {
  data?: { id: string; name: string; approved: boolean }[] | null;
  title: string;
  buttonText: string;
  deleteHandler: (id: string) => void;
  openModal: () => void;
  notApprovedMessage: string;
  isOwner: boolean;
  isEditableProfile: boolean;
}

const TagsContainer: FC<IPropTypes> = ({
  data,
  title,
  buttonText,
  deleteHandler,
  openModal,
  notApprovedMessage,
  isOwner,
  isEditableProfile,
}) => {
  return (
    <Col span={24}>
      <StyledSubTitle level={5} disabled>
        {title}
      </StyledSubTitle>
      <StyledRow justify="space-between">
        <Tags>
          {data &&
            data.length > 0 &&
            data.map((item) => {
              const { id, name, approved } = item;

              if (!approved) {
                return (
                  <Tooltip key={id} title={<span>{notApprovedMessage}</span>} overlayClassName={'styledTooltip'}>
                    <StyledTag key={id} color="processing" $isNotApproved={true}>
                      <p>{name}</p>
                      {(isOwner || isEditableProfile) && <StyledDeleteTagIcon onClick={() => deleteHandler(id)} />}
                    </StyledTag>
                  </Tooltip>
                );
              }

              return (
                <StyledTag key={id} $withCross={isOwner || isEditableProfile} color="processing">
                  <p>{name}</p>
                  {(isOwner || isEditableProfile) && <StyledDeleteTagIcon onClick={() => deleteHandler(id)} />}
                </StyledTag>
              );
            })}
        </Tags>
        {(isOwner || isEditableProfile) && (
          <AddButton type="link" onClick={openModal}>
            <AddTagIcon />
            <Typography.Text strong>{buttonText}</Typography.Text>
          </AddButton>
        )}
      </StyledRow>
    </Col>
  );
};

export default TagsContainer;
