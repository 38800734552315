import styled, { css } from 'styled-components';

import { Typography, Tag, Button, Modal, Select, Input, Menu, Checkbox, Row } from 'antd';
import { ReactComponent as CloseDeleteModeIcon } from 'modules/common/assets/images/profile/interests/search.svg';
import { ReactComponent as DeleteIcon } from 'modules/common/assets/images/profile/interests/deleteElement.svg';
import { ReactComponent as AddIcon } from 'modules/common/assets/images/profile/interests/addElement.svg';
import { ReactComponent as Close } from 'modules/profile/assets/images/close.svg';
import { ReactComponent as Warn } from 'modules/profile/assets/images/warn.svg';

export const StyledTitle = styled(Typography.Text)`
  font-size: 16px;
  line-height: 24px;
`;

export const StyledSubTitle = styled(Typography.Title)`
  margin-top: 20px;
  margin-bottom: 3px;
  font-size: 14px !important;
  font-weight: 400 !important;
  cursor: auto !important;
  color: #6b778c !important;
`;

interface IStyledTag {
  $isNotApproved?: boolean;
  $withCross?: boolean;
}

export const StyledDeleteTagIcon = styled(DeleteIcon)`
  box-sizing: content-box;
  fill: #1890ff;
  cursor: pointer;
  margin-left: 11px;
  padding: 3px;
  &:hover > path {
    fill: red;
  }
`;

export const StyledTag = styled(Tag)<IStyledTag>`
  border: none;
  padding: 4px;
  margin-top: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  border: 1px solid #1890ff;
  box-sizing: border-box;
  border-radius: 52px;
  background: #edf6ff;
  padding: 4px 12px;

  p {
    max-width: 550px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    line-height: 24px;
    padding: 0;
    @media (max-width: 1400px) {
      max-width: 400px;
    }
    @media (max-width: 1200px) {
      max-width: 200px;
    }
    @media (max-width: 850px) {
      max-width: 150px;
    }
  }
  svg {
    margin-left: 10px;
    cursor: pointer;
  }

  ${(props) =>
    props.$isNotApproved &&
    `
    background: #E4E4E4;
    border: 1px solid #4B4B4B;
    color: #4B4B4B;
    ${StyledDeleteTagIcon} {
      fill: #E4E4E4 !important;
    }
  `}
`;

export const Tags = styled.div`
  display: flex;
  min-width: 50%;
  align-items: center;
  flex-wrap: wrap;
  max-width: 75%;
  @media (max-width: 900px) {
    max-width: 50%;
  }
`;

export const AddButton = styled(Button)`
  transition: 0.3s;
  margin: 0;
  padding: 0;
  & * {
    color: #1890ff;
  }
`;

export const StyledRow = styled(Row)`
  display: flex;
  align-items: flex-start;
`;

export const StyledModal = styled(Modal)`
  border-radius: 20px !important;
  .ant-modal-footer {
    border: none;
    border-radius: 0px 0px 16px 16px;
  }
  .ant-modal-title {
    font-weight: 600;
  }
  .ant-modal-header {
    border-radius: 16px 16px 0px 0px;
  }
  .ant-modal-content {
    border-radius: 16px;
  }
`;

export const FooterOfModal = styled.div`
  background: #fff;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledAddButton = styled(Button)`
  margin-left: 10px;
`;

export const StyledCancelButton = styled(Button)`
  border: 1px solid #4a4a4a;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    padding-left: 30px !important;
  }
`;

export const InterestSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    padding-left: 30px !important;

    input {
      padding-left: 20px !important;
    }
  }
`;

export const SubtitleModal = styled(Typography.Text)`
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 400;
`;

export const PopularTitle = styled(Typography.Title)`
  font-size: 14px !important;
  margin-top: 32px !important;
`;

export const TagsModal = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const PlaceholderContainer = styled.div`
  padding-left: 25px;
`;

export const SelectorContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledSearchIcon = styled(CloseDeleteModeIcon)`
  position: absolute;
  left: 10px;
  top: 8px;
  z-index: 9999;
`;

export const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledSearchInput = styled(Input)`
  width: 100%;
`;

export const SearchContainer = styled(Menu)`
  display: flex;
  flex-direction: column;
  border: 1px solid #1890ff;
  ${({ isHidden }: { isHidden: boolean }) =>
    isHidden &&
    css`
      display: none;
    `}
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 18px 0 0 16px !important;

  :first-child {
    margin: 0 0 0 16px !important;
  }
`;

export const AddTagIcon = styled(AddIcon)`
  position: relative;
  left: -11px;
  top: 3px;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SelectorElement = styled.div`
  font-size: 14px;
  padding: 5px;
  transition: 0.4s;
  height: 32px;
  cursor: pointer;
  width: 472px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :first-child {
    margin-top: 0;
  }

  :hover {
    background: rgba(228, 228, 228, 0.4);
  }
`;

export const NotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;

  h2 {
    font-size: 12px;
    line-height: 16px;
    color: #6b778c;
    margin: 0;
    padding: 0;
  }
`;

export const SmallNotFoundContainer = styled(NotFoundContainer)``;

export const RequestInterestButton = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  word-break: break-word;
  text-align: center;
`;

export const ChangedElementsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

interface IChangedElement {
  isNewValue?: boolean;
}

export const ChangedElement = styled.div<IChangedElement>`
  border-radius: 52px;
  border: 1px solid #1890ff;
  background: #edf6ff;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  margin: 10px 5px 0 0;
  fill: #1890ff;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    color: #1890ff;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  ${({ isNewValue }) =>
    isNewValue &&
    `
    border: 1px solid #F5222D;
    background: #FED9DB;
    border-radius: 52px;
    fill: #F5222D;
    p {
      color: #F5222D;
    }
  `}
`;

export const StyledCloseButton = styled(Close)`
  box-sizing: content-box;
  margin-left: 11px;
  cursor: pointer;
  transition: 0.3s;
  padding: 3px;
  &:hover > path {
    fill: red;
  }
`;

export const NotApprovedMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
`;

export const NotApprovedMessage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -10px;

  p {
    color: #f5222d;
    font-size: 10px;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }
`;

export const StyledWarnIcon = styled(Warn)`
  margin-right: 2px;
`;
