import React, { FC } from 'react';

import {
  StyledModalDelete,
  StyledCloseIcon,
  DeleteModalContainer,
  TitleDeletePopover,
  TextDeletePopover,
  ControlsDeleteModal,
  StyledDeleteButton,
  StyledCancelDeleteButton,
} from './styles';
import BucketIcon from 'modules/profile/assets/images/achievements/bucket.png';

interface IProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
  closeHandler: () => void;
  onDeleteHandler: () => void;
  isVisibleCloseIcon: boolean;
  titleCancelButton: string;
  titleConfirmButton: string;
  discardHandler: () => void;
  type: 'DELETE' | 'CANCEL';
}

const DeleteModal: FC<IProps> = ({
  title,
  isOpen,
  closeHandler,
  subtitle,
  onDeleteHandler,
  titleCancelButton,
  titleConfirmButton,
  discardHandler,
  type,
}) => (
  <StyledModalDelete visible={isOpen} footer={null} onCancel={closeHandler} closeIcon={<StyledCloseIcon />} width={312}>
    <DeleteModalContainer>
      <img src={BucketIcon} alt={'bucket'} />
      <TitleDeletePopover level={5}>{title}</TitleDeletePopover>
      <TextDeletePopover>{subtitle}</TextDeletePopover>
      <ControlsDeleteModal isDeleteModal={type === 'DELETE'}>
        <StyledCancelDeleteButton size={'large'} onClick={discardHandler}>
          {titleCancelButton}
        </StyledCancelDeleteButton>
        <StyledDeleteButton size={'large'} type={'primary'} onClick={onDeleteHandler}>
          {titleConfirmButton}
        </StyledDeleteButton>
      </ControlsDeleteModal>
    </DeleteModalContainer>
  </StyledModalDelete>
);

export default DeleteModal;
