import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { Image } from 'antd';

import {
  MainContainer,
  Avatar,
  InfoContainer,
  UserContainer,
  MessageContainer,
  MessageText,
  MainInfoContainer,
  PostTextContainer,
  ReadIndicator,
  UserText,
} from '../../component.styles';
import { ReactionContainer, AvatarContainer } from './styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { reactionsTypes } from 'modules/common/const';
import { richTextToPlain } from 'utils/parsers';
import { NOTIFICATION_TYPES } from '../../constants';
import { ICommentReactionNotification } from '../../interfaces';
import PostAuthor from '../PostAuthor';

interface IProps {
  data: ICommentReactionNotification;
  changeNotify: (id: string) => void;
}

const PostReaction: FC<IProps> = ({ data, changeNotify }) => {
  const { createdAt, status, relatedEntity, id, userId } = data;
  const { post, postUser, reactionType, reactionUser, postType } = relatedEntity || {};
  if (!post || !reactionType || !reactionUser) {
    return null;
  }
  const parsedText = useMemo(() => richTextToPlain(post?.text || ''), [post?.text]);
  const { image: ReactionSVG, background } = reactionsTypes[reactionType] || {};

  return (
    <MainContainer onClick={() => changeNotify(id)}>
      <UserContainer>
        <AvatarContainer>
          <Avatar src={<Image preview={false} src={reactionUser?.photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
          <ReactionContainer style={{ background }}>
            <ReactionSVG />
          </ReactionContainer>
        </AvatarContainer>
        <MainInfoContainer>
          <InfoContainer>
            <MessageContainer>
              <UserText>{reactionUser?.fullName}</UserText>
              <MessageText>reacted to your comment in</MessageText>
              <PostAuthor userId={userId} postUser={postUser} postType={postType} />
              <MessageText>post</MessageText>
            </MessageContainer>
            <MessageContainer>{moment(createdAt).format('MMM DD')}</MessageContainer>
          </InfoContainer>
          {parsedText && <PostTextContainer dangerouslySetInnerHTML={{ __html: parsedText }} />}
        </MainInfoContainer>
      </UserContainer>
      {(status === NOTIFICATION_TYPES.SEEN || status === NOTIFICATION_TYPES.NEW) && <ReadIndicator />}
    </MainContainer>
  );
};

export default PostReaction;
