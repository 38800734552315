import styled, { css } from 'styled-components';

import { Modal, Avatar } from 'antd';

export const StyledViewAllModal = styled(Modal)`
  .ant-modal-header {
    padding: 16px;
    border-bottom: none;
  }
  .ant-modal-title {
    color: #4b4b4b !important;
    font-weight: 600 !important;
    font-size: 16px !important;
  }
  .ant-modal-close {
    img {
      margin-top: -3px;
    }
  }
  .ant-modal-body {
    padding: 16px 0 16px 16px;
  }
  .ant-modal-content {
    border-radius: 4px;
  }

  .rcs-inner-handle {
    background-color: #e4e4e4;
  }
  .rcs-custom-scrollbar {
    width: 4px;
  }
  .rcs-inner-container {
    :first-child {
      div {
        margin-right: 0 !important;
      }
    }
  }
`;

export const SubHeaderContainer = styled.div`
  padding: 0 16px 0 0;
`;

export const SubHeaderViewAllModal = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  padding: 0px 4px 10px 4px;
  border-bottom: 1px solid #e0e0e0;
`;

export const UsersAllModalContainer = styled.div`
  margin-top: 8px;
  padding-right: 20px;
`;

export const UserElement = styled.div`
  height: 64px;
  padding-top: 16px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 50px 1fr;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;

  :hover {
    background: #eff0f1;
  }
`;

export const UserInfo = styled.div`
  min-width: 0; // NOTE: do NOT remove it to make 'white-space' property working properly
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #eff0f1;
  height: 100%;
  box-sizing: border-box;
`;

export const AvatarContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserNameContainer = styled.div`
  display: flex;
  min-width: 0; // NOTE: do NOT remove it to make 'white-space' property working properly
  flex-direction: column;

  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    padding: 0;
    margin: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    color: #6b778c;
    padding: 0;
    margin: 0;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const FollowButton = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-left: 8px;

  ${({ isUnFollow }: { isUnFollow?: boolean }) =>
    isUnFollow &&
    css`
      color: #6b778c;
    `}
`;

export const StyledUserAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  align-self: flex-start;
`;

export const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 40px;

  ${({ isLoadedAllData }: { isLoadedAllData?: boolean }) =>
    isLoadedAllData &&
    `
    height: 0;
    margin-top: 0;
  `};
`;
