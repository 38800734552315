import React, { FC, useState, useMemo } from 'react';
import { Suggestion, Suggestions, MoreSuggestionsIcon } from './styles';

interface IProps {
  suggestions: string[];
  applySuggestion: (suggestion: string) => void;
}

const ThanksSUggestions: FC<IProps> = ({ suggestions, applySuggestion }) => {
  const croppedSuggestionsCount = 3;
  const [startSuggestionsIndex, setStartSuggestionsIndex] = useState(0);
  const hasMoreSuggestions = useMemo(() => {
    const newSuggestionIndex = startSuggestionsIndex + croppedSuggestionsCount;
    const isLastPage = newSuggestionIndex >= suggestions.length;
    return !isLastPage;
  }, [startSuggestionsIndex, croppedSuggestionsCount, suggestions]);
  const finalSuggestions = suggestions.slice(startSuggestionsIndex, startSuggestionsIndex + croppedSuggestionsCount);

  const moreSuggestionsClick = () => {
    const nextIndex = hasMoreSuggestions ? startSuggestionsIndex + croppedSuggestionsCount : 0;
    setStartSuggestionsIndex(nextIndex);
  };

  return (
    <Suggestions>
      {finalSuggestions.map((text, i) => {
        return (
          <Suggestion key={i} onClick={() => applySuggestion(text + ' ')}>
            {text}
          </Suggestion>
        );
      })}
      {suggestions.length > croppedSuggestionsCount && (
        <Suggestion key={suggestions.length} onClick={moreSuggestionsClick}>
          <MoreSuggestionsIcon />
        </Suggestion>
      )}
    </Suggestions>
  );
};

export default ThanksSUggestions;
