import mixpanel from 'mixpanel-browser';
import { POST_TYPES } from 'modules/common/const';
import config from 'app/config';

const emailsToExclude = [
  'johndoe_demo@astreya.com',
  'aalviri@gmail.com',
  'vmalai@astreya.com',
  'jclaudio@astreya.com',
  'ekeough@astreya.com',
];

const track = (...args) => {
  return mixpanel.track(...args);
};

const cleanObj = (obj) => {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const mixpanelUserSet = (user) => {
  try {
    const { email, id } = user;
    const env = emailsToExclude.includes(email) ? 'testing' : config.env;
    const params = cleanObj({
      $referring_domain: document.referrer,
      $email: email,
      'User ID': id, // use human-readable names
      Environment: env,
    });
    mixpanel.people?.set(params);
    mixpanel.identify(id);
  } catch (e) {
    console.log('MIXPANEL: error during collectin data', e);
  }
};

export type IPostTypeEvent =
  | 'auto bday'
  | 'auto anniversary'
  | 'auto hire'
  | 'auto promotion'
  | 'recognition'
  | 'thanks'
  | 'individual'
  | 'company'
  | 'team';

export type IProfileAboutSectionEvent =
  | 'trainings'
  | 'certifications'
  | 'skills'
  | 'interests'
  | 'preferred name'
  | 'about me'
  | 'timezone'
  | 'working hours'
  | 'profile picture';

export type IFollowResponse = 'accept' | 'deny';

export type IViewProfileSection = 'about' | 'timeline';

export type IFollowAction = 'follow' | 'unfollow';

interface IProfileUpdatedEvent {
  section: IProfileAboutSectionEvent;
}

interface IPostEvent {
  postType: IPostTypeEvent;
}

interface IReactEvent {
  reactionType: string;
}

interface IFollowResponseEvent {
  followResponse: IFollowResponse;
}

interface IFollowRequestEvent {
  action: IFollowAction;
}

interface IViewProfileEvent {
  section: IViewProfileSection;
}

interface ISearchEvent {
  searchType: string;
}

interface IApplicationEvent {
  application: string;
}

interface ISearchQuery {
  query: string;
}

interface ISearchClubView {
  filtersCount: number;
  resultsCount: number;
}

export const emitProfileUpdated = (props: IProfileUpdatedEvent) => {
  try {
    mixpanel.track('Update Profile', { 'Profile Section': props.section });
  } catch (e) {
    console.log('emitProfileUpdated error', e);
  }
};

export const emitViewProfile = (props: IViewProfileEvent) => {
  try {
    mixpanel.track('View Profile', { 'Profile Section': props.section });
  } catch (e) {
    console.log('emitViewProfile error', e);
  }
};

export const emitViewPost = (props: IPostEvent) => {
  try {
    mixpanel.track('View Post', { 'Post Type': props.postType });
  } catch (e) {
    console.log('emitViewPost error', e);
  }
};

export const emitCommentPost = (props: IPostEvent) => {
  try {
    mixpanel.track('Comment on Post', { 'Post Type': props.postType });
  } catch (e) {
    console.log('emitCommentPost error', e);
  }
};

export const emitReplyComment = () => {
  try {
    track('Reply to a comment');
  } catch (e) {
    console.log('emitReplyComment error', e);
  }
};

export const emitReactPost = (props: IPostEvent & IReactEvent) => {
  try {
    track('React to Post', { 'Post Type': props.postType, 'Reaction Type': props.reactionType });
  } catch (e) {
    console.log('emitReactPost error', e);
  }
};

export const emitReactComment = (props: IReactEvent) => {
  try {
    track('React to a comment', { 'Reaction Type': props.reactionType });
  } catch (e) {
    console.log('emitReactComment error', e);
  }
};

export const emitHidePost = (props: IPostEvent) => {
  try {
    track('Hide Featured Post', { 'Post Type': props.postType });
  } catch (e) {
    console.log('emitHidePost error', e);
  }
};

export const emitCreatePost = (props: IPostEvent) => {
  try {
    track('Create Post', { 'Post Type': props.postType });
  } catch (e) {
    console.log('emitCreatePost error', e);
  }
};

export const emitFollowResponse = (props: IFollowResponseEvent) => {
  try {
    track('Friend Request Accepted', { 'Follow Response': props.followResponse });
  } catch (e) {
    console.log('emitFollowResponse error', e);
  }
};

export const emitFollowRequest = (props: IFollowRequestEvent) => {
  try {
    mixpanel.track('Friend Request', { 'Follow Action': props.action });
  } catch (e) {
    console.log('emitFollowRequest error', e);
  }
};

export const emitApplicationClick = (props: IApplicationEvent) => {
  try {
    mixpanel.track('Click a Different Application', { Application: props.application });
  } catch (e) {
    console.log('emitApplicationClick error', e);
  }
};

export const emitSearchClick = (props: ISearchEvent) => {
  try {
    track('Click a Search Result', { 'Search Result': props.searchType });
  } catch (e) {
    console.log('emitSearchClick error', e);
  }
};

export const emitSearchGlobalQuery = (props: ISearchQuery) => {
  try {
    track('Search Global', { Query: props.query });
  } catch (e) {
    console.log('emitSearchGlobalQuery error', e);
  }
};

export const emitSearchKBQuery = (props: ISearchQuery) => {
  try {
    track('Search Knowladge Base', { Query: props.query });
  } catch (e) {
    console.log('emitSearchKBQuery error', e);
  }
};

export const emitNotificationsClick = (props: IPostEvent) => {
  try {
    track('Notification', { 'Post Type': props.postType });
  } catch (e) {
    console.log('emitNotificationsClick error', e);
  }
};

export const emitSearchClubView = (props: ISearchClubView) => {
  try {
    track('Search Club View', {
      'Number of Filters Selected': props.filtersCount,
      'Number of Results': props.resultsCount,
    });
  } catch (e) {
    console.log('emitSearchClubView error', e);
  }
};

export const mapPostTypes = (type) => {
  switch (type) {
    case POST_TYPES.INDIVIDUAL:
      return 'individual';
    case POST_TYPES.RECOGNITION:
      return 'recognition';
    case POST_TYPES.COMPANY_NEWS:
      return 'company';
    case POST_TYPES.TEAM_NEWS:
      return 'team';
    case POST_TYPES.THANKS:
      return 'thanks';
    case POST_TYPES.HR_BIRTHDAY_WISHES:
      return 'auto bday';
    case POST_TYPES.HR_NEW_HIRES:
      return 'auto hire';
    case POST_TYPES.HR_PROMOTION:
      return 'auto promotion';
    case POST_TYPES.HR_WORK_ANNIVERSARY:
      return 'auto anniversary';
    default:
      return 'individual';
  }
};
