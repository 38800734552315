import React, { useCallback } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { message } from 'antd';

import { RootState } from 'store/rootReducer';
import { UserRoles, UserState } from 'store/domains/user/user.types';
import { GET_USER_BY_ID } from '../../profile/gql/query';
import ProfileNotFound from '../../profile/pages/Profile/components/ProfileNotFound/ProfileNotFound';
import { profileRoutes } from '../../profile/config/Profile.router';
import { EMP_PROF_PERMISSIONS } from 'modules/auth/constants';

const roleTabsPermissionMapper = {
  [UserRoles.OWNER]: ['about', 'timeline', 'oneToOne', 'perfomanceReview', 'okr', 'tickets', 'tasks'],
  [UserRoles.MANAGER]: ['about', 'timeline'],
  [UserRoles.EMPLOYEE]: ['about', 'timeline', 'oneToOne', 'perfomanceReview', 'okr', 'tickets'],
};

interface IParams {
  id?: string;
}

const withCheckPermission = (Component) => () => {
  const params: IParams = useParams();

  const user: UserState = useSelector((state: RootState) => state?.user);

  const { role = UserRoles.EMPLOYEE } = user;
  const { id: userId, userData: observerUser } = user;
  const checkPermission = useCallback(
    (tabKey) => role && roleTabsPermissionMapper[role] && roleTabsPermissionMapper[role].includes(tabKey),
    [role],
  );
  const isOwner = !params.id || params.id === userId;
  const id = params.id ? params.id : userId;
  const {
    data: profileUser,
    refetch: refetchUser,
    error,
  } = useQuery(GET_USER_BY_ID, {
    variables: { id },
    skip: !id || isOwner,
    onError: (err: Error) => message.error(err),
    fetchPolicy: 'network-only',
  });
  const isFollowed = profileUser?.user.isFollowedByMe;

  if ((!observerUser && isOwner) || (!profileUser && !error && !isOwner)) {
    return null;
  }

  if (error) {
    const [errorData] = error.graphQLErrors;
    const extensions: any = errorData?.extensions;

    return <ProfileNotFound fullName={extensions.fullName} />;
  }

  if (params.id && isOwner) {
    return <Redirect to={profileRoutes.profile} />;
  }
  // Checking permissions to edit profile
  const { costCenter: profileCostCenter } = (!isOwner && profileUser?.user) || {};
  const { costCenter: observerCostCenter } = observerUser || {};
  const { permissionIds } = user;
  const isEditableWithinCostCenter = permissionIds?.some((pId) => pId === EMP_PROF_PERMISSIONS.MANAGE_WITHIN_OWN_COST_CENTER);
  const isEditableBeyondCostCenter = permissionIds?.some((pId) => pId === EMP_PROF_PERMISSIONS.MANAGE_BEYOND_OWN_COST_CENTER);
  const isSameCostCenter = isOwner || profileCostCenter?.id === observerCostCenter?.id;
  const isEditableProfile =
    isOwner || (!isOwner && isEditableBeyondCostCenter) || (!isOwner && isSameCostCenter && isEditableWithinCostCenter);

  return (
    <Component
      isEditableProfile={isEditableProfile}
      isOwner={isOwner}
      isFollowed={isFollowed}
      observerData={observerUser}
      profileData={profileUser}
      refetchUser={() => refetchUser && refetchUser()}
      checkPermission={checkPermission}
    />
  );
};

export default withCheckPermission;
