import styled, { css } from 'styled-components';
import { Image } from 'antd';

import { ReactComponent as DownloadAttachment } from '../../../../assets/downloadAttachment.svg';
import { ReactComponent as Attachment } from '../../../../assets/attachment.svg';

interface ICardsContainer {
  $repeat: number;
}
export const CardsContainer = styled.div<ICardsContainer>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.$repeat}, 1fr);
  grid-gap: 9px;
  margin-top: 14px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const Card = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 145px;
  position: relative;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
`;

export const StyledImage = styled(Image)`
  height: 135px;
  width: 135px;
  border-radius: 100%;
`;

export const TargetLinks = styled.span`
  text-align: center;
  position: relative;
  margin-top: 12px;
`;

export const TargetLink = styled.span`
  cursor: pointer;
  color: #1890ff;
  position: relative;

  :hover {
    text-decoration: underline;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RecognitionBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;

  h2 {
    color: #4b4b4b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const RecognitionCard = styled.div`
  width: 136px;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  img {
    width: 100%;
  }
`;

export const CostCenterImage = styled.img`
  margin-right: 5px;
`;

export const MainCommentsContainer = styled.div`
  padding: 0 20px 20px 8px;

  ${({ isEditMode }: { isEditMode?: boolean }) =>
    isEditMode &&
    css`
      padding: 0 20px 35px 8px;
    `}
`;

export const CommentsScrollContainer = styled.div`
  padding: 0 8px 0 0;
  display: flex;
  flex-direction: column;
  max-height: 220px;

  @media (max-height: 650px) {
    max-height: 150px;
  }

  ${({ isCompanyNewsType }: { isCompanyNewsType?: boolean }) =>
    isCompanyNewsType &&
    `
    @media (max-height: 650px) {
      max-height: 180px !important;
    }
    max-height: 275px !important;
`}
`;

export const CommentsInputAndScrollContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

interface ITextScrollContainer {
  isSliced?: boolean;
  isTeamNewsPost?: boolean;
}

export const TextScrollContainer = styled.div<ITextScrollContainer>`
  height: 100%;
  max-height: 500px;

  @media (max-height: 650px) {
    max-height: 250px;
  }

  .rcs-custom-scroll {
    height: 100%;
  }

  ${({ isTeamNewsPost }) =>
    isTeamNewsPost &&
    `
      max-height: 420px;
  `}

  ${({ isSliced }) =>
    isSliced &&
    `
    @media (max-height: 650px) {
      max-height: 60px
    }
    height: 140px;
  `}
`;

export const CommentInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  bottom: 0;
  background: #fff;
  z-index: 999;
  box-shadow: 0px -3px 13px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  align-self: flex-end;
  margin-top: -100000px;

  @media (max-height: 650px) {
    margin-top: -40px;
  }
  ${({ isHidden }: { isHidden?: boolean }) =>
    isHidden &&
    `
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    z-index: -999;
    position: absolute;
  `}
`;

export const CommentsMainInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const CommentsControlContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 8px 20px;

  span {
    font-weight: 600;
    color: #111111;
    margin-left: 4px;
  }

  p {
    color: #111111;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    padding: 0;
  }
`;

export const Point = styled.div`
  width: 4px;
  height: 4px;
  background: #c4c4c4;
  content: '';
  border-radius: 100%;
  margin: 0 16px;
`;

export const CancelButton = styled.div`
  color: #6b778c;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
`;

export const PdfMainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const PdfFileList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
`;

export const PdfFile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  width: 100%;

  a {
    display: flex;
    align-items: center;
  }

  p {
    color: #1890ff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    max-width: 280px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
`;

export const StyledAttachmentLink = styled.a`
  max-width: 280px;
`;

export const StyledDownloadAttachment = styled(DownloadAttachment)`
  margin-left: 12px;
  cursor: pointer;
`;

export const StyledAttachment = styled(Attachment)`
  margin-right: 5px;
`;

export const ShowMoreButton = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-top: 12px;
  cursor: pointer;
  user-select: none;
  margin-left: 8px;
`;
