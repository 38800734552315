import { FETCH_USER_META_REQUEST, FETCH_USER_PERMISSIONS, USER_DATA_RETRIEVED } from './user.actionTypes';
import { IUserModel } from 'modules/common/gql/models/user.model';

export const fetchUserMeta = (token: string) => ({
  type: FETCH_USER_META_REQUEST,
  token,
});

export const fetchUserPermissions = (permissions: string[]) => ({
  type: FETCH_USER_PERMISSIONS,
  permissions,
});

export const userDataRetrieved = (userData: IUserModel) => ({
  type: USER_DATA_RETRIEVED,
  userData,
})
