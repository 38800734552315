import styled from 'styled-components';
import { Row, Typography, Col } from 'antd';
import { ReactComponent as Visible } from '../../../Timeline/assets/visible.svg';
import { ReactComponent as Hide } from '../../../Timeline/assets/hide.svg';

export const StyledRow = styled(Row)`
  margin-top: 12px;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
`;

export const StyledText = styled.p`
  margin: 0;
  padding: 0;
`;

export const StyledCol = styled(Col)`
  @media (max-width: 1200px) {
    min-width: 100%;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
`;

export const SectionTitle = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledTitle = styled(Typography.Text)`
  font-size: 12px;
  cursor: auto !important;
  color: #8d8d8d !important;
  line-height: 16px;
`;

export const StyledLink = styled.div`
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const HideButton = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #1890ff;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
`;

export const StyledVisible = styled(Visible)`
  margin-right: 10px;
`;

export const StyledHide = styled(Hide)`
  margin-right: 10px;
`;
