import { gql } from '@apollo/client';
import { NEWS_FEED_COMMON_FRAGMENT, NEWS_FEED_COMMENT_FRAGMENT } from './query';

export const namedNewsFeedPostsAddedSubscription = (postfix: string) => gql`
  subscription OnNewsFeedPostAdded_${postfix} {
    onNewsFeedPostAdded {
      ... on NewsFeedIndividualPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
      }
      ... on NewsFeedCompanyNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        publishAt
        headline
        isFeatured
        featuredExpAt
      }
      ... on NewsFeedTeamNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        publishAt
        costCenter {
          id
          name
        }
        costCenterId
        headline
        isFeatured
        featuredExpAt
      }
      ... on NewsFeedHrBirthdayWishesPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrNewHiresPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrPromotionPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrWorkAnniversaryPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          hireDate
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedThanksPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedKudosPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
      }
      ... on NewsFeedRecognitionPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            isProfilePhotoAvatar
            avatarBgColor
          }
          badge
        }
    }
  }
`;

export const namedNewsFeedPostsUpdatedSubscription = (postfix: string) => gql`
  subscription OnNewsFeedPostUpdated_${postfix} {
    onNewsFeedPostUpdated {
      ... on NewsFeedIndividualPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedCompanyNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        publishAt
        headline
        isFeatured
        featuredExpAt
      }
      ... on NewsFeedTeamNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        publishAt
        costCenter {
          id
          name
        }
        costCenterId
        headline
        isFeatured
        featuredExpAt
      }
      ... on NewsFeedHrBirthdayWishesPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrNewHiresPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrPromotionPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrWorkAnniversaryPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          hireDate
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedThanksPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedKudosPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedRecognitionPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            isProfilePhotoAvatar
            avatarBgColor
          }
          badge
        }
    }
  }
`;

export const namedNewsFeedPostsDeletedSubscription = (postfix: string) => gql`
  subscription OnNewsFeedPostDeleted_${postfix} {
    onNewsFeedPostDeleted {
      ... on NewsFeedIndividualPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
      }
      ... on NewsFeedCompanyNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        publishAt
        headline
        isFeatured
        featuredExpAt
      }
      ... on NewsFeedTeamNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        publishAt
        costCenter {
          id
          name
        }
        costCenterId
        headline
        isFeatured
        featuredExpAt
      }
      ... on NewsFeedHrBirthdayWishesPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrNewHiresPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrPromotionPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedHrWorkAnniversaryPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          hireDate
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedThanksPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
        targetEmployees {
          userId
          fullName
          photoUrl
          positionBusinessTitle
          isProfilePhotoAvatar
          avatarBgColor
        }
      }
      ... on NewsFeedKudosPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
        ${NEWS_FEED_COMMENT_FRAGMENT}
      }
      ... on NewsFeedRecognitionPostModel {
          ${NEWS_FEED_COMMON_FRAGMENT}
          ${NEWS_FEED_COMMENT_FRAGMENT}
          targetEmployees {
            userId
            fullName
            photoUrl
            positionBusinessTitle
            isProfilePhotoAvatar
            avatarBgColor
          }
          badge
        }
    }
  }
`;

export const ON_COMMENT_UPDATED = gql`
  subscription onNewsFeedCommentUpdated {
    onNewsFeedCommentUpdated {
      _id
      postId
      text
      plainText
      parentCommentId
      mentions {
        userId
        fullName
        photoUrl
      }
      changed
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
      author {
        fullName
        photoUrl
        id
      }
      createdAt
      commentsCount
      comments(pagination: { limit: 1000 }) {
        data {
          _id
          postId
          text
          plainText
          mentions {
            userId
            fullName
            photoUrl
          }
          changed
          reactions {
            LIKE
            CELEBRATE
            SUPPORT
            LOVE
            INSIGHTFUL
            CURIOUS
          }
          author {
            fullName
            photoUrl
            id
          }
          createdAt
        }
      }
    }
  }
`;

export const ON_COMMENT_DELETED = gql`
  subscription onNewsFeedCommentDeleted {
    onNewsFeedCommentDeleted {
      _id
      parentCommentId
      commentsCount
      postId
    }
  }
`;

export const ON_COMMENT_ADDED = gql`
  subscription onNewsFeedCommentAdded {
    onNewsFeedCommentAdded {
      _id
      postId
      text
      plainText
      parentCommentId
      mentions {
        userId
        fullName
        photoUrl
      }
      changed
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
      author {
        fullName
        photoUrl
        id
      }
      commentsCount
      createdAt
      comments(pagination: { limit: 1000 }) {
        data {
          _id
          postId
          text
          plainText
          parentCommentId
          mentions {
            userId
            fullName
            photoUrl
          }
          changed
          reactions {
            LIKE
            CELEBRATE
            SUPPORT
            LOVE
            INSIGHTFUL
            CURIOUS
          }
          author {
            fullName
            photoUrl
            id
          }
          createdAt
        }
        pagination {
          nextCursor
          pageCount
          limit
        }
      }
    }
  }
`;

export const namedReactionsSubscriptions = (postfix: string) => gql`
  subscription OnNewsFeedReaction_${postfix} {
      onNewsFeedReaction {
        _id
        postId
        commentId
        reactions {
          LIKE
          CELEBRATE
          SUPPORT
          LOVE
          INSIGHTFUL
          CURIOUS
        }
        userInfo {
          reactionType
          userProxies {
            userId
            fullName
            photoUrl
            positionBusinessTitle
          }
        }
      }
    }
`;

export const namedFollowChangeSubsriptions = (postfix: string) => gql`
subscription onNewsFeedFollowChange_${postfix} {
    onNewsFeedFollowChange {
      _id
      followerId
      authorId
      status
      followerUser {
        id
        photoUrl
        fullName
      }
      authorUser {
        id
        photoUrl
        fullName
      }
    }
  }
`;
