import React from 'react';

import { Filters, FilterSkeleton } from '../../styles';

const EmptyFilterSkeleton = () => {
  return (
    <Filters>
      <FilterSkeleton active />
      <FilterSkeleton active />
      <FilterSkeleton active />
      <FilterSkeleton active />
      <FilterSkeleton active />
      <FilterSkeleton active />
      <FilterSkeleton active />
    </Filters>
  );
};

export default EmptyFilterSkeleton;
