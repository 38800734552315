import React, { FC, Fragment } from 'react';

import {
  ImagesMainContainer,
  FullSizePostImage,
  FullSizeImageContainer,
  MiddleSizeImagesContainer,
  SmallSizePostImage,
  SmallSizeImageContainer,
  PostImageWrapper,
  SmallSizeImagesContainer,
  SingledPdfContainer,
  StyledAttachment,
  PdfName,
  MiddlePdfContainer,
  MainBigSizeContainer,
  FullSizePdfImageContainer,
  SmallSizePdfImageContainer,
} from '../styles';
import { getFileExtension } from 'modules/news-feed/pages/Feed/utils';
import { IUploadedFile } from 'modules/common/gql/models/newsFeedPost.model';

interface IProps {
  files: IUploadedFile[];
  onChangeFeedId: (key: number) => void;
}

const ImagesContainer: FC<IProps> = ({ files, onChangeFeedId }) => {
  if (files.length === 2) {
    const [firstImage, lastImage] = files;
    return (
      <MiddleSizeImagesContainer>
        <SmallSizeImageContainer onClick={() => onChangeFeedId(0)}>
          {getFileExtension(firstImage.fileName || 'png') === 'pdf' ? (
            <MiddlePdfContainer>
              <PdfName href={firstImage.fileUrl}>
                <StyledAttachment />
                <p>{firstImage.fileName}</p>
              </PdfName>
              <SmallSizePdfImageContainer>
                <SmallSizePostImage src={firstImage.previewImageUrl} />
              </SmallSizePdfImageContainer>
            </MiddlePdfContainer>
          ) : (
            <SmallSizePostImage src={firstImage.fileUrl} />
          )}
        </SmallSizeImageContainer>
        <SmallSizeImageContainer onClick={() => onChangeFeedId(1)}>
          {getFileExtension(lastImage.fileName || 'png') === 'pdf' ? (
            <MiddlePdfContainer>
              <PdfName href={lastImage.fileUrl}>
                <StyledAttachment />
                <p>{lastImage.fileName}</p>
              </PdfName>
              <SmallSizePdfImageContainer>
                <SmallSizePostImage src={lastImage.previewImageUrl} />
              </SmallSizePdfImageContainer>
            </MiddlePdfContainer>
          ) : (
            <SmallSizePostImage src={lastImage.fileUrl} />
          )}
        </SmallSizeImageContainer>
      </MiddleSizeImagesContainer>
    );
  } else if (files.length >= 3) {
    const [firstImage, middleImage, lastImage] = files;

    return (
      <SmallSizeImagesContainer>
        <FullSizeImageContainer onClick={() => onChangeFeedId(0)}>
          {getFileExtension(firstImage.fileName || 'png') === 'pdf' ? (
            <MiddlePdfContainer>
              <PdfName href={firstImage.fileUrl}>
                <StyledAttachment />
                <p>{firstImage.fileName}</p>
              </PdfName>
              <FullSizePdfImageContainer>
                <FullSizePostImage src={firstImage.previewImageUrl} />
              </FullSizePdfImageContainer>
            </MiddlePdfContainer>
          ) : (
            <FullSizePostImage src={firstImage.fileUrl} />
          )}
        </FullSizeImageContainer>
        <MainBigSizeContainer>
          <SmallSizeImageContainer onClick={() => onChangeFeedId(0)}>
            {getFileExtension(middleImage.fileName || 'png') === 'pdf' ? (
              <MiddlePdfContainer>
                <PdfName href={middleImage.fileUrl}>
                  <StyledAttachment />
                  <p>{middleImage.fileName}</p>
                </PdfName>
                <SmallSizePdfImageContainer>
                  <SmallSizePostImage src={middleImage.previewImageUrl} />
                </SmallSizePdfImageContainer>
              </MiddlePdfContainer>
            ) : (
              <SmallSizePostImage src={middleImage.fileUrl} />
            )}
          </SmallSizeImageContainer>
          <SmallSizeImageContainer onClick={() => onChangeFeedId(0)}>
            {getFileExtension(lastImage.fileName || 'png') === 'pdf' ? (
              <MiddlePdfContainer>
                <PdfName href={lastImage.fileUrl}>
                  <StyledAttachment />
                  <p>{lastImage.fileName}</p>
                </PdfName>
                <SmallSizePdfImageContainer>
                  {files.length > 3 ? <PostImageWrapper>+ {files.length - 2} more</PostImageWrapper> : null}
                  <SmallSizePostImage src={lastImage.previewImageUrl} />
                </SmallSizePdfImageContainer>
              </MiddlePdfContainer>
            ) : (
              <Fragment>
                {files.length > 3 ? <PostImageWrapper>+ {files.length - 2} more</PostImageWrapper> : null}
                <SmallSizePostImage src={lastImage.fileUrl} />
              </Fragment>
            )}
          </SmallSizeImageContainer>
        </MainBigSizeContainer>
      </SmallSizeImagesContainer>
    );
  }

  const [image] = files;

  return (
    <ImagesMainContainer onClick={() => onChangeFeedId(0)}>
      {getFileExtension(image.fileName || 'png') === 'pdf' ? (
        <MiddleSizeImagesContainer>
          <SingledPdfContainer>
            <PdfName href={image.fileUrl}>
              <StyledAttachment />
              <p>{image.fileName}</p>
            </PdfName>
            <FullSizePostImage src={image.previewImageUrl} />
          </SingledPdfContainer>
        </MiddleSizeImagesContainer>
      ) : (
        <FullSizeImageContainer>
          <FullSizePostImage src={image.fileUrl} />
        </FullSizeImageContainer>
      )}
    </ImagesMainContainer>
  );
};

export default ImagesContainer;
