import React from 'react';
import {
  StyledModal,
  MainHeaderContainer,
  HeaderContainer,
  StyledCloseIcon,
  Controls,
  CancelButton,
  UploadButton,
} from '../styles';
import { PreviewAvatarImage, AvatarMainContainer, AvatarContentContainer } from './styles';
import { IAvatarModel } from 'modules/common/gql/models/avatar.model';

interface IProps {
  avatar: IAvatarModel;
  closeHandler: () => void;
  cancelHandler: () => void;
  onSave: () => void;
}

const SelectedAvatar = ({
  avatar,
  closeHandler,
  cancelHandler,
  onSave,
}: IProps) => {
  return (
    <StyledModal $withAvatars={false} visible={true} footer={null}>
      <MainHeaderContainer>
        <HeaderContainer>
          Select an Avatar
          <StyledCloseIcon onClick={closeHandler} />
        </HeaderContainer>
      </MainHeaderContainer>
      <AvatarContentContainer>
        <AvatarMainContainer>
          <PreviewAvatarImage src={avatar.photoUrl} />
        </AvatarMainContainer>
        <Controls>
          <CancelButton onClick={cancelHandler}>Cancel</CancelButton>
          <UploadButton onClick={onSave}>Select</UploadButton>
        </Controls>
      </AvatarContentContainer>
    </StyledModal>
  );
};

export default SelectedAvatar;