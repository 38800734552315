import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import appRoutes from 'app/routes';
import PrivateRoute from 'modules/auth/components/PrivateRoute';
import OrgChart from '../pages/OrgChart';
import ClubView from '../pages/ClubView';
import { joinPath } from 'utils/path';

export const teamRoutes = {
  orgChart: joinPath(appRoutes.team, 'org-chart'),
  clubView: joinPath(appRoutes.team, 'club-view'),
};

const OrgChartRouter = () => {
  return (
    <Switch>
      <Redirect exact from={appRoutes.team} to={teamRoutes.orgChart} />
      <PrivateRoute exact path={teamRoutes.orgChart} component={OrgChart} />
      <PrivateRoute path={`${teamRoutes.orgChart}/:id`} component={OrgChart} />
      <PrivateRoute exact path={`${teamRoutes.clubView}`} component={ClubView} />
    </Switch>
  );
};

export default OrgChartRouter;
