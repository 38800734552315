import { gql } from '@apollo/client';

export const NOTIFICATION_COUNTER_UPDATED = gql`
  subscription onNotificationsCounterUpdated {
    onNotificationsCounterUpdated {
      userId
      counter
    }
  }
`;

export const USER_PERMISSION_CHANGED = gql`
  subscription onUserPermissionsChanged {
    onUserPermissionsChanged {
      userId
      permissionIds
    }
  }
`;
