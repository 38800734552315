import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';

import store from '../store';
import config from './config';

const cache: InMemoryCache = new InMemoryCache({});

const wsLink = new WebSocketLink({
  uri: `${config.graphqlSubscriptionUrl}`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      const token = store.getState().auth?.accessToken;
      return {
        authorization: token ? `Bearer ${token}` : '',
      };
    },
  },
});

const httpLink: any = createUploadLink({
  uri: config.graphqlUrl,
});

const authLink = setContext((_, { headers }) => {
  const token = store.getState().auth?.accessToken;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  cache,
  link: authLink.concat(splitLink),
  name: 'astreya-web-client',
  version: '1.3',
  queryDeduplication: false,
});

export default client;
