import React, { FC } from 'react';
import {
  StyledModalDelete,
  StyledCloseIcon,
  DeleteModalContainer,
  TitleDeletePopover,
  TextDeletePopover,
  ControlsDeleteModal,
  StyledDeleteButton,
  StyledCancelDeleteButton,
} from './styles';
import BucketIcon from 'modules/profile/assets/images/achievements/bucket.png';

export interface IProps {
  title: string;
  subtitle: string;
  isVisible: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const RemoveItemModal: FC<IProps> = ({ title, subtitle, onClose, onSubmit, isVisible }) => {
  return (
    <StyledModalDelete visible={isVisible} footer={null} onCancel={onClose} closeIcon={<StyledCloseIcon />} width={312}>
      <DeleteModalContainer>
        <img src={BucketIcon} alt={'bucket'} />
        <TitleDeletePopover level={5}>{title}</TitleDeletePopover>
        <TextDeletePopover>{subtitle}</TextDeletePopover>
        <ControlsDeleteModal>
          <StyledCancelDeleteButton size={'large'} onClick={onClose}>
            Cancel
          </StyledCancelDeleteButton>
          <StyledDeleteButton size={'large'} type={'primary'} onClick={onSubmit}>
            Remove
          </StyledDeleteButton>
        </ControlsDeleteModal>
      </DeleteModalContainer>
    </StyledModalDelete>
  );
};

export default RemoveItemModal;
