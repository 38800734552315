import { gql } from '@apollo/client';
import { NEWS_FEED_COMMON_FRAGMENT } from './query';

export const ADD_NEWS_FEED_INDIVIDUAL_POST = gql`
  mutation addNewsFeedIndividualPost(
    $userId: String!
    $type: NF_POST_TYPES!
    $text: String!
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput]
    $plainText: String!
  ) {
    addNewsFeedIndividualPost(
      input: { userId: $userId, type: $type, text: $text, mentions: $mentions, plainText: $plainText }
      files: $files
    ) {
      _id
    }
  }
`;

export const ADD_NEWS_FEED_POST_RECOGNIZE = gql`
  mutation addNewsFeedPost(
    $userId: String!
    $type: NF_POST_TYPES!
    $text: String!
    $plainText: String!
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput]
    $targetEmployees: [NewsFeedMentionInput!]!
    $badge: String!
  ) {
    addNewsFeedPost(
      input: {
        RECOGNITION: {
          userId: $userId
          type: $type
          text: $text
          mentions: $mentions
          plainText: $plainText
          targetEmployees: $targetEmployees
          badge: $badge
        }
      }
      files: $files
    ) {
      _id
    }
  }
`;

export const UPDATE_NEWS_FEED_POST_RECOGNIZE = gql`
  mutation updateNewsFeedPost(
    $text: String!
    $plainText: String!
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput!]
    $targetEmployees: [NewsFeedMentionInput!]
    $badge: String!
    $postId: String!
  ) {
    updateNewsFeedPost(
      input: {
        RECOGNITION: { text: $text, mentions: $mentions, plainText: $plainText, targetEmployees: $targetEmployees, badge: $badge }
      }
      files: $files
      postId: $postId
    ) {
      _id
    }
  }
`;

export const ADD_NEWS_FEED_THANKS_POST = gql`
  mutation addNewsFeedThanksPost(
    $userId: String!
    $type: NF_POST_TYPES!
    $text: String!
    $plainText: String!
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput]
    $targetEmployees: [NewsFeedMentionInput!]!
  ) {
    addNewsFeedThanksPost(
      input: {
        userId: $userId
        type: $type
        text: $text
        mentions: $mentions
        plainText: $plainText
        targetEmployees: $targetEmployees
      }
      files: $files
    ) {
      _id
    }
  }
`;

export const ADD_NEWS_FEED_HR_BIRTHDAY_WISHES_POST = gql`
  mutation addNewsFeedKudosPost(
    $userId: String!
    $type: NF_POST_TYPES!
    $text: String!
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput]
  ) {
    addNewsFeedKudosPost(input: { userId: $userId, type: $type, text: $text, mentions: $mentions }, files: $files) {
      _id
    }
  }
`;

export const ADD_NEWS_FEED_HR_NEW_HIRES_POST = gql`
  mutation addNewsFeedHrNewHiresPost(
    $userId: String!
    $type: NF_POST_TYPES!
    $text: String!
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput]
  ) {
    addNewsFeedHrNewHiresPost(input: { userId: $userId, type: $type, text: $text, mentions: $mentions }, files: $files) {
      _id
    }
  }
`;

export const ADD_NEWS_FEED_HR_PROMOTION_POST = gql`
  mutation addNewsFeedHrPromotionPost(
    $userId: String!
    $type: NF_POST_TYPES!
    $text: String!
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput]
  ) {
    addNewsFeedHrPromotionPost(input: { userId: $userId, type: $type, text: $text, mentions: $mentions }, files: $files) {
      _id
    }
  }
`;

export const ADD_NEWS_FEED_HR_WORK_ANNIVERSARY_POST = gql`
  mutation addNewsFeedHrWorkAnniversaryPost(
    $userId: String!
    $type: NF_POST_TYPES!
    $text: String!
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput]
  ) {
    addNewsFeedHrWorkAnniversaryPost(input: { userId: $userId, type: $type, text: $text, mentions: $mentions }, files: $files) {
      _id
    }
  }
`;

export const UPLOAD_NEWS_FEED_IMAGE = gql`
  mutation addNewsFeedPostImage($file: Upload!, $postId: String!) {
    addNewsFeedPostImage(file: $file, postId: $postId) {
      ... on NewsFeedIndividualPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedCompanyNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedTeamNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedHrBirthdayWishesPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedHrNewHiresPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedHrPromotionPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedHrWorkAnniversaryPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedThanksPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedKudosPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
    }
  }
`;

export const ADD_NEWS_FEED_COMPANY_NEWS_POST = gql`
  mutation addNewsFeedCompanyNewsPost(
    $userId: String!
    $type: NF_POST_TYPES!
    $text: String!
    $publishAt: DateTime
    $headline: String!
    $isFeatured: Boolean!
    $featuredExpAt: DateTime
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput]
    $plainText: String!
  ) {
    addNewsFeedCompanyNewsPost(
      input: {
        userId: $userId
        type: $type
        text: $text
        publishAt: $publishAt
        headline: $headline
        isFeatured: $isFeatured
        featuredExpAt: $featuredExpAt
        mentions: $mentions
        plainText: $plainText
      }
      files: $files
    ) {
      _id
    }
  }
`;

export const ADD_NEWS_FEED_TEAM_NEWS_POST = gql`
  mutation addNewsFeedTeamNewsPost(
    $userId: String!
    $type: NF_POST_TYPES!
    $text: String!
    $publishAt: DateTime
    $costCenterId: String!
    $files: UploadMultiple
    $mentions: [NewsFeedMentionInput]
    $plainText: String!
    $isFeatured: Boolean!
    $headline: String!
    $featuredExpAt: DateTime
  ) {
    addNewsFeedTeamNewsPost(
      input: {
        userId: $userId
        type: $type
        text: $text
        publishAt: $publishAt
        costCenterId: $costCenterId
        mentions: $mentions
        plainText: $plainText
        isFeatured: $isFeatured
        headline: $headline
        featuredExpAt: $featuredExpAt
      }
      files: $files
    ) {
      _id
    }
  }
`;

export const UPDATE_NEWS_FEED_INDIVIDUAL_POST = gql`
  mutation updateNewsFeedIndividualPost($files: UploadMultiple, $input: NewsFeedIndividualPostUpdateInput!, $postId: String!) {
    updateNewsFeedIndividualPost(files: $files, input: $input, postId: $postId) {
      _id
    }
  }
`;

export const UPDATE_NEWS_FEED_THANKS_POST = gql`
  mutation updateNewsFeedThanksPost($files: UploadMultiple, $input: NewsFeedThanksPostUpdateInput!, $postId: String!) {
    updateNewsFeedThanksPost(files: $files, input: $input, postId: $postId) {
      _id
    }
  }
`;

export const UPDATE_NEWS_FEED_COMPANY_NEWS_POST = gql`
  mutation updateNewsFeedCompanyNewsPost($files: UploadMultiple, $input: NewsFeedCompanyNewsPostUpdateInput!, $postId: String!) {
    updateNewsFeedCompanyNewsPost(files: $files, input: $input, postId: $postId) {
      _id
    }
  }
`;

export const UPDATE_NEWS_FEED_TEAM_NEWS_POST = gql`
  mutation updateNewsFeedTeamNewsPost($files: UploadMultiple, $input: NewsFeedTeamNewsPostUpdateInput!, $postId: String!) {
    updateNewsFeedTeamNewsPost(files: $files, input: $input, postId: $postId) {
      _id
    }
  }
`;

export const UPDATE_NEWS_FEED_HR_NEW_HIRES_POST = gql`
  mutation updateNewsFeedHrNewHiresPost($files: UploadMultiple, $input: NewsFeedHrNewHiresPostUpdateInput!, $postId: String!) {
    updateNewsFeedHrNewHiresPost(files: $files, input: $input, postId: $postId) {
      _id
    }
  }
`;

export const UPDATE_NEWS_FEED_HR_PROMOTION_POST = gql`
  mutation updateNewsFeedHrPromotionPost($files: UploadMultiple, $input: NewsFeedHrPromotionPostUpdateInput!, $postId: String!) {
    updateNewsFeedHrPromotionPost(files: $files, input: $input, postId: $postId) {
      _id
    }
  }
`;

export const UPDATE_NEWS_FEED_HR_BIRTHDAY_WISHES_POST = gql`
  mutation updateNewsFeedHrBirthdayWishesPost(
    $files: UploadMultiple
    $input: NewsFeedHrBirthdayWishesPostUpdateInput!
    $postId: String!
  ) {
    updateNewsFeedHrBirthdayWishesPost(
      files: $files
      input: $input
      postId: $postId
    ) {
      ${NEWS_FEED_COMMON_FRAGMENT}
    }
  }
`;

export const UPDATE_NEWS_FEED_HR_WORK_ANNIVERSARY_WISHES_POST = gql`
  mutation updateNewsFeedHrWorkAnniversaryPost(
    $files: UploadMultiple
    $input: NewsFeedHrWorkAnniversaryPostUpdateInput!
    $postId: String!
  ) {
    updateNewsFeedHrWorkAnniversaryPost(
      files: $files
      input: $input
      postId: $postId
    ) {
      ${NEWS_FEED_COMMON_FRAGMENT}
    }
  }
`;

export const UPDATE_NEWS_FEED_POST = gql`
  mutation updateNewsFeedPost(
    $input: NewsFeedPostUpdateInput!
    $postId: String!,
    $files: UploadMultiple
  ) {
    updateNewsFeedPost(
      input: $input,
      postId: $postId,
      files: $files
    ) {
      ... on NewsFeedIndividualPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedCompanyNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedTeamNewsPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedHrBirthdayWishesPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedHrNewHiresPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedHrPromotionPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedHrWorkAnniversaryPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedThanksPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
      ... on NewsFeedKudosPostModel {
        ${NEWS_FEED_COMMON_FRAGMENT}
      }
    }
  }
`;

export const DELETE_NEWS_FEED_POST = gql`
  mutation DeleteNewsFeedPost($postId: String!) {
    deleteNewsFeedPost(postId: $postId)
  }
`;

export const SET_NEWS_FEED_POST_REACTION = gql`
  mutation SetNewsFeedPostReaction($type: NF_REACTION_TYPES!, $postId: String!) {
    setNewsFeedPostReaction(type: $type, postId: $postId) {
      _id
      postId
      commentId
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
    }
  }
`;

export const UNSET_NEWS_FEED_POST_REACTION = gql`
  mutation UnsetNewsFeedPostReaction($type: NF_REACTION_TYPES!, $postId: String!) {
    unsetNewsFeedPostReaction(type: $type, postId: $postId) {
      _id
      postId
      commentId
      reactions {
        LIKE
        CELEBRATE
        SUPPORT
        LOVE
        INSIGHTFUL
        CURIOUS
      }
    }
  }
`;

export const DELETE_FILES_FROM_POST = gql`
  mutation deleteNewsFeedPostMultipleFiles($fileIds: [String!]!, $postId: String!) {
    deleteNewsFeedPostMultipleFiles(fileIds: $fileIds, postId: $postId) {
      _id
      files {
        fileId
        fileName
        fileUrl
        fileType
        previewImageUrl
      }
    }
  }
`;

export const HIDE_POST = gql`
  mutation hideNewsFeedPost($postId: String!) {
    hideNewsFeedPost(postId: $postId)
  }
`;

export const UNHIDE_POST = gql`
  mutation unhideNewsFeedPost($postId: String!) {
    unhideNewsFeedPost(postId: $postId)
  }
`;

export const UNHIDE_POSTS = gql`
  mutation unhideAllNewsFeedPosts {
    unhideAllNewsFeedPosts
  }
`;

export const ADD_COMMENT = gql`
  mutation addNewsFeedComment(
    $text: String!
    $postId: ID!
    $plainText: String!
    $mentions: [NewsFeedMentionInput!]
    $parentCommentId: ID
  ) {
    addNewsFeedComment(
      input: { postId: $postId, text: $text, plainText: $plainText, mentions: $mentions, parentCommentId: $parentCommentId }
    ) {
      _id
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation updateNewsFeedComment($text: String!, $plainText: String!, $mentions: [NewsFeedMentionInput!], $commentId: String!) {
    updateNewsFeedComment(input: { text: $text, plainText: $plainText, mentions: $mentions }, commentId: $commentId) {
      _id
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteNewsFeedComment($commentId: String!) {
    deleteNewsFeedComment(commentId: $commentId)
  }
`;

export const SET_COMMENT_REACTION = gql`
  mutation setNewsFeedCommentReaction($type: NF_REACTION_TYPES!, $commentId: String!, $postId: String!) {
    setNewsFeedCommentReaction(type: $type, commentId: $commentId, postId: $postId) {
      _id
    }
  }
`;

export const UNSET_COMMENT_REACTION = gql`
  mutation unsetNewsFeedCommentReaction($type: NF_REACTION_TYPES!, $commentId: String!, $postId: String!) {
    unsetNewsFeedCommentReaction(type: $type, commentId: $commentId, postId: $postId) {
      _id
    }
  }
`;
