import React, { useState, FC, useCallback, useEffect } from 'react';
import { message, Col } from 'antd';
import { useMutation } from '@apollo/client';
import moment, { Moment } from 'moment';
import { useQuery } from '@apollo/client';

import { parseEditorMentions } from 'modules/common/utils';
import { MainContainer, CreatePostContainer, StyledCreatePostIcon } from './styled';
import { StyledCard } from '../../styles';
import FeedContainer from '../FeedContainer';
import CreatePostModal from './components/CreatePostModal';
import { IUserModel, IFollowStatus } from 'modules/common/gql/models/user.model';
import {
  ADD_NEWS_FEED_INDIVIDUAL_POST,
  ADD_NEWS_FEED_COMPANY_NEWS_POST,
  ADD_NEWS_FEED_TEAM_NEWS_POST,
  UPDATE_NEWS_FEED_INDIVIDUAL_POST,
  UPDATE_NEWS_FEED_COMPANY_NEWS_POST,
  UPDATE_NEWS_FEED_TEAM_NEWS_POST,
  DELETE_FILES_FROM_POST,
  ADD_NEWS_FEED_THANKS_POST,
  UPDATE_NEWS_FEED_THANKS_POST,
  ADD_NEWS_FEED_POST_RECOGNIZE,
  UPDATE_NEWS_FEED_POST_RECOGNIZE,
} from 'modules/news-feed/gql/mutation';
import { namedFollowChangeSubsriptions } from 'modules/news-feed/gql/subscription';
import { GET_FOLLOWED_USERS } from 'modules/team/gql/query';
import { FOLLOW_AUTHOR, UNFOLLOW_AUTHOR } from 'modules/team/gql/mutation';
import { GET_NEWS_FEED_ALL_BADGES } from 'modules/common/gql/query';
import { initialNewsFeedData, POST_TYPES, MIN_HEIGHT_IMAGE, MIN_WIDTH_IMAGE } from '../../constants';
import { IUploadedFile } from 'modules/common/gql/models/newsFeedPost.model';
import { BADGE_TYPE } from '../../constants';
import { richTextToPlain } from 'utils/parsers';
import { emitCreatePost, mapPostTypes, emitFollowRequest } from 'utils/mixpanel';

export interface IEditableMentions {
  userId: string;
}

export interface IEditableTargetEmployees {
  userId: string;
  fullName: string;
  photoUrl: string;
}

export type NewsFeedTypes =
  | POST_TYPES.COMPANY_NEWS
  | POST_TYPES.TEAM_NEWS
  | POST_TYPES.INDIVIDUAL
  | POST_TYPES.HR_BIRTHDAY_WISHES
  | POST_TYPES.HR_NEW_HIRES
  | POST_TYPES.HR_PROMOTION
  | POST_TYPES.HR_WORK_ANNIVERSARY
  | POST_TYPES.THANKS
  | POST_TYPES.RECOGNITION;

export type FilesType = File | IUploadedFile;

export type BadgeTypes =
  | BADGE_TYPE.CUSTOMER_FOCUS
  | BADGE_TYPE.EXCELLENCE
  | BADGE_TYPE.GROWTH
  | BADGE_TYPE.INNOVATION
  | BADGE_TYPE.LEADERSHIP
  | BADGE_TYPE.TEAM_WORK;

export interface IEditableData {
  _id: string;
  type: NewsFeedTypes;
  text: string;
  isFeatured: boolean;
  headline: string;
  featuredExpAt: Moment | null;
  publishAt: Moment | null;
  costCenterId: string;
  files: FilesType[];
  mentions: IEditableMentions[] | null;
  targetEmployees: IEditableTargetEmployees[] | null;
  tags: string[] | null;
  userId: string;
  commentsCount: number;
  createdAt: string;
  updatedAt: string;
  badge: BadgeTypes | null;
}

export interface IUpdateData {
  _id: string;
  text: string;
  isFeatured: boolean;
  featuredExpAt: string;
  publishAt: string;
  files: FilesType[];
  tags: string[] | null;
  costCenterId: string;
  mentions: IEditableMentions[] | null;
  targetEmployees: IEditableTargetEmployees[] | null;
  userId: string;
  commentsCount: number;
  createdAt: string;
  updatedAt: string;
}

interface IProps {
  user: IUserModel;
}

export interface IAuthorUser {
  status: IFollowStatus;
  authorUser: {
    id: string;
  };
}

export interface IFollowedAuthorsResult {
  getMyFollowedAuthors: {
    data: IAuthorUser[];
  };
}

export interface IFollowerUser {
  id: string;
  fullName: string;
  photoUrl: string;
  job: {
    positionTitle: string;
  };
}

export interface IFollowStatusChangedResult {
  onNewsFeedFollowChange: {
    _id: string;
    followerId: string;
    authorId: string;
    status: IFollowStatus;
    followerUser: IFollowerUser;
    authorUser: IFollowerUser;
  };
}

export interface IFollowSubscriptionResult {
  subscriptionData: {
    data: IFollowStatusChangedResult;
  };
}

export interface IBadge {
  _id: string;
  name: string;
  description: string;
  iconUrl: string;
}

export interface INewsFeedBadgeResult {
  newsFeedAllBadges: IBadge[];
}

const MainContent: FC<IProps> = ({ user }) => {
  const { costCenter } = user;
  const [isBlockedSubmit, setBlockedSubmit] = useState(false);
  const [isOpenedCreatePost, setOpenedCreatePost] = useState(false);
  const [editableValue, setEditableValue] = useState<IEditableData>({
    ...initialNewsFeedData,
    costCenterId: costCenter?.id || '',
  });
  const onError = (err) => {
    setBlockedSubmit(false);
    message.error(`Error: ${err.message}`);
  };
  const [editPostId, setEditPostId] = useState('');
  const [filesForDelete, setFilesForDelete] = useState<string[]>([]);
  const { data: badgesData } = useQuery<INewsFeedBadgeResult>(GET_NEWS_FEED_ALL_BADGES, {
    onError: (err: Error) => message.error(err),
  });

  const onClose = () => {
    setOpenedCreatePost(false);
    setEditPostId('');
    setEditableValue({
      ...initialNewsFeedData,
      costCenterId: costCenter?.id || '',
    });
    setFilesForDelete(() => []);
  };

  const [deleteImages] = useMutation(DELETE_FILES_FROM_POST, {
    onError: () => message.error('Error'),
    onCompleted: () => {
      setFilesForDelete(() => []);
      onClose();
    },
  });

  const deleteImagesHandler = () => {
    const { _id } = editableValue;
    if (filesForDelete.length > 0) {
      deleteImages({
        variables: {
          fileIds: filesForDelete,
          postId: _id,
        },
      });
    } else {
      onClose();
    }
  };

  const [addNewsFeedIndividual] = useMutation(ADD_NEWS_FEED_INDIVIDUAL_POST, {
    onCompleted: deleteImagesHandler,
    onError,
  });
  const [addNewsFeedThanks] = useMutation(ADD_NEWS_FEED_THANKS_POST, {
    onCompleted: deleteImagesHandler,
    onError,
  });
  const [addNewsFeedRecognitionPost] = useMutation(ADD_NEWS_FEED_POST_RECOGNIZE, {
    onCompleted: deleteImagesHandler,
    onError,
  });
  const [addNewsFeedCompanyNewsPost] = useMutation(ADD_NEWS_FEED_COMPANY_NEWS_POST, {
    onCompleted: deleteImagesHandler,
    onError,
  });
  const [addNewsFeedTeamNewsPost] = useMutation(ADD_NEWS_FEED_TEAM_NEWS_POST, {
    onCompleted: deleteImagesHandler,
    onError,
  });
  const [updateNewsFeedIndividualPost] = useMutation(UPDATE_NEWS_FEED_INDIVIDUAL_POST, {
    onCompleted: deleteImagesHandler,
    onError,
  });
  const [updateNewsFeedThanksPost] = useMutation(UPDATE_NEWS_FEED_THANKS_POST, {
    onCompleted: deleteImagesHandler,
    onError,
  });
  const [updateNewsFeedCompanyNewsPost] = useMutation(UPDATE_NEWS_FEED_COMPANY_NEWS_POST, {
    onCompleted: deleteImagesHandler,
    onError,
  });
  const [updateNewsFeedTeamNewsPost] = useMutation(UPDATE_NEWS_FEED_TEAM_NEWS_POST, {
    onCompleted: deleteImagesHandler,
    onError,
  });
  const [updateNewsFeedRecognizePost] = useMutation(UPDATE_NEWS_FEED_POST_RECOGNIZE, {
    onCompleted: deleteImagesHandler,
    onError,
  });

  const { data: followedUsers, subscribeToMore: subscribeToMoreFollowed } = useQuery<IFollowedAuthorsResult>(GET_FOLLOWED_USERS);

  const subscribeToFollowedStatus = useCallback(() => {
    subscribeToMoreFollowed({
      document: namedFollowChangeSubsriptions('PROFILE'),
      updateQuery: (prev, { subscriptionData }: IFollowSubscriptionResult) => {
        if (!subscriptionData?.data) {
          return prev;
        }
        const { authorUser, status } = subscriptionData?.data.onNewsFeedFollowChange;
        const newData = prev.getMyFollowedAuthors.data.map((el) => ({ ...el }));
        const changedUserIndex = newData.findIndex((el: any) => el.authorUser.id === authorUser.id);
        if (changedUserIndex >= 0) {
          newData[changedUserIndex].status = status;
        }
        return {
          getMyFollowedAuthors: {
            ...prev.getMyFollowedAuthors,
            data: newData,
          },
        };
      },
    });
  }, []);

  const [followUser] = useMutation(FOLLOW_AUTHOR, {
    onError: () => message.error('Error'),
    onCompleted: () => {
      emitFollowRequest({ action: 'follow' });
    },
  });

  const [unfollowUser] = useMutation(UNFOLLOW_AUTHOR, {
    onError: () => message.error('Error'),
    onCompleted: () => {
      emitFollowRequest({ action: 'unfollow' });
    },
  });

  const followHandler = (id: string) => {
    followUser({
      variables: {
        authorId: id,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWED_USERS,
        },
      ],
    });
  };

  const unfollowHandler = (id: string) => {
    unfollowUser({
      variables: {
        authorId: id,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWED_USERS,
        },
      ],
    });
  };

  const selectTypeHandler = (value: NewsFeedTypes) => {
    setEditableValue((oldValue) => ({
      ...oldValue,
      type: value,
    }));
  };

  const getImgSize = (imgSrc, type: string) => {
    if (type === 'application/pdf') {
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    let newImg = new Image();
    newImg.src = imgSrc;

    return new Promise((resolve) => {
      newImg.onload = function () {
        resolve(newImg.width >= MIN_WIDTH_IMAGE && newImg.height >= MIN_HEIGHT_IMAGE);
      };
    });
  };

  const changeFileHandler = async (files: FileList | null) => {
    if (files) {
      const filesData = Array.from(files);
      const imagesValidation = await Promise.all(filesData.map((elem) => getImgSize(URL.createObjectURL(elem), elem.type)));

      const filteredBySizeImages = filesData.filter((elem, key) => {
        return imagesValidation[key];
      });
      setEditableValue((oldValue) => ({
        ...oldValue,
        files: [...oldValue.files, ...filteredBySizeImages],
      }));
    }
  };

  const onChangeTextNewsFeed = (text: string, editor?: any) => {
    const editorMentions = editor?.ui?.autocompleters?.mentions?.getUsers() || [];
    setEditableValue((oldValue) => ({
      ...oldValue,
      text,
      mentions: parseEditorMentions(editorMentions),
    }));
  };

  const setValueHandler = (value: any, fieldName: string) => {
    setEditableValue((oldValue) => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const setDateTimeHandler = (value: Moment | string | null, fieldName: string) => {
    setEditableValue((oldValue) => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const setMentionsHandler = (newMentions: IEditableMentions[]) => {
    setEditableValue((oldValue) => ({
      ...oldValue,
      mentions: [...newMentions],
    }));
  };

  const deleteNotUploadedImage = (key: number) => {
    const cloneOfEditableValue = { ...editableValue };

    cloneOfEditableValue.files.splice(key, 1);

    setEditableValue(cloneOfEditableValue);
  };

  const deleteUploadedImage = (fileId: string) => {
    setFilesForDelete((images) => [...images, fileId]);
  };

  const editHandler = (id: string, posts: any) => {
    const postFound = posts?.find((el) => el._id === id);

    if (!postFound) {
      return;
    }

    const {
      _id,
      userId,
      text,
      isFeatured,
      featuredExpAt,
      publishAt,
      files,
      tags,
      costCenterId,
      mentions,
      targetEmployees,
      /*NOT EDITABLE VALUES*/
      type,
      headline,
      commentsCount,
      createdAt,
      updatedAt,
      badge,
    } = postFound;

    setEditPostId(id);
    setEditableValue({
      text: text || '',
      isFeatured: !!isFeatured,
      featuredExpAt: featuredExpAt ? moment(featuredExpAt) : null,
      publishAt: publishAt ? moment(publishAt) : null,
      files: files || [],
      tags: tags || null,
      costCenterId: costCenterId || '',
      mentions: mentions?.map((elem) => ({ userId: elem.userId })) || null,
      targetEmployees: targetEmployees || null,
      type: type || POST_TYPES.INDIVIDUAL,
      headline: headline || '',
      _id: _id || '',
      commentsCount: commentsCount || 0,
      userId: userId || '',
      createdAt: createdAt || '',
      updatedAt: updatedAt || '',
      badge: badge || null,
    });
    setOpenedCreatePost(true);
  };

  const editMainPosts = (id: string, posts: any) => {
    editHandler(id, posts);
  };

  const submitHandler = useCallback(() => {
    const { type, text, publishAt, isFeatured, featuredExpAt, headline, costCenterId, files, mentions, targetEmployees, badge } =
      editableValue;

    const plainText = richTextToPlain(text);
    const defaultVariables = { userId: user.id, type, text, mentions };
    const filesForUpload = files.filter((elem) => elem instanceof File);
    emitCreatePost({ postType: mapPostTypes(type) });

    switch (type) {
      case POST_TYPES.INDIVIDUAL:
      case POST_TYPES.HR_BIRTHDAY_WISHES:
      case POST_TYPES.HR_NEW_HIRES:
      case POST_TYPES.HR_PROMOTION:
      case POST_TYPES.HR_WORK_ANNIVERSARY:
        !!editPostId
          ? updateNewsFeedIndividualPost({
              variables: {
                input: {
                  text,
                  plainText,
                  mentions,
                },
                postId: editPostId,
                files: filesForUpload.length > 0 ? filesForUpload : undefined,
              },
            })
          : addNewsFeedIndividual({
              variables: { ...defaultVariables, files: filesForUpload.length > 0 ? filesForUpload : undefined, plainText },
            });
        return;
      case POST_TYPES.THANKS:
        !!editPostId
          ? updateNewsFeedThanksPost({
              variables: {
                input: {
                  text,
                  plainText,
                  mentions,
                  targetEmployees: targetEmployees?.map((e) => ({ userId: e.userId })),
                },
                postId: editPostId,
                files: filesForUpload.length > 0 ? filesForUpload : undefined,
              },
            })
          : addNewsFeedThanks({
              variables: {
                ...defaultVariables,
                targetEmployees: targetEmployees?.map((e) => ({ userId: e.userId })),
                files: filesForUpload.length > 0 ? filesForUpload : undefined,
                plainText,
              },
            });
        return;
      case POST_TYPES.COMPANY_NEWS:
        const companyPostsVariables = { publishAt, headline, isFeatured, featuredExpAt };
        !!editPostId
          ? updateNewsFeedCompanyNewsPost({
              variables: {
                input: {
                  text,
                  mentions: mentions || [],
                  publishAt: publishAt || undefined,
                  headline,
                  isFeatured,
                  featuredExpAt: featuredExpAt || undefined,
                  plainText,
                },
                postId: editPostId,
                files: filesForUpload.length > 0 ? filesForUpload : undefined,
              },
            })
          : addNewsFeedCompanyNewsPost({
              variables: {
                ...defaultVariables,
                ...companyPostsVariables,
                files: filesForUpload.length > 0 ? filesForUpload : undefined,
                plainText,
              },
            });
        return;
      case POST_TYPES.TEAM_NEWS:
        const teamPostsVariables = { publishAt, costCenterId, isFeatured, headline, featuredExpAt };
        !!editPostId
          ? updateNewsFeedTeamNewsPost({
              variables: {
                input: {
                  text,
                  mentions: mentions || [],
                  publishAt: publishAt || undefined,
                  costCenterId,
                  plainText,
                  isFeatured,
                  headline,
                  featuredExpAt: featuredExpAt || undefined,
                },
                postId: editPostId,
                files: filesForUpload.length > 0 ? filesForUpload : undefined,
              },
            })
          : addNewsFeedTeamNewsPost({
              variables: {
                ...defaultVariables,
                ...teamPostsVariables,
                files: filesForUpload.length > 0 ? filesForUpload : undefined,
                plainText,
              },
            });
        return;
      case POST_TYPES.RECOGNITION:
        !!editPostId
          ? updateNewsFeedRecognizePost({
              variables: {
                text,
                mentions,
                plainText,
                targetEmployees: targetEmployees?.map((e) => ({ userId: e.userId })),
                badge,
                postId: editPostId,
                files: filesForUpload.length > 0 ? filesForUpload : undefined,
              },
            })
          : addNewsFeedRecognitionPost({
              variables: {
                ...defaultVariables,
                files: filesForUpload.length > 0 ? filesForUpload : undefined,
                plainText,
                targetEmployees: targetEmployees?.map((e) => ({ userId: e.userId })),
                badge,
              },
            });
        return;
    }
  }, [editableValue, setEditableValue, editPostId]);

  useEffect(() => {
    subscribeToFollowedStatus();
  }, []);

  if (!followedUsers) {
    return null;
  }

  return (
    <>
      <Col xs={24} sm={24} md={16} span={16}>
        <MainContainer>
          {isOpenedCreatePost && (
            <CreatePostModal
              isOpen={isOpenedCreatePost}
              isEditMode={!!editPostId}
              closeHandler={onClose}
              editableValue={editableValue}
              selectTypeHandler={selectTypeHandler}
              user={user}
              changeFileHandler={changeFileHandler}
              submitHandler={submitHandler}
              onChangeTextNewsFeed={onChangeTextNewsFeed}
              setValueHandler={setValueHandler}
              setDateTimeHandler={setDateTimeHandler}
              setMentionsHandler={setMentionsHandler}
              deleteNotUploadedImage={deleteNotUploadedImage}
              deleteUploadedImage={deleteUploadedImage}
              filesForDelete={filesForDelete}
              badgesData={badgesData?.newsFeedAllBadges || []}
              isBlockedSubmit={isBlockedSubmit}
              setBlockedSubmit={setBlockedSubmit}
            />
          )}
          <StyledCard title={'Create a post'} bordered={false}>
            <CreatePostContainer onClick={() => setOpenedCreatePost(true)}>
              <StyledCreatePostIcon />
              Start creating a post
            </CreatePostContainer>
          </StyledCard>
          <FeedContainer
            editHandler={editMainPosts}
            followedUsers={followedUsers.getMyFollowedAuthors.data}
            followHandler={followHandler}
            unfollowHandler={unfollowHandler}
            userId={user.id}
            photoUrl={user.photoUrl || ''}
            postTypes={[
              POST_TYPES.HR_NEW_HIRES,
              POST_TYPES.HR_BIRTHDAY_WISHES,
              POST_TYPES.HR_PROMOTION,
              POST_TYPES.HR_WORK_ANNIVERSARY,
              POST_TYPES.THANKS,
              POST_TYPES.RECOGNITION,
              POST_TYPES.COMPANY_NEWS,
              POST_TYPES.INDIVIDUAL,
              POST_TYPES.TEAM_NEWS,
            ]}
            key={0}
            badgesData={badgesData?.newsFeedAllBadges || []}
          />
        </MainContainer>
      </Col>
    </>
  );
};

export default MainContent;
