import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  ONELOGIN_TOKEN_RETRIEVE_SUCCESS,
  ONELOGIN_TOKEN_RETRIEVE_FAILURE,
  LOGIN_SUCCESSFUL,
  LOGOUT_SUCCESSFUL,
  LOGIN_FAILURE,
  LOGIN_CANCELLED,
  LOGOUT_FAILURE,
  REFRESH_AUTH_TOKEN_SUCCESS,
  REFRESH_AUTH_TOKEN_FAILURE,
  IMPERSONALIZE_BY_EMAIL,
  COOKIES_RETRIEVE_FAILURE,
  COOKIES_RETRIEVE_SUCCESS,
  COOKIES_RETRIEVE_REQUEST,
  SET_DEFAULT_ROUTE,
} from './auth.actionTypes';
import { ILoginSuccessful } from './auth.types';

export const login = () => ({ type: LOGIN_REQUEST });

export const logout = (history: any) => ({ type: LOGOUT_REQUEST, history });

export const loginRedirectSuccess = (token: string, history: any) => ({ type: ONELOGIN_TOKEN_RETRIEVE_SUCCESS, token, history });

export const loginRedirectFailure = () => ({ type: ONELOGIN_TOKEN_RETRIEVE_FAILURE });

export const loginSuccessful = (input: ILoginSuccessful) => ({ type: LOGIN_SUCCESSFUL, ...input });

export const loginFailure = (error?: Error) => ({ type: LOGIN_FAILURE, error });

export const loginCancelled = () => ({ type: LOGIN_CANCELLED });

export const logoutSuccessful = () => ({ type: LOGOUT_SUCCESSFUL });

export const logoutFailure = (error?: Error) => ({ type: LOGOUT_FAILURE, error });

export const refreshTokenSuccess = (input: ILoginSuccessful) => ({ type: REFRESH_AUTH_TOKEN_SUCCESS, ...input });

export const refreshTokenFailure = (error?: Error) => ({ type: REFRESH_AUTH_TOKEN_FAILURE, error });

export const impersonalizeByEmail = (email: string, history: any) => ({ type: IMPERSONALIZE_BY_EMAIL, email, history });

export const cookiesRequest = () => ({ type: COOKIES_RETRIEVE_REQUEST });

export const cookiesFailure = (error?: Error) => ({ type: COOKIES_RETRIEVE_FAILURE, error });

export const cookiesSuccess = (expires: Date) => ({ type: COOKIES_RETRIEVE_SUCCESS, expires });

export const setDefaultRoute = (path: string) => ({ type: SET_DEFAULT_ROUTE, path });
