import React, { FC } from 'react';
import { utc } from 'moment';
import { Button, Image } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  DateContainer,
  StyledTimelineItem,
  TimelineAvatar,
  TimeLine,
  NameContainer,
  ThanksInfoContainer,
  Point,
  YearContainer,
  ControlContainer,
  StyledDeleteIcon,
  StyledEditIcon,
  StyledAvatar,
  FlexRow,
} from '../../styles';
import { ITimelineEventModel } from 'modules/common/gql/models/timelineEvent.model';
import birthDay from '../../assets/birthDay.svg';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { IUserModel } from 'modules/profile/gql/models/user.model';

interface IProps {
  data: ITimelineEventModel;
  isVisibleYear: boolean;
  openEditModal: () => void;
  openDeleteModal: () => void;
  user: IUserModel;
  isOwner: boolean;
}

const Personal: FC<IProps> = ({ data, user, isVisibleYear, openEditModal, openDeleteModal, isOwner }) => {
  const { date, name } = data;
  const { photoUrl, fullName: preferredName, id } = user;
  const history = useHistory();

  return (
    <StyledTimelineItem>
      {isVisibleYear && <YearContainer>{utc(date).format('YYYY')}</YearContainer>}
      <TimelineAvatar background={'#ECF6FF'}>
        <img src={birthDay} />
      </TimelineAvatar>
      <TimeLine>
        <DateContainer background={'#ECF6FF'} color={'#1890FF'}>
          Personal <Point /> {utc(date).format('DD MMMM')}
        </DateContainer>
        <ThanksInfoContainer>
          <FlexRow
            onClick={() => {
              window.scrollTo(0, 0);
              history.push(`/profile/${id}`);
            }}
          >
            <StyledAvatar
              size="small"
              alt="logo"
              src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />}
            />
            <NameContainer>
              <h1>{preferredName}</h1>
            </NameContainer>
          </FlexRow>
          <NameContainer style={{ paddingLeft: 40 }}>{name}</NameContainer>
          {isOwner && (
            <ControlContainer>
              <Button type="link" icon={<StyledEditIcon />} onClick={openEditModal} />
              <Button type="link" icon={<StyledDeleteIcon />} onClick={openDeleteModal} />
            </ControlContainer>
          )}
        </ThanksInfoContainer>
      </TimeLine>
    </StyledTimelineItem>
  );
};

export default Personal;
