import React, { FC, useRef, useLayoutEffect, useState, useEffect, useMemo } from 'react';

import {
  OrgChartContainer,
  Slides,
  CardContainer,
  ShowMoreCard,
  AvatarsContainer,
  ShowMoreAvatar,
  ShowMorePlusButton,
  Avatars,
  ShowLessCard,
} from '../styles';
import { IEntityData, IUser } from '../OrgChart';
import OrgChartCard from './OrgChartCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PositionsTypes } from '../OrgChart';
import { IFollowedAuthorsResult } from '../OrgChart';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';

interface IProps {
  data: IEntityData[];
  toProfileHandler: (id: string) => void;
  setPositionsData: (value: () => PositionsTypes[]) => void;
  followPopoverData: {
    userId: string | null;
    cardId: string | null;
  };
  changeFollowPopoverData: (id: string | null, cardId: string | null) => void;
  followedUsers: IFollowedAuthorsResult;
  followHandler: (id: string) => void;
  unfollowHandler: (id: string) => void;
}

const Slider: FC<IProps> = ({
  data,
  toProfileHandler,
  setPositionsData,
  followPopoverData,
  changeFollowPopoverData,
  followedUsers,
  followHandler,
  unfollowHandler,
}) => {
  const [isExtended, setExtended] = useState(false);

  const slicedData: IEntityData[] | IUser[] | null[] = useMemo(() => {
    if (data.length <= 6) {
      return data;
    }
    if (isExtended) {
      return [...data, null];
    }
    const sliced: any = [...data.slice(0, 5), null];
    return sliced;
  }, [data, isExtended]);

  const avatarsData = useMemo(() => {
    if (data.length <= 6) {
      return {
        count: 0,
        avatars: [],
      };
    }
    const otherData = data.slice(5, data.length);

    const otherAvatars = otherData.slice(0, 3).map((elem) => elem?.user?.photoUrl || emptyAvatar);

    return {
      avatars: otherAvatars || [],
      count: otherData.length,
    };
  }, [data]);

  const [isFirstRender, setFirstRender] = useState(true);
  const sliderRef = useRef<HTMLDivElement>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const cards = useRef<HTMLDivElement[]>([]);

  const lastCardForLine = useMemo(
    () => (slicedData.length > 6 ? 5 : slicedData.length <= 1 ? null : slicedData.length - 1),
    [slicedData],
  );

  const resizeHandler = () => setScreenWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useLayoutEffect(() => {
    if (!isFirstRender) {
      setPositionsData(() => []);
    }
    const positions: PositionsTypes[] = cards.current.map((elem) => {
      if (elem && sliderRef.current) {
        let centerX = elem.offsetLeft + elem.offsetWidth / 2;
        let centerY = sliderRef.current.offsetTop;

        return [centerX, centerY];
      } else {
        return null;
      }
    });
    setPositionsData(() => positions);
  }, [slicedData, screenWidth]);

  useEffect(() => {
    setFirstRender(() => false);
  }, []);

  const showAllCards = () => {
    setExtended(true);
  };

  return (
    <div ref={sliderRef}>
      <Slides>
        <OrgChartContainer elementsPerRow={slicedData.length > 6 ? 6 : slicedData.length}>
          {slicedData.map((elem, key) => {
            return (
              <div
                ref={(el: HTMLDivElement) => {
                  if (key === 0) {
                    cards.current[0] = el;
                  }
                  if (lastCardForLine && key === lastCardForLine) {
                    cards.current[1] = el;
                  }
                }}
                key={key}
              >
                {elem ? (
                  <CardContainer key={key}>
                    <OrgChartCard
                      user={elem?.user}
                      managerId={elem?.user ? elem?.user?.manager?.id : elem?.manager?.id}
                      organization={elem?.organization}
                      toProfileHandler={toProfileHandler}
                      followPopoverData={followPopoverData}
                      changeFollowPopoverData={changeFollowPopoverData}
                      followedUsers={followedUsers}
                      followHandler={followHandler}
                      unfollowHandler={unfollowHandler}
                      key={key}
                    />
                  </CardContainer>
                ) : isExtended ? (
                  <ShowLessCard onClick={() => setExtended(false)}>Show Less</ShowLessCard>
                ) : (
                  <div style={{ padding: '5px' }}>
                    <ShowMoreCard onClick={showAllCards}>
                      <AvatarsContainer>
                        <Avatars>
                          {avatarsData.avatars.map((elem, key) => (
                            <ShowMoreAvatar src={elem} fallback={emptyAvatar} preview={false} key={key} />
                          ))}
                        </Avatars>
                        <ShowMorePlusButton>+{avatarsData.count}</ShowMorePlusButton>
                      </AvatarsContainer>
                    </ShowMoreCard>
                  </div>
                )}
              </div>
            );
          })}
        </OrgChartContainer>
      </Slides>
    </div>
  );
};

export default Slider;
