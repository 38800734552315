import React, { FC } from 'react';
import { utc } from 'moment';

import { DateContainer, StyledTimelineItem, TimelineAvatar, TimeLine, Point, YearContainer } from '../../styles';
import birthDay from '../../assets/birthDay.svg';
import { ITimelineEventModel } from 'modules/common/gql/models/timelineEvent.model';

interface IProps {
  data: ITimelineEventModel;
  isVisibleYear: boolean;
}

const Thanks: FC<IProps> = ({ data, isVisibleYear }) => {
  const { date } = data;
  return (
    <StyledTimelineItem>
      {isVisibleYear && <YearContainer>{utc(date).format('YYYY')}</YearContainer>}
      <TimelineAvatar background={'#ECF6FF'}>
        <img src={birthDay} />
      </TimelineAvatar>
      <TimeLine>
        <DateContainer background={'#ECF6FF'} color={'#1890FF'}>
          Birthday <Point /> {utc(date).format('DD MMMM')}
        </DateContainer>
      </TimeLine>
    </StyledTimelineItem>
  );
};

export default Thanks;
