import React from 'react';
import { Switch } from 'react-router-dom';
import appRoutes from 'app/routes';
import { joinPath } from 'utils/path';
import PrivateRoute from 'modules/auth/components/PrivateRoute';
import Profile from '../pages/Profile';
import GlobalSearch from '../pages/GlobalSearch';

export const profileRoutes = {
  profile: joinPath(appRoutes.profile, ''),
  globalSearch: joinPath(appRoutes.profile, 'global-search'),
};

const ProfileRouter = () => (
  <Switch>
    <PrivateRoute exact path={profileRoutes.globalSearch} component={GlobalSearch} />
    <PrivateRoute exact path={profileRoutes.profile} component={Profile} />
    <PrivateRoute path={`${profileRoutes.profile}:id`} component={Profile} />
  </Switch>
);

export default ProfileRouter;
