import React, { FC, useMemo } from 'react';
import { utc } from 'moment';
import { Image } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  DateContainer,
  StyledTimelineItem,
  TimelineAvatar,
  TimeLine,
  NameContainer,
  Point,
  YearContainer,
  StyledAvatar,
  Button,
  ThanksInfoContainer,
  StyledHideIcon,
  StyledVisibleIcon,
  SliceButton,
  DescriptionContainer,
  MainDescriptionContainer,
  ThanksHeader,
  UserContainer,
  BadgeContainer,
  AvatarContainer,
  FlexRow,
} from '../../styles';
import { ITimelineEventModel } from 'modules/common/gql/models/timelineEvent.model';
import { TIMELINE_EVENT_TYPES } from 'modules/common/const';
import flaps from '../../assets/flaps.svg';
import recognition from '../../assets/recognition.svg';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { IBadge } from 'modules/news-feed/pages/Feed/components/MainContent/MainContent';

interface IProps {
  data: ITimelineEventModel;
  isVisibleYear: boolean;
  toggleEventVisibility: () => void;
  isOwner: boolean;
  openPostPreviewModal: (id: string) => void;
  badgesData: IBadge[];
}

const LONG_TEXT_LENGTH = 148;

const Thanks: FC<IProps> = ({ data, isVisibleYear, toggleEventVisibility, isOwner, openPostPreviewModal, badgesData }) => {
  const { type, date, hidden, relatedEntity } = data;
  const { user: relatedEntityUser, post } = relatedEntity || {};
  const { text, badge } = post || {};
  const history = useHistory();

  const plainText = useMemo(() => {
    let element = document.createElement('DIV');
    element.innerHTML = text || '';
    return element.textContent || element.innerText || '';
  }, [text]);
  const isLongText = useMemo(() => plainText.length > LONG_TEXT_LENGTH, [plainText]);
  const htmlToShow = useMemo(() => {
    let res = text;
    if (isLongText) {
      let subText = plainText.substring(0, LONG_TEXT_LENGTH);
      subText = subText + '...';
      res = `<span>${subText}</span>`;
    }

    return res || '';
  }, [isLongText, plainText]);

  const changedBadge = useMemo(() => badgesData.find((elem) => elem._id === badge), [badgesData, badge]);

  const pushToUser = (userId: string) => {
    window.scrollTo(0, 0);
    history.push(`/profile/${userId}`);
  };

  if (!relatedEntityUser || !post) {
    return null;
  }

  return (
    <StyledTimelineItem>
      {isVisibleYear && <YearContainer>{utc(date).format('YYYY')}</YearContainer>}
      <TimelineAvatar $hidden={hidden} background={'#F9F1FF'}>
        {type === TIMELINE_EVENT_TYPES.THANKS ? <img src={flaps} /> : <img src={recognition} />}
      </TimelineAvatar>
      <TimeLine>
        <DateContainer $hidden={hidden} background={'#F9F1FF'} color={'#6006D2'}>
          {type === TIMELINE_EVENT_TYPES.THANKS ? 'Thanks' : 'Recognition'} <Point /> {utc(date).format('DD MMMM')}
        </DateContainer>
        <ThanksInfoContainer $hidden={hidden} $isThanksType>
          <ThanksHeader>
            <NameContainer $isPointer={true}>
              {type === TIMELINE_EVENT_TYPES.RECOGNITION && (
                <FlexRow style={{ marginBottom: 15 }} onClick={() => openPostPreviewModal(data.id)}>
                  <BadgeContainer>
                    <img src={changedBadge?.iconUrl} />
                  </BadgeContainer>
                  <UserContainer>
                    <h1>{changedBadge?.name}</h1>
                  </UserContainer>
                </FlexRow>
              )}
              <FlexRow onClick={() => pushToUser(relatedEntityUser.id)}>
                <AvatarContainer>
                  <StyledAvatar
                    size="small"
                    alt="logo"
                    src={<Image preview={false} src={relatedEntityUser.photoUrl || emptyAvatar} fallback={emptyAvatar} />}
                  />
                </AvatarContainer>
                <UserContainer>
                  <h1>{relatedEntityUser.fullName}</h1>
                </UserContainer>
              </FlexRow>
            </NameContainer>
            {isOwner && (
              <>
                {!hidden ? (
                  <Button onClick={toggleEventVisibility}>
                    <StyledVisibleIcon /> Hide
                  </Button>
                ) : (
                  <Button onClick={toggleEventVisibility}>
                    <StyledHideIcon /> Show
                  </Button>
                )}
              </>
            )}
          </ThanksHeader>
          <MainDescriptionContainer>
            <DescriptionContainer dangerouslySetInnerHTML={{ __html: htmlToShow }} />
            {isLongText && <SliceButton onClick={() => openPostPreviewModal(data.id)}>See more</SliceButton>}
          </MainDescriptionContainer>
        </ThanksInfoContainer>
      </TimeLine>
    </StyledTimelineItem>
  );
};

export default Thanks;
