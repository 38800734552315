import styled, { css } from 'styled-components';
import { Avatar, Dropdown, Popover } from 'antd';
import { ReactComponent as IconMenu } from 'modules/news-feed/pages/Feed/assets/menu.svg';
interface IEditable {
  $isEditable?: boolean;
}

export const Menu = styled(IconMenu)<IEditable>`
  cursor: pointer;
  visibility: hidden;
  height: 18px;
  width: 18px;
  ${({ $isEditable }) =>
    $isEditable
      ? `
    visibility: visible;
  `
      : ``}
`;

export const CommentsMainContainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const CommentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  width: 100%;
`;

export const AvatarContainer = styled.div`
  width: 32px !important;
`;

export const CommentTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(149, 151, 161, 0.1);
  border-radius: 16px;
  padding: 8px 12px;
  color: #111;
  width: 100%;
  @media (max-width: 767px) {
    width: 100% !important;
  }

  h3 {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
  }

  p {
    margin: 8px 0 0 0;
    font-size: 14px;
  }
`;

export const MainCommentContainer = styled.div`
  width: 100%;
`;

export const RepliesContainer = styled.div``;

export const RepliesControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  width: 100%;
  max-width: 275px;

  @media (max-height: 650px) {
    max-width: 230px;
  }
`;

export const RepliesControl = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #6b778c;
    font-size: 12px;
    margin: 0 0 0 8px;
  }
`;

export const RepliesButton = styled.div`
  font-weight: 600;
  padding: 0;
  color: #6b778c;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: 12px;
  text-transform: capitalize;

  :focus {
    color: inherit;
  }

  ${({ isChanged }: { isChanged?: boolean }) =>
    isChanged &&
    css`
      color: #1890ff;
    `}
`;

export const Reply = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #111;
  width: 100%;

  h3 {
    font-size: 12px;
    margin: 0 0 0 8px;
    font-weight: 600;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
`;

export const RepliesElement = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 10px;
`;

interface IStyledAvatar {
  $isSmallAvatar?: boolean;
  $isChanged?: boolean;
}

export const StyledAvatar = styled(Avatar)<IStyledAvatar>`
  width: 35px !important;
  height: 35px !important;
  ${({ $isSmallAvatar }) =>
    $isSmallAvatar &&
    `width: 26px !important;
    height: 26px !important;
    `}
  ${({ $isChanged }) =>
    $isChanged &&
    `
      border: 3px solid #1890FF;
    `}
`;

export const RepliesNameContainer = styled.div`
  background: rgba(149, 151, 161, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  width: 100%;
  max-width: 275px;

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  @media (max-height: 650px) {
    max-width: 200px;
  }
`;

export const NameContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainNameContainer = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin: 0;
    padding: 0;
  }
`;

export const StyledFollowPopover = styled(Popover)``;

export const Point = styled.div`
  background: #c4c4c4;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  content: '';
  margin: 0 16px;
`;

export const Edited = styled.p`
  color: #6b778c;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 16px;
  margin: 0 !important;
  padding: 0 !important;
`;

export const StyledDropdown = styled(Dropdown)`
  z-index: 0;
`;

export const MainReplyContainer = styled.div`
  background: rgba(149, 151, 161, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  width: 100%;
  max-width: 360px;
`;

export const MainReactionsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: -5px;

  p {
    margin: 0 5px 0 0;
  }
`;

interface IReactionsContainer {
  isReply?: boolean;
}

export const ReactionsContainer = styled.div<IReactionsContainer>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 275px;
  justify-content: space-between;
  height: 24px;

  @media (max-height: 650px) {
    width: 230px;
  }
  @media (max-width: 767px) {
    width: 100% !important;
  }

  ${({ isReply }) =>
    isReply &&
    `
      @media (max-height: 650px) {
        width: 200px;
      }
  `}
`;

export const ReactionContainer = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  padding: 0;
  margin-left: -5px;
  :first-child {
    margin: 0;
  }

  svg {
    width: 14px;
  }
`;

export const ViewReactionsButton = styled.p`
  cursor: pointer;
  color: #1890ff;
  font-size: 12px;
  line-height: 16px;
`;

export const StyledDescription = styled.div`
  max-width: 400px;
  word-break: break-word;
  & > *:first-child {
    margin-top: 0;
  }

  ${(props: { isSliced?: boolean }) =>
    props.isSliced &&
    `
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
  `};
`;

export const SeeMoreButton = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
`;

export const Reactions = styled.div`
  display: flex;
`;

export const ReactionsMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReactionElement = styled.div`
  display: flex;
  align-items: center;
  p {
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    margin-left: 6px;
    text-transform: capitalize;
  }
`;

export const ReactionContainerForTooltip = styled(ReactionContainer)`
  border: none;
`;

export const ShowMoreButton = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
`;

export const RepliesMainContainer = styled.div`
  width: 100%;
`;

export const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 20px;

  ${({ isLoadedAllData }: { isLoadedAllData?: boolean }) =>
    isLoadedAllData &&
    `
    height: 0;
    margin-top: 0;
    display: none;
  `};
`;
