import styled, { css } from 'styled-components';
import { Typography, Avatar, Button, Popover, Image } from 'antd';
import Slider from 'react-slick';

import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { ReactComponent as EmptyOrganization } from 'modules/team/assets/emptyOrganization.svg';
import { ReactComponent as AstreyaLogo } from 'modules/team/assets/astreyaOrganizationLogo.svg';

export const StyledOrgChart = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: 91px;
`;

export const AvatarContainer = styled.div`
  position: relative;
`;

export const CountOfDirectReports = styled.div`
  border: 3px solid #ffffff;
  background: #54baf0;
  color: #ffffff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -3px;
  bottom: 5px;
  border-radius: 100%;
  z-index: 999;
  padding: 6px;
  box-sizing: border-box;
  font-size: 10px;
`;

export const CountOfUsersOrganization = styled(CountOfDirectReports)`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

export const StyledFollowPopover = styled(Popover)``;

export const MainContainer = styled.div`
  padding: 20px 106px 0 106px;
  width: 100%;
  /* max-width: 1440px; */
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    padding: 20px 48px 0 48px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled(Typography.Title)``;

interface IMainCardProps {
  isOpacity?: boolean;
}

export const MainCardContainer = styled.div`
  padding: 18px 16px;
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  width: 200px;
  height: 220px;
  z-index: 999;
  ${(props: IMainCardProps) =>
    props.isOpacity &&
    `
     opacity: 0.6;
  `};
`;

export const StyledAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  overflow: hidden;
`;

export const StyledAvatarContainer = styled.div`
  ${({ isChanged }: { isChanged?: boolean }) =>
    isChanged &&
    css`
      ${StyledAvatar} {
        border: 3px solid #1890ff;
      }
    `}
`;

export const NameContainer = styled.div`
  display: flex;

  margin-top: 10px;

  p {
    word-break: break-all;
    font-size: 14px;
    font-weight: 600;
    color: #111111;
    text-align: center;
    padding: 0;
    margin: 0;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ReportsToUser = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OrgChartMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;

export const InfoElement = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;

  p {
    width: 100%;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #4b4b4b;
    padding: 0;
    margin: 0;

    span {
      color: #6b778c;
    }
  }

  img {
    margin-right: 10px;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 4px;
  border-top: 1px solid #eff0f1;
  margin-top: 15px;
`;

export const StyledButton = styled(Button)`
  width: 160px;
  box-sizing: border-box;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

export const StyledArrow = styled(Arrow)`
  margin-left: 12px;
`;

export const UserContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

export const StyledSlider = styled(Slider)`
  .slick-next {
    width: 56px;
    height: 56px;
    z-index: 999;
    right: -15px;
  }
  .slick-prev {
    width: 56px;
    height: 56px;
    z-index: 999;
    left: -15px;
  }
  .slick-list {
    overflow: visible;
  }
`;

export const StyledSlide = styled.div`
  padding: 0 9px;
  box-sizing: border-box;
  width: 264px;
  display: flex !important;
  justify-content: center;
`;

export const Line = styled.div`
  width: 2px;
  background: red;
  content: '';
  height: 20px;
  position: absolute;
  left: 50%;
  top: -18px;
  z-index: 99999;
`;

export const DirectReports = styled.div`
  margin-top: 30px;
  overflow: hidden;
  flex: 1;
  padding-bottom: 100px;
  margin-bottom: -80px;
  padding-top: 20px;
`;

export const ArrowContainer = styled.div`
  width: 28px;
  height: 28px;
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 100%;
`;

export const MainSliderContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

export const SupOrgContainer = styled.div``;

interface ISubOrgSliderContainer {
  slidesToShow?: number;
}

export const SubOrgSliderContainer = styled.div<ISubOrgSliderContainer>`
  overflow-x: hidden;
  width: 100%;
  max-width: 1200px;
  height: 260px;
  padding-top: 10px;
  margin-top: -10px;
  @media (max-width: 1400px) {
    max-width: 700px;
  }
  ${({ slidesToShow }) =>
    slidesToShow === 3 &&
    `
    max-width: 700px;
  `}
`;

export const StyledTopLine = styled.div`
  position: absolute;
  left: 50%;
  height: 1000px;
  background: #e0e0e0;
  opacity: 0.45;
  width: 2px;
  content: '';
  bottom: 100%;
  z-index: -1;
`;

export const StyledBottomLine = styled.div`
  position: absolute;
  left: 50%;
  opacity: 0.45;
  height: 1000px;
  background: #e0e0e0;
  width: 2px;
  content: '';
  top: 100%;
`;

interface IOrgChartContainer {
  elementsPerRow: number;
}

export const OrgChartContainer = styled.div<IOrgChartContainer>`
  display: grid;
  grid-gap: 10px;
  grid-row-gap: 25px;

  grid-template-columns: ${({ elementsPerRow }) => `repeat(${elementsPerRow}, 1fr)`};
`;

export const Slides = styled.div`
  display: flex;
  justify-content: center;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainSubOrgContainer = styled.div`
  width: 100%;
`;

export const UserCardContainer = styled.div`
  position: absolute;
  left: calc(50% - 105px);
  z-index: 999;
`;

export const EmptySlide = styled.div`
  width: 191px;
  height: 214px;
  content: '';
`;

interface IArrowBottomButton {
  isToManagerButton?: boolean;
}

export const ArrowBottomButton = styled.div<IArrowBottomButton>`
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  flex-direction: column;
  position: absolute;
  left: calc(50% - 14px);
  bottom: -10px;
  cursor: pointer;
  z-index: 999;

  ${({ isToManagerButton }) =>
    isToManagerButton &&
    `
    bottom: initial;
    top: -10px;
    transform: rotate(180deg);
  `}
`;

export const StyledEmptyOrganization = styled(EmptyOrganization)``;

interface IReportsToContainer {
  isOrganization?: boolean;
}

export const ReportsToContainer = styled.div<IReportsToContainer>`
  ${({ isOrganization }) =>
    isOrganization &&
    `
    margin-top: 50px;
  `}
`;

export const SubOrgSubjectContainer = styled.div``;

export const UserSubOrgContainer = styled.div`
  position: relative;
`;

export const OrganizationSubOrgContainer = styled.div`
  position: relative;
  margin-top: 50px;
`;

export const UserSubOrgLine = styled(StyledBottomLine)`
  height: 50px;
`;

export const OrganizationSubOrgLine = styled(StyledTopLine)`
  height: 50px;
`;

export const ShowMoreCard = styled(MainCardContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 18px;
`;

export const AvatarsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 25px;
`;

export const ShowMoreAvatar = styled(Image)`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-left: -25px !important;
  z-index: auto !important;
  background: #fff;
  :first-child {
    margin-left: 0;
  }
`;

export const Avatars = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const ShowMorePlusButton = styled.div`
  width: 30px;
  height: 30px;
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1890ff;
  border-radius: 100%;
  margin-left: -25px !important;
  z-index: 99;
`;
export const StyledAstreyaLogo = styled(AstreyaLogo)`
  border-radius: 100%;
`;

export const LogoContainer = styled.div`
  position: relative;
`;

export const ShowLessCard = styled(ShowMoreCard)`
  font-size: 16px;
  font-weight: 500;
`;
