import styled, { css } from 'styled-components';

import { Layout, Avatar, Image } from 'antd';
import { ReactComponent as TerminatedIcon } from 'modules/common/assets/images/popovers/terminated.svg';
import { ReactComponent as InactiveIcon } from 'modules/common/assets/images/popovers/inactive.svg';
import { ReactComponent as ActiveIcon } from 'modules/common/assets/images/popovers/active.svg';
import { ReactComponent as RightArrow } from '../../assets/images/rightArrow.svg';
import { ReactComponent as PostDot } from 'modules/common/assets/images/globalSearch/postDot.svg';

export const StyledTerminatedIcon = styled(TerminatedIcon)`
  margin-left: 10px;
`;

export const StyledInactiveIcon = styled(InactiveIcon)`
  margin-left: 10px;
`;

export const StyledActiveIcon = styled(ActiveIcon)`
  margin-left: 10px;
`;

export const StyledPostDot = styled(PostDot)`
  margin: 0 8px;
`;

export const MainContainer = styled(Layout)`
  margin: 0 auto;
  background: #fafbfc;
  min-height: calc(100vh - 56px);
  padding-bottom: 71px; // footer height
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 20px 106px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  @media (max-width: 992px) {
    padding: 20px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledTitle = styled.h1`
  font-weight: 600 !important;
  font-size: 30px;
  line-height: 38px;
  color: #111111;
  padding: 0;
  margin: 0;
`;

export const HeaderTitle = styled.h1`
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 38px;
  color: #111111;
  padding: 0;
  margin: 0 0 32px 0;
`;

export const PostType = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #1890ff;
`;

export const EmptyPostImage = styled.div`
  min-width: 192px;
  min-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ecf6ff;
`;

export const PostImage = styled.div`
  min-width: 192px;
  min-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ecf6ff;
  overflow: hidden;
  img {
    max-width: 192px;
    transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    -webkit-transform: scale(1.5, 1.5);
  }
`;

export const SearchTypesContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
`;

export const SearchType = styled.div`
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid #4b4b4b;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #4b4b4b;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;

  ${(props: { $isActive?: boolean }) =>
    props.$isActive &&
    `
    color: #1890FF;
    background: #E2F6FF;
    border: 1px solid #1890FF;
  `};
`;

export const CardsContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardsMainContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 300px);
  grid-gap: 16px;
  margin-top: 24px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 300px);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 300px);
  }
`;

export const PostsMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
`;

export const PostCardContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  height: 160px;
  overflow: hidden;
`;

export const PostCardContent = styled.div`
  padding: 16px;
  height: 100%;
  width: 100%;
`;

export const UserContainer = styled.div`
  display: flex;
  user-select: none;
  cursor: pointer;
`;

export const UserPostInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PostAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

export const UserInfoContainer = styled.div`
  h3 {
    color: #000000;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }

  p {
    color: #6b778c;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    padding: 0;
  }
`;

export const PostCardHeadline = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  color: #111;
  font-weight: 600;
`;

export const PostCardDescription = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #4b4b4b;
  margin-top: 16px;
  width: 100%;
  word-wrap: break-word;
  max-width: 1000px;
`;

export const UserCardContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  p {
    color: #4b4b4b;
    font-size: 14px;
    line-height: 24px;
    margin: 4px 0 0 0;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const UserCardAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  h3 {
    color: #111111;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    padding: 0;
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const UserCardButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  background: #1890ff;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
  margin-top: 16px;
  user-select: none;
  cursor: pointer;
`;

export const PostCardButton = styled(UserCardButton)`
  width: 130px;
  margin-top: 0;
`;

export const SubmitArrow = styled(RightArrow)`
  margin-left: 12px;
`;

export const CardsContainer = styled.div`
  flex: 1 0 auto;
`;

export const PaginationMainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 18px;

  ${({ isArticleType }: { isArticleType: boolean }) =>
    isArticleType &&
    css`
      margin-top: 100px;
    `}
`;

export const PaginationElement = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #1890ff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eff0f1;
  border-right: none;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;

  :first-child {
    border-left: none !important;
  }

  ${({ isChanged }: { isChanged: boolean }) =>
    isChanged &&
    css`
      background: #1890ff;
      color: #fff;
    `}
`;

export const NextPaginationControlButton = styled.div`
  padding: 13px 32px;
  border: 1px solid #eff0f1;
  border-radius: 0px 5px 5px 0px;
  color: rgba(24, 144, 255, 1);
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
`;

export const PrevPaginationControlButton = styled(NextPaginationControlButton)`
  border-radius: 5px 0 0 5px;
  border-right: none;
`;

export const EmptyContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    color: #4b4b4b;
    font-size: 14px;
    line-height: 24px;
    margin: 16px 0 0 0;
    padding: 0;
  }
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
`;

export const StyledImage = styled(Image)`
  width: 120px;
  height: 120px;
`;

export const CreateSupportContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 120px;
  max-width: 600px;

  h2 {
    color: #111111;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    padding: 0;
  }
`;

export const CreateSupportButton = styled.a`
  background: #1890ff;
  border-radius: 4px;
  padding: 4px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  user-select: none;

  :hover {
    color: #fff;
  }
`;

export const MainCreateSupportContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SearchMainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TargetLinks = styled.span`
  text-align: center;
  position: relative;
  margin-top: 12px;
`;

export const TargetLink = styled.span`
  cursor: pointer;
  color: #1890ff;
  position: relative;

  :hover {
    text-decoration: underline;
  }
`;

export const SpinnerMainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 40px;

  ${({ isLoadedAllData }: { isLoadedAllData?: boolean }) =>
    isLoadedAllData &&
    `
    height: 0;
    margin-top: 0;
    display: none;
  `};
`;
