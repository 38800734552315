import React, { useMemo, useState, FC, useEffect, Fragment, useRef } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import CustomScroll from 'react-custom-scroll';
import 'react-custom-scroll/dist/customScroll.css';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { message } from 'antd';
import qs from 'qs';

import {
  MainContainer,
  Header,
  MainContent,
  Headline,
  EmptyContainer,
  SpinnerContainer,
  PlaceholderBody,
  PlaceholderHead,
  Placeholder,
  Preloader,
} from './styles';
import Mention from './components/Mention';
import Comment from './components/Сomment';
import PostReaction from './components/PostReaction';
import CommentReaction from './components/CommentReaction';
import CommentMention from './components/CommentMention';
import CommentReply from './components/CommentReply';
import FollowRequested from './components/FollowRequested';
import FollowAccepted from './components/FollowAccepted';
import HrPost from './components/HrPost';
import { USER_NOTIFICATIONS } from 'modules/common/gql/query';
import { ReactComponent as EmptyImage } from 'modules/common/components/Header/assets/notifcationPopover/emptyImage.svg';
import Spinner from 'modules/common/components/Spinner';
import { MARK_NOTIFICATION_AS_READ } from 'modules/common/gql/mutation';
import appRoutes from 'app/routes';
import { GET_NEWS_FEED_ALL_BADGES } from 'modules/common/gql/query';
import { NOTIFICATION_ENTITY_TYPES } from './constants';
import { IUserNotificationResult, INewsFeedBadgeResult } from './interfaces';

interface IProps {
  closeNotifyPopover: () => void;
  notificationsCountData?: number;
  isOpenedNotifyPopover: boolean;
}

const NotificationsPopover: FC<IProps> = ({ closeNotifyPopover, isOpenedNotifyPopover }) => {
  const [isMountedComponent, setMountedComponent] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const goToProfile = (id: string) => history.push(`${appRoutes.profile}/${id}`);
  const [isFetchMoreBlock, setFetchMoreBlock] = useState(false);
  const loadingDivRef = useRef<HTMLDivElement>(null);
  const [changedNotifyId, setChangedNotifyId] = useState<null | string>(null);
  const [fetchUserNotifications, notificationQuery] = useLazyQuery<IUserNotificationResult>(USER_NOTIFICATIONS, {
    onError: () => {},
    fetchPolicy: 'cache-first',
  });
  const searchData = useMemo(() => qs.parse(location.search.slice(1)), [location.search]);

  const { data: badgesData } = useQuery<INewsFeedBadgeResult>(GET_NEWS_FEED_ALL_BADGES, {
    onError: (err: Error) => message.error(err),
  });

  const { data, refetch: notifyRefetch, loading: isLoadingNotifications } = notificationQuery;

  const [markAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    onError: (err) => message.error(err),
  });

  const isLoadedAllData = useMemo(
    () => data && data.userNotifications.data.length >= data.userNotifications.pagination.total,
    [data],
  );

  useEffect(() => {
    fetchUserNotifications({
      variables: {
        offset: 0,
        limit: 4,
      },
    });
    setMountedComponent(true);
  }, []);

  useEffect(() => {
    if (notifyRefetch && isMountedComponent && isOpenedNotifyPopover) {
      notifyRefetch();
    }
  }, [isOpenedNotifyPopover]);

  const fetchMoreHandler = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting || !data || isLoadedAllData || isFetchMoreBlock || !notificationQuery.fetchMore) {
      return;
    }
    const { userNotifications } = data;
    const { pagination } = userNotifications;
    const { offset } = pagination;
    setFetchMoreBlock(true);
    notificationQuery.fetchMore({
      variables: {
        offset: offset + 4,
        limit: 4,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        setFetchMoreBlock(false);
        return Object.assign({}, previousResult, {
          userNotifications: {
            ...previousResult.userNotifications,
            data: [...previousResult.userNotifications.data, ...fetchMoreResult.userNotifications.data],
            pagination: fetchMoreResult.userNotifications.pagination,
          },
        });
      },
    });
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(fetchMoreHandler, options);

    if (loadingDivRef.current) {
      observer.unobserve(loadingDivRef.current);
      observer.observe(loadingDivRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [loadingDivRef.current, data, isFetchMoreBlock]);

  const todayNotifyData = useMemo(() => {
    if (!data) {
      return [];
    }
    const dataToFiler = data.userNotifications.data;
    return dataToFiler.filter((elem) => moment(elem.createdAt).diff(moment(), 'days') >= 0);
  }, [data]);

  const earlyNotifyData = useMemo(() => {
    if (!data) {
      return [];
    }
    const dataToFiler = data.userNotifications.data;
    return dataToFiler.filter((elem) => moment(elem.createdAt).diff(moment(), 'days') < 0);
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const changedNotify = data.userNotifications.data.find((elem) => elem.id === changedNotifyId);
    if (changedNotify) {
      switch (changedNotify.relatedEntityType) {
        case NOTIFICATION_ENTITY_TYPES.POST_REACTED:
          history.push({
            pathname: location.pathname,
            search: qs.stringify({
              ...searchData,
              changedFeedId: changedNotify.relatedEntity?.post?._id,
            }),
          });
          break;
        case NOTIFICATION_ENTITY_TYPES.POST_COMMENTED:
        case NOTIFICATION_ENTITY_TYPES.POST_COMMENT_MENTIONED:
        case NOTIFICATION_ENTITY_TYPES.POST_COMMENT_REPLIED:
        case NOTIFICATION_ENTITY_TYPES.POST_COMMENT_REACTED:
          history.push({
            pathname: location.pathname,
            search: qs.stringify({
              ...searchData,
              changedFeedId: changedNotify.relatedEntity?.comment?.postId,
              previewModalChangedComment: changedNotify.relatedEntity?.comment?._id,
            }),
          });
          break;
        default:
          history.push({
            pathname: location.pathname,
            search: qs.stringify({
              ...searchData,
              changedFeedId: changedNotify.relatedEntity?.post?._id,
            }),
          });
      }
      setChangedNotifyId(null);
    }
  }, [data, changedNotifyId]);

  const changeNotify = async (id: string, shouldClose: boolean = true, shouldRefetch: boolean = true) => {
    await markAsRead({
      variables: {
        id,
      },
    });
    setChangedNotifyId(() => id);
    shouldRefetch && notifyRefetch && notifyRefetch();
    shouldClose && closeNotifyPopover();
  };

  if (!data?.userNotifications?.data?.length) {
    return (
      <MainContainer>
        <Header>Notifications</Header>
        {isLoadingNotifications && (
          <Placeholder>
            <Preloader />
            <PlaceholderHead />
            <PlaceholderBody />
            <PlaceholderHead />
            <PlaceholderBody />
          </Placeholder>
        )}
        {!isLoadingNotifications && (
          <EmptyContainer>
            <EmptyImage />
            <p>You have no notifications yet</p>
          </EmptyContainer>
        )}
      </MainContainer>
    );
  }

  const renderNotification = (notification, key) => {
    const { relatedEntityType } = notification;
    switch (relatedEntityType) {
      case NOTIFICATION_ENTITY_TYPES.FOLLOW_REQUEST:
        return <FollowRequested key={key} data={notification} changeNotify={changeNotify} />;
      case NOTIFICATION_ENTITY_TYPES.FOLLOW_ACCEPTED:
        return <FollowAccepted goToProfile={goToProfile} key={key} data={notification} changeNotify={changeNotify} />;
      case NOTIFICATION_ENTITY_TYPES.POST_REACTED:
        return <PostReaction key={key} data={notification} changeNotify={changeNotify} />;
      case NOTIFICATION_ENTITY_TYPES.POST_COMMENTED:
        return <Comment key={key} data={notification} changeNotify={changeNotify} />;
      case NOTIFICATION_ENTITY_TYPES.POST_COMMENT_MENTIONED:
        return <CommentMention key={key} data={notification} changeNotify={changeNotify} />;
      case NOTIFICATION_ENTITY_TYPES.POST_COMMENT_REPLIED:
        return <CommentReply key={key} data={notification} changeNotify={changeNotify} />;
      case NOTIFICATION_ENTITY_TYPES.POST_COMMENT_REACTED:
        return <CommentReaction key={key} data={notification} changeNotify={changeNotify} />;
      case NOTIFICATION_ENTITY_TYPES.POST_BIRTHDAY_WISHES_TARGETED:
      case NOTIFICATION_ENTITY_TYPES.POST_NEW_HIRES_TARGETED:
      case NOTIFICATION_ENTITY_TYPES.POST_PROMOTION_TARGETED:
      case NOTIFICATION_ENTITY_TYPES.POST_WORK_ANNIVERSARY_TARGETED:
        return <HrPost key={key} changeNotify={changeNotify} data={notification} />;
      default:
        return (
          <Mention data={notification} changeNotify={changeNotify} key={key} badgesData={badgesData?.newsFeedAllBadges || []} />
        );
    }
  };

  return (
    <MainContainer>
      <Header>Notifications</Header>
      <CustomScroll heightRelativeToParent={'450px'}>
        <MainContent>
          {todayNotifyData.length > 0 && (
            <Fragment>
              <Headline>Today</Headline>
              {todayNotifyData.map(renderNotification)}
            </Fragment>
          )}
          {earlyNotifyData.length > 0 && (
            <Fragment>
              <Headline>Earlier</Headline>
              {earlyNotifyData.map(renderNotification)}
            </Fragment>
          )}
          <SpinnerContainer ref={loadingDivRef} isLoadedAllData={isLoadedAllData}>
            {!isLoadedAllData && <Spinner />}
          </SpinnerContainer>
        </MainContent>
      </CustomScroll>
    </MainContainer>
  );
};

export default NotificationsPopover;
