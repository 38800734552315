 import { useCallback } from 'react';
 import { useQuery } from '@apollo/client';
 import { message } from 'antd';
 import { GET_MY_FOLLOWERS } from '../gql/query';
 import { IFollowersResult } from '../pages/Profile/Profile';

 export const useFollowers = () => {
  const onError = useCallback((err: Error) => message.error(err), []);
  const followersQuery = useQuery<IFollowersResult>(GET_MY_FOLLOWERS, { onError });
  return followersQuery;
 };