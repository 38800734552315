import React, { FC } from 'react';
import moment from 'moment';
import { Image } from 'antd';

import {
  MainContainer,
  Avatar,
  InfoContainer,
  UserContainer,
  MessageContainer,
  MessageText,
  MainInfoContainer,
  ReadIndicator,
  UserText,
} from '../../component.styles';
import { ReactionContainer, AvatarContainer } from './styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { reactionsTypes } from 'modules/common/const';
import { NOTIFICATION_TYPES } from '../../constants';
import { IPostReactionNotification } from '../../interfaces';

interface IProps {
  data: IPostReactionNotification;
  changeNotify: (id: string) => void;
}

const PostReaction: FC<IProps> = ({ data, changeNotify }) => {
  const { createdAt, status, relatedEntity, id } = data;
  const { reactionUser, reactionType } = relatedEntity;
  const { image: ReactionSVG, background } = reactionsTypes[reactionType] || {};

  if (!relatedEntity) {
    return null;
  }

  return (
    <MainContainer onClick={() => changeNotify(id)}>
      <UserContainer>
        <AvatarContainer>
          <Avatar src={<Image preview={false} src={reactionUser?.photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
          <ReactionContainer style={{ background }}>
            <ReactionSVG />
          </ReactionContainer>
        </AvatarContainer>
        <MainInfoContainer>
          <InfoContainer>
            <MessageContainer>
              <UserText>{reactionUser?.fullName}</UserText>
              <MessageText>reacted to your post</MessageText>
            </MessageContainer>
            <MessageContainer>{moment(createdAt).format('MMM DD')}</MessageContainer>
          </InfoContainer>
        </MainInfoContainer>
      </UserContainer>
      {(status === NOTIFICATION_TYPES.SEEN || status === NOTIFICATION_TYPES.NEW) && <ReadIndicator />}
    </MainContainer>
  );
};

export default PostReaction;
