import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import qs from 'qs';
import { useQuery } from '@apollo/client';

import {
  MainContainer,
  Content,
  Header,
  StyledTitle,
  MainContent,
  SearchContainer,
  MainSupportDocumentsContainer,
  SupportDocuments,
  SupportDocument,
} from './styles';
import SupportSearch from 'modules/common/components/SupportSearch';
import { GET_POPULAR_ARTICLES } from '../../gql/query';
import Footer from 'modules/common/components/Footer';

export interface IArticle {
  tags: string[];
  _id: number;
  title: string;
  contents: string;
  slug: string;
}
interface IArticlesResult {
  popularArticles: {
    data: IArticle[];
    pagination: {
      total: number;
    };
  };
}

const Support = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const { data: articlesData } = useQuery<IArticlesResult>(GET_POPULAR_ARTICLES, {
    onError: () => {},
    fetchPolicy: 'cache-first',
  });

  const submitSearch = () => {
    history.push({
      pathname: '/profile/global-search',
      search: qs.stringify({
        type: 'ARTICLE',
        search: searchValue,
        from: 'NAVBAR',
      }),
    });
  };

  if (!articlesData) {
    return null;
  }

  const toArticle = (articleId: number, slug: string) => ({
    pathname: `/knowledge-base/${articleId}/${slug}`,
    search: qs.stringify({
      restrictCountView: true,
    }),
  });

  const { data: articles } = articlesData.popularArticles;

  return (
    <>
      <MainContainer>
        <Content>
          <Header>
            <StyledTitle level={3}>Support</StyledTitle>
          </Header>
          <MainContent>
            <h1>Welcome to Astreya Support. How can we help ?</h1>
            <SearchContainer>
              <SupportSearch value={searchValue} onChange={(value) => setSearchValue(value)} onSubmit={submitSearch} />
            </SearchContainer>
          </MainContent>
          <MainSupportDocumentsContainer>
            <h2>Helpful Support Documents</h2>
            <SupportDocuments>
              {articles.map(({ title, _id, slug }) => (
                <Link to={toArticle(_id, slug)}>
                  <SupportDocument>{title}</SupportDocument>
                </Link>
              ))}
            </SupportDocuments>
          </MainSupportDocumentsContainer>
        </Content>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Support;
