export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_CANCELLED = 'LOGIN_CANCELLED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const ONELOGIN_TOKEN_RETRIEVE_SUCCESS = 'ONELOGIN_TOKEN_RETRIEVE_SUCCESS';
export const ONELOGIN_TOKEN_RETRIEVE_FAILURE = 'ONELOGIN_TOKEN_RETRIEVE_FAILURE';
export const REFRESH_AUTH_TOKEN_SUCCESS = 'REFRESH_AUTH_TOKEN_SUCCESS';
export const REFRESH_AUTH_TOKEN_FAILURE = 'REFRESH_AUTH_TOKEN_FAILURE';
export const IMPERSONALIZE_BY_EMAIL = 'IMPERSONALIZE_BY_EMAIL';
export const COOKIES_RETRIEVE_REQUEST = 'COOKIES_RETRIEVE_REQUEST';
export const COOKIES_RETRIEVE_SUCCESS = 'COOKIES_RETRIEVE_SUCCESS';
export const COOKIES_RETRIEVE_FAILURE = 'COOKIES_RETRIEVE_FAILURE';
export const SET_DEFAULT_ROUTE = 'SET_DEFAULT_ROUTE';
