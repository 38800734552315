import React, { FC, useCallback } from 'react';

import { TIMELINE_EVENT_TYPES } from 'modules/common/const';
import { Thanks, Personal, Certification, Transfer, BirthDay, Promotion, WorkAnniversary } from '..';
import { ITimelineEventModel } from 'modules/common/gql/models/timelineEvent.model';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import { IBadge } from 'modules/news-feed/pages/Feed/components/MainContent/MainContent';

interface IProps {
  data: ITimelineEventModel;
  user: IUserModel;
  isVisibleYear: boolean;
  isOwner: boolean;
  openEditModal: () => void;
  openDeleteModal: () => void;
  toggleEventVisibility: (options: any) => void;
  openPostPreviewModal: (id: string) => void;
  screenWidth: number;
  badgesData: IBadge[];
}

const TimeLineItem: FC<IProps> = ({
  data,
  user,
  isVisibleYear,
  openEditModal,
  openDeleteModal,
  toggleEventVisibility,
  isOwner,
  openPostPreviewModal,
  screenWidth,
  badgesData,
}) => {
  const toggleVisibility = useCallback(() => {
    toggleEventVisibility({ variables: { id: data.id, hidden: !data.hidden } });
  }, [data.id, data.hidden]);

  switch (data.type) {
    case TIMELINE_EVENT_TYPES.THANKS:
    case TIMELINE_EVENT_TYPES.RECOGNITION:
      return (
        <Thanks
          isOwner={isOwner}
          data={data}
          isVisibleYear={isVisibleYear}
          toggleEventVisibility={toggleVisibility}
          openPostPreviewModal={openPostPreviewModal}
          badgesData={badgesData}
        />
      );

    case TIMELINE_EVENT_TYPES.PERSONAL:
      return (
        <Personal
          isOwner={isOwner}
          data={data}
          user={user}
          isVisibleYear={isVisibleYear}
          openEditModal={openEditModal}
          openDeleteModal={openDeleteModal}
        />
      );

    case TIMELINE_EVENT_TYPES.CERTIFICATION:
    case TIMELINE_EVENT_TYPES.TRAINING:
      return (
        <Certification isOwner={isOwner} data={data} isVisibleYear={isVisibleYear} toggleEventVisibility={toggleVisibility} />
      );

    case TIMELINE_EVENT_TYPES.TRANSFER:
      return <Transfer data={data} isVisibleYear={isVisibleYear} />;

    case TIMELINE_EVENT_TYPES.PROMOTION:
      return <Promotion data={data} isVisibleYear={isVisibleYear} />;

    case TIMELINE_EVENT_TYPES.BIRTHDAY:
      return <BirthDay data={data} isVisibleYear={isVisibleYear} />;

    case TIMELINE_EVENT_TYPES.WORK_ANNIVERSARY:
    case TIMELINE_EVENT_TYPES.START_DATE:
      return <WorkAnniversary data={data} isVisibleYear={isVisibleYear} screenWidth={screenWidth} />;

    default:
      return null;
  }
};

export default TimeLineItem;
