import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import appRoutes from 'app/routes';
import { Login, LoginRedirect, Impersonalize } from '../pages/Login';
import { ContentContainer, MainContainer } from '../styles';
import { authRoutes } from '../constants';

const AuthRouter = () => (
  <MainContainer>
    <ContentContainer>
      <Switch>
        <Redirect exact from={appRoutes.auth} to={authRoutes.login} />
        <Route path={authRoutes.login}>
          <Login />
        </Route>
        <Route path={authRoutes.loginRedirect}>
          <LoginRedirect />
        </Route>
        <Route path={authRoutes.impersonalize}>
          <Impersonalize />
        </Route>
      </Switch>
    </ContentContainer>
  </MainContainer>
);

export default AuthRouter;
