import React, { Fragment, FC, ReactChild, useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import config from 'app/config';
import { hlp } from 'app/helphero';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import qs from 'qs';

import usePermissions from 'modules/common/hooks/usePermissions';
import { cookiesRequest, setDefaultRoute } from 'store/domains/auth/auth.actions';
import CompletenessProfileModal from 'modules/common/components/modals/CompletenessProfileModal';
import { modalViewDays } from 'modules/common/components/modals/CompletenessProfileModal/const';
import { RootState } from 'store/rootReducer';
import { UserState } from 'store/domains/user/user.types';
import { get, set } from 'utils/localStorage';
import { setupIntercomScript } from 'utils/intercom';
import { authRoutes } from 'modules/auth/constants';
import { mixpanelUserSet } from 'utils/mixpanel';
import hfWidget from 'utils/happyFoxWidget';
import embedSRIframe from 'utils/smartRecruiters';
import PreviewModal from 'modules/news-feed/pages/Feed/components/MainContent/components/PreviewModal/PreviewModal';

interface IProps {
  children: ReactChild;
}

const MainWrapper: FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const isDevelopmentMode = process.env.NODE_ENV === 'development';
  const withCookie: number | null = useSelector((state: RootState) => state.auth.cdnCookieExp);
  const user: UserState = useSelector((state: RootState) => state.user);
  const isAuthorized = useSelector((state: RootState) => state.auth.isAuthorized);
  const [isOpenedCompletenessModal, setOpenedCompletenessModal] = useState<boolean>(false);
  const closeCompletenessModal = () => {
    setOpenedCompletenessModal(false);
  };

  const searchData = useMemo(() => qs.parse(location.search.slice(1)), [location.search]);

  const changedFeedIdForPreviewModal = useMemo(() => {
    const changedFeedId = searchData.changedFeedId;
    if (typeof changedFeedId === 'string') {
      return changedFeedId;
    }
  }, [searchData]);

  // Initing permissions
  usePermissions();

  useEffect(() => {
    if (!isAuthorized) {
      const isLoginRoute = pathname === authRoutes.login || pathname === authRoutes.impersonalize;
      !isLoginRoute && dispatch(setDefaultRoute(pathname));
      return;
    }
    dispatch(cookiesRequest());
  }, [dispatch, isAuthorized]);

  useEffect(() => {
    // Mixpanel init
    mixpanel.init(config.mixpanelKey || '');
    // Smart Recruiters hacked iframe attach
    embedSRIframe();
  }, []);

  useEffect(() => {
    if (isAuthorized && user?.userData) {
      setupIntercomScript(user.userData);
      mixpanelUserSet(user.userData);
      hlp.identify(user.userData.id);
      hfWidget(user.userData);
    }
  }, [isAuthorized, user]);

  useEffect(() => {
    const lastViewOfModal = get('lastViewOfModal');
    const parsedLastViewOfModal = lastViewOfModal ? moment(JSON.parse(lastViewOfModal)) : moment().subtract(-1, 'd');
    if (!user.userData || parsedLastViewOfModal.isSame(moment(), 'date')) {
      return;
    }
    const { profileCompleteness, userVisit } = user.userData;
    const { total } = profileCompleteness || {};
    const { daysCount } = userVisit || {};

    if (modalViewDays.includes(daysCount) && total < 100) {
      setOpenedCompletenessModal(true);
      set('lastViewOfModal', JSON.stringify(moment().format('YYYY-MM-DD')));
    }
  }, [user]);

  const closePreviewFeedModal = () => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...searchData,
        changedFeedId: undefined,
        previewModalIsOpenedText: undefined,
        previewModalChangedComment: undefined,
      }),
    });
  };

  if (isAuthorized && !withCookie && !isDevelopmentMode) {
    return null;
  }

  return (
    <Fragment>
      {isOpenedCompletenessModal && user.userData && (
        <CompletenessProfileModal isOpen={isOpenedCompletenessModal} closeHandler={closeCompletenessModal} user={user.userData} />
      )}
      {changedFeedIdForPreviewModal && (
        <PreviewModal
          postId={changedFeedIdForPreviewModal}
          userId={user.userData?.id || ''}
          changedInitialImageKey={0}
          onClose={closePreviewFeedModal}
          photoUrl={user.userData?.photoUrl}
        />
      )}
      {children}
    </Fragment>
  );
};

export default MainWrapper;
