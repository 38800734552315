import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  margin-top: 16px;
  box-shadow: 0px 2px 10px rgba(166, 169, 174, 0.219608) !important;
  border-radius: 5px;

  &:first-child {
    margin-top: 0;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  line-height: 24px !important;
`;
