import React, { FC, useState, useMemo, useRef } from 'react';

import {
  StyledModal,
  FooterOfModal,
  StyledAddButton,
  StyledCancelButton,
  StyledSelect,
  DropdownContainer,
  StyledSearchIcon,
  InterestSelect,
  RequestInterestButton,
  StyledCloseButton,
  ChangedElement,
  ChangedElementsContainer,
  NotApprovedMainContainer,
  NotApprovedMessage,
  StyledWarnIcon,
  SmallNotFoundContainer,
} from '../styles';
import { ReactComponent as Close } from '../../../../../../../assets/images/achievements/close.svg';
import { ISkillModel } from 'modules/common/gql/models/skill.model';
import { IHobbieModel } from 'modules/common/gql/models/hobbie.model';

interface IOption {
  id: string;
  name: string;
}

interface IProps {
  title: string;
  subTitle: string;
  placeholder: string;
  data: IOption[];
  isOpen: boolean;
  onClose: () => void;
  addHobbyHandler: (value: string[]) => void;
  setFilterValue: (value: string) => void;
  requestInterestMessage: string;
  filterValue: string;
  submitButtonText: string;
  alreadyChangedValues: ISkillModel[] | IHobbieModel[];
}

interface IValue {
  value: string;
  isNewValue: boolean;
}

const NewTagModal: FC<IProps> = ({
  title,
  subTitle,
  placeholder,
  data,
  isOpen,
  onClose,
  addHobbyHandler,
  setFilterValue,
  requestInterestMessage,
  filterValue,
  submitButtonText,
  alreadyChangedValues,
}) => {
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const ref = useRef<any>(null);
  const [changedValue, setChangedValue] = useState<IValue[]>([]);
  const oldValues = useMemo(() => changedValue.filter((elem) => !elem.isNewValue), [changedValue]);
  const newValues = useMemo(() => changedValue.filter((elem) => elem.isNewValue), [changedValue]);

  const isValidForm = useMemo(() => Boolean(oldValues.length || newValues.length), [oldValues, newValues]);
  const filteredData = useMemo(
    () => data.filter((elem) => !changedValue.some((changedValue) => elem.name === changedValue.value)),
    [data, changedValue],
  );

  const isViewRequestContainer = useMemo(
    () =>
      filteredData.length > 0 &&
      filteredData.length <= 3 &&
      !filteredData.some((elem) => filterValue.toLowerCase() === elem.name.toLowerCase()),
    [filteredData, filterValue],
  );
  const isAlreadyChangedValue = useMemo(
    () => alreadyChangedValues.some((elem) => elem.name.toLowerCase() === filterValue.toLowerCase()),
    [alreadyChangedValues, filterValue],
  );

  const onChangeOption = (name: string) => {
    setChangedValue((oldValue) => [
      ...oldValue,
      {
        value: name,
        isNewValue: false,
      },
    ]);
    ref?.current?.blur();
    setSelectedValue(null);
  };

  const cancelHandler = () => {
    onClose();
    setFilterValue('');
    setChangedValue([]);
  };

  const onSubmit = () => {
    if (changedValue) {
      addHobbyHandler(changedValue.map((elem) => elem.value));
      cancelHandler();
    }
  };

  const changeNewValue = () => {
    if (!changedValue.some((elem) => elem.value === filterValue)) {
      setChangedValue((oldValue) => [
        ...oldValue,
        {
          value: filterValue,
          isNewValue: true,
        },
      ]);
    }
    setFilterValue('');
    ref?.current?.blur();
    setSelectedValue(null);
  };

  const deleteElement = (name: string) => {
    const cloneOfChangedValue = [...changedValue];

    const indexOfElement = cloneOfChangedValue.findIndex((elem) => elem.value === name);

    cloneOfChangedValue.splice(indexOfElement, 1);

    setChangedValue(cloneOfChangedValue);
  };

  return isOpen ? (
    <StyledModal
      title={title}
      visible={isOpen}
      maskClosable={false}
      closeIcon={<Close />}
      footer={
        <FooterOfModal>
          <StyledCancelButton onClick={cancelHandler}>Cancel</StyledCancelButton>
          <StyledAddButton type="primary" disabled={!isValidForm} onClick={onSubmit}>
            {newValues.length && oldValues.length
              ? `Add and ${submitButtonText}${Boolean(newValues.length > 1) ? 's' : ''}`
              : newValues.length
              ? `${submitButtonText}${Boolean(newValues.length > 1) ? 's' : ''}`
              : 'Add'}
          </StyledAddButton>
        </FooterOfModal>
      }
      onCancel={cancelHandler}
    >
      <DropdownContainer>
        <StyledSearchIcon />
        <InterestSelect
          ref={ref}
          showSearch
          showArrow={false}
          filterOption={() => true}
          placeholder={placeholder}
          onChange={(value) => {
            onChangeOption(`${value}`);
          }}
          inputValue={filterValue}
          onSearch={(value) => {
            if (value.length <= 90) {
              setFilterValue(value);
            }
          }}
          notFoundContent={
            isAlreadyChangedValue ? (
              <SmallNotFoundContainer>
                <h2>The item has already been added to your profile</h2>
              </SmallNotFoundContainer>
            ) : (
              <SmallNotFoundContainer>
                <h2>{requestInterestMessage}</h2>
                <RequestInterestButton onClick={changeNewValue}>Request to add {filterValue}</RequestInterestButton>
              </SmallNotFoundContainer>
            )
          }
          value={selectedValue}
          dropdownRender={(originalNode) => (
            <div>
              {originalNode}
              {isViewRequestContainer &&
                (isAlreadyChangedValue ? (
                  <SmallNotFoundContainer>
                    <h2>The item has already been added to your profile</h2>
                  </SmallNotFoundContainer>
                ) : (
                  <SmallNotFoundContainer>
                    <h2>{requestInterestMessage}</h2>
                    <RequestInterestButton onClick={changeNewValue}>Request to add {filterValue}</RequestInterestButton>
                  </SmallNotFoundContainer>
                ))}
            </div>
          )}
        >
          {filteredData.map((elem) => {
            const { id, name } = elem;

            return (
              <StyledSelect.Option value={name} key={id}>
                {name}
              </StyledSelect.Option>
            );
          })}
        </InterestSelect>
      </DropdownContainer>
      <ChangedElementsContainer>
        {oldValues.map((elem, key) => {
          return (
            <ChangedElement key={key}>
              <p>{elem.value}</p>
              <StyledCloseButton onClick={() => deleteElement(elem.value)} />
            </ChangedElement>
          );
        })}
      </ChangedElementsContainer>
      {newValues.length > 0 && (
        <NotApprovedMainContainer>
          <NotApprovedMessage>
            <StyledWarnIcon />
            <p>Approval Required</p>
          </NotApprovedMessage>
          <ChangedElementsContainer>
            {newValues.map((elem, key) => {
              return (
                <ChangedElement key={key} isNewValue={elem.isNewValue}>
                  <p>{elem.value}</p>
                  <StyledCloseButton onClick={() => deleteElement(elem.value)} />
                </ChangedElement>
              );
            })}
          </ChangedElementsContainer>
        </NotApprovedMainContainer>
      )}
    </StyledModal>
  ) : null;
};

export default NewTagModal;
