import React, { FC, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Button, Avatar, Image } from 'antd';

import {
  SectionTitle,
  StyledLink,
  StyledSpace,
  ListName,
  ArrowIcon,
  StyledList,
  ListItem,
  EmptyContainer,
  ProfileLink,
} from './styles';
import { ReactComponent as CupIcon } from 'modules/profile/assets/images/cup.svg';
import { IUserModel } from 'modules/common/gql/models/user.model';
import { teamRoutes } from 'modules/team/config/Team.router';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { IEntityData } from 'modules/team/pages/OrgChart/OrgChart';

const { Text } = Typography;

interface IListItem {
  id?: string;
  photo?: string;
  name?: string;
  type?: string;
  position?: string;
  location?: string;
}

export interface IProps {
  reports: IEntityData[];
  goToProfile: (id: string) => void;
  user: IUserModel;
  isOwner: boolean;
}

const DirectReports: FC<IProps> = ({ reports, goToProfile, user, isOwner }) => {
  const history = useHistory();
  const goToOrgChart = useCallback(() => history.push(`${teamRoutes.orgChart}?userId=${user.id}`), [history]);
  const parsedReports: IListItem[] = useMemo(() => {
    return reports.map((report) => {
      const { municipality, countryCode } = (report?.user?.job.addresses?.length && report?.user?.job.addresses[0]) || {};
      const location = `${municipality || ''}${municipality ? ', ' : ''}${countryCode || ''}`;
      return {
        id: report.user?.id,
        photo: report.user?.photoUrl,
        name: report.user?.fullName,
        type: report.user?.costCenter?.name,
        position: report.user?.job?.positionTitle,
        location,
      };
    });
  }, [reports]);

  const hasReports = useMemo(() => parsedReports && parsedReports.length > 0, [parsedReports]);
  let title = 'Direct Reports';
  if (hasReports) {
    title += ` (${reports.length})`;
  }
  return (
    <>
      <Row>
        <Col span={24}>
          <StyledSpace>
            <SectionTitle>{title}</SectionTitle>
            {hasReports && (
              <Button onClick={goToOrgChart} type="link">
                <StyledLink>View Chart</StyledLink>
                <ArrowIcon />
              </Button>
            )}
          </StyledSpace>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {hasReports && (
            <StyledList
              dataSource={parsedReports}
              renderItem={(item: any) => (
                <ListItem>
                  <Col span={6}>
                    <ProfileLink onClick={() => goToProfile(item.id)}>
                      <Avatar src={<Image preview={false} src={item.photo || emptyAvatar} fallback={emptyAvatar} />} />
                      <ListName>{item.name}</ListName>
                    </ProfileLink>
                  </Col>
                  <Col span={6}>
                    <Text>{item.type}</Text>
                  </Col>
                  <Col span={6} style={{ paddingRight: '10px' }}>
                    <Text>{item.position}</Text>
                  </Col>
                  <Col span={6}>
                    <Text>{item.location}</Text>
                  </Col>
                </ListItem>
              )}
            />
          )}
          {!hasReports && (
            <EmptyContainer>
              <CupIcon />
              {isOwner ? <p>You don’t have any direct reports yet</p> : <p>Employee doesn’t have direct reports yet</p>}
            </EmptyContainer>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DirectReports;
