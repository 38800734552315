//@ts-nocheck

export const setupIntercomScript = (user) => {
  let APP_ID = 'c4tv4at7';
  let current_user_email = user.email;
  let current_user_name = user.fullName;
  let current_user_id = user.id;

  let w: any = window;

  w.intercomSettings = {
    app_id: APP_ID,
    name: current_user_name,
    email: current_user_email,
    user_id: current_user_id,
  };

  (function () {
    let ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      let d = document;
      let i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      let l = function () {
        let s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
        let x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};
