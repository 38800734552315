import {
  FETCH_USER_META_REQUEST,
  FETCH_USER_META_SUCCESS,
  FETCH_USER_META_FAILURE,
  FETCH_USER_PERMISSIONS,
  USER_DATA_RETRIEVED,
} from './user.actionTypes';
import { LOGIN_SUCCESSFUL } from '../auth/auth.actionTypes';
import { UserState, UserRoles } from './user.types';
import * as localStorage from 'utils/localStorage';
import { ILoginSuccessful } from '../auth/auth.types';
import { storageKeys } from '../auth/auth.constants';

const tokenOutputStored = localStorage.get(storageKeys.tokenOutput);
const userOutput = localStorage.get(storageKeys.userOutput);
const userDataOutput = localStorage.get(storageKeys.userDataOutput);
const tokenParsed: ILoginSuccessful = tokenOutputStored ? JSON.parse(tokenOutputStored) : {};
const userParsed = userOutput ? JSON.parse(userOutput) : {};
const userData = userDataOutput ? JSON.parse(userDataOutput) : null;
const initialState = {
  id: userParsed?.id || null,
  roles: userParsed.roles || null,
  role: tokenParsed.accessToken && tokenParsed.refreshToken ? UserRoles.OWNER : null,
  error: null,
  permissionIds: userParsed.permissionIds || null,
  userData,
};

const userReducer = (state: UserState = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESSFUL: {
      delete action.type;
      localStorage.set(storageKeys.tokenOutput, JSON.stringify(action));
      localStorage.set(storageKeys.userOutput, JSON.stringify({ ...action.user }));
      return {
        ...state,
        role: UserRoles.OWNER,
        id: action.user.id,
        roles: action.user.roles,
        permissionIds: action.user.permissionIds,
      };
    }
    case FETCH_USER_PERMISSIONS: {
      return {
        ...state,
        permissionIds: action.permissions,
      };
    }
    case FETCH_USER_META_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_USER_META_SUCCESS: {
      return {
        ...state,
        role: action.role,
      };
    }
    case FETCH_USER_META_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case USER_DATA_RETRIEVED: {
      localStorage.set(storageKeys.userDataOutput, JSON.stringify(action.userData));
      return {
        ...state,
        userData: action.userData,
      }
    }
  }
  return state;
};

export default userReducer;
