import React, { FC, useMemo, useState, useCallback } from 'react';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { ITargetEmployeesModel } from 'modules/common/gql/models/targetEmployees.model';
import { hrPostsIcons, POST_TYPES, hrPostsTitle } from '../../constants';
import {
  MainContainer,
  Header,
  Card,
  CardsContainer,
  Subtitle,
  CardWrapper,
  CardLastWrapper,
  StyledAvatar,
  MainHeaderContainer,
  AvatarContainer,
} from './styles';
import emptyCardAvatar from 'modules/common/assets/images/emptyHrPostsAvatar.svg';
import { profileRoutes } from 'modules/profile/config/Profile.router';

interface IProps {
  targetEmployees: ITargetEmployeesModel[];
  type: string;
  onChangeFeedId: (id: string, key: number) => void;
  id: string;
  postAge: string;
}

const PostCard: FC<IProps> = ({ targetEmployees, type, onChangeFeedId, id, postAge }) => {
  const history = useHistory();
  const [isAvatarPlaceholderDisplayed, setIsAvatarPlaceholderDisplayed] = useState(false);

  const onAvatarErrorHandler = useCallback(() => setIsAvatarPlaceholderDisplayed(true), []);

  const targetEmployeeLength = targetEmployees.length;
  const slicedCards = targetEmployees.slice(0, 12);

  return (
    <MainContainer>
      <MainHeaderContainer>
        <img src={hrPostsIcons[type]} alt={'icon'} />
        <Header>
          <h2>{hrPostsTitle[type](targetEmployeeLength)}</h2>
          <p>{postAge}</p>
        </Header>
      </MainHeaderContainer>
      <CardsContainer>
        {slicedCards.map((elem, key) => {
          const { photoUrl, fullName, positionBusinessTitle, userId, hireDate, isProfilePhotoAvatar, avatarBgColor } = elem;
          const isLastCard = targetEmployeeLength > slicedCards.length && slicedCards.length - 1 === key;
          const tenure = useMemo(() => {
            if (!hireDate) {
              return 0;
            }
            const hire = moment(hireDate);
            const now = moment(new Date());
            return now.diff(hire, 'years');
          }, [hireDate]);

          return (
            <Card
              key={userId}
              isDontHaveAvatar={!Boolean(photoUrl)}
              onClick={() => (isLastCard ? onChangeFeedId(id, 0) : history.push(`${profileRoutes.profile}${userId}`))}
            >
              <AvatarContainer
                isHaveAvatar={Boolean(!!photoUrl && !isProfilePhotoAvatar)}
                $avatarBgColor={isProfilePhotoAvatar ? avatarBgColor : null}
              >
                <StyledAvatar
                  preview={false}
                  fallback={emptyCardAvatar}
                  onError={onAvatarErrorHandler}
                  src={photoUrl || emptyCardAvatar}
                  $isAvatarPlaceholder={!photoUrl || isAvatarPlaceholderDisplayed || isProfilePhotoAvatar}
                  $avatarBgColor={isProfilePhotoAvatar ? avatarBgColor : null}
                  $isDontHaveAvatar={!Boolean(photoUrl)}
                />
              </AvatarContainer>
              {!isLastCard && (
                <CardWrapper>
                  <p>{fullName}</p>
                  {type === POST_TYPES.HR_PROMOTION && <Subtitle>{positionBusinessTitle}</Subtitle>}
                  {type === POST_TYPES.HR_WORK_ANNIVERSARY && !!tenure && (
                    <Subtitle>{`${pluralize('year', tenure, true)} @ Astreya`}</Subtitle>
                  )}
                </CardWrapper>
              )}
              {isLastCard && (
                <CardLastWrapper>
                  <h2>+{targetEmployeeLength - slicedCards.length}</h2>
                </CardLastWrapper>
              )}
            </Card>
          );
        })}
      </CardsContainer>
    </MainContainer>
  );
};

export default PostCard;
