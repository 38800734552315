import styled from 'styled-components';
import { Typography, Button } from 'antd';

import { ReactComponent as Computer } from './images/computer.svg';
import { ReactComponent as oneLoginIcon } from './images/oneLogin.svg';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledIllustration = styled(Computer)`
  margin-top: 85px;
`;

export const StyledTitle = styled(Typography.Title)`
  margin-top: 50px;
  margin-bottom: 0 !important;
`;

export const StyledButton = styled(Button)`
  margin-top: 32px;
  width: 100%;
  padding: 16px 57px;
  box-sizing: border-box;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-weight: 600;
`;

export const StyledOneLoginIcon = styled(oneLoginIcon)`
  margin-right: 8px;
`;
