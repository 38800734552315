import styled from 'styled-components';
import { Card, Typography, Timeline, Button as AntButton, Avatar, Image } from 'antd';
import { ReactComponent as Hide } from './assets/hide.svg';
import { ReactComponent as Visible } from './assets/visible.svg';
import { ReactComponent as EditIcon } from 'modules/common/assets/images/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'modules/common/assets/images/delete-icon.svg';

const HIDDEN_BACKGROUND_LABEL_COLOR = '#EFF0F1;';
const HIDDEN_BACKGROUND_COLOR = '#FAFAFA;';
const HIDDEN_COLOR = '#6B778C;';

interface IPropsBackground {
  background: string;
  color?: string;
  $hidden?: boolean;
}

interface IHiddenCheck {
  $hidden?: boolean;
  $isThanksType?: boolean;
}

interface IPointed {
  $isPointer?: boolean;
  isRecognition?: boolean;
}

export const MainContainer = styled(Card)`
  width: 100%;
  color: #4b4b4b;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #4b4b4b;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled(Typography.Title)`
  font-size: 16px !important;
  font-weight: 600 !important;
`;

export const TimelineContainer = styled.div`
  width: 100%;
  margin-top: 45px;
  padding-left: 55px;
  @media (max-width: 1300px) {
    padding-left: 0;
  }
`;

export const StyledTimeline = styled(Timeline)``;

export const StyledMainTimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-head {
    background: rgba(239, 240, 241, 1);
    border: none;
  }
`;

export const AddButton = styled(AntButton)`
  font-weight: 600;
  border-radius: 4px;
  & svg {
    position: relative;
    top: 3px;
    left: -5px;
  }

  & svg > path {
    fill: #fff;
  }
`;

export const ControlContainer = styled.div`
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 6px;
  right: 8px;
`;

export const StyledEditIcon = styled(EditIcon)``;

export const StyledDeleteIcon = styled(DeleteIcon)``;

export const StyledTimelineItem = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  position: relative;
  padding-bottom: 20px;
  width: 100%;

  :hover {
    ${ControlContainer} {
      opacity: 1;
    }
  }
`;

export const WorkAnniversaryItem = styled(StyledTimelineItem)``;

export const YearContainer = styled.div`
  position: absolute;
  left: -80px;
  color: #1b283f;
  font-size: 12px;
  font-weight: 600;
  @media (max-width: 1300px) {
    left: 2px;
    top: -30px;
  }
`;

export const Point = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  margin: 0 10px;
  content: '';
  box-sizing: border-box;
`;

export const TimelineAvatar = styled.div<IPropsBackground>`
  position: relative;
  top: -8px;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.$hidden ? HIDDEN_BACKGROUND_COLOR : props.background)};
`;

export const DateContainer = styled.div<IPropsBackground>`
  background: ${(props) => (props.$hidden ? HIDDEN_BACKGROUND_LABEL_COLOR : props.background)};
  color: ${(props) => (props.$hidden ? HIDDEN_COLOR : props.color)};
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 4px 8px;
  ${Point} {
    background: ${(props) => (props.$hidden ? HIDDEN_COLOR : props.color)};
  }
  font-weight: 600;
  font-size: 12px;
`;

export const TimeLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`;

export const ThanksInfoContainer = styled.div<IHiddenCheck>`
  padding: 8px;
  position: relative;
  border: 1px solid #e6eaf0;
  border-radius: 2px;
  margin-top: 16px;
  width: 100%;
  background: ${(props) => (props.$hidden ? HIDDEN_BACKGROUND_COLOR : '#fff;')};
  @media (max-width: 1300px) {
    width: 262px;
    ${({ $isThanksType }) =>
      $isThanksType &&
      `
      width: 350px;
    `}
  }
`;

export const ThanksHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

export const WorkAnniversaryInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #e6eaf0;
  margin-top: 12px;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
    width: 262px;
    padding: 16px;
    overflow: hidden;
  }
`;

export const StyledAnniversaryImage = styled.img`
  @media (max-width: 1300px) {
    align-self: flex-end;
    margin-right: -16px;
    margin-top: -40px;
  }
`;

export const WorkAnniversaryText = styled.div`
  padding-left: 16px;
  h1 {
    font-size: 20px;
    font-weight: 600;
    color: #111111;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 14px;
    color: #4a4a4a;
    padding: 0;
    margin: 0;
    margin-top: 18px;

    span {
      color: rgba(24, 144, 255, 1);
    }
  }

  @media (max-width: 1300px) {
    padding-left: 0;
  }
`;

export const ThanksInfoWithoutMessage = styled(ThanksInfoContainer)<IHiddenCheck>`
  align-items: center;
  background: ${(props) => (props.$hidden ? HIDDEN_BACKGROUND_COLOR : '#fff')};
`;

export const ThanksInfo = styled(ThanksInfoContainer)<IHiddenCheck>`
  align-items: flex-start;
  background: ${(props) => (props.$hidden ? HIDDEN_BACKGROUND_COLOR : '#fff')};
`;

export const StyledAvatar = styled(Avatar)`
  position: relative;
  cursor: pointer;
`;

export const RecognitionStyledAvatar = styled(Avatar)``;

export const StyledImage = styled(Image)`
  &.ant-image {
    img {
      width: 32px;
      height: 32px;
    }
  }

  &.ant-image-img {
    width: 32px;
    height: 32px;
  }
`;

export const CertificateInfoMessage = styled(ThanksInfoWithoutMessage)<IHiddenCheck>`
  display: flex;
  justify-content: space-between;
  img {
    max-width: 32px;
    max-height: 32px;
  }
  background: ${(props) => (props.$hidden ? HIDDEN_BACKGROUND_COLOR : '#fff;')};
`;

export const CertificateInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TransferInfoContainer = styled.div`
  padding: 4px 8px;
  position: relative;
  border: 1px solid #e6eaf0;
  border-radius: 2px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  span {
    h2 {
      color: #1890ff !important;
    }
  }

  img {
    padding: 0 14px;
  }

  @media (max-width: 1300px) {
    width: 262px;
  }
`;

export const NameContainer = styled.div<IPointed>`
  display: flex;
  flex-direction: column;
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer' : 'auto')};
  h1 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-left: 15px;
    padding: 0;
  }

  p {
    color: rgba(74, 74, 74, 1);
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
`;

interface IUserContainerProps {
  isRecognition?: boolean;
}

export const UserContainer = styled.div<IUserContainerProps>`
  display: flex;
  align-items: center;

  ${Point} {
    background: #4b4b4b;
  }

  ${({ isRecognition }) =>
    isRecognition &&
    `
    margin-left: 12px;
`};
`;

export const BadgeContainer = styled.div`
  width: 24px;
  height: 24px;
  align-items: center;
  z-index: 999;
  img {
    width: 100%;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RecognitionNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  h1 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  p {
    color: rgba(74, 74, 74, 1);
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
`;

export const Button = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  color: #1890ff;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
`;

export const ThanksWithMessageButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHideIcon = styled(Hide)`
  margin-right: 8px;
`;

export const StyledVisibleIcon = styled(Visible)`
  margin-right: 8px;
`;

export const Mention = styled.span`
  cursor: pointer;
  color: #1890ff;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledBold = styled.b`
  font-weight: 500;
`;

export const MainDescriptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const DescriptionContainer = styled.div`
  word-break: break-word;
  margin-top: 8px;
  padding-left: 40px;
  ol {
    padding: 0 0 0 20px;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

export const SliceButton = styled.span`
  color: #1890ff;
  font-size: 14px;
  font-weight: 400;
  padding-left: 40px;
  line-height: 24px;
  margin-top: 10px;
  user-select: none;
  cursor: pointer;
`;

export const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 40px;

  ${({ isLoadedAllData }: { isLoadedAllData?: boolean }) =>
    isLoadedAllData &&
    `
    height: 0;
    margin-top: 0;
  `};
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
