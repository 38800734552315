import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { MainContainer, HrPostIcon, HrPostText, HrPostTextContainer, ReadIndicator } from './styles';
import { RelatedEntityType, hrNotificationsIconTypes, RelatedEntityTypes } from './const';
import { NOTIFICATION_TYPES } from '../../constants';
import { getMessageForNotify } from './utils';

interface IUser {
  fullName: string;
  id: string;
  hireDate?: string;
  userId?: string;
}

interface INotifyData {
  id: string;
  status: string;
  createdAt: string;
  relatedEntity: {
    post: {
      targetEmployees: IUser[];
    };
  };
  user: IUser;
  relatedEntityType: RelatedEntityType;
  userId: string;
}

interface IProps {
  data: INotifyData;
  changeNotify: (id: string) => void;
}

const HrPost: FC<IProps> = ({ data, changeNotify }) => {
  const history = useHistory();
  const { relatedEntityType, relatedEntity, createdAt, id, user } = data;
  const { fullName, id: userId } = user;
  const targetEmployees = relatedEntity?.post?.targetEmployees || [];
  const changedHrPostIcon = useMemo(
    () => hrNotificationsIconTypes[relatedEntityType],
    [relatedEntityType, hrNotificationsIconTypes],
  );
  const workAnniversaryYears = useMemo(() => {
    if (relatedEntityType === RelatedEntityTypes.POST_WORK_ANNIVERSARY_TARGETED) {
      const currentEmployee = targetEmployees.find((elem) => elem.userId === userId);
      const hireDate = currentEmployee?.hireDate;
      if (hireDate) {
        return moment().diff(moment(hireDate), 'years') || 0;
      }
    }
    return 0;
  }, [targetEmployees, userId]);

  const toProfileHandler = () => {
    if (
      relatedEntityType === RelatedEntityTypes.POST_BIRTHDAY_WISHES_TARGETED &&
      !moment(createdAt).isSame(moment(), 'date') &&
      targetEmployees.length <= 1
    ) {
      return;
    }
    if (
      targetEmployees.length > 1 ||
      relatedEntityType === RelatedEntityTypes.POST_NEW_HIRES_TARGETED ||
      relatedEntityType === RelatedEntityTypes.POST_PROMOTION_TARGETED ||
      relatedEntityType === RelatedEntityTypes.POST_WORK_ANNIVERSARY_TARGETED
    ) {
      changeNotify(id);
      return;
    }
    history.push(`/profile/${userId}`);
  };

  return (
    <MainContainer onClick={toProfileHandler}>
      <HrPostIcon src={changedHrPostIcon} />
      <HrPostTextContainer>
        <HrPostText>
          <p>{getMessageForNotify(relatedEntityType, fullName, targetEmployees.length, workAnniversaryYears)}</p>
          <span>{moment(createdAt).format('MMM DD')}</span>
        </HrPostText>
        {(status === NOTIFICATION_TYPES.SEEN || status === NOTIFICATION_TYPES.NEW) && <ReadIndicator />}
      </HrPostTextContainer>
    </MainContainer>
  );
};

export default HrPost;
