import styled from 'styled-components';
import { Layout, Breadcrumb, Row, Tabs } from 'antd';
const { Sider, Content } = Layout;

export const ProfileContainer = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: 90px;
`;

export const MainContainer = styled(Layout)`
  max-width: 1440px;
  margin: 0 auto;
  background: transparent;
`;

export const InnerContainer = styled(Layout)`
  padding: 0 0 24px 0;
  background: transparent;
`;

export const HeaderRow = styled(Row)`
  padding: 22px 106px 18px 106px;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #111111;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding: 22px 44px 18px 44px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    line-height: 38px;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumb)`
  margin: 0;
`;

export const BreadCrumbElement = styled(Breadcrumb.Item)`
  color: #6b778c;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
`;

export const MenuElement = styled.div`
  color: #4b4b4b;
  font-size: 12px;
  line-height: 16px;
  margin-top: 14px;
  cursor: pointer;

  :first-child {
    margin-top: 0;
  }
`;

export const OtherUsersContainer = styled.span`
  cursor: pointer;
  :hover {
    color: #1890ff;
  }
`;

export const StyledSider = styled(Sider)`
  background: transparent;
  padding: 0 16px 24px 106px;
  box-sizing: content-box;
  @media (max-width: 1200px) {
    width: 270px !important;
    max-width: 270px !important;
    min-width: 270px !important;
    flex: 0 !important;
    padding: 0 16px 24px 44px;
  }
  @media (max-width: 992px) {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    box-sizing: border-box;
    padding: 0 44px 24px 44px;
  }
`;

export const MainContent = styled(Content)`
  padding: 0 106px 0 0;
  @media (max-width: 1200px) {
    padding: 0 44px 0 0;
  }
  @media (max-width: 992px) {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    box-sizing: border-box;
    padding: 0 44px 24px 44px;
  }
`;

export const StyledTabs = styled(Tabs)`
  & > .ant-tabs-nav {
    padding-left: 24px;
    padding-right: 68px;
    background: #fff;
    box-shadow: 0px 2px 10px rgba(166, 169, 174, 0.219608);
    border-radius: 5px;
    margin: 0 0 8px 0 !important;
  }

  & .ant-tabs-ink-bar {
    height: 4px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: #1994df;
  }

  & .ant-tabs-tab {
    margin-right: 27px;
    padding: 16px 0;
  }

  & .ant-tabs-tab-btn {
    padding: 0 10px;
    font-weight: 500;
    color: #4b4b4b;
  }
`;

export const StyledLayout = styled(Layout)`
  background: transparent;
  @media (max-width: 992px) {
    flex-direction: column !important;
  }
`;
