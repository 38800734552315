import React, { FC, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { impersonalizeByEmail } from 'store/domains/auth/auth.actions';

const Impersonalize: FC = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const email = search.replace('?email=', '');
  const history = useHistory();

  useEffect(() => {
    dispatch(impersonalizeByEmail(email, history));
  }, []);
  return <div>Impersonalizing...</div>
};

export default Impersonalize;
