import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { profileRoutes } from 'modules/profile/config/Profile.router';
import { Card, CardsContainer, CardLastWrapper, StyledImage } from './styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';

interface IProps {
  employees: any;
  onClickHandler: () => void;
}

const TargetEmployees: FC<IProps> = ({ employees, onClickHandler: openPreview }) => {
  const slicedEmployees = employees.slice(0, 4);
  const history = useHistory();
  const goToProfile = (id: string) => history.push(`${profileRoutes.profile}${id}`);
  return (
    <CardsContainer $repeat={slicedEmployees.length}>
      {slicedEmployees.map((elem, key) => {
        const { photoUrl, userId } = elem;
        const hasMoreCards = employees.length > slicedEmployees.length && slicedEmployees.length - 1 === key;
        const onClick = () => (hasMoreCards ? openPreview() : goToProfile(userId));
        return (
          <Card key={key} onClick={onClick}>
            <StyledImage src={photoUrl || emptyAvatar} fallback={emptyAvatar} preview={false} />
            {hasMoreCards && (
              <CardLastWrapper>
                <h2>+{employees.length - slicedEmployees.length}</h2>
              </CardLastWrapper>
            )}
          </Card>
        );
      })}
    </CardsContainer>
  );
};

export default TargetEmployees;
