import React from 'react';
import { useSelector } from 'react-redux';
import { MenuPopoverMainContainer, MenuPopoverElement } from '../../styles';
import { appsMenuElements } from '../../constants';
import { UserState } from 'store/domains/user/user.types';
import { RootState } from 'store/rootReducer';
import { emitApplicationClick } from 'utils/mixpanel';
import useOrgChart from 'modules/common/gql/hooks/useOrgChartQuery';

const MenuPopover = () => {
  const user: UserState = useSelector((state: RootState) => state?.user);
  const { permissionIds } = user;
  const orgChartQuery = useOrgChart(user.id);
  const directReports = orgChartQuery?.userOrgChart?.directReports || [];

  return (
    <MenuPopoverMainContainer id="menu-popover">
      {appsMenuElements.map((elem, key) => {
        const { image, link, text, restrictPermission, mixpanelApplication } = elem;
        const isRestricted = restrictPermission && !permissionIds?.includes(restrictPermission);
        if ((link === 'https://app.peakon.com/dashboard' && directReports.length <= 0) || isRestricted) {
          return null;
        }

        return (
          <MenuPopoverElement
            onClick={() => {
              emitApplicationClick({ application: mixpanelApplication });
            }}
            href={link}
            target={'_blank'}
            key={key}
          >
            {image && <img src={image} />}
            {text && <span>{text}</span>}
          </MenuPopoverElement>
        );
      })}
    </MenuPopoverMainContainer>
  );
};

export default MenuPopover;
