import Oidc from 'oidc-client';
import constants from './config';
import { authRoutes } from 'modules/auth/constants';

const settings = {
  authority: `https://${constants.oneLoginSubdomain}.onelogin.com/oidc/2`,
  client_id: constants.clientId,
  redirect_uri: window.location.origin + authRoutes.loginRedirect,
  response_type: 'id_token token',
  scope: 'openid profile',
  filterProtocolClaims: true,
  loadUserInfo: true,
};
const mgr = new Oidc.UserManager(settings);

export default mgr;
