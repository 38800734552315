/* eslint-disable max-len */
function embedHFWidget(user) {
  const closeIframe: any = document.createElement('img');
  let embedButton = document.createElement('a');
  embedButton.style.zIndex = '2147483646';
  embedButton.style.backgroundColor = 'ff8600' ? '#ff8600' : '#333';
  embedButton.innerHTML = 'Feedback' ? 'Feedback' : 'Contact Us';
  embedButton.style.color = '#FFF';
  let buttonCss =
    'font-family: "OpenSansRegular", sans-serif; position: fixed; padding: 10px 20px 10px 20px; letter-spacing: 1px; -webkit-border-bottom-left-radius: 10px; -moz-border-radius-bottomleft: 10px; border-bottom-left-radius: 10px; -webkit-border-bottom-right-radius: 10px; -moz-border-radius-bottomright: 10px; border-bottom-right-radius: 10px;';
  embedButton.style.cssText +=
    buttonCss +
    ' -webkit-transform-origin: 100% 50%; -webkit-transform: rotate(90deg) translate(50%, 50%); -moz-transform-origin: 100% 50%; -moz-transform: rotate(90deg) translate(50%, 50%); -ms-transform-origin: 100% 50%; -ms-transform: rotate(90deg) translate(50%, 50%); -o-transform-origin: 100% 50%; -o-transform: rotate(90deg) translate(50%, 50%); transform-origin: 100% 50%; transform: rotate(90deg) translate(50%, 50%); top: 40%; right: 0;';
  embedButton.style.cursor = 'pointer';
  embedButton.style.textDecoration = 'none';
  embedButton.addEventListener(
    'click',
    function embed() {
      let embedDiv = document.createElement('div');
      embedDiv.style.border = '0';
      embedDiv.style.position = 'fixed';
      embedDiv.style.top = '0';
      embedDiv.style.left = '0';
      embedDiv.style.right = '0';
      embedDiv.style.bottom = '0';
      embedDiv.style.width = '100%';
      embedDiv.style.height = '100%';
      embedDiv.style.overflow = 'hidden';
      embedDiv.style.zIndex = '2147483647';
      function removeDiv() {
        let divToRemove = document.getElementById('embedDiv');
        if (divToRemove) {
          divToRemove?.parentNode?.removeChild(divToRemove);
          return false;
        }
      }
      embedDiv.id = 'embedDiv';
      embedDiv.addEventListener('click', removeDiv, false);
      document.body.appendChild(embedDiv);
      const iframe: any = document.createElement('iframe');
      const name = `${user.firstName} ${user.lastName}`;
      const email = user.email;
      iframe.src = `https://support.astreya.com/supportwidgets/code_support_widget/2?name=${name}&email=${email}`;
      iframe.style.position = 'absolute';
      iframe.style.border = '0';
      iframe.style.background = 'none transparent';
      iframe.style.width = '50%';
      iframe.style.height = '80%';
      iframe.style.top = '10%';
      iframe.style.left = '25%';

      iframe.addEventListener(
        'load',
        function resizeIframe() {
          const grayDiv = document.getElementById('embedDiv');
          if (grayDiv) {
            grayDiv.style.backgroundColor = 'rgba(0,0,0,0.3)';
            grayDiv?.appendChild(closeIframe);
          }
        },
        false,
      );
      iframe.id = 'embedWidget';
      iframe.frameBorder = '0';
      iframe.allowTransparency = 'true';
      embedDiv.appendChild(iframe);
      closeIframe.type = 'button';
      closeIframe.addEventListener('click', removeDiv, false);
      closeIframe.src = 'https://support.astreya.com/media/img/1415967259__close.png';
      closeIframe.className = 'supportwidgetclose';
    },
    false,
  );
  document.body.appendChild(embedButton);
}

export default embedHFWidget;
