import styled from 'styled-components';
import { Layout, Row } from 'antd';

import { ReactComponent as ArrowLeft } from '../KnowledgeBaseElement/assets/arrowLeft.svg';

export const MainContentContainer = styled.div`
  width: 100%;
  overflow: auto;
  background: #fff;
  min-height: calc(100vh - 56px);
`;

export const MainContainer = styled(Layout)`
  max-width: 1440px;
  margin: 0 auto;
  background: transparent;
`;

export const HeaderRow = styled(Row)`
  padding: 22px 106px 18px 106px;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: #111111;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    padding: 22px 44px 18px 44px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    line-height: 38px;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-top: 10px;
  }
`;

export const ContentContent = styled.div`
  padding: 0px 106px 115px 106px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 992px) {
    padding: 0px 20px 20px;
  }
`;

export const ContentContainer = styled.div`
  max-width: 900px;
  width: 100%;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #111111;
  margin: 0;
  padding: 0;
`;

export const Section = styled.div`
  background: #ecf6ff;
  border-radius: 4px;
  padding: 4px 12px;
  color: #1890ff;
  margin-right: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  user-select: none;
`;

export const SplitLine = styled.div`
  width: 1px;
  height: 40px;
  background: #d8d8d8;
  content: '';
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 20px;
  font-size: 16px;

  * {
    padding: 0;
    margin: 0;
  }

  article {
    font-weight: 400;
    font-style: normal;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: #4b4b4b;
    font-weight: 400;
    margin-top: 5px 0;
  }

  em {
    font-size: 14px;
    line-height: 24px;
    color: #4b4b4b;
    font-weight: 400;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700 !important;
    margin-top: 10px;
    color: #111111;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  img {
    border: 1px solid #dee2e4;
    margin: 10px;
  }

  ol {
    margin: 0 20px 20px 0;
    padding-left: 40px;
    list-style-type: decimal;
    line-height: 1.6;
    margin-left: -20px;
    font-size: 14px;
    color: #4b4b4b;
    font-weight: 400;
  }

  ul {
    list-style: none;
    font-size: 14px;
    color: #4b4b4b;
    font-weight: 400;
  }

  li {
    white-space: normal;
    margin-top: 15px;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    color: #1890ff;
  }
`;

export const MainTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
`;

export const Tag = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1890ff;
  cursor: pointer;
  margin-right: 24px;
`;

export const GoBackButton = styled.div`
  color: #1890ff;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  user-select: none;
  cursor: pointer;
`;

export const StyledArrow = styled(ArrowLeft)`
  margin-right: 10px;
`;

export const AttachmentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-top: -6px;
`;

export const AttachmentElement = styled.a`
  display: flex;
  align-items: center;
  margin-right: 45px;
  margin-top: 6px;

  p {
    color: #1890ff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 0 5px;
    padding: 0;
  }
`;
