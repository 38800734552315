import { gql } from '@apollo/client';

export const DETACH_SKILL = gql`
  mutation detachSkillFromUser($userId: String!, $skillId: String!) {
    detachSkillFromUser(userId: $userId, skillId: $skillId)
  }
`;

export const DETACH_HOBBY = gql`
  mutation detachHobbyFromUser($userId: String!, $hobbyId: String!) {
    detachHobbyFromUser(userId: $userId, hobbyId: $hobbyId)
  }
`;

export const DETACH_TRAINING = gql`
  mutation detachTrainingFromUser($userId: String!, $trainingId: String!) {
    detachTrainingFromUser(userId: $userId, trainingId: $trainingId)
  }
`;

export const ATTACH_TRAINING = gql`
  mutation attachTrainingToUser($trainingId: String!, $issueDate: DateTime!, $userId: String!) {
    attachTrainingToUser(input: { trainingId: $trainingId, issueDate: $issueDate }, userId: $userId) {
      id
    }
  }
`;

export const ATTACH_CERTIFICATE = gql`
  mutation attachCertificationToUser($certificationId: String!, $issueDate: DateTime!, $userId: String!) {
    attachCertificationToUser(input: { certificationId: $certificationId, issueDate: $issueDate }, userId: $userId) {
      id
    }
  }
`;

export const ATTACH_INTERESTS = gql`
  mutation createHobbiesAndAttachToUser($userId: String!, $input: [CreateProfileEntityInput!]!) {
    createHobbiesAndAttachToUser(userId: $userId, input: $input) {
      name
    }
  }
`;

export const ATTACH_SKILL = gql`
  mutation createSkillsAndAttachToUser($userId: String!, $input: [CreateProfileEntityInput!]!) {
    createSkillsAndAttachToUser(userId: $userId, input: $input) {
      name
    }
  }
`;

export const DETACH_CERTIFICATION = gql`
  mutation detachCertificationFromUser($userId: String!, $certificationId: String!) {
    detachCertificationFromUser(userId: $userId, certificationId: $certificationId)
  }
`;

export const UPDATE_WORKING_HOURS = gql`
  mutation updateEmploymentByUserId($id: String!, $workingHoursStart: String!, $workingHoursEnd: String!) {
    updateEmploymentByUserId(id: $id, input: { workingHoursStart: $workingHoursStart, workingHoursEnd: $workingHoursEnd }) {
      id
    }
  }
`;

export const UPDATE_LOCAL_TIME_ZONE = gql`
  mutation updateUserById($id: String!, $timeZone: String!) {
    updateUserById(id: $id, input: { timeZone: $timeZone }) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_MISSION = gql`
  mutation updateEmploymentByUserId($id: String!, $employeeMission: String!) {
    updateEmploymentByUserId(id: $id, input: { employeeMission: $employeeMission }) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_DESCRIPTION = gql`
  mutation updateEmploymentByUserId($id: String!, $employeeDescription: String!) {
    updateEmploymentByUserId(id: $id, input: { employeeDescription: $employeeDescription }) {
      id
    }
  }
`;

export const UPDATE_PREFERED_NAME = gql`
  mutation updateUserById($id: String!, $fullName: String!) {
    updateUserById(id: $id, input: { fullName: $fullName }) {
      id
    }
  }
`;

export const CREATE_PERSONAL_TIMELINE_EVENT = gql`
  mutation createPersonalTimelineEvent($input: CreateTimelineEventInput!) {
    createPersonalTimelineEvent(input: $input) {
      id
      name
      type
      date
      relatedEntityId
      relatedEntity
      hidden
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PERSONAL_TIMELINE_EVENT = gql`
  mutation updatePersonalTimelineEvent($input: UpdateTimelineEventInput!, $id: String!) {
    updatePersonalTimelineEvent(input: $input, id: $id) {
      id
      name
      type
      date
      relatedEntityId
      relatedEntity
      hidden
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_PERSONAL_TIMELINE_EVENT = gql`
  mutation deletePersonalTimelineEvent($id: String!) {
    deletePersonalTimelineEvent(id: $id)
  }
`;

export const TOGGLE_EVENT_VISIBILITY = gql`
  mutation toggleTimelineEventVisibility($hidden: Boolean!, $id: String!) {
    toggleTimelineEventVisibility(hidden: $hidden, id: $id) {
      id
    }
  }
`;

export const FOLLOW_USER = gql`
  mutation followAuthor($id: String!) {
    followAuthor(authorId: $id)
  }
`;

export const UNFOLLOW_USER = gql`
  mutation unfollowAuthor($id: String!) {
    unfollowAuthor(authorId: $id)
  }
`;

export const TOGGLE_PHONE = gql`
  mutation toggleField($id: String!, $hidden: Boolean!, $phoneId: String!) {
    toggleField(input: { phone: { id: $phoneId, hidden: $hidden } }, id: $id) {
      id
    }
  }
`;

export const TOGGLE_BIRTHDAY = gql`
  mutation toggleField($id: String!, $hidden: Boolean!) {
    toggleField(input: { birthDate: $hidden }, id: $id) {
      id
    }
  }
`;

export const SET_PHOTO = gql`
  mutation setPhoto($file: UploadImage!, $id: String!) {
    setPhoto(file: $file, id: $id) {
      id
    }
  }
`;

export const SET_AVATAR = gql`
  mutation setAvatar($avatarId: String!, $id: String!) {
    setAvatar(avatarId: $avatarId, id: $id) {
      id
    }
  }
`;

export const ACCEPT_FOLLOW_REQUEST = gql`
  mutation acceptFollow($followerId: String!) {
    acceptFollow(followerId: $followerId)
  }
`;

export const DECLINE_FOLLOW_REQUEST = gql`
  mutation declineFollow($followerId: String!) {
    declineFollow(followerId: $followerId)
  }
`;
