import React, { FC } from 'react';
import { Typography } from 'antd';
import { StyledInnerTitle, StyledCol } from '../../styles';
import { IUserModel } from 'modules/profile/gql/models/user.model';

export interface IProps {
  user: IUserModel;
}
const Location: FC<IProps> = ({ user }) => {
  const { workingAddress } = user;

  if (!workingAddress) {
    return null;
  }

  return (
    <StyledCol span={24}>
      <StyledInnerTitle disabled>Location</StyledInnerTitle>
      <Typography.Text>{`${workingAddress?.municipality}, ${workingAddress?.countryCode}`}</Typography.Text>
    </StyledCol>
  );
};

export default Location;
