import React, { FC, useMemo } from 'react';
import 'react-custom-scroll/dist/customScroll.css';
import CustomScroll from 'react-custom-scroll';
import pluralize from 'pluralize';

import {
  MainContainer,
  FilterGroups,
  FilterGroup,
  Filters,
  Filter as StyledFilter,
  LoadingContainer,
  EmptyListContainer,
  StyledCloseFilter,
  StyledPlusFilter,
  SearchResultsContainer,
  ViewButton,
  MainFilters,
} from './styles';
import { FiltersContainer } from '../../styles';
import { ICityFilter, ICostCenterFilter, IInterestFilter, IJobTitleFilter, ISkillFilter } from '../SubHeader';
import Spinner from 'modules/common/components/Spinner';
import { FilterTypes } from '../SubHeader';
import { Filter } from '../../ClubView';
import EmptyFilterSkeleton from './components/EmptyFilterSkeleton';
import { emitSearchClubView } from 'utils/mixpanel';

interface IProps {
  cities: ICityFilter[];
  costCenters: ICostCenterFilter[];
  interests: IInterestFilter[];
  jobTitles: IJobTitleFilter[];
  skills: ISkillFilter[];
  queriesLoading: boolean;
  changeFilter: (value: string, group: FilterTypes) => void;
  filtersData: Filter;
  deleteFilter: (value: string, group: FilterTypes) => void;
  countClubViewUsers?: number;
  onClose: () => void;
  searchValue: string;
}

const SearchPopover: FC<IProps> = ({
  cities,
  costCenters,
  interests,
  jobTitles,
  skills,
  queriesLoading,
  changeFilter,
  filtersData,
  deleteFilter,
  countClubViewUsers,
  onClose,
  searchValue,
}) => {
  const filteredInterests = interests.filter((elem) => {
    const { id, name } = elem;

    return !filtersData.interestIds?.includes(`${id}:${name}`);
  });

  const filteredSkills = skills.filter((elem) => {
    const { id, name } = elem;

    return !filtersData.skillIds?.includes(`${id}:${name}`);
  });

  const isEmptyList = useMemo(
    () => cities.length < 1 && costCenters.length < 1 && interests.length < 1 && jobTitles.length < 1 && skills.length < 1,
    [cities, costCenters, interests, jobTitles, skills],
  );

  const filtersCount = Object.keys(filtersData).reduce((prev, cur) => {
    return Array.isArray(filtersData[cur]) ? prev + filtersData[cur].length : ++prev;
  }, 0);

  const filtersParsed = Object.keys(filtersData).filter((elem) => filtersData[elem] && filtersData[elem].length > 0);
  return (
    <MainContainer>
      <CustomScroll flex={'1'}>
        {isEmptyList && !queriesLoading ? (
          <EmptyListContainer>
            <h1>No Search Results</h1>
            <p>Try to broaden your search</p>
          </EmptyListContainer>
        ) : (
          <FilterGroups>
            <SearchResultsContainer>
              <h1>{searchValue.length > 0 ? 'Search Results' : 'Trending Searches'}</h1>
              {filtersParsed.length > 0 && (
                <ViewButton
                  onClick={() => {
                    onClose();
                    emitSearchClubView({ resultsCount: countClubViewUsers || 0, filtersCount });
                  }}
                >
                  {`View ${pluralize('Result', countClubViewUsers, true)}`}
                </ViewButton>
              )}
            </SearchResultsContainer>
            <FiltersContainer style={{ marginTop: 0 }}>
              {Object.keys(filtersData).map((key: any) => {
                const changedFilter = filtersData[key];

                if (!changedFilter) {
                  return null;
                }

                if (Array.isArray(changedFilter)) {
                  return changedFilter.map((filter) => {
                    const filterValue = ['interestIds', 'skillIds', 'costCenterId'].includes(key)
                      ? filter?.split(':')[1] || ''
                      : filter;
                    return (
                      <StyledFilter isChanged key={key}>
                        <p>{filterValue}</p> <StyledCloseFilter onClick={() => deleteFilter(filter, key)} />
                      </StyledFilter>
                    );
                  });
                } else {
                  const filterValue = ['interestIds', 'skillIds', 'costCenterId'].includes(key)
                    ? changedFilter.split(':')[1] || ''
                    : changedFilter;

                  return (
                    <StyledFilter isChanged key={key}>
                      <p>{filterValue}</p> <StyledCloseFilter onClick={() => deleteFilter(changedFilter, key)} />
                    </StyledFilter>
                  );
                }
              })}
            </FiltersContainer>
            {queriesLoading ? (
              <FilterGroup>
                <h2>Interests</h2>
                <EmptyFilterSkeleton />
              </FilterGroup>
            ) : (
              filteredInterests.length > 0 && (
                <FilterGroup>
                  <h2>Interests</h2>
                  <MainFilters>
                    <Filters>
                      {filteredInterests.map((elem) => {
                        const { id, name } = elem;

                        return (
                          <StyledFilter key={id} onClick={() => changeFilter(`${id}:${name}`, 'interestIds')}>
                            <p>{name}</p>
                            <StyledPlusFilter />
                          </StyledFilter>
                        );
                      })}
                    </Filters>
                  </MainFilters>
                </FilterGroup>
              )
            )}
            {queriesLoading ? (
              <FilterGroup>
                <h2>Skills</h2>
                <EmptyFilterSkeleton />
              </FilterGroup>
            ) : (
              filteredSkills.length > 0 && (
                <FilterGroup>
                  <h2>Skills</h2>
                  <MainFilters>
                    <Filters>
                      {filteredSkills.map((elem) => {
                        const { id, name } = elem;

                        return (
                          <StyledFilter key={id} onClick={() => changeFilter(`${id}:${name}`, 'skillIds')}>
                            <p>{name}</p>
                            <StyledPlusFilter />
                          </StyledFilter>
                        );
                      })}
                    </Filters>
                  </MainFilters>
                </FilterGroup>
              )
            )}
            {queriesLoading ? (
              <FilterGroup>
                <h2>City</h2>
                <EmptyFilterSkeleton />
              </FilterGroup>
            ) : (
              !filtersData.city &&
              cities.length > 0 && (
                <FilterGroup>
                  <h2>City</h2>
                  <MainFilters>
                    <Filters>
                      {cities.map((elem) => {
                        const { city } = elem;

                        return (
                          <StyledFilter key={city} onClick={() => changeFilter(city, 'city')}>
                            <p>{city}</p>
                            <StyledPlusFilter />
                          </StyledFilter>
                        );
                      })}
                    </Filters>
                  </MainFilters>
                </FilterGroup>
              )
            )}
            {queriesLoading ? (
              <FilterGroup>
                <h2>Title</h2>
                <EmptyFilterSkeleton />
              </FilterGroup>
            ) : (
              !filtersData.jobTitle &&
              jobTitles.length > 0 && (
                <FilterGroup>
                  <h2>Title</h2>
                  <MainFilters>
                    <Filters>
                      {jobTitles.map((elem) => {
                        const { title } = elem;

                        return (
                          <StyledFilter key={title} onClick={() => changeFilter(title, 'jobTitle')}>
                            <p>{title}</p>
                            <StyledPlusFilter />
                          </StyledFilter>
                        );
                      })}
                    </Filters>
                  </MainFilters>
                </FilterGroup>
              )
            )}
            {queriesLoading ? (
              <FilterGroup>
                <h2>Program</h2>
                <EmptyFilterSkeleton />
              </FilterGroup>
            ) : (
              !filtersData.costCenterId &&
              costCenters.length > 0 && (
                <FilterGroup>
                  <h2>Program</h2>
                  <MainFilters>
                    <Filters>
                      {costCenters.map((elem) => {
                        const { id, name } = elem;

                        return (
                          <StyledFilter key={id} onClick={() => changeFilter(`${id}:${name}`, 'costCenterId')}>
                            <p>{name}</p>
                            <StyledPlusFilter />
                          </StyledFilter>
                        );
                      })}
                    </Filters>
                  </MainFilters>
                </FilterGroup>
              )
            )}
            {queriesLoading && (
              <LoadingContainer>
                <Spinner width={46} height={46} />
              </LoadingContainer>
            )}
          </FilterGroups>
        )}
      </CustomScroll>
    </MainContainer>
  );
};

export default SearchPopover;
