import React, { FC, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';

import {
  MainModalContainer,
  PictureContainer,
  PictureFileLabel,
  PictureInput,
  SubmitButton,
  SubmitContainer,
  UploadTitleContainer,
  UploadPictureContainer,
  UploadPictureElement,
  SmallPictureFileLabel,
  UploadTitleSmallContainer,
  StyledDeletePhotoImage,
  UploadPictureElementContainer,
  WarningText,
  PostTypeSwitch,
  PostTypeThanks,
  PostTypeRecognize,
  StyledClapIcon,
  StyledRecognizeIcon,
  PostTypeCloseIcon,
  UploadPdfElement,
  StyledAttachmentImage,
} from '../styled';
import ThanksSuggestions from './ThanksSuggestions';
import { IEditableData } from '../MainContent';
import { ReactComponent as UploadImage } from 'modules/news-feed/pages/Feed/assets/createPostModal/uploadImage.svg';
import Editor from 'modules/common/components/forms/RichTextEditor';
import { FilesType } from '../MainContent';
import Spinner from 'modules/common/components/Spinner';
import { NewsFeedTypes } from '../../MainContent/MainContent';
import { POST_TYPES, placeholderEditor } from '../../../constants';
import UsersMultiSelector from './UsersMultiSelector';
import BadgesContainer from './BadgesContainer';
import { IBadge } from '../../MainContent/MainContent';
import { IUserModel } from 'modules/common/gql/models/user.model';
import { GET_THANKS_MESSAGE_SUGGESTIONS } from 'modules/common/gql/query';
import { NF_PERMISSIONS } from 'modules/auth/constants';
import { IThanksMessageSuggestionsResponse } from './CreatePostModal';
import { getFileExtension } from 'modules/news-feed/pages/Feed/utils';
import Pdf from './PdfContainer';

interface IProps {
  editableValue: IEditableData;
  isEditMode: boolean;
  changeFileHandler: (file: FileList | null) => void;
  submitHandler: () => void;
  onChangeTextNewsFeed: (value: string, editor?: any) => void;
  deleteNotUploadedImage: (index: number) => void;
  deleteUploadedImage: (fileId: string) => void;
  isImagesListInvalid: boolean;
  isBlockedSubmit: boolean;
  submitButtonBlock: () => void;
  filteredImages: FilesType[];
  type: NewsFeedTypes;
  setValueHandler: (value: any, fieldName: string) => void;
  badgesData: IBadge[];
  user: IUserModel;
  isPdfListInvalid: boolean;
  isInValidImage: boolean;
  isInValidPdf: boolean;
}

const Individual: FC<IProps> = ({
  isEditMode,
  editableValue,
  changeFileHandler,
  submitHandler,
  onChangeTextNewsFeed,
  deleteNotUploadedImage,
  deleteUploadedImage,
  isImagesListInvalid,
  isBlockedSubmit,
  submitButtonBlock,
  filteredImages,
  type,
  user,
  setValueHandler,
  badgesData,
  isPdfListInvalid,
  isInValidImage,
  isInValidPdf,
}) => {
  const { permissionIds = [] } = user;
  const isEditIndividual = isEditMode && type === POST_TYPES.INDIVIDUAL;
  const editorRef = useRef<any>(null);
  const { text, targetEmployees, badge } = editableValue;
  const { data: messagesThanksSuggestions } = useQuery<IThanksMessageSuggestionsResponse>(GET_THANKS_MESSAGE_SUGGESTIONS);
  const isIndividualDataValid = useMemo(() => {
    const { text, type, badge, targetEmployees } = editableValue;

    if (type === POST_TYPES.RECOGNITION) {
      return (targetEmployees || []).length > 0 && badge;
    }
    return Boolean(text.length > 0) || filteredImages.length > 0;
  }, [editableValue, filteredImages]);

  const preselectedUsers = useMemo(
    () => targetEmployees?.map((u) => ({ id: u.userId, fullName: u.fullName, photoUrl: u.photoUrl })),
    [targetEmployees],
  );
  const setEmptyValue = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element: any = event.target;
    element.value = '';
  };

  const resetPostType = (e) => {
    e.stopPropagation();
    setValueHandler(POST_TYPES.INDIVIDUAL, 'type');
  };

  const setBadge = (badge: string | null) => {
    setValueHandler(badge, 'badge');
  };

  return (
    <MainModalContainer isDontHaveTopPadding>
      <PostTypeSwitch>
        {type !== POST_TYPES.THANKS && !isEditIndividual && (
          <PostTypeRecognize
            onClick={() => {
              setValueHandler(POST_TYPES.RECOGNITION, 'type');
            }}
            $active={type === POST_TYPES.RECOGNITION && !isEditMode}
          >
            Recognize someone
            <StyledRecognizeIcon />
            {!isEditMode && type === POST_TYPES.RECOGNITION && <PostTypeCloseIcon onClick={resetPostType} />}
          </PostTypeRecognize>
        )}
        {type !== POST_TYPES.RECOGNITION && !isEditIndividual && (
          <PostTypeThanks
            onClick={() => {
              setValueHandler(POST_TYPES.THANKS, 'type');
            }}
            $active={type === POST_TYPES.THANKS && !isEditMode}
          >
            Thank someone
            <StyledClapIcon />
            {!isEditMode && type === POST_TYPES.THANKS && <PostTypeCloseIcon onClick={resetPostType} />}
          </PostTypeThanks>
        )}
      </PostTypeSwitch>
      {[POST_TYPES.THANKS, POST_TYPES.RECOGNITION].includes(type) && (
        <UsersMultiSelector
          setTargetEmployees={(users) => {
            const employees = users.map((u) => ({ userId: u.id, photoUrl: u.photoUrl, fullName: u.fullName }));
            setValueHandler(employees, 'targetEmployees');
          }}
          preselectedUsers={preselectedUsers}
        />
      )}
      {type === POST_TYPES.RECOGNITION && <BadgesContainer changedBadge={badge} setBadge={setBadge} badgesData={badgesData} />}
      {type === POST_TYPES.THANKS && messagesThanksSuggestions?.newsFeedThanksMessageSuggestions.length && (
        <ThanksSuggestions
          suggestions={messagesThanksSuggestions.newsFeedThanksMessageSuggestions}
          applySuggestion={(text) => {
            const editor = editorRef?.current.editor;
            editor.selection.setContent(text);
            editor.focus();
          }}
        />
      )}
      <Editor
        value={text}
        editorRef={editorRef}
        onChange={onChangeTextNewsFeed}
        placeholder={placeholderEditor[type]}
        hasMarkupPermission={permissionIds.includes(NF_PERMISSIONS.RICH_MARKUP)}
      />
      <PictureContainer>
        {filteredImages.length > 0 ? (
          <UploadPictureContainer>
            {filteredImages.map((elem, key) => {
              const extension = getFileExtension((elem instanceof File ? elem.name : elem?.fileName) || 'png');

              if (extension === 'pdf') {
                return (
                  <UploadPictureElementContainer key={key}>
                    <UploadPdfElement>
                      <StyledDeletePhotoImage
                        onClick={() => (elem instanceof File ? deleteNotUploadedImage(key) : deleteUploadedImage(elem.fileId))}
                      />
                      <StyledAttachmentImage />
                      <Pdf src={elem} page={1} scale={0.1} key={key} />
                    </UploadPdfElement>
                  </UploadPictureElementContainer>
                );
              }
              return (
                <UploadPictureElementContainer key={key}>
                  <UploadPictureElement>
                    <StyledDeletePhotoImage
                      onClick={() => (elem instanceof File ? deleteNotUploadedImage(key) : deleteUploadedImage(elem.fileId))}
                    />
                    <img src={elem instanceof File ? URL.createObjectURL(elem) : elem.fileUrl} />
                  </UploadPictureElement>
                </UploadPictureElementContainer>
              );
            })}
            <SmallPictureFileLabel htmlFor={'pictureUploaded'}>
              <UploadTitleSmallContainer>
                <UploadImage />
                <p>Add photos or PDFs</p>
              </UploadTitleSmallContainer>
            </SmallPictureFileLabel>
          </UploadPictureContainer>
        ) : (
          <PictureFileLabel htmlFor={'pictureUploaded'}>
            <UploadTitleContainer>
              <UploadImage />
              <p>Add photos or PDFs</p>
            </UploadTitleContainer>
          </PictureFileLabel>
        )}
        <PictureInput
          accept="image/x-png,image/gif,image/jpeg,application/pdf"
          multiple={true}
          type={'file'}
          id={'pictureUploaded'}
          onChange={(e) => {
            changeFileHandler(e.target.files);
          }}
          onClick={setEmptyValue}
        />
      </PictureContainer>
      <SubmitContainer>
        <SubmitButton
          type="primary"
          onClick={() => {
            submitButtonBlock();
            submitHandler();
          }}
          disabled={
            !isIndividualDataValid || isInValidImage || isInValidPdf || isImagesListInvalid || isPdfListInvalid || isBlockedSubmit
          }
        >
          {isBlockedSubmit ? <Spinner color={'#1890FF'} /> : 'Post'}
        </SubmitButton>
      </SubmitContainer>
      {isInValidImage && <WarningText>You can upload JPEG, PNG or GIF files. File size limit is 5 Mb.</WarningText>}
      {isInValidPdf && <WarningText>Pdf file size limit is 10 Mb.</WarningText>}
      {isImagesListInvalid && <WarningText>Maximum images per post - 10 images. Please delete redundant images.</WarningText>}
      {isPdfListInvalid && <WarningText>Maximum pdf files per post - 5. Please delete redundant files.</WarningText>}
    </MainModalContainer>
  );
};

export default Individual;
