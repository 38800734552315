import React, { FC } from 'react';

import { MainContainer, StyledButton, StyledInput, StyledSearch } from './styles';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

const SupportSearch: FC<IProps> = ({ onChange, onSubmit, value }) => {
  const enterPressHandler = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  return (
    <MainContainer>
      <StyledInput
        placeholder={'Search (ex. holidays, password reset, or OneLogin)'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={enterPressHandler}
      />
      <StyledButton onClick={onSubmit}>
        <StyledSearch />
        Search
      </StyledButton>
    </MainContainer>
  );
};

export default SupportSearch;
