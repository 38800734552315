import React, { useState, useMemo, useCallback, FC, useEffect, useRef } from 'react';
import moment, { Moment, utc } from 'moment';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

import {
  MainContainer,
  Header,
  StyledTitle,
  TimelineContainer,
  StyledTimeline,
  StyledMainTimelineItem,
  AddButton,
  SpinnerContainer,
} from './styles';
import Spinner from 'modules/common/components/Spinner';
import { TimeLineItem } from './components';
import { ReactComponent as PlusIcon } from 'modules/common/assets/images/profile/interests/addElement.svg';
import EventModal from './components/EventModal';
import RemoveModal from 'modules/common/components/modals/RemoveItemModal';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import {
  CREATE_PERSONAL_TIMELINE_EVENT,
  DELETE_PERSONAL_TIMELINE_EVENT,
  UPDATE_PERSONAL_TIMELINE_EVENT,
  TOGGLE_EVENT_VISIBILITY,
} from 'modules/profile/gql/mutation';
import { GET_EVENTS_BY_USER } from 'modules/profile/gql/query';
import { IReactionModel } from 'modules/common/gql/models/reaction.model';
import { GET_NEWS_FEED_POST_REACTIONS } from 'modules/news-feed/gql/query';
import { GET_NEWS_FEED_ALL_BADGES } from 'modules/common/gql/query';
import { INewsFeedBadgeResult } from 'modules/news-feed/pages/Feed/components/MainContent/MainContent';

export interface IEvent {
  title?: string;
  date: Moment | string | null;
}

export interface IProps {
  user: IUserModel;
  isOwner: boolean;
}

interface IReactionsResult {
  newsFeedPostReactions: IReactionModel;
}

interface IEventResult {
  id: string;
  name: string;
  type: string;
  date: string;
  relatedEntityId: string;
  relatedEntity: any;
  hidden: boolean;
  createdAt: string;
  updatedAt: string;
}

interface IEventsResult {
  timelineEventsByUser: {
    data: IEventResult[];
    pagination: {
      nextCursor: any;
      limit: number;
    };
  };
}

const EmploymentTimeline: FC<IProps> = ({ user, isOwner }) => {
  const location = useLocation();
  const history = useHistory();
  const parsedSearch = useMemo(() => qs.parse(location.search.slice(1)), [location.search]);
  const loadingDivRef = useRef<HTMLDivElement>(null);
  const eventsQuery = useQuery<IEventsResult>(GET_EVENTS_BY_USER, {
    variables: {
      userId: user.id,
      pagination: { limit: 5 },
    },
  });
  const { data: badgesData } = useQuery<INewsFeedBadgeResult>(GET_NEWS_FEED_ALL_BADGES, {
    onError: (err: Error) => message.error(err),
  });
  const events = useMemo(() => eventsQuery?.data?.timelineEventsByUser.data || [], [eventsQuery]);
  const [changedEventId, setChangedEventId] = useState<string | null>(null);
  const [changedIdForDelete, setChangedIdForDelete] = useState<string | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [editableEvent, setEditableEvent] = useState('');
  const initialEvent: IEvent = useMemo(
    () => ({
      title: '',
      date: '',
    }),
    [],
  );
  const [eventTitle, setEventTitle] = useState(initialEvent.title);
  const [eventDate, setEventDate] = useState(initialEvent.date);
  const [createPersonalEvent] = useMutation(CREATE_PERSONAL_TIMELINE_EVENT, {
    variables: {
      input: {
        name: eventTitle,
        date: eventDate,
      },
    },
    onCompleted: (result) => {
      eventsQuery.updateQuery((prevResult) => {
        const prevResultClone = JSON.parse(JSON.stringify(prevResult));
        const { data } = prevResultClone.timelineEventsByUser;

        if (result.createPersonalTimelineEvent) {
          data.push(result.createPersonalTimelineEvent);
          const sortedDataByDate = data.sort(
            (firstElem, lastElem) => moment(lastElem.date).valueOf() - moment(firstElem.date).valueOf(),
          );
          prevResultClone.timelineEventsByUser.data = sortedDataByDate;
        }

        return prevResultClone;
      });
    },
  });
  const [loadReactions] = useLazyQuery<IReactionsResult>(GET_NEWS_FEED_POST_REACTIONS, {
    onError: () => {},
  });
  const [deletePersonalEvent] = useMutation(DELETE_PERSONAL_TIMELINE_EVENT, {
    onCompleted: (result) => {
      if (result.deletePersonalTimelineEvent) {
        eventsQuery.updateQuery((prevResult) => {
          const prevResultClone = JSON.parse(JSON.stringify(prevResult));
          const { data } = prevResultClone.timelineEventsByUser;

          if (changedIdForDelete) {
            const indexOfElement = data.findIndex((elem) => elem.id === changedIdForDelete);

            if (indexOfElement !== -1) {
              data.splice(indexOfElement, 1);
            }
            setChangedIdForDelete(() => null);
          }

          return prevResultClone;
        });
      }
    },
  });
  const [updatePersonalEvent] = useMutation(UPDATE_PERSONAL_TIMELINE_EVENT, {
    onCompleted: (result) => {
      eventsQuery.updateQuery((prevResult) => {
        const prevResultClone = JSON.parse(JSON.stringify(prevResult));
        const { data } = prevResultClone.timelineEventsByUser;
        const idOfResult = result.updatePersonalTimelineEvent.id;
        const indexOfElement = data.findIndex((elem) => elem.id === idOfResult);

        if (indexOfElement !== -1) {
          data.splice(indexOfElement, 1, result.updatePersonalTimelineEvent);
        }

        return prevResultClone;
      });
    },
  });
  const [toggleEventVisibility] = useMutation(TOGGLE_EVENT_VISIBILITY, {
    onCompleted: (result) => {
      eventsQuery.updateQuery((prevResult) => {
        const prevResultClone = JSON.parse(JSON.stringify(prevResult));
        const { data } = prevResultClone.timelineEventsByUser;
        const idOfResult = result.toggleTimelineEventVisibility.id;
        const indexOfElement = data.findIndex((elem) => elem.id === idOfResult);
        const element = data[indexOfElement];

        if (indexOfElement !== -1) {
          data.splice(indexOfElement, 1, {
            ...element,
            hidden: !element.hidden,
          });
        }
        return prevResultClone;
      });
    },
  });

  const onToggleEventVisibility = useCallback(
    (options: any) => {
      if (!isOwner) {
        return;
      }
      toggleEventVisibility(options);
    },
    [isOwner],
  );

  const onClose = useCallback(() => {
    setIsOpenModal(false);
    setIsOpenDeleteModal(false);
    setEventTitle('');
    setEventDate('');
    setEditableEvent('');
  }, [setEventTitle, setEventDate, editableEvent, setIsOpenModal]);

  const onCreate = useCallback(() => {
    createPersonalEvent();
    onClose();
  }, [eventTitle, eventDate, createPersonalEvent]);

  const onDelete = useCallback(() => {
    deletePersonalEvent({ variables: { id: editableEvent } });
    onClose();
  }, [deletePersonalEvent, editableEvent]);

  const onUpdate = useCallback(() => {
    updatePersonalEvent({
      variables: {
        id: editableEvent,
        input: {
          name: eventTitle,
          date: eventDate,
        },
      },
    });
    onClose();
  }, [eventTitle, eventDate, editableEvent, updatePersonalEvent]);

  const changedEvent = useMemo(() => {
    if (!events) {
      return null;
    }

    return events.find((elem) => elem.id === changedEventId);
  }, [events, changedEventId]);

  const openPostPreviewModal = (id: string) => {
    setChangedEventId(() => id);
  };

  useEffect(() => {
    if (changedEvent && changedEvent?.relatedEntity?.post?._id) {
      loadReactions({
        variables: {
          postId: changedEvent.relatedEntity.post._id,
        },
      });
      history.push({
        pathname: location.pathname,
        search: qs.stringify({
          ...parsedSearch,
          changedFeedId: changedEvent?.relatedEntity?.post?._id,
          previewModalIsOpenedText: true,
        }),
      });
    }
  }, [changedEvent]);

  const resizeHandler = () => setScreenWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  /** Working with pagination */
  const [isFetchMoreBlock, setFetchMoreBlock] = useState(false);
  const pagination = eventsQuery?.data?.timelineEventsByUser?.pagination;
  const callback = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting || !pagination?.nextCursor || isFetchMoreBlock) {
      return;
    }
    setFetchMoreBlock(true);
    eventsQuery.fetchMore({
      variables: {
        pagination: {
          limit: 5,
          nextCursor: pagination.nextCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setFetchMoreBlock(false);
        if (!fetchMoreResult) {
          return previousResult;
        }
        const initialData = previousResult?.timelineEventsByUser?.data || [];
        return Object.assign({}, previousResult, {
          timelineEventsByUser: {
            ...previousResult.timelineEventsByUser,
            data: [...initialData, ...fetchMoreResult.timelineEventsByUser.data],
            pagination: fetchMoreResult.timelineEventsByUser.pagination,
          },
        });
      },
    });
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(callback, options);

    if (loadingDivRef.current) {
      observer.unobserve(loadingDivRef.current);
      observer.observe(loadingDivRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [loadingDivRef.current, eventsQuery, isFetchMoreBlock]);

  return (
    <MainContainer>
      <Header>
        <StyledTitle>Employment Timeline</StyledTitle>
        {isOwner && (
          <AddButton onClick={() => setIsOpenModal((v) => !v)} type="primary">
            <PlusIcon /> Add Event
          </AddButton>
        )}
      </Header>
      <TimelineContainer>
        <StyledTimeline>
          {events.map((elem, key) => {
            const { date, id, name } = elem;
            const prevYear = key !== 0 && utc(events[key - 1].date).format('YYYY');
            const currentYear = utc(date).format('YYYY');

            return (
              <StyledMainTimelineItem key={key}>
                <TimeLineItem
                  user={user}
                  data={elem}
                  isOwner={isOwner}
                  isVisibleYear={key === 0 || currentYear < prevYear}
                  toggleEventVisibility={onToggleEventVisibility}
                  openEditModal={() => {
                    setEditableEvent(id);
                    setEventDate(date);
                    setEventTitle(name);
                    setIsOpenModal(true);
                  }}
                  openDeleteModal={() => {
                    setEditableEvent(id);
                    setChangedIdForDelete(id);
                    setIsOpenDeleteModal(true);
                  }}
                  openPostPreviewModal={openPostPreviewModal}
                  screenWidth={screenWidth}
                  badgesData={badgesData?.newsFeedAllBadges || []}
                />
              </StyledMainTimelineItem>
            );
          })}
        </StyledTimeline>
      </TimelineContainer>
      <EventModal
        eventTitle={eventTitle}
        eventDate={eventDate}
        disabledDate={moment(user?.employment.hireDate).add('-1', 'd')}
        isEdit={!!editableEvent}
        isOpen={isOpenModal}
        onSubmit={editableEvent ? onUpdate : onCreate}
        onClose={onClose}
        onChangeDate={(v) => setEventDate(v)}
        onChangeTitle={(v) => setEventTitle(v)}
      />
      <RemoveModal
        title="Do you want to remove the event?"
        subtitle="If you remove an event, it will disappear from your timeline"
        isVisible={isOpenDeleteModal}
        onClose={onClose}
        onSubmit={onDelete}
      />
      <SpinnerContainer ref={loadingDivRef} isLoadedAllData={!pagination || !pagination.nextCursor}>
        {pagination && pagination.nextCursor && <Spinner width={30} height={30} />}
      </SpinnerContainer>
    </MainContainer>
  );
};

export default EmploymentTimeline;
