import React, { FC, useState } from 'react';
import { Row, Image } from 'antd';
import pluralize from 'pluralize';

import {
  StyledCard,
  StyledTitle,
  StatusActiveIcon,
  StatusLeaveIcon,
  StyledAvatar,
  StyledRow,
  FollowedContainer,
  FollowedElement,
  FollowersCountContainer,
  FollowedAvatarContainer,
  FollowerAvatar,
  MainFollowersContainer,
  AvatarContainer,
  FollowButton,
  WrapperAvatar,
  AvatarMainContainer,
  CostCenter,
  StyledCamera,
  ProfileCol,
  BadgesContainer,
  Badge,
  BadgesTitle,
} from './styles';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import WorkingHours from './components/WorkingHours';
import Description from './components/Description';
import PreferedName from './components/PreferedName';
import Time from './components/Time';
import Location from './components/Location';
import Email from './components/Email';
import Phones from './components/Phones';
import { IFollowedData, IFollowerData } from '../../Profile';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { IFollowStatus } from 'modules/common/gql/models/user.model';
import splitNumber from 'utils/splitNumber';
import { OpenedFollowersPopover } from '../../Profile';
import AvatarModal from './components/AvatarModal';
import { INewsFeedBadgeModel } from 'modules/profile/gql/models/userBadges.model';

export interface IProps {
  user: IUserModel;
  refetchUser: () => void;
  followedAuthors: IFollowedData[];
  followers: IFollowerData[];
  followersCount: number;
  followedAuthorsCount: number;
  isOwner: boolean;
  isEditableProfile: boolean;
  followUser: () => void;
  unfollowUser: () => void;
  openFollowersModalHandler: (name: OpenedFollowersPopover) => void;
  userBadges?: INewsFeedBadgeModel[];
}

const ProfileInfo: FC<IProps> = ({
  user,
  refetchUser,
  followedAuthors,
  followers,
  isOwner,
  isEditableProfile,
  followUser,
  unfollowUser,
  openFollowersModalHandler,
  followersCount,
  followedAuthorsCount,
  userBadges,
}) => {
  const [isOpenedAvatarModal, setOpenedAvatarModal] = useState(false);
  const {
    firstName: fN,
    lastName: lN,
    middleName: mN,
    photoUrl,
    job,
    costCenter,
    followedByMeStatus,
    isActive,
    terminatedAt,
  } = user || {};
  const firstName = fN ? ` ${fN}` : ``;
  const lastName = lN ? ` ${lN}` : ``;
  const middleName = mN ? ` ${mN}` : ``;
  const [firstAuthor] = followedAuthors;
  const [firstFollower] = followers;

  return (
    <StyledCard>
      <AvatarModal
        isOpened={isOpenedAvatarModal}
        closeHandler={() => setOpenedAvatarModal(false)}
        refetchUser={refetchUser}
        userId={user.id}
      />
      <StyledRow>
        <ProfileCol span={24}>
          <AvatarContainer>
            <AvatarMainContainer>
              {isOwner && (
                <WrapperAvatar onClick={() => setOpenedAvatarModal(true)}>
                  <StyledCamera />
                </WrapperAvatar>
              )}
              <StyledAvatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
            </AvatarMainContainer>
            <StyledTitle level={3}>
              {firstName + middleName + lastName}{' '}
              {isActive ? <StatusActiveIcon /> : !isActive && !terminatedAt ? <StatusLeaveIcon /> : null}
            </StyledTitle>
          </AvatarContainer>
          <div key={costCenter?.name || ''}>
            <CostCenter>{costCenter?.name || ''}</CostCenter>
          </div>
          <div key={job?.positionBusinessTitle}>
            <CostCenter>{job?.positionBusinessTitle}</CostCenter>
          </div>
          {isOwner && (
            <MainFollowersContainer>
              <FollowedContainer>
                <FollowedElement onClick={() => followers.length > 0 && openFollowersModalHandler('FOLLOWERS')}>
                  <FollowedAvatarContainer>
                    <FollowerAvatar
                      src={
                        <Image
                          preview={false}
                          src={firstFollower?.followerUser?.photoUrl || emptyAvatar}
                          fallback={emptyAvatar}
                        />
                      }
                    />
                    <FollowersCountContainer>{splitNumber(followersCount)}</FollowersCountContainer>
                  </FollowedAvatarContainer>
                  Followers
                </FollowedElement>
                <FollowedElement onClick={() => followedAuthors.length > 0 && openFollowersModalHandler('FOLLOWED_AUTHORS')}>
                  <FollowedAvatarContainer>
                    <FollowerAvatar
                      src={
                        <Image preview={false} src={firstAuthor?.authorUser?.photoUrl || emptyAvatar} fallback={emptyAvatar} />
                      }
                    />
                    <FollowersCountContainer>{splitNumber(followedAuthorsCount)}</FollowersCountContainer>
                  </FollowedAvatarContainer>
                  Following
                </FollowedElement>
              </FollowedContainer>
            </MainFollowersContainer>
          )}
          {userBadges && userBadges.length ? (
            <BadgesContainer>
              <div>
                {userBadges.map(({ name, iconUrl }) => {
                  return <Badge key={name} src={iconUrl} alt={name} />;
                })}
              </div>
              <BadgesTitle>{pluralize('Badge', userBadges.length, true)}</BadgesTitle>
            </BadgesContainer>
          ) : null}
          {!isOwner && followedByMeStatus === IFollowStatus.FOLLOWING && (
            <FollowButton onClick={unfollowUser} isFollowed={true}>
              Unfollow
            </FollowButton>
          )}
          {!isOwner && followedByMeStatus === IFollowStatus.PENDING && <FollowButton isPending={true}>Pending</FollowButton>}
          {!isOwner && followedByMeStatus === IFollowStatus.NONE && (
            <FollowButton onClick={followUser} isFollowed={false}>
              Follow
            </FollowButton>
          )}
        </ProfileCol>
      </StyledRow>
      <Row>
        {user && <Description isEditable={isEditableProfile} user={user} refetchUser={refetchUser} />}
        {user && <PreferedName isOwner={isOwner} user={user} refetchUser={refetchUser} />}
        {user && <Location user={user} />}
        {user && <Time user={user} refetchUser={refetchUser} isEditable={isEditableProfile} />}
        {user && <WorkingHours user={user} refetchUser={refetchUser} isEditable={isEditableProfile} />}
        {user && <Email user={user} />}
        {user && <Phones user={user} refetchUser={refetchUser} isEditable={isEditableProfile} isOwner={isOwner} />}
      </Row>
    </StyledCard>
  );
};

export default ProfileInfo;
