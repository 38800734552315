import React, { useMemo, Fragment } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import qs from 'qs';

import {
  MainContainer,
  MainContentContainer,
  HeaderContainer,
  HeaderRow,
  ContentContent,
  ContentContainer,
  Title,
  Content,
  MainTagsContainer,
  StyledArrow,
  GoBackButton,
  AttachmentsContainer,
  AttachmentElement,
} from './styles';
import { ARTICLE_BY_ID } from '../../gql/query';
import { ReactComponent as AttachmentFile } from './assets/attachmentFile.svg';
import Footer from 'modules/common/components/Footer';

export interface IAttachment {
  filename: string;
  url: string;
}
export interface IArticle {
  tags: string[];
  _id: number;
  title: string;
  contents: string;
  portalSection: {
    name: string;
    externalSectionId: string;
  };
  attachments: IAttachment[];
}

interface IArticleResult {
  knowledgeBaseArticle: IArticle;
}

const KnowledgeBaseElement = () => {
  const params: any = useParams();
  const history = useHistory();
  const idOfArticle = useMemo(() => params?.id, [params]);
  const location = useLocation();
  const { search } = location;
  const restrictCountView = qs.parse(search.slice(1))?.restrictCountView;

  const { data } = useQuery<IArticleResult>(ARTICLE_BY_ID, {
    variables: {
      id: idOfArticle,
      countView: !restrictCountView,
    },
  });

  const changedArticle = data?.knowledgeBaseArticle;

  if (!changedArticle) {
    return null;
  }

  const { title, contents, attachments } = changedArticle;

  return (
    <Fragment>
      <MainContentContainer>
        <MainContainer>
          <HeaderRow>
            <HeaderContainer>
              <GoBackButton onClick={() => history.goBack()}>
                <StyledArrow />
                Go Back
              </GoBackButton>
            </HeaderContainer>
          </HeaderRow>
          <ContentContent>
            <ContentContainer>
              <Title>{title}</Title>
              <Content dangerouslySetInnerHTML={{ __html: contents }} />
              {attachments.length > 0 && (
                <MainTagsContainer>
                  <p>Attachments</p>
                  <AttachmentsContainer>
                    {attachments.map((elem) => {
                      const { filename, url } = elem;

                      return (
                        <AttachmentElement href={url} download={filename} target={'_blank'}>
                          <AttachmentFile />
                          <p>{filename}</p>
                        </AttachmentElement>
                      );
                    })}
                  </AttachmentsContainer>
                </MainTagsContainer>
              )}
            </ContentContainer>
          </ContentContent>
        </MainContainer>
      </MainContentContainer>
      <Footer />
    </Fragment>
  );
};

export default KnowledgeBaseElement;
