import styled from 'styled-components';
import { Image } from 'antd';

interface ICardsContainer {
  $repeat: number;
}
export const CardsContainer = styled.div<ICardsContainer>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.$repeat}, 1fr);
  grid-gap: 9px;
  margin-top: 14px;
  padding-left: 60px;
  padding-right: 60px;
`;

export const Card = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 185px;
  position: relative;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
`;

export const StyledImage = styled(Image)`
  height: 135px;
  width: 135px;
  border-radius: 100%;
`;

export const CardWrapper = styled.div`
  position: absolute;
  background: linear-gradient(180deg, rgba(62, 62, 62, 0) 67.97%, rgba(46, 46, 46, 0.86) 100%);
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
`;

export const CardLastWrapper = styled(CardWrapper)`
  background: linear-gradient(180deg, rgba(36, 36, 36, 0) 0%, #262626 100%, #c4c4c4);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 135px;
  position: absolute;
  top: calc(50% - 67.5px);
  left: calc(50% - 67.5px);
  border-radius: 50%;

  h2 {
    color: #ffffff;
    font-weight: 500;
    font-size: 25px;
    line-height: 16px;
    z-index: 1;
    margin: 0;
    padding: 0;
  }
`;
