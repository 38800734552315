import React, { FC, useState } from 'react';
import moment from 'moment';
import { Image } from 'antd';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import {
  MainContainer,
  Avatar,
  InfoContainer,
  UserContainer,
  MessageContainer,
  MessageText,
  MainInfoContainer,
  ReadIndicator,
  UserText,
} from '../../component.styles';
import { ButtonContainer, StyledAcceptButton, StyledDeclineButton } from './styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { ACCEPT_FOLLOW_REQUEST, DECLINE_FOLLOW_REQUEST } from 'modules/profile/gql/mutation';
import { useFollowers } from 'modules/profile/hooks/useFollowers';
import { emitFollowResponse } from 'utils/mixpanel';
import { NOTIFICATION_TYPES } from '../../constants';
import { IFollowNotification } from '../../interfaces';

interface IProps {
  data: IFollowNotification;
  changeNotify: (id: string, shouldClose?: boolean, shouldRefetch?: boolean) => void;
}

const FollowNotification: FC<IProps> = ({ data, changeNotify }) => {
  const history = useHistory();
  const { refetch: refetchFollowers } = useFollowers();
  const { createdAt, status, relatedEntity, id } = data;

  const [acceptRequest] = useMutation(ACCEPT_FOLLOW_REQUEST, {
    onCompleted: refetchFollowers,
  });
  const [declineRequest] = useMutation(DECLINE_FOLLOW_REQUEST);

  const { id: followerId, fullName, photoUrl } = relatedEntity?.user;
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);

  if (!relatedEntity) {
    return null;
  }

  return (
    <MainContainer>
      <UserContainer>
        <div onClick={() => history.push(`/profile/${followerId}`)}>
          <Avatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
        </div>
        <MainInfoContainer>
          <InfoContainer>
            <MessageContainer onClick={() => history.push(`/profile/${followerId}`)}>
              <UserText>{fullName}</UserText>
              <MessageText>requested to follow you</MessageText>
            </MessageContainer>
            <MessageContainer>{moment(createdAt).format('MMM DD')}</MessageContainer>
          </InfoContainer>
          {isRequestCompleted && <MessageContainer style={{ marginTop: 10 }}>Request accepted</MessageContainer>}
          {!isRequestCompleted && (
            <ButtonContainer>
              <StyledAcceptButton
                type="primary"
                onClick={() => {
                  acceptRequest({
                    variables: {
                      followerId,
                    },
                  });
                  changeNotify(id, false, false);
                  setIsRequestCompleted(true);
                  emitFollowResponse({ followResponse: 'accept' });
                }}
              >
                Accept
              </StyledAcceptButton>
              <StyledDeclineButton
                onClick={() => {
                  declineRequest({
                    variables: {
                      followerId,
                    },
                  });
                  changeNotify(id);
                  emitFollowResponse({ followResponse: 'deny' });
                }}
              >
                Decline
              </StyledDeclineButton>
            </ButtonContainer>
          )}
        </MainInfoContainer>
      </UserContainer>
      {(status === NOTIFICATION_TYPES.SEEN || status === NOTIFICATION_TYPES.NEW) && <ReadIndicator />}
    </MainContainer>
  );
};

export default FollowNotification;
