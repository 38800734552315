import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButton = styled(Button)`
  font-weight: 600;
  color: #1890FF;
  width: 100%;
  background: #ECF6FF;
  border: none;
  border-radius: 4px;

  &:hover {
    background: #ECF6FF;
    box-shadow: none;
  }

  & svg {
    position: relative;
    top: 3px;
    left: -5px;
  }
`;
