import React, { useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { message, notification } from 'antd';
import qs from 'qs';

import closeNotification from '../assets/images/closeNotification.svg';
import { ACCEPT_FOLLOW_REQUEST, DECLINE_FOLLOW_REQUEST } from 'modules/profile/gql/mutation';

interface IParams {
  id?: string;
}

const withReactionsForMail = (Component) => () => {
  const params: IParams = useParams();
  const location = useLocation();
  const history = useHistory();

  const [acceptRequest] = useMutation(ACCEPT_FOLLOW_REQUEST, {
    onCompleted: () => {
      notification.success({
        message: 'Follow request is accepted',
        duration: 3,
        className: 'followNotifyAccepted',
        closeIcon: <img src={closeNotification} />,
      });
      history.push('/profile');
    },
    onError: (err) => message.error(err),
  });

  const [declineRequest] = useMutation(DECLINE_FOLLOW_REQUEST, {
    onCompleted: () => {
      notification.error({
        message: 'Follow request is declined',
        duration: 3,
        className: 'followNotifyDeclined',
        closeIcon: <img src={closeNotification} />,
      });
      history.push('/profile');
    },
    onError: (err) => message.error(err),
  });

  useEffect(() => {
    // REACTIONS ON EMAIL ACTIONS
    const { search } = location;
    const splittedSearchData = qs.parse(search.slice(1));

    if (splittedSearchData?.requestType) {
      switch (splittedSearchData.requestType) {
        case 'ACCEPT': {
          acceptRequest({
            variables: {
              followerId: params.id,
            },
          });
          return;
        }
        case 'DECLINE': {
          declineRequest({
            variables: {
              followerId: params.id,
            },
          });
          return;
        }
        case 'VIEW_PROFILE': {
          if (!params.id) {
            return;
          }
          notification.success({
            message: 'Your follow request is accepted',
            duration: 3,
            className: 'followNotifyAccepted',
            closeIcon: <img src={closeNotification} />,
          });
          history.push(`/profile/${params.id}`);
          return;
        }
      }
    }
  }, [location]);

  return <Component />;
};

export default withReactionsForMail;
