import styled, { css } from 'styled-components';
import { Typography, Button, Modal } from 'antd';

import { ReactComponent as CloseIcon } from 'modules/profile/assets/images/achievements/close.svg';

export const StyledModalDelete = styled(Modal)`
  position: relative;
  .ant-modal-content {
    border-radius: 28px;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 30px;
  top: 30px;
`;

export const DeleteModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleDeletePopover = styled(Typography.Title)`
  margin-top: 25px;
  max-width: 220px;
  text-align: center;
  color: #111111 !important;
`;

export const TextDeletePopover = styled(Typography.Text)`
  font-size: 14px;
  color: #4a4a4a !important;
  text-align: center;
  width: 248px;
`;

export const StyledDeleteButton = styled(Button)`
  width: 121px;
  padding: 9px 14px;
  height: auto;
  border-radius: 6px;
  font-weight: 600;
  box-sizing: border-box !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCancelDeleteButton = styled(StyledDeleteButton)`
  border: 1px solid #f5222d;
  color: #f5222d;
  width: 121px;
`;

export const ControlsDeleteModal = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  max-width: 330px;

  ${({ isDeleteModal }: { isDeleteModal: boolean }) =>
    isDeleteModal &&
    css`
      ${StyledDeleteButton} {
        background: #f5222d;
        color: #ffffff;
        border: none;
        :hover {
          background: #f5222d;
        }
      }
      ${StyledCancelDeleteButton} {
        border: 1px solid #6b778c;
        color: #6b778c;
        background: transparent;

        :hover {
          border: 1px solid #6b778c;
          color: #6b778c;
          background: transparent;
        }
      }
    `}
`;
