import React, { useState, useMemo, useEffect, FC } from 'react';
import { useQuery } from '@apollo/client';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { ReactComponent as Search } from '../../assets/bx-search.svg';
import { SearchControlContainer, GlobalSearchInput, StyledSearchPopover } from '../../styles';
import SearchPopover from './components/SearchPopover';
import useDebounce from 'modules/common/hooks/useDebounce';
import { searchPosts, searchUsers } from 'modules/common/utils';
import { UserState } from 'store/domains/user/user.types';
import { RootState } from 'store/rootReducer';
import { emitSearchGlobalQuery } from 'utils/mixpanel';
import { GET_NEWS_FEED_ALL_BADGES } from 'modules/common/gql/query';
import { IUploadedFile } from 'modules/common/gql/models/newsFeedPost.model';

export enum SEARCH_TYPES {
  PEOPLE = 'PEOPLE',
  POST = 'POST',
}

export interface IPreviewPost {
  user_id: string;
  post_id: string;
  post_type: string;
}

export interface IAddress {
  id: string;
  countryCode: string;
  usageType: string;
  municipality: string;
}

export interface IUser {
  id: string;
  photoUrl?: string;
  job: {
    positionTitle: string;
    addresses: IAddress[];
  };
  addresses: IAddress[];
  costCenter: {
    name: string;
  };
  terminatedAt: null | string;
  isActive: boolean;
}

export interface IPostUser {
  first_name: string;
  last_name: string;
  user_id: string;
  full_name: string;
  photo_url: string | null;
}

export interface IPost {
  post_id: string;
  post_type: string;
  headline: string;
  text: string;
  author: IPostUser;
  mentioned_users: IPostUser[] | null;
  targeted_users: IPostUser[] | null;
  publishing_date: string;
  fileUrls?: string[] | null;
  files?: IUploadedFile[] | null;
  badge: string | null;
}

export interface IGlobalSearchPost {
  type: SEARCH_TYPES.POST;
  data: IPost[];
}

export interface IGlobalSearchUser {
  type: SEARCH_TYPES.PEOPLE;
  data: IUser[];
}

export type IGlobalSearchData = IGlobalSearchUser | IGlobalSearchPost;

export interface IUserData {
  user_id: string;
  full_name: string;
  first_name: string;
  last_name: string;
  terminated_at: string;
  is_active: boolean;
  cost_center: string;
  job_title: string;
  photo_url: string;
}

export interface IArticleData {
  title: string;
  image_url: string;
  article_id: string;
  slug: string;
}

export interface IUserResult {
  searchUsersGlobal: {
    data: IUserData[];
    pagination: {
      total: number;
      offset: number;
    };
  };
}
export interface IPostResult {
  searchNewsFeedPostsGlobal: {
    data: IPost[];
    pagination: {
      total: number;
      offset: number;
    };
  };
}

export interface IBadge {
  _id: string;
  name: string;
  description: string;
  iconUrl: string;
}

export interface INewsFeedBadgeResult {
  newsFeedAllBadges: IBadge[];
}

const GlobalSearch: FC = () => {
  const [inputSearchValue, setInputSearchValue] = useState('');
  const history = useHistory();
  const location = useLocation();
  const searchValue = useDebounce(inputSearchValue, 500);
  const onError = (err: Error) => message.error(err);
  const [foundUsers, setFoundUsers] = useState([]);
  const [foundPosts, setFoundPosts] = useState([]);
  const [foundArticles, setFoundArticles] = useState([]);
  const [isOpenedGlobalSearch, setOpenedGlobalSearch] = useState(false);
  const { data: badgesData } = useQuery<INewsFeedBadgeResult>(GET_NEWS_FEED_ALL_BADGES, {
    onError: (err: Error) => message.error(err),
  });
  const parsedSearch = useMemo(() => qs.parse(location.search.slice(1)), [location.search]);

  const onClose = () => {
    setOpenedGlobalSearch(() => false);
    setInputSearchValue(() => '');
    setFoundUsers([]);
    setFoundPosts([]);
    setFoundArticles([]);
  };

  useEffect(() => {
    if (!!searchValue) {
      emitSearchGlobalQuery({ query: searchValue });
      searchUsers(searchValue)
        .then((res) => {
          if (!!res?.data?.searchUsersGlobal?.data?.length) {
            setFoundUsers(res.data.searchUsersGlobal.data);
          }
        })
        .catch(onError);
      searchPosts(searchValue)
        .then((res) => {
          if (!!res?.data?.searchNewsFeedPostsGlobal?.data?.length) {
            setFoundPosts(res.data.searchNewsFeedPostsGlobal.data);
          }
        })
        .catch(onError);
      // searchArticles(searchValue)
      //   .then((res) => {
      //     if (!!res?.data?.articlesBySearchFilter?.data?.length) {
      //       setFoundArticles(res.data.articlesBySearchFilter.data);
      //     }
      //   })
      //   .catch(onError);
    }
  }, [searchValue]);
  const filteredUsersData: IUserData[] = useMemo(() => foundUsers.slice(0, 3), [foundUsers]);
  const filteredPostsData: IPost[] = useMemo(
    () => foundPosts.filter((e: IPost) => e.author && e.author.first_name).slice(0, 3),
    [foundPosts],
  );
  const filteredArticlesData: IArticleData[] = useMemo(() => foundArticles.slice(0, 3), [foundArticles]);
  const user: UserState = useSelector((state: RootState) => state?.user);

  const openPost = (id: string) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...parsedSearch,
        changedFeedId: id,
      }),
    });
  };

  const toGlobalSearchPage = (e) => {
    if (e.keyCode === 13) {
      if ((filteredUsersData || []).length > 0) {
        history.push(`/profile/global-search?search=${inputSearchValue}&type=PEOPLE`);
        onClose();
      } else if ((filteredPostsData || []).length > 0) {
        history.push(`/profile/global-search?search=${inputSearchValue}&type=POST`);
        onClose();
      }
    }
  };

  return (
    <SearchControlContainer>
      {!isOpenedGlobalSearch && <Search onClick={() => setOpenedGlobalSearch(true)} style={{ cursor: 'pointer' }} />}
      {isOpenedGlobalSearch && (
        <StyledSearchPopover
          trigger={['focus']}
          onVisibleChange={(val) => !val && onClose()}
          content={
            <SearchPopover
              currentUserId={user.id || ''}
              openPost={openPost}
              postData={filteredPostsData}
              userData={filteredUsersData}
              articlesData={filteredArticlesData}
              closeSearch={onClose}
              searchValue={searchValue}
              badgesData={badgesData?.newsFeedAllBadges || []}
            />
          }
          visible={Boolean(searchValue.length)}
          overlayClassName={'globalSearch'}
          placement={'bottom'}
        >
          <GlobalSearchInput
            suffix={<Search />}
            autoFocus
            value={inputSearchValue}
            onChange={(e) => setInputSearchValue(e.target.value)}
            onKeyDown={toGlobalSearchPage}
            placeholder={'Search Connect'}
            onBlur={() => {
              !Boolean(searchValue.length) && onClose();
            }}
          />
        </StyledSearchPopover>
      )}
    </SearchControlContainer>
  );
};

export default GlobalSearch;
