import React, { FC, useMemo, useState, useEffect, useRef, useCallback } from 'react';
import CustomScroll from 'react-custom-scroll';
import 'react-custom-scroll/dist/customScroll.css';
import { useMutation } from '@apollo/client';
import { message, Image } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  StyledViewAllModal,
  SubHeaderViewAllModal,
  UsersAllModalContainer,
  UserElement,
  UserInfo,
  UserNameContainer,
  FollowButton,
  StyledUserAvatar,
  SubHeaderContainer,
  AvatarContainer,
  SpinnerContainer,
} from './styles';
import Spinner from 'modules/common/components/Spinner';
import closeIcon from 'modules/team/assets/closeIcon.png';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { IFollowersResult, IFollowedAuthorsResult, OpenedFollowersPopover } from '../../Profile';
import { UNFOLLOW_USER } from 'modules/profile/gql/mutation';
import { IFollowStatus } from 'modules/common/gql/models/user.model';
import { emitFollowRequest } from 'utils/mixpanel';

interface IProps {
  onClose: () => void;
  followedAuthorsData: IFollowedAuthorsResult;
  followersData: IFollowersResult;
  followedAuthorsCount: number;
  followersCount: number;
  openedModal: OpenedFollowersPopover;
  fetchMoreFollowed: (options: any) => void;
  fetchMoreFollowers: (options: any) => void;
  refetchFollowed: () => void;
}

const FollowerModal: FC<IProps> = ({
  onClose,
  openedModal,
  followedAuthorsData,
  followersData,
  refetchFollowed,
  fetchMoreFollowed,
  fetchMoreFollowers,
  followedAuthorsCount,
  followersCount,
}) => {
  const history = useHistory();
  const isFollowedModal = openedModal === 'FOLLOWED_AUTHORS';
  const [unFollowUsers, setUnFollowUsers] = useState<string[]>([]);
  const data = useMemo(
    () =>
      isFollowedModal
        ? followedAuthorsData.getMyFollowedAuthors.data.map((elem) => elem.authorUser)
        : followersData.getMyFollowers.data.map((elem) => elem.followerUser),
    [followedAuthorsData, followersData, isFollowedModal],
  );
  const fetchMore = useCallback((options) => (isFollowedModal ? fetchMoreFollowed(options) : fetchMoreFollowers(options)), [
    isFollowedModal,
  ]);
  const [unfollowUser] = useMutation(UNFOLLOW_USER, {
    onCompleted: () => {
      emitFollowRequest({ action: 'unfollow' });
    },
    onError: () => {},
  });

  const toUnFollowListHandler = (id: string) => {
    const cloneOfUnFollowUsers = [...unFollowUsers];

    const indexOfElement = cloneOfUnFollowUsers.findIndex((elem) => elem === id);

    if (indexOfElement === -1) {
      cloneOfUnFollowUsers.push(id);
    } else {
      cloneOfUnFollowUsers.splice(indexOfElement, 1);
    }

    setUnFollowUsers(() => cloneOfUnFollowUsers);
  };

  const onCloseSubmit = async () => {
    onClose();
    if (unFollowUsers.length > 0) {
      const promises = unFollowUsers.map((elem) => {
        return unfollowUser({
          variables: {
            id: elem,
          },
        });
      });

      try {
        await Promise.all(promises);
        refetchFollowed();
      } catch (err) {
        message.error('Unfollow error');
      }
    }
  };

  const pagination = useMemo(
    () => (isFollowedModal ? followedAuthorsData.getMyFollowedAuthors.pagination : followersData.getMyFollowers.pagination),
    [data, isFollowedModal],
  );

  // Working with pagination
  const [isFetchMoreBlock, setFetchMoreBlock] = useState(false);
  const loadingDivRef = useRef<HTMLDivElement>(null);
  const callback = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting || !pagination || !pagination.nextCursor || isFetchMoreBlock) {
      return;
    }
    setFetchMoreBlock(true);
    fetchMore({
      variables: {
        limit: 15,
        nextCursor: pagination.nextCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setFetchMoreBlock(false);
        if (!fetchMoreResult) {
          return previousResult;
        }
        const keyName = isFollowedModal ? 'getMyFollowedAuthors' : 'getMyFollowers';
        const initialData = previousResult[keyName]?.data || [];
        return Object.assign({}, previousResult, {
          [keyName]: {
            ...previousResult[keyName],
            data: [...initialData, ...fetchMoreResult[keyName].data],
            pagination: fetchMoreResult[keyName].pagination,
          },
        });
      },
    });
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(callback, options);
    if (loadingDivRef.current) {
      observer.unobserve(loadingDivRef.current);
      observer.observe(loadingDivRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [loadingDivRef.current, data, isFetchMoreBlock, openedModal]);

  return (
    <StyledViewAllModal
      footer={null}
      onCancel={onCloseSubmit}
      title={isFollowedModal ? 'Following' : 'Followers'}
      visible={Boolean(openedModal)}
      closeIcon={<img src={closeIcon} />}
      width={400}
    >
      <SubHeaderContainer>
        <SubHeaderViewAllModal>
          {isFollowedModal
            ? `You are following ${followedAuthorsCount} employee${followedAuthorsCount > 1 ? 's' : ''}`
            : `${followersCount} employee${followersCount > 1 ? 's' : ''} ${followersCount > 1 ? 'are' : 'is'} following you`}
        </SubHeaderViewAllModal>
      </SubHeaderContainer>
      <CustomScroll heightRelativeToParent={'450px'} freezePosition={data.length <= 1}>
        <UsersAllModalContainer>
          {isFollowedModal
            ? followedAuthorsData.getMyFollowedAuthors.data.map((elem) => {
                const {
                  status,
                  authorUser: { photoUrl, job, id, fullName: preferredName },
                } = elem;
                const { positionTitle } = job;
                const isPending = status === IFollowStatus.PENDING;
                const isUnFollowed = unFollowUsers.includes(id);
                return (
                  <UserElement
                    key={id}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCloseSubmit();
                      history.push(`/profile/${id}`);
                    }}
                  >
                    <AvatarContainer>
                      <StyledUserAvatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
                    </AvatarContainer>
                    <UserInfo>
                      <UserNameContainer>
                        <h2>{preferredName}</h2>
                        <p>{positionTitle}</p>
                      </UserNameContainer>
                      <FollowButton
                        isUnFollow={!isUnFollowed}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (isPending) {
                            return;
                          }
                          toUnFollowListHandler(id);
                        }}
                      >
                        {isPending ? 'Pending' : isUnFollowed ? 'Follow' : 'Unfollow'}
                      </FollowButton>
                    </UserInfo>
                  </UserElement>
                );
              })
            : followersData.getMyFollowers.data.map((elem) => {
                const { photoUrl, job, id, fullName: preferredName } = elem.followerUser;
                const { positionTitle } = job;

                return (
                  <UserElement
                    key={id}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCloseSubmit();
                      history.push(`/profile/${id}`);
                    }}
                  >
                    <AvatarContainer>
                      <StyledUserAvatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
                    </AvatarContainer>
                    <UserInfo>
                      <UserNameContainer>
                        <h2>{preferredName}</h2>
                        <p>{positionTitle}</p>
                      </UserNameContainer>
                      {openedModal === 'FOLLOWED_AUTHORS' && (
                        <FollowButton
                          isUnFollow={!Boolean(unFollowUsers.includes(id))}
                          onClick={(e) => {
                            e.stopPropagation();
                            toUnFollowListHandler(id);
                          }}
                        >
                          {unFollowUsers.includes(id) ? 'Follow' : 'Unfollow'}
                        </FollowButton>
                      )}
                    </UserInfo>
                  </UserElement>
                );
              })}
          <SpinnerContainer ref={loadingDivRef} isLoadedAllData={!pagination || !pagination.nextCursor}>
            {pagination && pagination.nextCursor && <Spinner width={30} height={30} />}
          </SpinnerContainer>
        </UsersAllModalContainer>
      </CustomScroll>
    </StyledViewAllModal>
  );
};

export default FollowerModal;
