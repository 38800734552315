import React, { FC, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import EmptyButton from '../EmptyButton';
import {
  StyledInnerTitle,
  WorkingHoursControls,
  ControlButton,
  StyledText,
  StyledCol,
  StyledConfirm,
  StyledCancell,
} from '../../styles';
import { EditContainer, StyledTextArea } from './styled';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import { UPDATE_EMPLOYEE_DESCRIPTION } from 'modules/profile/gql/mutation';
import { emitProfileUpdated } from 'utils/mixpanel';

export interface IProps {
  user: IUserModel;
  refetchUser: () => void;
  isEditable: boolean;
}

const Description: FC<IProps> = ({ user, refetchUser, isEditable }) => {
  const { employment } = user || {};
  const [isEditMode, setEditMode] = useState(false);
  const [editableValue, setEditableValue] = useState<string>(employment?.employeeDescription);
  const [
    updateEmployeeDescription,
    { loading: updateEmployeeDescriptionLoading, called: updateEmployeeDescriptionCalled },
  ] = useMutation(UPDATE_EMPLOYEE_DESCRIPTION);

  useEffect(() => {
    if (!updateEmployeeDescriptionLoading && updateEmployeeDescriptionCalled) {
      emitProfileUpdated({ section: 'about me' });
      refetchUser();
    }
  }, [updateEmployeeDescriptionLoading, updateEmployeeDescriptionCalled]);

  const onClose = () => {
    setEditMode(() => false);
  };

  const cancelHandler = () => {
    setEditableValue(employment?.employeeDescription);
    onClose();
  };

  const saveDescription = () => {
    updateEmployeeDescription({
      variables: {
        id: user.id,
        employeeDescription: editableValue || '',
      },
    });
    onClose();
  };

  if (!isEditable && !employment?.employeeDescription) {
    return null;
  }

  return (
    <StyledCol
      onClick={() => isEditable && !isEditMode && setEditMode(true)}
      $isEditable={isEditable && !!employment.employeeDescription && !isEditMode}
      span={24}
    >
      <StyledInnerTitle disabled>About Me</StyledInnerTitle>
      {isEditMode && (
        <EditContainer>
          <StyledTextArea value={editableValue} onChange={(e) => setEditableValue(e.target.value)} rows={5} />
          <WorkingHoursControls>
            <ControlButton onClick={saveDescription}>
              <StyledConfirm />
            </ControlButton>
            <ControlButton onClick={cancelHandler} isCanceButton={true}>
              <StyledCancell />
            </ControlButton>
          </WorkingHoursControls>
        </EditContainer>
      )}
      {!isEditMode && employment?.employeeDescription && (
        <div>
          <StyledText>{employment?.employeeDescription}</StyledText>
        </div>
      )}
      {isEditable && (!employment || !employment.employeeDescription) && !isEditMode && (
        <EmptyButton text="Add Description" onClick={() => setEditMode(true)} />
      )}
    </StyledCol>
  );
};

export default Description;
