/* eslint-disable max-len */
import styled from 'styled-components';
import { Input, Tabs, Modal, Button, Switch, Radio, Typography, Popover, Dropdown, Avatar, DatePicker } from 'antd';
import Slider from 'react-slick';
import { Mention } from 'react-mentions';
import { StyledCard } from '../../styles';
import { ReactComponent as DeletePhotoImage } from 'modules/news-feed/pages/Feed/assets/deletePhoto.svg';
import { ReactComponent as LeftArrow } from '../../assets/previewModal/leftArrow.svg';
import { ReactComponent as RightArrow } from '../../assets/previewModal/rightArrow.svg';
import { ReactComponent as Close } from '../../assets/previewModal/close.svg';
import { ReactComponent as CreatePostIcon } from '../../assets/createPost.svg';
import { ReactComponent as ClapIcon } from 'modules/news-feed/pages/Feed/assets/createPostModal/clap.svg';
import { ReactComponent as RecognizeIcon } from 'modules/news-feed/pages/Feed/assets/createPostModal/recognize.svg';
import { ReactComponent as CloseIcon } from 'modules/news-feed/pages/Feed/assets/createPostModal/closeIcon.svg';
import { ReactComponent as MenuIcon } from '../../assets/previewModal/previewModalMenu.svg';
import { ReactComponent as Like } from '../../assets/like.svg';
import { ReactComponent as Attachment } from '../../assets/attachment.svg';

export const StyledClapIcon = styled(ClapIcon)`
  position: relative;
  top: 2px;
  left: 5px;
`;

export const StyledRecognizeIcon = styled(RecognizeIcon)`
  position: relative;
  top: 3px;
  left: 5px;
`;

export const MainCardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledInput = styled(Input)`
  cursor: pointer !important;
  input {
    color: #9da5ac !important;
    font-size: 14px !important;
    cursor: pointer !important;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    margin: 0;
  }
  .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
`;

export const StyledTab = styled(Tabs.TabPane)``;

export const Card = styled(StyledCard)`
  .ant-card-body {
    padding: 0;
  }
`;

export const StyledModal = styled(Modal)`
  border-radius: 20px !important;
  z-index: 2;
  .ant-modal-footer {
    border: none;
    border-radius: 0px 0px 4px 4px;
  }
  .ant-modal-header {
    border-radius: 4px 4px 0px 0px;
    border-width: 0;
  }
  .ant-modal-content {
    border-radius: 4px;
    background: #fafafa;
  }
  .ant-modal-body {
    padding: 0;
  }
  .tox {
    height: 170px !important;
    margin-top: 10px;
    border-radius: 2px;
    border-color: #d9d9d9;
    .tox-statusbar {
      display: none;
    }
    .tox-toolbar__group {
      border-color: #d9d9d9;
    }
  }
  .tox-edit-area {
    border-top: 1px solid #ccc;
  }
  .tox .tox-tbtn {
    height: 24px;
  }
  .tox .tox-tbtn svg {
    zoom: 0.75;
  }
  .ant-modal-title {
    color: #4b4b4b;
  }
  ${({ isBlockedClose }: { isBlockedClose?: boolean }) =>
    isBlockedClose &&
    `
    .ant-modal-close {
      display: none;
    }
  `};
`;

export const TypeFeedContainer = styled.div`
  background: #fff;
  padding: 0;
`;

interface IMainModalContainer {
  isDontHaveTopPadding?: boolean;
}

export const MainModalContainer = styled.div<IMainModalContainer>`
  width: 100%;
  background: #ffffff;
  padding: 16px 12px;

  ${({ isDontHaveTopPadding }) =>
    isDontHaveTopPadding &&
    `
    padding-top: 0 !important;
  `}
`;

export const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  color: #111111;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 8px;

  img {
    border-radius: 100%;
  }
`;

export const TextareaWithMentions = styled.div`
  .news_feed_input_with_mentions {
    width: 100%;
    margin-top: 20px;
    height: 72px;
    & textarea {
      padding-left: 8px;
      padding-top: 3px;
      border: 1px solid #eff0f1;
      border-radius: 2px;
      &:focus {
        outline: none;
        box-shadow: none;
        font-size: 14px;
      }
    }
    & .news_feed_input_with_mentions__suggestions {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
    }
  }
`;

export const MentionOption = styled(Mention)`
  position: relative;
  background: #fff;
  z-index: 10;
  color: #1890ff;
  top: 3px;
  left: 8px;
  &::selection {
    color: #fff;
    background: #1890ff;
  }
`;

export const MentionSuggestion = styled.div`
  display: flex;
  box-sizing: border-box;
  min-width: 286px;
  padding: 12px;
  &:hover {
    background: #ecf6ff;
  }
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const MentionSuggestionText = styled.span`
  position: relative;
  left: 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`;

export const PictureContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;
`;

export const UploadPictureContainer = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
  padding-bottom: 10px;
`;

export const UploadPictureElementContainer = styled.div``;

export const UploadPictureElement = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  position: relative;
  margin-left: 8px;
  overflow: hidden;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(149, 151, 161, 0.1);

  img {
    width: 100%;
  }
`;

export const UploadPdfElement = styled(UploadPictureElement)`
  position: relative;
`;

export const StyledAttachmentImage = styled(Attachment)`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 17px);
  width: 34px;
  height: 24px;
`;

export const PictureFileLabel = styled.label`
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  min-height: 80px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 5px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%236B778CFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  img {
    max-width: 100%;
  }
`;

export const StyledDeletePhotoImage = styled(DeletePhotoImage)`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  user-select: none;
`;

export const SmallPictureFileLabel = styled(PictureFileLabel)`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-top: 0;
  margin-left: 8px;
`;

export const PictureInput = styled.input`
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  z-index: -1;
`;

export const SubmitContainer = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e4e4e4;
`;

export const SubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 4px;

  &:disabled,
  &:disabled:hover {
    color: #fff;
    border: none;
    background: rgba(24, 144, 255, 0.3);
  }
`;

export const UploadTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: text;

  p {
    padding: 10px 0 0 0;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: #4b4b4b;
  }
`;

export const UploadTitleSmallContainer = styled.div`
  width: 85px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  p {
    padding: 10px 0 0 0;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: #4b4b4b;
  }
`;

export const CompanyNewsInput = styled(Input)`
  border-radius: 2px;
  padding: 11px;
  color: #6b778c;
  ::placeholder {
    color: #6b778c;
  }
`;

export const CompanyNewsDescription = styled(Input.TextArea)`
  resize: none;
  margin-top: 16px;
  border-radius: 2px;
  color: #6b778c;
  ::placeholder {
    color: #6b778c;
  }
`;

export const StyledSwitch = styled(Switch)`
  margin-left: 16px;

  &.ant-switch-checked {
    .ant-switch-handle {
      top: 1px;
      ::before {
        background: #1890ff;
      }
    }
    &.ant-switch {
      border: 1px solid #1890ff;
    }
  }

  .ant-switch-handle {
    width: 19px;
    height: 19px;
    top: 1px;
    ::before {
      background: #6b778c;
    }
  }
  &.ant-switch {
    height: 24px;
    background: #ffffff !important;
    border: 1px solid #6b778c;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  margin-top: 24px;
`;

export const DatePickerContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  color: #6b778c;
  input {
    ::placeholder {
      color: #6b778c;
    }
  }
  .ant-picker-suffix {
    svg path {
      fill: #4b4b4b;
    }
  }
`;

export const CompanyNewsDatePickersContainer = styled.div`
  margin-bottom: 24px;
`;

export const BehalfRadioGroup = styled(Radio.Group)`
  margin-bottom: 15px;
`;

export const StyledBehalfRadio = styled(Radio)`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const CostCentersSelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 5px 15px 15px 0;
  max-height: 155px;
  overflow-y: auto;
`;

interface ICostCenterSelecorItem {
  $active?: boolean;
}
export const CostCentersSelectorItem = styled.span<ICostCenterSelecorItem>`
  position: relative;
  padding: 4px 12px;
  margin-right: 8px;
  background: #ffffff;
  border: 0.5px solid #111111;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  ${(props) =>
    props.$active
      ? `
    color: #1890FF;
    border: 1.5px solid #1890FF;
  `
      : ``}
`;

export const MainPreviewModalContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 800px 400px;
  @media (max-width: 1300px) {
    grid-template-columns: 600px 350px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 400px 300px;
  }
  @media (max-width: 767px) {
    overflow-y: auto;
    display: block;
  }
`;

export const StyledPreviewModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: 8px;
  }

  @media (max-height: 650px) {
    .ant-modal-body {
      height: 500px !important;
    }
  }

  ${({ doNotHaveImages }: { doNotHaveImages?: boolean }) =>
    doNotHaveImages
      ? `
        width: 400px !important;
        ${MainPreviewModalContainer} {
          grid-template-columns: 100%;
        }
      `
      : `
        @media (max-width: 1300px) {
          width: 950px !important;
        }
        @media (max-width: 1024px) {
          width: 700px !important;
        }
      `};

  .rcs-inner-handle {
    background-color: #e4e4e4;
  }
  .rcs-custom-scrollbar {
    width: 4px;
  }
`;

export const StyledSliderContainer = styled.div`
  width: 100%;
  height: 650px;

  @media (max-height: 650px) {
    height: 500px;
  }
`;

export const SideContainer = styled.div`
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px -3px 13px rgba(0, 0, 0, 0.08);
  @media (max-height: 650px) {
    height: 500px;
  }
`;

export const PictureWrapper = styled.div`
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s;
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  height: 650px;
  @media (max-width: 767px) {
    height:325px;
  }
  .slick-prev {
    opacity: 0;
    left: 25px;
    z-index: 999;
    transition: 0.2s;
  }
  .slick-next {
    opacity: 0;
    right: 25px;
    z-index: 999;
    transition: 0.2s;
  }
  :hover {
    .slick-prev {
      opacity: 1;
    }
    .slick-next {
      opacity: 1;
    }
  }

  @media (max-height: 650px) {
    height: 500px;
  }
`;

interface IStyledSlide {
  background?: string;
}

export const StyledSlide = styled.div<IStyledSlide>`
  width: 100%;
  height: 650px;
  @media (max-width: 767px) {
    height:325px;
  }
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: #fff;

  img {
    width: 100%;
    max-height: 650px;
    @media (max-width: 767px) {
      height:325px;
    }
  }

  @media (max-height: 650px) {
    height: 500px;
  }

  ${({ background }) =>
    background &&
    `
    background: ${background} !important
  `}
`;

export const StyledLeftArrow = styled(LeftArrow)`
  width: 40px;
  height: 40px;
`;

export const StyledRightArrow = styled(RightArrow)`
  width: 40px;
  height: 40px;
`;

export const MainUserContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const UserNameContainer = styled.div`
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const Name = styled(Typography.Title)`
  margin: 0 !important;
`;

export const SubName = styled(Typography.Text)`
  font-size: 12px;
  color: #4a4a4a;
`;

export const PreviewModalContainer = styled.div`
  padding: 0 8px 16px 16px;
  display: flex;
  flex-direction: column;
`;

export const CommentsMainContainer = styled.div`
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const InnerWrapper = styled.div`
  height: 100%;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  margin-top: 14px;
  text-overflow: ellipsis;

  ol {
    padding: 0 0 0 20px;
  }

  ${(props: { isSliced?: boolean }) =>
    props.isSliced
      ? `
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `
      : ``};
`;

export const ViewAllText = styled.div`
  color: #1890ff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
`;

export const SliceButton = styled.div`
  color: #1890ff;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  user-select: none;
  cursor: pointer;
`;

export const MainDescriptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 8px;
`;

export const TargetEmployeesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  ${(props: { isSliced?: boolean }) =>
    props.isSliced
      ? `
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
     
    `
      : ``};
`;

export const CommentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 24px;

  p {
    font-size: 12px;
    color: #9597a1;
    margin: 0;
    cursor: pointer;
  }
`;

export const MainReactionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const ReactionsContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ReactionContainer = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border: 3px solid #fff;
  padding: 0;
  margin-left: -5px;
  :first-child {
    margin: 0;
  }
`;

interface IButtonContainer {
  isOpenedComments?: boolean;
}

export const ButtonsContainer = styled.div<IButtonContainer>`
  width: 100%;
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;

  ${({ isOpenedComments }) =>
    !isOpenedComments &&
    `
    border-bottom: none;
  `};
`;

export const StyledLike = styled(Like)`
  width: 16px;
  fill: #111111;
`;

interface IStyledButton {
  isChangedReaction?: boolean;
}

export const StyledButton = styled.div<IStyledButton>`
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  fill: #111111;

  p {
    font-size: 14px;
    color: #111111;
    padding: 0 0 0 10px;
    margin: 0;
  }

  :hover {
    background: #ecf6ff;
    fill: #1890ff;
    p {
      color: #1890ff;
    }
    ${StyledLike} {
      fill: #1890ff;
    }
  }

  ${(props: { isChangedReaction?: boolean }) =>
    props.isChangedReaction &&
    `
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #8E72AF;
      text-transform: capitalize;

      :hover {
        p {
          color: #8E72AF;
        }
      }
    }
  `};
`;

export const CreatePostContainer = styled.div`
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 40px;
  display: flex;
  align-items: center;
  background: #fafafa;
  width: 100%;
  box-sizing: border-box;
  padding: 11px 19px;
  user-select: none;
  cursor: pointer;
  color: #4b4b4b;
`;

export const StyledCreatePostIcon = styled(CreatePostIcon)`
  margin-right: 15px;
  color: #4b4b4b;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`;

export const StyledPopover = styled(Popover)`
  .ant-popover-arrow {
    visibility: hidden;
  }
`;

export const ReactionsPopoverContainer = styled.div`
  background: #fff;
  padding: 10px;
  border-radius: 40px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px 17px !important;
`;

export const ReactionElement = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :first-child {
    margin-left: 0;
  }
`;

export const EditPopoverContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 25px;
  width: 250px;
  box-sizing: border-box;
  z-index: 0;
`;

export const EditPopoverElement = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  align-items: center;
  cursor: pointer;

  :last-child {
    margin-top: 18px;
  }

  img {
    margin-right: 16px;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  z-index: 0;
`;

export const CommentsPreviewContainer = styled.div`
  height: 300px;
  overflow: auto;
`;

export const EmptyCommentsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
`;

export const CommentsInput = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
`;

export const CommentInput = styled(Input)`
  background: rgba(239, 240, 241, 0.4);
  border-radius: 40px;
  border: none;

  input {
    color: #9da5ac;
    font-size: 14px;
    background: transparent;
  }
`;

export const StyledCloseIcon = styled(Close)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const StyledPreviewAvatar = styled(Avatar)`
  border-radius: 100%;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  position: relative;
`;

export const WarningText = styled.div`
  color: #f5222d;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PreviewModalImage = styled.img`
  width: auto !important;
  max-height: 100% !important;
  max-width: 100% !important;
`;

interface IEmptyArea {
  size: number;
}
export const EmptyArea = styled.div<IEmptyArea>`
  ${(props) => (props.size ? `padding: ` + props.size / 2 + `px 0;` : ``)}
`;

export const PostTypeSwitch = styled.div`
  position: relative;
  margin-top: 8px;
  margin-bottom: 24px;
`;
interface IPostType {
  $active: boolean;
}

export const PostType = styled.div<IPostType>`
  display: inline-block;
  padding: 11px 15px;
  font-size: 14px;
  line-height: 16px;
  background-position: center right;
  background-repeat: no-repeat;
  border-radius: 20px;
  border-width: 2px;
  cursor: pointer;
  height: 43px;

  svg {
    z-index: 1;
  }
`;

export const PostTypeRecognize = styled(PostType)`
  color: #6006d2;
  background: #f9f1ff;
  svg > g > path {
    fill: #6006d2;
  }
  ${(props) =>
    props.$active
      ? `
    border: 2px solid #7323D7;
  `
      : ``}
`;

export const PostTypeThanks = styled(PostType)`
  color: #04aa77;
  background: #e9f9f4;
  margin-left: 6px;
  svg > g > path {
    fill: #04aa77;
  }
  ${(props) =>
    props.$active
      ? `
    border: 2px solid #04AA77;
  `
      : ``}
`;

export const PostTypeCloseIcon = styled(CloseIcon)`
  margin-left: 10px;
`;

export const FeaturedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectorImage = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
`;

export const PreviewModalHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #eff0f1;
  height: 48px;
  display: flex;
  align-items: center;

  img {
    width: 16px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    padding: 0;
    margin: 0 0 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
  }
`;

export const StyledPreviewMenuIcon = styled(MenuIcon)`
  cursor: pointer;
`;

export const FlexBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const CostCenterImage = styled.img`
  margin-right: 5px;
`;
