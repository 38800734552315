import { ReactComponent as Love } from './assets/images/reactions/like.svg';
import { ReactComponent as Like } from './assets/images/reactions/upperFinger.svg';
import { ReactComponent as Lamp } from './assets/images/reactions/lamp.svg';
import { ReactComponent as Flaps } from './assets/images/reactions/flaps.svg';
import { ReactComponent as Support } from './assets/images/reactions/support.svg';
import { ReactComponent as Curious } from './assets/images/reactions/curios.svg';
import LoveSrc from './assets/images/reactions/like.svg';
import LikeSrc from './assets/images/reactions/upperFinger.svg';
import LampSrc from './assets/images/reactions/lamp.svg';
import FlapsSrc from './assets/images/reactions/flaps.svg';
import SupportSrc from './assets/images/reactions/support.svg';
import CuriousSrc from './assets/images/reactions/curios.svg';

export enum REACTIONS {
  LIKE = 'LIKE',
  CELEBRATE = 'CELEBRATE',
  SUPPORT = 'SUPPORT',
  LOVE = 'LOVE',
  INSIGHTFUL = 'INSIGHTFUL',
  CURIOUS = 'CURIOUS',
}

export const reactionsTypes = {
  [REACTIONS.LOVE]: {
    type: REACTIONS.LOVE,
    image: Love,
    src: LoveSrc,
    background: '#DF704D',
    tooltipLabel: 'Love',
  },
  [REACTIONS.LIKE]: {
    type: REACTIONS.LIKE,
    image: Like,
    src: LikeSrc,
    background: '#1485BD',
    tooltipLabel: 'Like',
  },
  [REACTIONS.INSIGHTFUL]: {
    type: REACTIONS.INSIGHTFUL,
    image: Lamp,
    src: LampSrc,
    background: '#F5BB5C',
    tooltipLabel: 'Insightful',
  },
  [REACTIONS.CELEBRATE]: {
    type: REACTIONS.CELEBRATE,
    image: Flaps,
    src: FlapsSrc,
    background: '#6DAE4F',
    tooltipLabel: 'Celebrate',
  },
  [REACTIONS.SUPPORT]: {
    type: REACTIONS.SUPPORT,
    image: Support,
    src: SupportSrc,
    background: '#BBA9D1',
    tooltipLabel: 'Support',
  },
  [REACTIONS.CURIOUS]: {
    type: REACTIONS.CURIOUS,
    image: Curious,
    src: CuriousSrc,
    background: '#DEB8DC',
    tooltipLabel: 'Curious',
  },
};

export enum TIMELINE_EVENT_TYPES {
  CERTIFICATION = 'CERTIFICATION',
  TRAINING = 'TRAINING',
  BIRTHDAY = 'BIRTHDAY',
  PERSONAL = 'PERSONAL',
  PROMOTION = 'PROMOTION',
  START_DATE = 'START_DATE',
  THANKS = 'THANKS',
  TRANSFER = 'TRANSFER',
  WORK_ANNIVERSARY = 'WORK_ANNIVERSARY',
  RECOGNITION = 'RECOGNITION',
}

export const COMPANY = {
  name: 'Astreya',
  subtitle: 'AstreyaHQ',
};

export enum POST_TYPES {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY_NEWS = 'COMPANY_NEWS',
  TEAM_NEWS = 'TEAM_NEWS',
  THANKS = 'THANKS',
  RECOGNITION = 'RECOGNITION',
  HR_NEW_HIRES = 'HR_NEW_HIRES',
  HR_BIRTHDAY_WISHES = 'HR_BIRTHDAY_WISHES',
  HR_PROMOTION = 'HR_PROMOTION',
  HR_WORK_ANNIVERSARY = 'HR_WORK_ANNIVERSARY',
}

export const emoticonsForEditor = {
  relaxed: {
    keywords: ['relaxed'],
    char: '😴',
  },
  frowning_face: {
    keywords: ['frowning_face'],
    char: '🙁',
  },
};
