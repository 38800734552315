import styled, { css } from 'styled-components';
import { Avatar } from 'antd';

import { ReactComponent as TerminatedIcon } from '../../assets/images/popovers/terminated.svg';
import { ReactComponent as InactiveIcon } from '../../assets/images/popovers/inactive.svg';
import { ReactComponent as ActiveIcon } from '../../assets/images/popovers/active.svg';

export const StyledTerminatedIcon = styled(TerminatedIcon)`
  margin-left: 10px;
`;

export const StyledInactiveIcon = styled(InactiveIcon)`
  margin-left: 10px;
`;

export const StyledActiveIcon = styled(ActiveIcon)`
  margin-left: 10px;
`;

export const FollowPopoverContainer = styled.div`
  z-index: 99999;
`;

export const FollowInfoContainer = styled.div`
  display: flex;
`;

export const FollowAvatar = styled(Avatar)`
  border-radius: 100%;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  margin-top: 5px;
  overflow: hidden;
`;

export const FollowNameContainer = styled.div`
  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  p {
    color: #4b4b4b;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  :last-child {
    p {
      margin-top: 6px;
    }
  }
`;

interface IFollowbutton {
  isFollowed?: boolean;
  isPending?: boolean;
}

export const FollowPopoverButton = styled.div<IFollowbutton>`
  background: #1890ff;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 12px;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #1890ff;

  ${({ isFollowed }) =>
    isFollowed &&
    css`
      background: #fff;
      color: #1890ff;
    `}
  
  ${({ isPending }) =>
    isPending &&
    css`
      cursor: auto;
      background: #ECF6FF;
      border-color: #ECF6FF;
      color: #1890ff;
    `}

`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;
