import React, { FC } from 'react';

import { SpinnerMainContainer } from './styled';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

const Spinner: FC<IProps> = ({ width, height, color }) => <SpinnerMainContainer width={width} height={height} color={color} />;

export default Spinner;
