import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { ContentContainer, Article, ArticleHeader, Articles, ViewArticleButton, StyledArrowRight } from './styles';
import { IArticle } from '../../GlobalSearch';
import { richTextToPlain } from 'utils/parsers';
import { emitSearchClick } from 'utils/mixpanel';

interface IProps {
  data: IArticle[];
}

const KnowledgeBaseList: FC<IProps> = ({ data }) => {
  return (
    <ContentContainer>
      {data && (
        <Fragment>
          <Articles>
            {data.map((elem) => {
              const { title, contents, article_id, slug } = elem;

              const firstPhrases = `${richTextToPlain(contents).slice(0, 150)} ...`;

              return (
                <Article>
                  <ArticleHeader>
                    <h1>{title}</h1>
                    <Link to={`/knowledge-base/${article_id}/${slug}`}>
                      <ViewArticleButton onClick={() => emitSearchClick({ searchType: 'article' })}>
                        View Article
                        <StyledArrowRight />
                      </ViewArticleButton>
                    </Link>
                  </ArticleHeader>
                  <p>{firstPhrases}</p>
                </Article>
              );
            })}
          </Articles>
        </Fragment>
      )}
    </ContentContainer>
  );
};

export default KnowledgeBaseList;
