export enum PositionTimeTypes {
  FullTime = 'Full_time',
  PartTime = 'Part_time',
}

export enum EmployeeTypes {
  Regular = 'Regular',
  Temporary = 'Temporary',
}

export enum PayRateTypes {
  Hourly = 'Hourly',
  Salary = 'Salary',
}

export interface IJobModel {
  id: string;
  positionTitle: string;
  positionBusinessTitle: string;
  primaryJob: boolean;
  employeeType: EmployeeTypes.Regular | EmployeeTypes.Temporary | null;
  positionTimeType: PositionTimeTypes.FullTime | PositionTimeTypes.PartTime | null;
  fullTimeEquivalentPercentage: number | null;
  payRateType: PayRateTypes.Hourly | PayRateTypes.Salary | null;
}
