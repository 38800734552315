const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  graphqlUrl: process.env.REACT_APP_GRAPHQL_URL,
  graphqlSubscriptionUrl: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  oneLoginSubdomain: process.env.REACT_APP_ONE_ID_SUBDOMAIN,
  tinyMCEApiKey: process.env.REACT_APP_TINYMCE_API_KEY,
  helpHeroApiKey: process.env.REACT_APP_HELP_HERO_KEY,
  googleAnalyticsApiKey: process.env.REACT_APP_GA_KEY,
  mixpanelKey: process.env.REACT_APP_MIXPANEL_KEY,
  careersGetJsonUrl: process.env.REACT_APP_CAREERS_GET_JSON_URL,
  env: process.env.REACT_APP_ENV,
  baseDomain: '.astreya.com',
};

export default config;
