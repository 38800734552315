import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import client from 'app/apolloClient';
import { GET_USER_ORG_CHART } from 'modules/team/gql/query';

const useOrgChartQuery: any = (userId: string | null) => {
  const queryFromCache = client.readQuery({
    query: GET_USER_ORG_CHART,
    variables: { userId },
  });
  const query = useQuery(GET_USER_ORG_CHART, {
    variables: { userId },
    skip: !userId,
    onError: () => {},
  });

  useEffect(() => {
    const hasQueryData = query?.data;
    const hasCachedData = queryFromCache;
    if (hasQueryData && !hasCachedData && userId) {
      client.writeQuery({
        query: GET_USER_ORG_CHART,
        data: query.data,
        variables: { userId },
      });
    }
  }, [queryFromCache, query, userId]);

  return queryFromCache ? queryFromCache : query?.data;
};

useOrgChartQuery.loadingUsers = {};

export default useOrgChartQuery;
