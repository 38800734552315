import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
  padding: 16px;
  border-radius: 4px;
  transition: 0.3s;
  cursor: pointer;

  :hover {
    background: #fafafa;
  }
`;

export const HrPostIcon = styled.img`
  width: 30px;
`;

export const HrPostTextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const HrPostText = styled.div`
  font-size: 12px;
  line-height: 16px;
  max-width: 270px;

  p {
    color: #4b4b4b;
    margin: 0;
    padding: 0;
  }

  span {
    color: #6b778c;
    margin: 0;
    padding: 0;
  }
`;

export const ReadIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #ff8600;
  position: absolute;
  content: '';
  top: 50%;
  right: -6px;
`;
