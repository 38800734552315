import { gql } from '@apollo/client';

export const GET_CDN_COOKIE = gql`
  query getCdnCookie {
    getCdnCookie {
      cookies
      expires
      domain
      path
    }
  }
`;
