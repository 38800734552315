import fireIcon from 'modules/news-feed/pages/Feed/assets/hrPosts/fire.svg';
import medalIcon from 'modules/news-feed/pages/Feed/assets/hrPosts/medal.svg';
import partyIcon from 'modules/news-feed/pages/Feed/assets/hrPosts/party.svg';
import briefcaseIcon from 'modules/news-feed/pages/Feed/assets/hrPosts/briefcase.svg';

export enum RelatedEntityTypes {
  POST_NEW_HIRES_TARGETED = 'POST_NEW_HIRES_TARGETED',
  POST_BIRTHDAY_WISHES_TARGETED = 'POST_BIRTHDAY_WISHES_TARGETED',
  POST_PROMOTION_TARGETED = 'POST_PROMOTION_TARGETED',
  POST_WORK_ANNIVERSARY_TARGETED = 'POST_WORK_ANNIVERSARY_TARGETED',
}

export type RelatedEntityType =
  | RelatedEntityTypes.POST_BIRTHDAY_WISHES_TARGETED
  | RelatedEntityTypes.POST_NEW_HIRES_TARGETED
  | RelatedEntityTypes.POST_PROMOTION_TARGETED
  | RelatedEntityTypes.POST_WORK_ANNIVERSARY_TARGETED;

export const hrNotificationsIconTypes = {
  [RelatedEntityTypes.POST_BIRTHDAY_WISHES_TARGETED]: partyIcon,
  [RelatedEntityTypes.POST_NEW_HIRES_TARGETED]: fireIcon,
  [RelatedEntityTypes.POST_PROMOTION_TARGETED]: medalIcon,
  [RelatedEntityTypes.POST_WORK_ANNIVERSARY_TARGETED]: briefcaseIcon,
};

interface ITextModel {
  preferredName: string;
  employeesCount: number;
  workAnniversaryCount: number;
}

export const textForMultipleUsers: { [key: string]: (data: ITextModel) => string } = {
  [RelatedEntityTypes.POST_BIRTHDAY_WISHES_TARGETED]: ({ employeesCount, preferredName }) =>
    `Today we celebrate you. Happy birthday ${preferredName}! You and ${employeesCount} other colleague${
      employeesCount ? 's' : ''
    } have a birthday today.`,
  [RelatedEntityTypes.POST_NEW_HIRES_TARGETED]: ({ employeesCount, preferredName }) =>
    `${preferredName}, welcome to Astreya! We are happy to have you on board. You and ${employeesCount} more recently joined our team.`,
  [RelatedEntityTypes.POST_PROMOTION_TARGETED]: ({ employeesCount, preferredName }) =>
    `${preferredName}, congratulations on your promotion! You and ${employeesCount} more are celebrating a promotion.`,
  [RelatedEntityTypes.POST_WORK_ANNIVERSARY_TARGETED]: ({ employeesCount, workAnniversaryCount, preferredName }) =>
    `${preferredName}, congratulations on your ${
      workAnniversaryCount > 1 ? (workAnniversaryCount > 2 ? `${workAnniversaryCount}th` : '2nd') : '1st'
    } work anniversary at Astreya! You and ${employeesCount} more are celebrating a work anniversary today`,
};

export const textForSingleUser: { [key: string]: (data: ITextModel) => string } = {
  [RelatedEntityTypes.POST_BIRTHDAY_WISHES_TARGETED]: ({ preferredName }) =>
    `Today we celebrate you. Happy birthday ${preferredName}!`,
  [RelatedEntityTypes.POST_NEW_HIRES_TARGETED]: ({ preferredName }) =>
    `${preferredName}, welcome to Astreya! We are happy to have you on board.`,
  [RelatedEntityTypes.POST_PROMOTION_TARGETED]: ({ preferredName }) => `${preferredName}, congratulations on your promotion!`,
  [RelatedEntityTypes.POST_WORK_ANNIVERSARY_TARGETED]: ({ workAnniversaryCount, preferredName }) =>
    `${preferredName}, congratulations on your ${
      workAnniversaryCount > 1 ? (workAnniversaryCount > 2 ? `${workAnniversaryCount}th` : '2nd') : '1st'
    } work anniversary at Astreya!`,
};
