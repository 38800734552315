import React, { FC } from 'react';
import {
  UserText,
} from '../../component.styles';
import { POST_TYPES } from 'modules/news-feed/pages/Feed/constants';
import { IUserForNotification } from '../../interfaces';

const hrPostTypesLabels = {
  [POST_TYPES.HR_BIRTHDAY_WISHES]: 'Birthday Wishes',
  [POST_TYPES.HR_NEW_HIRES]: 'New Hires',
  [POST_TYPES.HR_PROMOTION]: 'Promotion',
  [POST_TYPES.HR_WORK_ANNIVERSARY]: 'Work Anniversary',
};

interface IProps {
  userId: string;
  postUser: IUserForNotification;
  postType: POST_TYPES;
}

const PostAuthor: FC<IProps> = ({ userId, postUser, postType }) => {
  const isHrPost = Object.keys(hrPostTypesLabels).includes(postType);
  const text = isHrPost ?
    hrPostTypesLabels[postType] :
    userId === postUser?.id ?
      'your' :
      `${postUser?.fullName}'s`;

  return (
    <UserText>{text}</UserText>
  );
};

export default PostAuthor;
