import React, { FC } from 'react';

import { EditPopoverContainer, EditPopoverElement } from '../styles';
import editImage from 'modules/news-feed/pages/Feed/assets/edit.svg';
import deleteImage from 'modules/news-feed/pages/Feed/assets/delete.svg';

interface IProps {
  deleteHandler: () => void;
  editHandler: () => void;
}

const EditPopover: FC<IProps> = ({ deleteHandler, editHandler }) => {
  return (
    <EditPopoverContainer>
      <EditPopoverElement onClick={editHandler}>
        <img src={editImage} />
        Edit
      </EditPopoverElement>
      <EditPopoverElement onClick={deleteHandler}>
        <img src={deleteImage} />
        Delete
      </EditPopoverElement>
    </EditPopoverContainer>
  );
};

export default EditPopover;
