import {
  LOGIN_REQUEST,
  LOGIN_SUCCESSFUL,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESSFUL,
  LOGOUT_FAILURE,
  REFRESH_AUTH_TOKEN_SUCCESS,
  REFRESH_AUTH_TOKEN_FAILURE,
  COOKIES_RETRIEVE_SUCCESS,
  SET_DEFAULT_ROUTE,
} from './auth.actionTypes';
import * as localStorage from 'utils/localStorage';
import { clearAuthData } from 'modules/auth/utils';
import { IAuthState } from './auth.types';
import { getTokenExpirationTime } from './auth.helper';
import { storageKeys } from './auth.constants';

const tokenOutputStored = localStorage.get(storageKeys.tokenOutput);
const tokenOutputParsed = tokenOutputStored ? JSON.parse(tokenOutputStored) : {};
const { accessToken = null, refreshToken = null, refreshExpires = null } = tokenOutputParsed;
const accessExpires = getTokenExpirationTime(accessToken);
const defaultRoute = localStorage.get(storageKeys.defaultRoute) || '';

const initialState: IAuthState = {
  accessToken,
  refreshToken,
  accessExpires,
  refreshExpires,
  defaultRoute,
  isAuthorized: !!accessToken && !!refreshToken,
  inProgress: false,
  error: null,
  cdnCookieExp: null,
};

const authReducer = (state: IAuthState = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        inProgress: true,
      };
    }
    case LOGIN_SUCCESSFUL: {
      const { accessToken, refreshToken, refreshExpires } = action;
      const accessExpires = getTokenExpirationTime(accessToken);
      localStorage.remove(storageKeys.defaultRoute);
      return {
        ...state,
        isAuthorized: true,
        inProgress: false,
        refreshExpires: Date.parse(refreshExpires),
        accessToken,
        accessExpires,
        refreshToken,
        defaultRoute: '',
      };
    }
    case LOGIN_FAILURE: {
      clearAuthData();
      return {
        ...state,
        inProgress: false,
        error: action.error,
      };
    }
    case LOGOUT_REQUEST: {
      return {
        ...state,
        inProgress: false,
      };
    }
    case LOGOUT_SUCCESSFUL: {
      clearAuthData();
      return {
        ...state,
        isAuthorized: false,
        inProgress: false,
        accessToken: null,
        refreshToken: null,
        refreshExpires: null,
        accessExpires: null,
      };
    }
    case LOGOUT_FAILURE: {
      clearAuthData();
      return {
        ...state,
        inProgress: false,
        error: action.error,
      };
    }
    case REFRESH_AUTH_TOKEN_SUCCESS: {
      delete action.type;
      localStorage.set(storageKeys.tokenOutput, JSON.stringify(action));
      const { accessToken, refreshToken, refreshExpires } = action;
      const accessExpires = getTokenExpirationTime(accessToken);
      return {
        ...state,
        isAuthorized: true,
        inProgress: false,
        refreshExpires: Date.parse(refreshExpires),
        accessToken,
        accessExpires,
        refreshToken,
      };
    }
    case REFRESH_AUTH_TOKEN_FAILURE: {
      clearAuthData();
      return {
        ...state,
        error: action.error,
        isAuthorized: false,
        inProgress: false,
        accessToken: null,
        refreshToken: null,
        refreshExpires: null,
        accessExpires: null,
      };
    }
    case COOKIES_RETRIEVE_SUCCESS: {
      return {
        ...state,
        cdnCookieExp: action.expires,
      };
    }
    case SET_DEFAULT_ROUTE: {
      localStorage.set(storageKeys.defaultRoute, action.path);
      return {
        ...state,
        defaultRoute: action.path,
      };
    }
  }
  return state;
};

export default authReducer;
