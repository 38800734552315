import styled from 'styled-components';
import { Typography, Button, Modal } from 'antd';
import { ReactComponent as CloseIcon } from 'modules/common/assets/images/close.svg';

export const StyledModalDelete = styled(Modal)`
  position: relative;
  .ant-modal-content {
    border-radius: 28px;
  }
`;

export const StyledCancelButton = styled(Button)`
  border: 1px solid #4a4a4a;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 25px;
  right: 25px;
`;

export const DeleteModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleDeletePopover = styled(Typography.Title)`
  margin-top: 25px;
  max-width: 200px;
  text-align: center;
`;

export const TextDeletePopover = styled(Typography.Text)`
  font-size: 14px;
  color: #4a4a4a !important;
  text-align: center;
  max-width: 200px;
`;

export const ControlsDeleteModal = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  max-width: 330px;
`;

export const StyledDeleteButton = styled(Button)`
  width: 120px;
  padding: 9px 14px;
  height: auto;
  border-radius: 6px;
  font-weight: 600;
  box-sizing: border-box !important;
`;

export const StyledCancelDeleteButton = styled(StyledDeleteButton)`
  border: 1px solid #1890ff;
  color: #1890ff;
`;
