import React, { FC, useMemo, Fragment } from 'react';
import { utc } from 'moment';
import {
  DateContainer,
  StyledTimelineItem,
  TimelineAvatar,
  TimeLine,
  NameContainer,
  Point,
  CertificateInfoMessage,
  YearContainer,
  CertificateInfoContainer,
  Button,
  StyledVisibleIcon,
  StyledHideIcon,
  StyledImage,
} from '../../styles';
import { ITimelineEventModel } from 'modules/common/gql/models/timelineEvent.model';
import { TIMELINE_EVENT_TYPES } from 'modules/common/const';
import emptyAchImage from 'modules/profile/assets/images/achievements/emptyAchImage.svg';
import ach from '../../assets/ach.svg';

interface IProps {
  data: ITimelineEventModel;
  isVisibleYear: boolean;
  toggleEventVisibility: () => void;
  isOwner: boolean;
}

const Certification: FC<IProps> = ({ data, isVisibleYear, toggleEventVisibility, isOwner }) => {
  const { date, hidden, name, relatedEntity, type } = data;
  const isCertificate = useMemo(() => type === TIMELINE_EVENT_TYPES.CERTIFICATION, [type]);
  const achImageUrl = relatedEntity?.photoUrl || emptyAchImage;
  return (
    <StyledTimelineItem>
      {isVisibleYear && <YearContainer>{utc(date).format('YYYY')}</YearContainer>}
      <TimelineAvatar $hidden={hidden} background={'#FFF0E0'}>
        <img src={ach} alt={'ach'} />
      </TimelineAvatar>
      <TimeLine>
        <DateContainer $hidden={hidden} background={'#FFF0E0'} color={'#FA8C16'}>
          {isCertificate ? 'Certification' : 'Training'} <Point /> {utc(date).format('DD MMMM')}
        </DateContainer>
        <CertificateInfoMessage $hidden={hidden}>
          <CertificateInfoContainer>
            <StyledImage src={achImageUrl} fallback={emptyAchImage} />
            <NameContainer>
              <h1>{name}</h1>
            </NameContainer>
          </CertificateInfoContainer>
          {isOwner && (
            <Button style={{ top: 16 }} onClick={toggleEventVisibility}>
              {!hidden ?
                <Fragment><StyledVisibleIcon /> Hide</Fragment> :
                <Fragment><StyledHideIcon /> Show</Fragment>
              }
            </Button>
          )}
        </CertificateInfoMessage>
      </TimeLine>
    </StyledTimelineItem>
  );
};

export default Certification;
