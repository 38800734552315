import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { Image } from 'antd';

import {
  MainContainer,
  Avatar,
  InfoContainer,
  UserContainer,
  MessageContainer,
  MessageText,
  MainInfoContainer,
  PostTextContainer,
  ReadIndicator,
  UserText,
} from '../../component.styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { richTextToPlain } from 'utils/parsers';
import { NOTIFICATION_TYPES } from '../../constants';
import { ICommentNotification } from '../../interfaces';

interface IProps {
  data: ICommentNotification;
  changeNotify: (id: string) => void;
}

const Comment: FC<IProps> = ({ data, changeNotify }) => {
  const { createdAt, status, relatedEntity, id } = data;
  const { text = '' } = relatedEntity.comment || {};
  const { commentUser } = relatedEntity;
  const parsedText = useMemo(() => richTextToPlain(text), [text]);

  if (!relatedEntity) {
    return null;
  }

  return (
    <MainContainer onClick={() => changeNotify(id)}>
      <UserContainer>
        <Avatar src={<Image preview={false} src={commentUser?.photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
        <MainInfoContainer>
          <InfoContainer>
            <MessageContainer>
              <UserText>{commentUser?.fullName}</UserText>
              <MessageText>commented on your post</MessageText>
            </MessageContainer>
            <MessageContainer>{moment(createdAt).format('MMM DD')}</MessageContainer>
          </InfoContainer>
          <PostTextContainer dangerouslySetInnerHTML={{ __html: parsedText }} />
        </MainInfoContainer>
      </UserContainer>
      {(status === NOTIFICATION_TYPES.SEEN || status === NOTIFICATION_TYPES.NEW) && <ReadIndicator />}
    </MainContainer>
  );
};

export default Comment;
