import React, { FC, useMemo, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tooltip, Image } from 'antd';

import {
  FollowPopoverContainer,
  FollowInfoContainer,
  FollowAvatar,
  FollowNameContainer,
  FollowPopoverButton,
  StyledActiveIcon,
  StyledInactiveIcon,
  StyledTerminatedIcon,
  NameContainer,
} from '../styles';
import { IUser, IAuthorUser } from '../../../../team/pages/ClubView/ClubView';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { RootState } from 'store/rootReducer';
import { UserState } from 'store/domains/user/user.types';
import { IFollowStatus } from 'modules/common/gql/models/user.model';

interface IProps {
  userData: IUser;
  followedUsers: IAuthorUser[];
  followHandler: (id: string) => void;
  unfollowHandler: (id: string) => void;
}

const FollowPopover: FC<IProps> = ({ userData, followedUsers, followHandler, unfollowHandler }) => {
  const user: UserState = useSelector((state: RootState) => state?.user);
  const { id, job, photoUrl, terminatedAt, isActive, workingAddress, fullName: preferredName = '' } = userData;
  const followedProfile = useMemo(() => followedUsers.find((elem) => elem.authorUser.id === id), [id, followedUsers]);
  const isFollowed = useMemo(() => !!followedProfile && followedProfile.status === IFollowStatus.FOLLOWING, [followedProfile]);
  const isPending = useMemo(() => !!followedProfile && followedProfile.status === IFollowStatus.PENDING, [followedProfile]);
  const isCurrentUser = user.id === id;

  return (
    <FollowPopoverContainer>
      <Link to={`/profile/${id}`}>
        <FollowInfoContainer>
          <FollowAvatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
          <FollowNameContainer>
            <NameContainer>
              {preferredName.length >= 18 ? (
                <Tooltip title={<span>{preferredName}</span>} overlayClassName={'followPopoverTooltip'}>
                  <h2>{preferredName}</h2>
                </Tooltip>
              ) : (
                <h2>{preferredName}</h2>
              )}
              {terminatedAt ? <StyledTerminatedIcon /> : isActive ? <StyledActiveIcon /> : <StyledInactiveIcon />}
            </NameContainer>
            {terminatedAt ? (
              <p>Inactive</p>
            ) : (
              <Fragment>
                {job?.positionTitle &&
                  (job.positionTitle.length >= 29 ? (
                    <Tooltip title={<span>{job.positionTitle}</span>} overlayClassName={'followPopoverTooltip'}>
                      <p>{job.positionTitle}</p>
                    </Tooltip>
                  ) : (
                    <p>{job.positionTitle}</p>
                  ))}
                {workingAddress?.municipality &&
                  workingAddress?.countryCode &&
                  (`${workingAddress.municipality}, ${workingAddress.countryCode}`.length >= 29 ? (
                    <Tooltip
                      title={
                        <span>
                          {workingAddress.municipality}, {workingAddress.countryCode}
                        </span>
                      }
                      overlayClassName={'followPopoverTooltip'}
                    >
                      <p>
                        {workingAddress.municipality}, {workingAddress.countryCode}
                      </p>
                    </Tooltip>
                  ) : (
                    <p>
                      {workingAddress.municipality}, {workingAddress.countryCode}
                    </p>
                  ))}
              </Fragment>
            )}
          </FollowNameContainer>
        </FollowInfoContainer>
      </Link>
      {!terminatedAt && !isCurrentUser && isFollowed && (
        <FollowPopoverButton onClick={() => unfollowHandler(id)} isFollowed={isFollowed}>
          Unfollow
        </FollowPopoverButton>
      )}
      {!terminatedAt && !isCurrentUser && isPending && <FollowPopoverButton isPending={isPending}>Pending</FollowPopoverButton>}
      {!terminatedAt && !isCurrentUser && !isFollowed && !isPending && (
        <FollowPopoverButton onClick={() => followHandler(id)} isFollowed={isFollowed}>
          Follow
        </FollowPopoverButton>
      )}
    </FollowPopoverContainer>
  );
};

export default FollowPopover;
