import { IPhoneModel } from './phone.model';
import { IAddressModel } from './address.model';
import { IHobbieModel } from './hobbie.model';
import { ISkillModel } from './skill.model';
import { ITrainingModel } from './training.model';
import { ICertificationModel } from './certification.model';
import { IJobModel } from './job.model';
import { IEmploymentModel } from './employment.model';
import { IOrganizationModel } from './organization.model';
import { ITimelineEventModel } from './timelineEvent.model';
import { IRoleModel } from './role.model';
import { IAchievementsModel } from './achievements.model';
import { ICompleteness } from './completeness.model';
import { IUserVisit } from './userVisit.model';

export enum IFollowStatus {
  PENDING = 'PENDING',
  FOLLOWING = 'FOLLOWING',
  NONE = 'NONE',
}

export interface IAddress {
  id: string;
  countryCode: string;
  usageType: string;
  municipality: string;
}

export interface IUserForNewsFeed {
  id: string;
  fullName: string;
  photoUrl?: string;
  job: {
    positionTitle: string;
    addresses: IAddress[];
  };
  workingAddress: IAddress;
  costCenter: IOrganizationModel;
  terminatedAt: string | null;
  isActive: boolean;
}

export interface IUserForCompleteness {
  id: string;
  photoUrl: string;
  profileCompleteness: ICompleteness;
  userVisit: IUserVisit;
}

export interface IUserModel {
  id: string;
  createdAt?: string;
  fullName: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  email?: string;
  photoUrl?: string;
  addresses: IAddressModel[];
  workingAddress?: IAddressModel;
  phones?: IPhoneModel[];
  skills?: ISkillModel[];
  hobbies?: IHobbieModel[];
  trainings?: ITrainingModel[];
  certifications?: ICertificationModel[];
  job: IJobModel;
  employment?: IEmploymentModel;
  costCenter?: IOrganizationModel;
  timelineEvents?: ITimelineEventModel[];
  permissionIds?: string[];
  roles?: IRoleModel[];
  birthDate: string;
  birthDateHidden: boolean;
  timeZone: string | null;
  trainingToUsers: IAchievementsModel[];
  certificationToUsers: IAchievementsModel[];
  profileCompleteness: ICompleteness;
  userVisit: IUserVisit;
  isFollowedByMe?: boolean;
  terminatedAt: string | null;
  isActive: boolean;
  isProfilePhotoAvatar: boolean;
  avatarBgColor: string | null;
}
