import React from 'react';
import {
  StyledModal,
  MainHeaderContainer,
  HeaderContainer,
  StyledCloseIcon,
  EditorMainContainer,
  StyledAvatarEditor,
  ClearEditorContainer,
  StyledClearEditor,
  SliderContainer,
  StyledSlider,
  StyledMinImage,
  StyledMaxImage,
  Controls,
  CancelButton,
  UploadButton,
} from '../styles';
import { AvatarContentContainer } from './styles';

interface IProps {
  file: File;
  scale: number;
  setScale: (arg: number) => any;
  editorRef: any;
  closeHandler: () => void;
  clearChangedFile: () => void;
  onSave: () => void;
}

const SelectedFile = ({
  file,
  scale,
  setScale,
  editorRef,
  closeHandler,
  clearChangedFile,
  onSave,
}: IProps) => {
  return (
    <StyledModal $withAvatars={false} visible={true} footer={null}>
      <MainHeaderContainer>
        <HeaderContainer>
          Upload Photo
          <StyledCloseIcon onClick={closeHandler} />
        </HeaderContainer>
      </MainHeaderContainer>
      <AvatarContentContainer>
        <EditorMainContainer>
          <StyledAvatarEditor
            ref={editorRef}
            image={file}
            scale={scale}
            width={480}
            height={480}
            border={50}
            color={[196, 196, 196, 0.4]}
          />
          <ClearEditorContainer onClick={clearChangedFile}>
            <StyledClearEditor />
          </ClearEditorContainer>
        </EditorMainContainer>
        <SliderContainer>
          <StyledMinImage />
          <StyledSlider min={1} max={3} step={0.1} onChange={(value) => setScale(value)} tooltipVisible={false} />
          <StyledMaxImage />
        </SliderContainer>
        <Controls>
          <CancelButton onClick={closeHandler}>Cancel</CancelButton>
          <UploadButton onClick={onSave}>Upload</UploadButton>
        </Controls>
      </AvatarContentContainer>
    </StyledModal>
  );
};

export default SelectedFile;