import React, { FC, useState, useMemo, useEffect } from 'react';
import { Input } from 'antd';
import { useMutation } from '@apollo/client';

import {
  StyledInnerTitle,
  WorkingHoursControls,
  ControlButton,
  StyledText,
  StyledCol,
  StyledConfirm,
  StyledCancell,
} from '../../styles';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import { EditContainer } from './styled';
import { UPDATE_PREFERED_NAME } from 'modules/profile/gql/mutation';
import { emitProfileUpdated } from 'utils/mixpanel';

export interface IProps {
  user: IUserModel;
  refetchUser: () => void;
  isOwner: boolean;
}

const PreferedName: FC<IProps> = ({ user, refetchUser, isOwner }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [editableValue, setEditableValue] = useState(user.fullName);
  const [updatePreferedName, { loading: updatePreferedNameLoading, called: updatePreferedNameCalled }] = useMutation(
    UPDATE_PREFERED_NAME,
  );
  const isValid = useMemo(() => editableValue.length > 0, [editableValue]);

  useEffect(() => {
    if (!updatePreferedNameLoading && updatePreferedNameCalled) {
      refetchUser();
      emitProfileUpdated({ section: 'preferred name' });
    }
  }, [updatePreferedNameLoading, updatePreferedNameCalled]);

  const onClose = () => {
    setEditMode(() => false);
  };

  const cancelHandler = () => {
    setEditableValue(user.fullName);
    onClose();
  };

  const saveName = () => {
    updatePreferedName({
      variables: {
        id: user.id,
        fullName: editableValue,
      },
    });
    onClose();
  };

  if (!isOwner && !user.fullName) {
    return null;
  }

  return (
    <StyledCol onClick={() => isOwner && !isEditMode && setEditMode(true)} $isEditable={isOwner && !isEditMode} span={24}>
      <StyledInnerTitle disabled>Preferred Name</StyledInnerTitle>
      {isEditMode ? (
        <EditContainer>
          <Input maxLength={40} value={editableValue} onChange={(e) => setEditableValue(e.target.value)} />
          <WorkingHoursControls>
            <ControlButton onClick={saveName} isInvisible={!isValid}>
              <StyledConfirm />
            </ControlButton>
            <ControlButton onClick={cancelHandler} isCanceButton>
              <StyledCancell />
            </ControlButton>
          </WorkingHoursControls>
        </EditContainer>
      ) : (
        <div>
          <StyledText>{user.fullName}</StyledText>
        </div>
      )}
    </StyledCol>
  );
};

export default PreferedName;
