import React, { Fragment, FC, useState } from 'react';
import { Popover } from 'antd';
import moment from 'moment';

import {
  RepliesContainer,
  RepliesControl,
  RepliesElement,
  RepliesButton,
  StyledAvatar,
  RepliesNameContainer,
  NameContainer,
  StyledDropdown,
  Menu,
  MainReplyContainer,
  StyledDescription,
  ReactionsContainer,
  AvatarContainer,
  Reply,
  MainReactionsContainer,
  ReactionContainer,
  ViewReactionsButton,
  Reactions,
  ReactionsMainContainer,
  ReactionElement,
  ReactionContainerForTooltip,
  MainNameContainer,
  Point,
  Edited,
  ShowMoreButton,
  RepliesMainContainer,
  StyledFollowPopover,
  SeeMoreButton,
} from '../styles';
import EditPopover from 'modules/news-feed/pages/Feed/components/PostCard/components/EditPopover';
import { EditPopoverContainer, EditPopoverElement } from '../../PostCard/styles';
import editImage from 'modules/news-feed/pages/Feed/assets/edit.svg';
import deleteImage from 'modules/news-feed/pages/Feed/assets/delete.svg';
import { reactionsTypes } from 'modules/common/const';
import { IComment } from 'modules/common/gql/models/newsFeedPost.model';
import ReactionsPopover from 'modules/news-feed/pages/Feed/components/PostCard/components/ReactionsPopover';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { getPostReactions } from '../utils';
import { IUser, IAuthorUser } from 'modules/team/pages/ClubView/ClubView';
import FollowPopover from 'modules/common/components/popovers/FollowPopover';
import { IFollowPopoverData } from '../Comments';
import { richTextToPlain } from 'utils/parsers';

interface IProps {
  replies: IComment[];
  currentUserId: string | null;
  isFullPost?: boolean;
  setCommentIdForDelete: (id: string) => void;
  editHandler: (id: string) => void;
  unsetReactionHandler: (reactionType: string, id: string) => void;
  handleVisibleReactionPopover: (id: string | null) => void;
  reactionPopoverId: string | null;
  setReactionHandler: (reactionType: string, id: string) => void;
  closeFollowPopover: () => void;
  userByIdData?: IUser | null;
  followHandler: (id: string) => void;
  unfollowHandler: (id: string) => void;
  followedUsers: IAuthorUser[];
  followPopoverData: IFollowPopoverData | null;
  setPopoverData: (data: IFollowPopoverData | null) => void;
  showMorePosts: string[];
  setMorePost: (id: string) => void;
  setIdForReactions: (id: string | null) => void;
  mentionsClickHandler: (a: any) => void;
  changeCommentForScroll: (id: string) => void;
  commentId: string;
}

const Replies: FC<IProps> = ({
  replies,
  currentUserId,
  isFullPost,
  setCommentIdForDelete,
  editHandler,
  unsetReactionHandler,
  handleVisibleReactionPopover,
  reactionPopoverId,
  setReactionHandler,
  closeFollowPopover,
  userByIdData,
  followHandler,
  unfollowHandler,
  followedUsers,
  followPopoverData,
  setPopoverData,
  showMorePosts,
  setMorePost,
  setIdForReactions,
  mentionsClickHandler,
  changeCommentForScroll,
  commentId,
}) => {
  const [isSliced, setSliced] = useState(replies.length > 2);

  return (
    <RepliesMainContainer>
      <RepliesContainer>
        {(isSliced ? replies.slice(0, 2) : replies).map((elem) => {
          const { author, text, _id, reactions, changed, createdAt } = elem;
          const { photoUrl, id: userId, fullName } = author;

          const currentReactions = getPostReactions(reactions);
          const bestReactions = currentReactions.sort((firstElem, secondElem) => secondElem.count - firstElem.count).slice(0, 3);
          const allReactions = currentReactions.reduce((acc, current) => acc + current.count, 0);
          const keyOfCurrentReaction =
            reactions && Object.keys(reactions).find((elem) => reactions[elem]?.includes(currentUserId));
          const currentReaction = keyOfCurrentReaction ? reactionsTypes[keyOfCurrentReaction] : null;

          return (
            <RepliesElement key={_id}>
              <StyledFollowPopover
                onVisibleChange={(val) => !val && closeFollowPopover()}
                trigger={['click']}
                content={() =>
                  userByIdData && (
                    <FollowPopover
                      followHandler={followHandler}
                      unfollowHandler={unfollowHandler}
                      userData={userByIdData}
                      followedUsers={followedUsers}
                    />
                  )
                }
                visible={Boolean(userByIdData && followPopoverData?.commentId === _id)}
                overlayClassName={'followPopover'}
                placement={'topRight'}
              >
                <AvatarContainer
                  onClick={() =>
                    setPopoverData({
                      commentId: _id,
                      userId,
                    })
                  }
                >
                  <StyledAvatar
                    icon={<img src={photoUrl || emptyAvatar} alt={'logo'} />}
                    $isChanged={Boolean(userByIdData && followPopoverData?.commentId === _id)}
                    $isSmallAvatar={true}
                  />
                </AvatarContainer>
              </StyledFollowPopover>
              <Reply>
                {isFullPost ? (
                  <RepliesNameContainer>
                    <NameContainer>
                      <MainNameContainer>
                        <h3>{fullName}</h3>
                        {changed && (
                          <Fragment>
                            <Point />
                            <Edited>Edited</Edited>
                          </Fragment>
                        )}
                      </MainNameContainer>
                      <StyledDropdown
                        overlay={
                          <EditPopover
                            deleteHandler={() => setCommentIdForDelete(_id)}
                            editHandler={() => {
                              editHandler(_id);
                            }}
                          />
                        }
                        placement="bottomRight"
                        overlayStyle={{ zIndex: 999999999 }}
                        trigger={['hover']}
                      >
                        <Menu $isEditable={currentUserId === userId} />
                      </StyledDropdown>
                    </NameContainer>
                    <StyledDescription onClick={mentionsClickHandler} dangerouslySetInnerHTML={{ __html: text }} />
                  </RepliesNameContainer>
                ) : (
                  <MainReplyContainer>
                    <NameContainer>
                      <MainNameContainer>
                        <h3>{fullName}</h3>
                        {changed && (
                          <Fragment>
                            <Point />
                            <Edited>Edited</Edited>
                          </Fragment>
                        )}
                      </MainNameContainer>
                      <StyledDropdown
                        overlay={
                          <EditPopoverContainer>
                            <EditPopoverElement onClick={() => editHandler(_id)}>
                              <img src={editImage} />
                              Edit
                            </EditPopoverElement>
                            <EditPopoverElement onClick={() => setCommentIdForDelete(_id)}>
                              <img src={deleteImage} />
                              Delete
                            </EditPopoverElement>
                          </EditPopoverContainer>
                        }
                        placement="bottomRight"
                        overlayStyle={{ zIndex: 999999999 }}
                      >
                        <Menu $isEditable={currentUserId === userId} />
                      </StyledDropdown>
                    </NameContainer>
                    <StyledDescription
                      onClick={mentionsClickHandler}
                      dangerouslySetInnerHTML={{ __html: text }}
                      isSliced={!showMorePosts.includes(_id)}
                    />
                    {!showMorePosts.includes(_id) && richTextToPlain(text).length > 120 && (
                      <SeeMoreButton
                        onClick={() => {
                          setMorePost(_id);
                        }}
                      >
                        See more
                      </SeeMoreButton>
                    )}
                  </MainReplyContainer>
                )}
                <ReactionsContainer isReply={true}>
                  <RepliesControl>
                    <p>{moment(createdAt).fromNow()}</p>
                    <StyledDropdown
                      onVisibleChange={(flag) => (flag ? handleVisibleReactionPopover(_id) : handleVisibleReactionPopover(null))}
                      visible={reactionPopoverId === _id}
                      overlay={
                        <ReactionsPopover
                          setReaction={(type) => setReactionHandler(type, _id)}
                          closeReactionsDropdown={() => handleVisibleReactionPopover(null)}
                        />
                      }
                      placement="topCenter"
                    >
                      {currentReaction ? (
                        <RepliesButton
                          onClick={() => unsetReactionHandler(currentReaction?.type || '', _id)}
                          isChanged={currentReaction}
                        >
                          {currentReaction?.type.toLowerCase()}
                        </RepliesButton>
                      ) : (
                        <RepliesButton isChanged={currentReaction} onClick={() => setReactionHandler('LIKE', _id)}>
                          Like
                        </RepliesButton>
                      )}
                    </StyledDropdown>
                  </RepliesControl>
                  {allReactions > 0 && (
                    <MainReactionsContainer onClick={() => setIdForReactions(_id)}>
                      <ViewReactionsButton>{allReactions}</ViewReactionsButton>
                      <Popover
                        content={() => (
                          <ReactionsMainContainer>
                            {currentReactions.map((elem, i) => {
                              const { type, count } = elem;
                              const { image: ReactionSVG, background } = reactionsTypes[type] || {};
                              return (
                                <ReactionElement key={`${i}_${type}`}>
                                  <ReactionContainerForTooltip key={`${i}_${type}`} style={{ background }}>
                                    <ReactionSVG />
                                  </ReactionContainerForTooltip>
                                  <p>{count}</p>
                                </ReactionElement>
                              );
                            })}
                          </ReactionsMainContainer>
                        )}
                        title={null}
                        overlayClassName={'commentReaction'}
                      >
                        <Reactions>
                          {bestReactions &&
                            bestReactions.map((elem, i) => {
                              const { type } = elem;
                              const { image: ReactionSVG, background } = reactionsTypes[type] || {};
                              return (
                                <ReactionContainer key={`${i}_${type}`} style={{ background }}>
                                  <ReactionSVG />
                                </ReactionContainer>
                              );
                            })}
                        </Reactions>
                      </Popover>
                    </MainReactionsContainer>
                  )}
                </ReactionsContainer>
              </Reply>
            </RepliesElement>
          );
        })}
      </RepliesContainer>
      {isSliced && (
        <ShowMoreButton
          onClick={() => {
            changeCommentForScroll(commentId);
            setSliced(false);
          }}
        >
          Show {replies.length - 2} more replies
        </ShowMoreButton>
      )}
    </RepliesMainContainer>
  );
};

export default Replies;
