import React, { FC, useState, useEffect, useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { EDITOR_CONFIG, PLUGIN_NAMES_MAP } from 'app/tinymce';
import TinyMCEConfig from 'app/tinymce';
import config from 'app/config';
import { RichEditorSkeleton, EditorContainer } from '../styles';
import { emoticonsForEditor } from 'modules/common/const';

export const KEY_CODE_MAP = {
  KeyB: 'Bold',
  KeyI: 'Italic',
  KeyU: 'Underline',
  Digit1: 'Header 1',
  Digit2: 'Header 2',
  Digit3: 'Header 3',
  Digit4: 'Header 4',
  Digit5: 'Header 5',
  Digit6: 'Header 6',
  Digit7: 'Paragraph',
  Digit8: 'Div',
  Digit9: 'Address',
};

const getEditorConfig = ({ placeholder, hasMarkupPermission }: { placeholder: string; hasMarkupPermission: boolean }) => ({
  placeholder,
  menubar: false,
  paste_as_text: true,
  height: EDITOR_CONFIG.height,
  plugins: EDITOR_CONFIG.plugins.reduce<string[]>((allowedPluginList, pluginName) => {
    if (!hasMarkupPermission && (pluginName === PLUGIN_NAMES_MAP.link || pluginName === PLUGIN_NAMES_MAP.autolink)) {
      return allowedPluginList;
    }
    return allowedPluginList.concat(pluginName);
  }, []),
  toolbar: hasMarkupPermission ? EDITOR_CONFIG.toolbar : EDITOR_CONFIG.toolbarWithoutPermissions,
  content_style: EDITOR_CONFIG.styles,
  mentions_item_type: EDITOR_CONFIG.itemType,
  mentions_selector: EDITOR_CONFIG.selector,
  emoticons_append: emoticonsForEditor,
});

const tinyMCEIns = new TinyMCEConfig();

export interface IProps {
  onChange: (value: string, editor?: any) => void;
  value?: string;
  setStatusInitialMentions?: (isHave: boolean) => void;
  editorRef?: any;
  placeholder?: string;
  hasMarkupPermission: boolean;
}

const RichTextEditor: FC<IProps> = ({
  value,
  onChange,
  editorRef,
  hasMarkupPermission,
  setStatusInitialMentions,
  placeholder = '@ to tag a colleague.',
}) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [placeholder, hasMarkupPermission]);

  const onInitEditorHandler = useCallback(
    (_, editor: any) => {
      try {
        tinyMCEIns.init(editor);
        tinyMCEIns.registerAtMentions(null);
        if (setStatusInitialMentions) {
          setStatusInitialMentions(editor?.ui?.autocompleters?.mentions?.getUsers()?.length > 0);
        }

        if (!hasMarkupPermission) {
          // watches for 'keydown' event to deny formatting
          editor.on('keydown', function (event) {
            const { metaKey, altKey, ctrlKey, shiftKey, code } = event;
            const keyCodeToCheck = KEY_CODE_MAP[code];
            const isShortcutCombination =
              (keyCodeToCheck && metaKey) ||
              (keyCodeToCheck && altKey) ||
              (keyCodeToCheck && ctrlKey) ||
              (keyCodeToCheck && shiftKey && altKey) ||
              (keyCodeToCheck && shiftKey && ctrlKey);

            if (isShortcutCombination) {
              editor.execCommand('mceSetContent', false, tinyMCEIns.getPlainTextIncludingMentionTags());
            }
          });
        }
        setLoading(false);
      } catch {}
    },
    [hasMarkupPermission],
  );

  const MCEEditor = useCallback(
    ({ value, onChange }) => {
      return (
        <Editor
          value={value}
          ref={editorRef}
          id="post-editor"
          onEditorChange={onChange}
          onInit={onInitEditorHandler}
          apiKey={config.tinyMCEApiKey}
          init={getEditorConfig({ placeholder, hasMarkupPermission })}
        />
      );
    },
    [hasMarkupPermission, placeholder, onInitEditorHandler],
  );

  return (
    <EditorContainer>
      <RichEditorSkeleton active loading={isLoading} />
      <MCEEditor value={value} onChange={onChange} />
    </EditorContainer>
  );
};

export default RichTextEditor;
