import styled from 'styled-components';

import { ReactComponent as Search } from 'modules/common/assets/images/supportSearch/search.svg';

export const MainContainer = styled.div`
  width: 900px;
  padding: 14px 20px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const StyledInput = styled.input`
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  border: none;
  outline: none;
  width: 90%;
  height: 30px;

  ::placeholder {
    color: #6b778c;
  }
`;

export const StyledButton = styled.div`
  background: #1890ff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  color: #fff;
  user-select: none;
  cursor: pointer;
  width: 100px;
`;

export const StyledSearch = styled(Search)`
  margin-right: 10px;
`;
