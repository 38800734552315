import styled from 'styled-components';

export const ReactionContainer = styled.div`
  position: relative;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border: 3px solid #fff;
  padding: 0;
  top: -10px;
  :first-child {
    margin: 0;
  }
`;

export const AvatarContainer = styled.div``;
