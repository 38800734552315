import { gql } from '@apollo/client';

export const GET_USERS_CLUBS = gql`
  query usersClubs($clubCategory: ClubCatergories!, $offset: Int!, $limit: Int!, $filters: FilterCategoriesInput) {
    usersClubs(
      pagination: { offset: $offset, limit: $limit, usersPerLimit: 6 }
      input: { where: { clubCategory: $clubCategory }, filterCategories: $filters }
    ) {
      pagination {
        total
      }
      data {
        users {
          id
          fullName
          photoUrl
          job {
            positionTitle
          }
          workingAddress {
            id
            countryCode
            usageType
            municipality
          }
          isActive
          terminatedAt
        }
        total
        clubGroupData {
          groupName
          groupId
        }
      }
    }
  }
`;

export const GET_CLUB_VIEW_FILTERS = gql`
  query usersClubsFilter(
    $clubCategory: ClubCatergories!
    $filterCategories: FilterCategoriesInput
    $pagination: OffsetPaginationInput!
  ) {
    usersClubsFilter(
      pagination: $pagination
      input: { where: { clubCategory: $clubCategory }, filterCategories: $filterCategories }
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_USER_ORG_CHART = gql`
  query userOrgChart($userId: String, $organizationId: String) {
    userOrgChartMixed(userId: $userId, organizationId: $organizationId) {
      subject {
        user {
          id
          fullName
          photoUrl
          job {
            positionTitle
            addresses {
              id
              countryCode
              usageType
              municipality
            }
          }
          workingAddress {
            id
            countryCode
            usageType
            municipality
          }
          costCenter {
            name
          }
          isActive
          terminatedAt
          manager {
            id
          }
          directReportsCount
        }
        organization {
          id
          name
        }
      }
      reportsTo {
        user {
          id
          fullName
          photoUrl
          job {
            positionTitle
            addresses {
              id
              countryCode
              usageType
              municipality
            }
          }
          workingAddress {
            id
            countryCode
            usageType
            municipality
          }
          costCenter {
            name
          }
          isActive
          terminatedAt
          manager {
            id
          }
          directReportsCount
        }
        organization {
          id
          name
        }
      }
      supOrg {
        user {
          id
          fullName
          photoUrl
          job {
            positionTitle
            addresses {
              id
              countryCode
              usageType
              municipality
            }
          }
          workingAddress {
            id
            countryCode
            usageType
            municipality
          }
          costCenter {
            name
          }
          isActive
          terminatedAt
          manager {
            id
          }
          directReportsCount
        }
        organization {
          id
          name
        }
      }
      directReports {
        user {
          id
          fullName
          photoUrl
          job {
            positionTitle
            addresses {
              id
              countryCode
              usageType
              municipality
            }
          }
          workingAddress {
            id
            countryCode
            usageType
            municipality
          }
          costCenter {
            name
          }
          isActive
          terminatedAt
          manager {
            id
          }
          directReportsCount
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

export const GET_FOLLOWED_USERS = gql`
  query getMyFollowedAuthors {
    getMyFollowedAuthors(pagination: { limit: 10000 }) {
      data {
        status
        authorUser {
          id
          addresses {
            id
            countryCode
            usageType
            municipality
          }
        }
      }
    }
  }
`;

export const GET_ALL_USERS_BY_CLUB = gql`
  query usersByClubGroup($clubCategory: ClubCatergories!, $groupId: String!, $limit: Int!, $offset: Int!) {
    usersByClubGroup(
      pagination: { limit: $limit, offset: $offset }
      input: { where: { clubCategory: $clubCategory, groupId: $groupId } }
    ) {
      pagination {
        total
      }
      data {
        id
        fullName
        photoUrl
        job {
          positionTitle
        }
        addresses {
          id
          countryCode
          usageType
          municipality
        }
      }
    }
  }
`;

export const GET_FILTERS = gql`
  query getClubViewInterestsFilters($textFilter: String, $appliedFilters: ClubViewAppliedFilters) {
    getClubViewInterestsFilters(pagination: { limit: 4, offset: 0 }, textFilter: $textFilter, appliedFilters: $appliedFilters) {
      data {
        name
        id
      }
    }
    getClubViewSkillsFilters(pagination: { limit: 4, offset: 0 }, textFilter: $textFilter, appliedFilters: $appliedFilters) {
      data {
        name
        id
      }
    }
    getClubViewCitiesFilters(pagination: { limit: 4, offset: 0 }, textFilter: $textFilter, appliedFilters: $appliedFilters) {
      data {
        city
      }
    }
    getClubViewJobTitleFilters(pagination: { limit: 4, offset: 0 }, textFilter: $textFilter, appliedFilters: $appliedFilters) {
      data {
        title
      }
    }
    getClubViewCostCentersFilters(pagination: { limit: 4, offset: 0 }, textFilter: $textFilter, appliedFilters: $appliedFilters) {
      data {
        name
        id
      }
    }
  }
`;

export const GET_DEFAULT_CLUB_VIEW = gql`
  query getDefaultClubViewV2 {
    getDefaultClubViewV2(pagination: { limit: 6, offset: 0 }) {
      data {
        appliedFilters {
          interestFilters {
            name
            id
          }
          skillFilters {
            name
            id
          }
          adressFilter {
            city
          }
          jobFilter {
            title
          }
          costCenterFilter {
            id
            name
          }
        }
        data {
          data {
            id
            fullName
            photoUrl
            job {
              positionTitle
            }
            workingAddress {
              id
              countryCode
              usageType
              municipality
            }
            isActive
            terminatedAt
          }
        }
      }
    }
  }
`;

export const GET_CLUB_VIEW_USERS = gql`
  query getClubViewUsers($appliedFilters: ClubViewAppliedFilters, $limit: Int!, $offset: Int!) {
    getClubViewUsers(pagination: { limit: $limit, offset: $offset }, appliedFilters: $appliedFilters) {
      data {
        id
        fullName
        photoUrl
        job {
          positionTitle
        }
        workingAddress {
          countryCode
          usageType
          municipality
        }
        isActive
        terminatedAt
      }
      pagination {
        total
        limit
        offset
      }
    }
  }
`;

export const GET_COUNT_CLUB_VIEW_USERS = gql`
  query countClubViewUsers($appliedFilters: ClubViewAppliedFilters) {
    countClubViewUsers(appliedFilters: $appliedFilters)
  }
`;
