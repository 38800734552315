import styled from 'styled-components';
import { Skeleton } from 'antd';

import { ReactComponent as PlusFilter } from 'modules/team/assets/plusFilter.svg';
import { ReactComponent as CloseFilter } from 'modules/team/assets/closeFilter.svg';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    color: #111111;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    padding-bottom: 30px;
  }

  .rcs-inner-handle {
    background-color: #e4e4e4;
  }
  .rcs-custom-scrollbar {
    width: 4px;
  }

  @media (max-height: 700px) {
    height: 350px;
  }
`;

export const FilterGroups = styled.div`
  padding: 10px;
  /* max-height: 700px;
  overflow: hidden; */
`;

export const FilterGroup = styled.div`
  margin-top: 20px;
  h2 {
    color: #111111;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
  margin-top: -3px;
`;

interface IChangedFilter {
  isChanged?: boolean;
}

export const Filter = styled.div<IChangedFilter>`
  position: relative;
  background: #fafafa;
  border-radius: 20px;
  padding: 4px 12px;
  user-select: none;
  margin-right: 5px !important;
  margin-top: 5px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 580px;
  overflow: hidden;
  border: 1px solid transparent;
  height: 34px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }

  svg {
    cursor: pointer;
  }

  ${({ isChanged }) =>
    isChanged &&
    `
    border: 1px solid #FF8600;
    background: #FFF3E6;
    color: #FF8600;
  `};
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 450px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyListContainer = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #6b778c;
    margin: 0;
    padding: 0;
  }

  p {
    color: #6b778c;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }

  @media (max-height: 700px) {
    height: 350px;
  }
`;

export const StyledPlusFilter = styled(PlusFilter)`
  margin-left: 14px;
`;

export const StyledCloseFilter = styled(CloseFilter)`
  margin-left: 11px;
  box-sizing: content-box;
  transition: 0.3s;
  padding: 3px;
  &:hover > path {
    fill: red;
  }
`;

export const SearchResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ViewButton = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`;

export const MainFilters = styled.div``;

export const FilterSkeleton = styled(Skeleton.Button)`
  height: 24px !important;
  border-radius: 20px !important;
  margin-right: 5px !important;
`;
