import jwtDecode from 'jwt-decode';

interface IDecode {
  exp: string;
}

export const getTokenExpirationTime = (jwt: string): number | null => {
  if (!jwt) {
    return null;
  }
  // @ts-ignore
  const jwtDecoded = jwtDecode<IDecode>(jwt);
  // @ts-ignore
  const jwtExpiresInSeconds = jwtDecoded.exp;
  // @ts-ignore
  const jwtExpiresInMs = jwtExpiresInSeconds * 1000;

  return jwtExpiresInMs;
};

export const getTokenLifetimeLeft = (expirationMs: number): number => {
  return expirationMs - Date.now();
};
