import { combineReducers } from 'redux';
import auth from './domains/auth/auth.reducer';
import user from './domains/user/user.reducer';

const rootReducer = combineReducers({
  auth,
  user,
});

// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
