import React, { FC } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Tooltip, Image } from 'antd';

import {
  CardsMainContainer,
  UserCardContainer,
  UserCardAvatar,
  NameContainer,
  StyledActiveIcon,
  StyledInactiveIcon,
  StyledTerminatedIcon,
  UserCardButton,
  CardsContentContainer,
  SubmitArrow,
  PostsMainContainer,
  PostCardContainer,
  PostCardDescription,
  PostAvatar,
  PostCardContent,
  UserPostInfo,
  UserContainer,
  EmptyPostImage,
  UserInfoContainer,
  StyledPostDot,
  PostType,
  PostCardButton,
  PostCardHeadline,
  PostImage,
  StyledImage,
  TargetLinks,
  TargetLink,
  SpinnerMainContainer,
} from '../styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import emptyPost from 'modules/common/assets/images/globalSearch/emptyPost.svg';
import { postTypes } from 'modules/news-feed/pages/Feed/constants';
import { SEARCH_TYPES } from '../GlobalSearch';
import { richTextToPlain } from 'utils/parsers';
import KnowledgeBaseList from './KnowledgeBaseList';
import { emitSearchClick } from 'utils/mixpanel';
import { POST_TYPES } from 'modules/common/const';
import appRoutes from 'app/routes';
import { IBadge } from '../GlobalSearch';
import Spinner from 'modules/common/components/Spinner';

export interface IPagination {
  total: number;
}
interface IProps {
  data: any;
  dataType: string;
  previewModalOpen: (id: string) => void;
  currentUserId: string;
  badgesData: IBadge[];
  postsLoaderRef: any;
  usersLoaderRef: any;
  totalPosts?: number;
  totalUsers?: number;
}

const composeTargetEmployeesText = (
  post: any,
  idOfCurrentUser: string,
  pushHistory: (path: string) => void,
  badgesData: IBadge[],
) => {
  const { post_type, targeted_users, badge: badgeId } = post;
  let parsedTargetEmployees: any = [];
  if (targeted_users?.length) {
    const targetEmployeesClone = [...targeted_users];
    const index = targetEmployeesClone.findIndex((elem) => elem.user_id === idOfCurrentUser);
    if (index !== -1) {
      const [deletedElement] = targetEmployeesClone.splice(index, 1);
      targetEmployeesClone.unshift(deletedElement);
    }
    parsedTargetEmployees = targetEmployeesClone;
  }

  if (!parsedTargetEmployees?.length) {
    return '';
  }

  const isRecognitionType = post_type === POST_TYPES.RECOGNITION;
  const badge = badgesData.find((el) => el._id === badgeId);
  const verb = isRecognitionType ? `recognized for ${badge?.name}.` : 'thanked: ';
  return (
    <TargetLinks>
      {parsedTargetEmployees.map((el, i) => {
        const isLastTarget = i === parsedTargetEmployees.length - 1;
        return (
          <>
            {i > 0 && `${isLastTarget ? ' and' : ','} `}
            <TargetLink onClick={() => pushHistory(`${appRoutes.profile}/${el.user_id}`)}>{el.full_name}</TargetLink>
          </>
        );
      })}
      <span>{parsedTargetEmployees.length > 1 ? ` were ${verb}` : ` was ${verb}`}</span>
    </TargetLinks>
  );
};

const CardsContainer: FC<IProps> = ({
  dataType,
  data,
  previewModalOpen,
  currentUserId,
  badgesData,
  postsLoaderRef,
  usersLoaderRef,
  totalPosts,
  totalUsers,
}) => {
  const history = useHistory();
  const symbolsToSplit = 200;

  if (!data) {
    return null;
  }

  if (dataType === SEARCH_TYPES.ARTICLE) {
    return <KnowledgeBaseList data={data} />;
  }

  if (dataType === SEARCH_TYPES.POST) {
    return (
      <CardsContentContainer>
        <PostsMainContainer>
          {data.map((elem) => {
            const { post_id, files, publishing_date, text, headline, author, post_type } = elem;
            const { full_name, photo_url } = author;
            const [firstImage] = files?.map(item => item.previewImageUrl || item.fileUrl) || [];
            const postTypeLabel = postTypes.find((el) => el.value === post_type)?.label || '';
            const parsedText = richTextToPlain(text);
            const isThanksType = post_type === POST_TYPES.THANKS;
            const isRecognitionType = post_type === POST_TYPES.RECOGNITION;
            const additionalText =
              isThanksType || isRecognitionType
                ? composeTargetEmployeesText(elem, currentUserId, (path: string) => history.push(path), badgesData)
                : '';
            const mainText = parsedText.length >= symbolsToSplit ? `${parsedText.substring(0, symbolsToSplit)}...` : parsedText;
            return (
              <PostCardContainer key={post_id}>
                {firstImage ? (
                  <PostImage>
                    <Image src={firstImage} fallback={emptyPost} />
                  </PostImage>
                ) : (
                  <EmptyPostImage>
                    <img src={emptyPost} />
                  </EmptyPostImage>
                )}
                <PostCardContent>
                  <UserContainer>
                    <PostAvatar src={<Image preview={false} src={photo_url || emptyAvatar} fallback={emptyAvatar} />} />
                    <UserPostInfo>
                      <UserInfoContainer>
                        <h3>
                          {full_name}
                          <StyledPostDot />
                          <PostType>{postTypeLabel}</PostType>
                        </h3>
                        <p>{moment(publishing_date).format('MMMM DD, YYYY')}</p>
                      </UserInfoContainer>
                      <PostCardButton
                        onClick={() => {
                          previewModalOpen(post_id);
                          emitSearchClick({ searchType: 'post' });
                        }}
                      >
                        View Post
                        <SubmitArrow />
                      </PostCardButton>
                    </UserPostInfo>
                  </UserContainer>
                  {headline && <PostCardHeadline>{headline}</PostCardHeadline>}
                  <PostCardDescription>
                    {additionalText}
                    {mainText}
                  </PostCardDescription>
                </PostCardContent>
              </PostCardContainer>
            );
          })}
        </PostsMainContainer>
        <SpinnerMainContainer ref={postsLoaderRef} isLoadedAllData={!totalPosts || totalPosts === data.length}>
          <Spinner width={30} height={30} />
        </SpinnerMainContainer>
      </CardsContentContainer>
    );
  }

  return (
    <CardsContentContainer>
      <CardsMainContainer>
        {data.map((elem) => {
          const { cost_center, photo_url, terminated_at, job_title, is_active, user_id, full_name: preferredName } = elem;

          return (
            <UserCardContainer key={user_id}>
              <UserCardAvatar src={<StyledImage preview={false} src={photo_url || emptyAvatar} fallback={emptyAvatar} />} />
              <NameContainer>
                {preferredName?.length >= 15 ? (
                  <Tooltip title={<span>{preferredName}</span>} overlayClassName={'clubViewTooltip'}>
                    <h3>{preferredName}</h3>
                  </Tooltip>
                ) : (
                  <h3>{preferredName}</h3>
                )}
                {terminated_at ? <StyledTerminatedIcon /> : is_active ? <StyledActiveIcon /> : <StyledInactiveIcon />}
              </NameContainer>
              {cost_center?.length >= 21 ? (
                <Tooltip title={<span>{cost_center}</span>} overlayClassName={'clubViewTooltip'}>
                  <p>{cost_center}</p>
                </Tooltip>
              ) : (
                <p>{cost_center}</p>
              )}
              {job_title?.length >= 21 ? (
                <Tooltip title={<span>{job_title}</span>} overlayClassName={'clubViewTooltip'}>
                  <p>{job_title}</p>
                </Tooltip>
              ) : (
                <p>{job_title}</p>
              )}
              <UserCardButton
                onClick={() => {
                  history.push(`/profile/${user_id}`);
                  emitSearchClick({ searchType: 'people' });
                }}
              >
                View Profile
                <SubmitArrow />
              </UserCardButton>
            </UserCardContainer>
          );
        })}
      </CardsMainContainer>
      <SpinnerMainContainer ref={usersLoaderRef} isLoadedAllData={!totalUsers || totalUsers === data.length}>
        <Spinner width={30} height={30} />
      </SpinnerMainContainer>
    </CardsContentContainer>
  );
};

export default CardsContainer;
