import React from 'react';
import { Switch, Route } from 'react-router-dom';
import appRoutes from 'app/routes';
import Header from '../Header';

const HeaderRouter = () => {
  return (
    <Switch>
      <Route
        path={[
          appRoutes.profile,
          appRoutes.newsFeed,
          appRoutes.team,
          appRoutes.careers,
          appRoutes.knowledgeBase,
          appRoutes.support,
          appRoutes.error,
        ]}
      >
        <Header />
      </Route>
      <Route exact path={appRoutes.home}>
        <Header />
      </Route>
    </Switch>
  );
};

export default HeaderRouter;
