import React, { FC } from 'react';

import { StyledSearchContainer } from '../../../styles';
import { IUserData, IPost, IArticleData, IBadge } from '../GlobalSearch';
import MoreContainer from './MoreContainer';

interface IProps {
  userData?: IUserData[];
  postData?: IPost[];
  articlesData?: IArticleData[];
  closeSearch: () => void;
  searchValue: string;
  openPost: (id: string) => void;
  currentUserId: string;
  badgesData: IBadge[];
}

const SearchPopover: FC<IProps> = ({ userData, postData, closeSearch, searchValue, openPost, articlesData, currentUserId, badgesData }) => {
  return (
    <StyledSearchContainer>
      <MoreContainer
        currentUserId={currentUserId}
        openPost={openPost}
        userData={userData}
        postData={postData}
        closeSearch={closeSearch}
        searchValue={searchValue}
        articlesData={articlesData}
        badgesData={badgesData}
      />
    </StyledSearchContainer>
  );
};

export default SearchPopover;
