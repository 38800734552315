import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import * as localStorage from 'utils/localStorage';
import { GET_USER_BY_ID } from 'modules/profile/gql/query';
import { IUserModel } from 'modules/common/gql/models/user.model';
import { fetchUserPermissions, userDataRetrieved } from 'store/domains/user/user.actions';
import { USER_PERMISSION_CHANGED } from 'modules/common/gql/subscriptions';
import { IAuthState } from 'store/domains/auth/auth.types';
import { storageKeys } from 'store/domains/auth/auth.constants';

interface IUserResult {
  user: IUserModel;
  onUserPermissionsChanged: {
    permissionIds: string[];
  };
}

const usePermissions = () => {
  const userOutputStored = localStorage.get(storageKeys.userOutput);
  const userOutputParsed = userOutputStored ? JSON.parse(userOutputStored) : {};
  const { id } = userOutputParsed;
  const dispatch = useDispatch();
  const { accessToken }: IAuthState = useSelector((state: any) => state.auth);

  const [getUser, { data, subscribeToMore, refetch }] = useLazyQuery<IUserResult>(GET_USER_BY_ID);

  useEffect(() => {
    if (!data && id) {
      getUser({
        variables: {
          id,
        },
      });
    }
    if (data) {
      dispatch(userDataRetrieved(data.user));
    }
  }, [data, id]);

  useEffect(() => {
    if (!data || !data.user.permissionIds) {
      return;
    }

    localStorage.set(
      'userOutput',
      JSON.stringify({
        ...userOutputParsed,
        permissionIds: data.user.permissionIds,
      }),
    );
    dispatch(fetchUserPermissions(data.user.permissionIds));
  }, [data]);

  useEffect(() => {
    if (!accessToken || accessToken.length <= 0 || !subscribeToMore) {
      return;
    }
    subscribeToMore({
      document: USER_PERMISSION_CHANGED,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data.onUserPermissionsChanged) {
          return prev;
        }
        const { permissionIds } = subscriptionData.data.onUserPermissionsChanged;

        return Object.assign({}, prev, {
          user: {
            permissionIds: permissionIds,
          },
        });
      },
    });
  }, [subscribeToMore, accessToken]);

  return [refetch];
};

export default usePermissions;
