import styled from 'styled-components';
import { Mentions, Avatar } from 'antd';

import { ReactComponent as Close } from 'modules/news-feed/pages/Feed/assets/mentions/close.svg';

export const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 35px;
`;

export const StyledMentions = styled(Mentions)`
  width: 100%;

  border: 1px solid #eff0f1 !important;
  outline: none !important;
  box-shadow: none;
  textarea {
    background: #ffffff;
    border-radius: 2px;
    padding: 11px 8px !important;
    ::placeholder {
      color: #6b778c !important;
    }
  }
`;

export const StyledOption = styled(Mentions.Option)``;

export const StyledElement = styled.div`
  display: flex;
  align-items: center;
  width: 320px !important;
  padding: 8px;

  p {
    margin: 0;
    padding: 0;
    color: #111111;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-right: 12px;
`;

export const MentionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Mention = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: rgba(236, 246, 255, 0.5);
  border-radius: 4px;
  margin-right: 3px;
  margin-top: 10px;
  user-select: none;

  p {
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    margin: 0 8px;
    padding: 0;
  }
`;

export const StyledClose = styled(Close)`
  cursor: pointer;
`;

export const InputPlaceholder = styled.div`
  color: #6b778c;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
`;
