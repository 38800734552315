import styled from 'styled-components';
import { Modal, Avatar, Progress } from 'antd';

import { ReactComponent as CloseIcon } from 'modules/profile/assets/images/achievements/close.svg';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    display: none;
  }
`;

export const ModalMainContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 16px;
`;

export const AvatarContainer = styled.div`
  border: 4px solid #ffffff;
  background: #ffffff;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -32px;
  left: calc(50% - 32px);
  box-sizing: border-box;
  padding: 0;
`;

export const StyledAvatar = styled(Avatar)`
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  color: #111111;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
  position: relative;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 0;
  top: calc(50% - 14px);
  cursor: pointer;
`;

export const ProgressMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const Percent = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const StyledProgress = styled(Progress)`
  margin-top: 4px;
`;

export const StatusElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const StatusElement = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    margin-left: 18px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CancelButton = styled.div`
  border: 1px solid #4b4b4b;
  border-radius: 4px;
  box-sizing: border-box;
  width: 256px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  color: #4b4b4b;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const ToProfileButton = styled(CancelButton)`
  background: #1890ff;
  border: none;
  color: #fff;
`;
