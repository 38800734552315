import React, { useState, Fragment, useMemo, FC } from 'react';
import { Row, Col, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';

import TagsContainer from './components/TagsContainer';
import { StyledTitle } from './styles';
import NewTagModal from './components/NewTagModal';
import { IUserModel } from 'modules/common/gql/models/user.model';
import { GET_ALL_SKILLS, GET_ALL_HOBBIES } from 'modules/profile/gql/query';
import { DETACH_SKILL, DETACH_HOBBY, ATTACH_INTERESTS, ATTACH_SKILL } from 'modules/profile/gql/mutation';
import { emitProfileUpdated } from 'utils/mixpanel';

type ModeType = 'interests' | 'skills' | null;

interface ITag {
  id: string;
  name: string;
  approved: boolean;
}

interface IProps {
  user: IUserModel;
  refetchUser: () => void;
  isOwner: boolean;
  isEditableProfile: boolean;
}

interface IAllSkillsResult {
  skills: {
    data: ITag[];
  };
}

interface IAllHobbiesResult {
  hobbies: {
    data: ITag[];
  };
}

export interface IOption {
  value: string;
  type: 'NEW' | 'EXISTING';
}

const interestsSortCallBack = ({ name: nameA }: ITag, { name: nameB }: ITag) => {
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

const Interests: FC<IProps> = ({ user, refetchUser, isOwner, isEditableProfile }) => {
  const { skills, hobbies } = user;
  const [changedValue, changeValue] = useState<string>('');
  const { data: allSkills, previousData: previousAllSkills } = useQuery<IAllSkillsResult>(GET_ALL_SKILLS, {
    variables: {
      name: changedValue,
    },
  });
  const { data: allHobbies, previousData: previousAllHobbies } = useQuery<IAllHobbiesResult>(GET_ALL_HOBBIES, {
    variables: {
      name: changedValue,
    },
  });

  const [detachSkill] = useMutation(DETACH_SKILL, { onCompleted: refetchUser, onError: (err) => message.error(err) });
  const [detachHobby] = useMutation(DETACH_HOBBY, { onCompleted: refetchUser, onError: (err) => message.error(err) });
  const [attachInterests] = useMutation(ATTACH_INTERESTS, {
    onError: () => message.error('Interests not attached'),
    onCompleted: refetchUser,
  });
  const [attachSkill] = useMutation(ATTACH_SKILL, {
    onError: () => message.error('Skill not attached'),
    onCompleted: refetchUser,
  });
  const [openedModal, setOpenedModal] = useState<ModeType>(null);

  const userSkills = useMemo(() => [...(skills ?? [])].sort(interestsSortCallBack), [skills]);

  const userHobbies = useMemo(() => [...(hobbies ?? [])].sort(interestsSortCallBack), [hobbies]);

  const filteredSkills = useMemo(() => {
    const skillsData = (allSkills ? allSkills.skills.data : previousAllSkills?.skills.data || []).filter(
      (skill) => !skills?.some((elem) => elem.id === skill.id),
    );

    return skillsData;
  }, [allSkills, skills, previousAllSkills]);

  const filteredHobbies = useMemo(() => {
    const hobbiesData = (allHobbies ? allHobbies.hobbies.data : previousAllHobbies?.hobbies.data || []).filter(
      (hobby) => !hobbies?.some((elem) => elem.id === hobby.id),
    );

    return hobbiesData;
  }, [allHobbies, hobbies, previousAllHobbies]);

  const onClose = () => {
    setOpenedModal(null);
  };

  const deleteHobbyHandler = (id: string) => {
    detachHobby({
      variables: {
        userId: user.id,
        hobbyId: id,
      },
    });
    emitProfileUpdated({ section: 'interests' });
  };

  const deleteSkillsHandler = (id: string) => {
    detachSkill({
      variables: {
        userId: user.id,
        skillId: id,
      },
    });
    emitProfileUpdated({ section: 'skills' });
  };

  const addHobbyHandler = (value: string[]) => {
    attachInterests({
      variables: {
        input: value.map((elem) => ({
          name: elem,
        })),
        userId: user.id,
      },
      refetchQueries: [{ query: GET_ALL_HOBBIES }],
    });
    emitProfileUpdated({ section: 'interests' });
    onClose();
  };

  const addSkillsHandler = (value: string[]) => {
    attachSkill({
      variables: {
        input: value.map((elem) => ({
          name: elem,
        })),
        userId: user.id,
      },
      refetchQueries: [{ query: GET_ALL_SKILLS }],
    });
    emitProfileUpdated({ section: 'skills' });
    onClose();
  };

  const setFilterValue = (value: string) => {
    changeValue(value);
  };

  return (
    <Fragment>
      {openedModal === 'interests' && (
        <NewTagModal
          key="add_new_interest_modal"
          title={'Add New Interest'}
          subTitle={'You can find existing interests from the database'}
          placeholder={'Interest (ex: Drawing)'}
          data={filteredHobbies}
          isOpen={openedModal === 'interests'}
          onClose={onClose}
          addHobbyHandler={addHobbyHandler}
          setFilterValue={setFilterValue}
          requestInterestMessage={'Can’t find the interest you’re trying to add?'}
          submitButtonText={'Request new Interest'}
          filterValue={changedValue}
          alreadyChangedValues={hobbies || []}
        />
      )}
      {openedModal === 'skills' && (
        <NewTagModal
          key="add_new_skill_modal"
          title={'Add New Skill'}
          subTitle={'You can find existing skills from the database'}
          placeholder={'Skill (ex: Data Analysis)'}
          data={filteredSkills}
          isOpen={openedModal === 'skills'}
          onClose={onClose}
          addHobbyHandler={addSkillsHandler}
          setFilterValue={setFilterValue}
          requestInterestMessage={'Can’t find the skill you’re trying to add?'}
          submitButtonText={'Request new Skill'}
          filterValue={changedValue}
          alreadyChangedValues={skills || []}
        />
      )}
      <Row>
        <Col span={24}>
          <StyledTitle strong>Interests and Skills</StyledTitle>
        </Col>
      </Row>
      <Row>
        <TagsContainer
          data={userHobbies.filter((elem) => elem.approved)}
          title={'Interests'}
          buttonText={'Add New Interest'}
          deleteHandler={deleteHobbyHandler}
          openModal={() => setOpenedModal('interests')}
          notApprovedMessage={'This Interest Isn’t In Our Library. It Will be Approved Soon'}
          isOwner={isOwner}
          isEditableProfile={isEditableProfile}
        />
        <TagsContainer
          title={'Skills'}
          data={userSkills.filter((elem) => elem.approved)}
          buttonText={'Add New Skill'}
          deleteHandler={deleteSkillsHandler}
          openModal={() => setOpenedModal('skills')}
          notApprovedMessage={'This Skill Isn’t In Our Library. It Will be Approved Soon'}
          isOwner={isOwner}
          isEditableProfile={isEditableProfile}
        />
      </Row>
    </Fragment>
  );
};

export default Interests;
