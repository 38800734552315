import styled, { css } from 'styled-components';
import { Typography, Avatar, Checkbox, Modal, Popover, Select, Input } from 'antd';

import { ReactComponent as ArrowSelector } from '../../assets/arrowSelector.svg';

interface IStyledClubViewProps {
  isOpenedSearch?: boolean;
}

export const StyledClubView = styled.div<IStyledClubViewProps>`
  background: #fff;
  width: 100%;
  overflow: auto;
  ${({ isOpenedSearch }) =>
    isOpenedSearch &&
    css`
      overflow: hidden;
    `}
`;

export const MainContainer = styled.div`
  padding: 20px 104px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  .rcs-inner-handle {
    background-color: #e4e4e4;
  }
  .rcs-custom-scrollbar {
    width: 4px;
  }
  @media (max-width: 992px) {
    padding: 20px 20px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  margin-top: 4px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled(Typography.Title)``;

export const ClubViewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  grid-column-gap: 36px;
  flex: 1;
  margin-bottom: 100px;
  @media (max-width: 850px) {
    grid-column-gap: 0;
  }
`;

export const HeaderClubViewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-top: 63px;
  grid-column-gap: 36px;
  margin-bottom: 19px;

  p {
    color: #1890ff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    word-wrap: break-word;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1450px) {
    grid-column-gap: 0;
  }
`;

export const ClubViewMainContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  @media (max-width: 850px) {
    margin-right: 16px;
  }
`;

export const UsersLoadingContainer = styled.div`
  width: 100%;
  flex: 1;
`;

export const StyledTooltipContainer = styled.div`
  p {
    cursor: pointer;
  }
`;

export const ClubViewElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #1890ff;
    padding: 0;
    margin: 0;
    word-wrap: break-word;
    max-width: 140px;
    text-align: center;
    span {
      color: #6b778c;
      font-weight: 400;
    }
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  cursor: pointer;
  overflow: hidden;
  /* :first-child {
    margin-top: 0;
  } */
`;

export const StyledAvatarContainer = styled.div`
  ${({ isChanged }: { isChanged?: boolean }) =>
    isChanged &&
    css`
      ${StyledAvatar} {
        border: 3px solid #1890ff;
      }
    `}
`;

export const AvatarContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  /* 
  :first-child {
    margin-top: 0;
  } */
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  border-top: 1px solid #eff0f1;
  height: 80px;
  box-sizing: border-box;
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    margin: 0;
  }
  @media (max-width: 1500px) {
    height: auto;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 16px 0;
  }
`;

export const CategoriesContainer = styled.div`
  margin-left: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1500px) {
    margin-left: 0;
    max-width: 700px;
  }
`;

export const ViewAllButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1890ff;
  cursor: pointer;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationElements = styled.div`
  display: flex;
  padding: 0 14px;
`;

export const PaginationButton = styled.div`
  border: 1px solid #eff0f1;
  border-radius: 6px;
  padding: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  fill: #1890ff;
  ${({ isBlocked }: { isBlocked?: boolean }) =>
    isBlocked &&
    css`
      fill: #6b778c;
    `}
`;

export const PaginationElement = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #111;
  padding: 0 3px;
  user-select: none;
  cursor: pointer;
  ${({ isChanged }: { isChanged?: boolean }) =>
    isChanged &&
    css`
      color: #1890ff;
    `}
`;

export const MainSubHeaderContainer = styled.div`
  padding-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SelectorContainer = styled.div`
  width: 150px;
  height: 32px;
  margin-right: 16px;
  margin-top: 8px;
  :first-child {
    margin-left: 0px;
  }
`;

export const StyledSelectorInput = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 14px;
  border: 1px solid #eff0f1;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 8px;
  cursor: pointer;
  p {
    line-height: 14px;
    color: #6b778c;
    margin: 0;
    padding: 0;
  }
`;

export const StyledArrow = styled(ArrowSelector)`
  position: absolute;
  right: 8px;
  top: calc(16px - 4px);
`;

export const OverlaySelector = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.21);
  border-radius: 4px;
  width: auto;
  box-sizing: border-box;
  padding: 8px 0;
`;

export const OptionsContainer = styled.div`
  max-height: 400px;
  overflow: auto;
`;

export const OverlayElement = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  transition: 0.4s;
  padding: 9px 13px;
  box-sizing: border-box;
  cursor: pointer;
  :hover {
    background: #ecf6ff;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  .ant-checkbox-inner {
    border: 2px solid #6b778c;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: transparent;
    border: 2px solid #1890ff;
    ::after {
      border: 2px solid #1890ff;
      border-top: 0;
      border-left: 0;
      width: 5px;
      height: 9px;
      left: 20%;
    }
  }
`;

export const StyledViewAllModal = styled(Modal)`
  .ant-modal-header {
    padding: 16px;
    border-bottom: none;
  }
  .ant-modal-title {
    color: #4b4b4b !important;
    font-weight: 600 !important;
    font-size: 16px !important;
  }
  .ant-modal-close {
    img {
      margin-top: -3px;
    }
  }
  .ant-modal-body {
    padding: 16px 0 16px 16px;
  }
  .ant-modal-content {
    border-radius: 4px;
  }
  .rcs-inner-handle {
    background-color: #e4e4e4;
  }
  .rcs-custom-scrollbar {
    width: 4px;
  }
  .rcs-inner-container {
    :first-child {
      div {
        margin-right: 0 !important;
      }
    }
  }
`;

export const SubHeaderContainer = styled.div`
  padding: 0 16px 0 0;
`;

export const EmptyDevelopersContainer = styled.div`
  height: 400px;
  width: 100%;
  padding: 15px 15px 15px 0;
`;

export const SubHeaderViewAllModal = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  padding: 0px 4px 10px 4px;
  border-bottom: 1px solid #e0e0e0;
`;

export const UsersAllModalContainer = styled.div`
  margin-top: 8px;
  overflow: hidden;
  padding-bottom: 60px;
`;

export const UserElement = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-top: 16px;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 16px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #eff0f1;
  height: 100%;
  box-sizing: border-box;
`;

export const UserNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    padding: 0;
    margin: 0;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    color: #6b778c;
    padding: 0;
    margin: 0;
  }
`;

export const FollowButton = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  ${({ isUnFollow }: { isUnFollow?: boolean }) =>
    isUnFollow &&
    css`
      color: #6b778c;
    `}
`;

export const StyledUserAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 16px;
  align-self: flex-start;
`;

export const StyledFollowPopover = styled(Popover)``;

export const FollowContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  font-size: 14px;
  line-height: 24px;
  color: #4b4b4b;
  box-shadow: 0px -3px 13px rgba(0, 0, 0, 0.08);
  background: #ffffff;
`;

export const FollowAllButton = styled.div`
  background: #1890ff;
  border-radius: 4px;
  width: 100px;
  padding: 4px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  max-height: 450px;
  overflow: auto;
`;

export const StyledSelect = styled(Select)`
  width: 150px;
  height: 32px;
`;

export const SearchContainer = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`;

export const Search = styled(Input.Search)`
  width: 100%;
  .ant-input-search-button {
    border: none;
    border-top: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }
  .ant-input {
    border: 1px solid #d9d9d9 !important;
    border-right: none !important;
    box-shadow: none;
  }
`;

export const EmptyClubViewMainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyClubViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 14px;
    line-height: 24px;
    color: #4b4b4b;
  }
`;

export const SearchFiltersInput = styled.input`
  padding: 10px 15px;
  border: 1px solid #eff0f1;
  border-radius: 2px;
  background: #ffffff;
  color: #6b778c;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  width: 100%;
`;

export const StyledSearchPopover = styled(Popover)``;

export const InputContainer = styled.div`
  max-width: 600px;
  width: 100%;
  position: relative;
`;

export const FiltersContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 0;
  position: relative;
`;

export const ClubViewUsersContainer = styled(ClubViewContainer)`
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, 57px);
  width: 1200px;
  grid-row-gap: 15px;
  margin-top: 28px;
  height: auto;
  border-bottom: none;

  @media (max-width: 1450px) {
    grid-template-columns: repeat(10, 1fr);
    width: 100%;
  }
  @media (max-width: 1150px) {
    grid-template-columns: repeat(9, 1fr);
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  max-width: 600px;
  position: absolute;
  bottom: -30px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
  margin-top: 20px;
  ${({ isLoadedAllData }: { isLoadedAllData?: boolean }) =>
    isLoadedAllData &&
    `
    height: 0;
  `};
`;

export const UsersContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: auto;
  max-height: 600px;
  @media (max-width: 1000px) {
    max-width: 95%;
  }
`;

export const MainClubViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1600px;
`;
