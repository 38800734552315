import { all } from 'redux-saga/effects';
import {
  authFlowWorker,
  watchLogout,
  watchLogin,
  watchRefreshFlow,
  watchImpersonalize,
  watchGetCookiesFlow,
  watchSuccessFetchCookies,
} from './domains/auth/auth.saga';
import { userFlowWorker } from './domains/user/user.saga';

const rootSaga = function* () {
  yield all([
    authFlowWorker(),
    watchLogin(),
    watchLogout(),
    watchRefreshFlow(),
    userFlowWorker(),
    watchImpersonalize(),
    watchGetCookiesFlow(),
    watchSuccessFetchCookies(),
  ]);
};

export default rootSaga;