import React, { useState, FC, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { message, Image } from 'antd';
import { v1 as randomId } from 'uuid';

import { MainContainer, StyledAvatar, RichEditorSkeleton } from './styles';
import { ADD_COMMENT, UPDATE_COMMENT } from 'modules/news-feed/gql/mutation';
import { EDITOR_CONFIG } from 'app/tinymce';
import TinyMCEConfig from 'app/tinymce';
import config from 'app/config';
import { Editor } from '@tinymce/tinymce-react';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { IComment } from 'modules/common/gql/models/newsFeedPost.model';
import { emitReplyComment, emitCommentPost, mapPostTypes } from 'utils/mixpanel';
import { KEY_CODE_MAP } from 'modules/common/components/forms/RichTextEditor/RichTextEditor';
import { emoticonsForEditor } from 'modules/common/const';

interface IProps {
  postId: string;
  userAvatar?: string;
  changedCommentId: string | null;
  replyCommentId: string | null;
  comments: IComment[];
  cancelCommentHandler: () => void;
  cancelReplyHandler: () => void;
  editorRef: any;
  postType: string;
}

const CommentsInput: FC<IProps> = ({
  postId,
  userAvatar,
  changedCommentId,
  comments,
  cancelCommentHandler,
  replyCommentId,
  cancelReplyHandler,
  editorRef,
  postType,
}) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<string>('');
  const [addComment] = useMutation(ADD_COMMENT, {
    onError: (err) => message.error(err),
  });
  const [updatedComment] = useMutation(UPDATE_COMMENT, {
    onError: (err) => message.error(err),
  });

  const editorMentions = editorRef?.current?.editor?.ui?.autocompleters?.mentions?.getUsers() || [];

  const tinyConfig = useMemo(() => new TinyMCEConfig(), []);

  const submitHandler = (e) => {
    const plainText = tinyConfig.getPlainText();

    if (e.keyCode === 13) {
      e.preventDefault();
      const commonVars = {
        text: value,
        plainText: plainText,
        mentions: editorMentions.map((elem) => ({
          userId: elem,
        })),
      };
      if (replyCommentId) {
        addComment({
          variables: {
            ...commonVars,
            postId,
            parentCommentId: replyCommentId,
          },
        });
        emitReplyComment();
      } else if (changedCommentId) {
        updatedComment({
          variables: {
            ...commonVars,
            commentId: changedCommentId,
          },
        });
      } else {
        addComment({
          variables: {
            ...commonVars,
            postId,
          },
        });
        emitCommentPost({ postType: mapPostTypes(postType) });
      }

      cancelCommentHandler();
      cancelReplyHandler();
      setValue('');
    }
  };

  useEffect(() => {
    if (!changedCommentId || !replyCommentId) {
      setValue('');
    }
  }, [changedCommentId, replyCommentId]);

  useEffect(() => {
    let allComments: IComment[] = [];
    if (!changedCommentId) {
      return;
    }

    comments.forEach((elem) => {
      allComments = [...allComments, ...elem.comments.data];
      allComments = [...allComments, elem];
    });

    const changedComment = allComments.find((elem) => elem._id === changedCommentId);

    if (changedComment) {
      setValue(changedComment.text);
    }
  }, [changedCommentId, comments]);

  const onEditor = (value: string) => {
    setValue(value);
  };

  return (
    <MainContainer isEmpty={tinyConfig?.getPlainText()?.length < 96} isLoading={isLoading}>
      {isLoading && <RichEditorSkeleton active />}
      <StyledAvatar size={'small'} icon={<Image preview={false} src={userAvatar || emptyAvatar} fallback={emptyAvatar} />} />
      <Editor
        ref={editorRef}
        id={`${postId}-${randomId()}`}
        apiKey={config.tinyMCEApiKey}
        onInit={(_, editor: any) => {
          tinyConfig.init(editor);
          tinyConfig.registerAtMentions(postId);

          editor.on('keydown', function (event) {
            const { metaKey, altKey, ctrlKey, shiftKey, code } = event;
            const keyCodeToCheck = KEY_CODE_MAP[code];
            const isShortcutCombination =
              (keyCodeToCheck && metaKey) ||
              (keyCodeToCheck && altKey) ||
              (keyCodeToCheck && ctrlKey) ||
              (keyCodeToCheck && shiftKey && altKey) ||
              (keyCodeToCheck && shiftKey && ctrlKey);

            if (isShortcutCombination) {
              editor.execCommand('mceSetContent', false, tinyConfig.getPlainTextIncludingMentionTags());
            }
          });
          setLoading(false);
        }}
        init={{
          height: 40,
          max_height: 120,
          branding: false,
          placeholder: 'Write a comment',
          menubar: false,
          plugins: ['paste', 'lists link', 'code', 'autoresize', 'emoticons'],
          toolbar: ['emoticons'],
          content_style: EDITOR_CONFIG.styles,
          mentions_item_type: EDITOR_CONFIG.itemType,
          mentions_selector: EDITOR_CONFIG.selector,
          paste_as_text: true,
          statusbar: false,
          autoresize_bottom_margin: 0,
          // auto_focus: true,
          // autoresize_overflow_padding: 0,
          // inline: true,
          emoticons_append: emoticonsForEditor,
        }}
        onEditorChange={onEditor}
        value={value}
        onKeyDown={(e) => submitHandler(e)}
      />
    </MainContainer>
  );
};

export default CommentsInput;
