export interface IReactions {
  [key: string]: string[];
}

export interface IReactionsResult {
  type: string;
  count: number;
}

export const getPostReactions = (reactions: IReactions) => {
  if (!reactions) {
    return [];
  }
  const currentReactionsTypes: string[] = Object.keys(reactions).filter((key) => key !== '__typename') || [];
  const resultedReactionsArray: IReactionsResult[] = [];
  const data = currentReactionsTypes.reduce((acc, currentKey) => {
    acc.push({ type: currentKey, count: reactions[currentKey]?.length });
    return acc;
  }, resultedReactionsArray);

  return data.filter((elem) => elem.count && elem.count > 0);
};
