import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IAuthState } from 'store/domains/auth/auth.types';
import { authRoutes } from '../../constants';

interface IPrivateRoute {
  component: any;
  path: any;
  exact?: boolean;
}

const PrivateRoute = ({ component: Component, ...rest }: IPrivateRoute) => {
  const { isAuthorized }: IAuthState = useSelector((state: any) => state.auth);
  return <Route {...rest} render={(props) => (isAuthorized ? <Component {...props} /> : <Redirect to={authRoutes.login} />)} />;
};

export default PrivateRoute;
