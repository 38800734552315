import { POST_TYPES } from 'modules/news-feed/pages/Feed/constants';

export enum NOTIFICATION_TYPES {
  SEEN = 'SEEN',
  NEW = 'NEW',
  READ = 'READ',
}

export enum NOTIFICATION_ENTITY_TYPES {
  POST_MENTIONED = 'POST_MENTIONED',
  FOLLOW_REQUEST = 'FOLLOW_REQUEST',
  FOLLOW_ACCEPTED = 'FOLLOW_ACCEPTED',
  POST_THANKS_TARGETED = 'POST_THANKS_TARGETED',
  POST_RECOGNITION_TARGETED = 'POST_RECOGNITION_TARGETED',
  POST_REACTED = 'POST_REACTED',
  POST_COMMENTED = 'POST_COMMENTED',
  POST_COMMENT_MENTIONED = 'POST_COMMENT_MENTIONED',
  POST_COMMENT_REPLIED = 'POST_COMMENT_REPLIED',
  POST_COMMENT_REACTED = 'POST_COMMENT_REACTED',
  POST_NEW_HIRES_TARGETED = 'POST_NEW_HIRES_TARGETED',
  POST_BIRTHDAY_WISHES_TARGETED = 'POST_BIRTHDAY_WISHES_TARGETED',
  POST_PROMOTION_TARGETED = 'POST_PROMOTION_TARGETED',
  POST_WORK_ANNIVERSARY_TARGETED = 'POST_WORK_ANNIVERSARY_TARGETED',
}

export const typesOfPosts = {
  [POST_TYPES.COMPANY_NEWS]: 'company news',
  [POST_TYPES.HR_BIRTHDAY_WISHES]: 'birthday wishes',
  [POST_TYPES.HR_NEW_HIRES]: 'new hires',
  [POST_TYPES.HR_PROMOTION]: 'promotion',
  [POST_TYPES.HR_WORK_ANNIVERSARY]: 'work aniversary',
  [POST_TYPES.INDIVIDUAL]: 'individual',
  [POST_TYPES.TEAM_NEWS]: 'team news',
  [POST_TYPES.THANKS]: 'thanks',
};
