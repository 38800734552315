import React, { FC, useMemo } from 'react';
import { Moment, utc } from 'moment';
import { StyledModal, Footer, CancelButton, AddButton } from './styles';
import Input from 'modules/common/components/forms/Input';
import Datepicker from 'modules/common/components/forms/Datepicker';

export interface IProps {
  onSubmit: () => void;
  onClose: () => void;
  onChangeTitle: (value: string) => void;
  onChangeDate: (value: Moment | string | null) => void;
  eventTitle?: string;
  eventDate: Moment | string | null;
  disabledDate: Moment | string | null;
  isOpen: boolean;
  isEdit: boolean;
}

const EventModal: FC<IProps> = ({
  isOpen,
  isEdit,
  onSubmit,
  onClose,
  eventTitle,
  eventDate,
  disabledDate,
  onChangeTitle,
  onChangeDate,
}) => {
  const isValid = useMemo(() => (eventTitle?.length || 0) <= 300 && !!eventDate, [eventTitle, eventDate]);
  const disabledBefore = (current) => {
    return current && current < utc(disabledDate).endOf('day');
  };

  return (
    <StyledModal
      title={`${isEdit ? 'Edit' : 'Add'} Personal Event`}
      visible={isOpen}
      maskClosable={false}
      footer={
        <Footer>
          <CancelButton type="link" onClick={onClose}>
            Cancel
          </CancelButton>
          <AddButton type="primary" onClick={onSubmit} disabled={!isValid}>
            {isEdit ? 'Edit Event' : 'Publish Event'}
          </AddButton>
        </Footer>
      }
      onCancel={onClose}
    >
      <Input label="Event" value={eventTitle} onChange={(e) => onChangeTitle(e.target.value)} placeholder="Event Name" />
      <Datepicker
        label="Date"
        value={eventDate}
        onChange={onChangeDate}
        allowClear={false}
        placeholder="Date"
        disabledDate={disabledBefore}
        format={'MMM DD, YYYY'}
      />
    </StyledModal>
  );
};

export default EventModal;
