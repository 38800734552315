import React, { useCallback } from 'react';
import { Tooltip } from 'antd';
import { ReactionsPopoverContainer, ReactionElement } from '../styles';
import { reactionsTypes } from 'modules/common/const';

export interface IProps {
  setReaction: (type: string) => void,
  closeReactionsDropdown: () => void,
}

const ReactionsPopover = ({ setReaction, closeReactionsDropdown }) => {
  return (
    <ReactionsPopoverContainer>
      {Object.entries(reactionsTypes).map((elem) => {
        const [key, value] = elem;
        const { image: ImageSVG, type, tooltipLabel } = value;
        const onClick = useCallback(() => {
          setReaction(type);
          closeReactionsDropdown();
        }, [type, closeReactionsDropdown]);

        return (
          <Tooltip
            placement={'top'}
            title={tooltipLabel}
            overlayClassName={'styledTooltip'}
          >
            <ReactionElement 
              onClick={onClick}
              key={key}
            >
              <ImageSVG />
            </ReactionElement>
          </Tooltip>
        );
      })}
    </ReactionsPopoverContainer>
  );
};

export default ReactionsPopover;
