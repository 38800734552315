import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { Image } from 'antd';

import {
  MainContainer,
  Avatar,
  InfoContainer,
  UserContainer,
  MessageContainer,
  MessageText,
  MainInfoContainer,
  PostTextContainer,
  ReadIndicator,
  MentiontText,
  UserText,
} from '../../component.styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { richTextToPlainWithMention } from 'utils/parsers';
import TargetPost from './components/TargetedPost';
import { emitNotificationsClick, mapPostTypes } from 'utils/mixpanel';
import {
  NOTIFICATION_TYPES,
  NOTIFICATION_ENTITY_TYPES,
  typesOfPosts
} from '../../constants';
import {
  IPostNotification,
  IBadge
} from '../../interfaces';

interface IProps {
  data: IPostNotification;
  changeNotify: (id: string) => void;
  badgesData: IBadge[];
}

const Mention: FC<IProps> = ({ data, changeNotify, badgesData }) => {
  const { createdAt, status, relatedEntity, user, id, relatedEntityType } = data;

  if (!relatedEntity) {
    return null;
  }

  const { photoUrl, fullName: preferredName = '' } = relatedEntity?.user || {};
  const { text = '', type = '' } = relatedEntity?.post || {};
  const { fullName: mentionedUserPreferredName = '' } = user || {};

  const parsedText = useMemo(() => richTextToPlainWithMention(mentionedUserPreferredName, text), [
    text,
    mentionedUserPreferredName,
  ]);

  return (
    <MainContainer
      onClick={() => {
        changeNotify(id);
        emitNotificationsClick({ postType: mapPostTypes(type) });
      }}>
      {(relatedEntityType === NOTIFICATION_ENTITY_TYPES.POST_THANKS_TARGETED ||
        relatedEntityType === NOTIFICATION_ENTITY_TYPES.POST_RECOGNITION_TARGETED) && (
        <TargetPost data={data} badgesData={badgesData} />
      )}
      {relatedEntityType === NOTIFICATION_ENTITY_TYPES.POST_MENTIONED && (
        <UserContainer>
          <Avatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
          <MainInfoContainer>
            <InfoContainer>
              <MessageContainer>
                <UserText>{preferredName}</UserText>
                <MessageText>mentioned you in the</MessageText>
                <MentiontText>{typesOfPosts[type]}</MentiontText>
              </MessageContainer>
              <MessageContainer>{moment(createdAt).format('MMM DD')}</MessageContainer>
            </InfoContainer>
            <PostTextContainer dangerouslySetInnerHTML={{ __html: parsedText }} />
          </MainInfoContainer>
        </UserContainer>
      )}
      {(status === NOTIFICATION_TYPES.SEEN || status === NOTIFICATION_TYPES.NEW) && <ReadIndicator />}
    </MainContainer>
  );
};

export default Mention;
