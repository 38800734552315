import React from 'react';
import { Switch } from 'react-router-dom';
import appRoutes from 'app/routes';
import { joinPath } from 'utils/path';

import PrivateRoute from 'modules/auth/components/PrivateRoute';
import Support from '../pages/Support';

export const profileRoutes = {
  support: joinPath(appRoutes.support, ''),
};

const SupportRouter = () => (
  <Switch>
    <PrivateRoute exact path={profileRoutes.support} component={Support} />
  </Switch>
);

export default SupportRouter;
