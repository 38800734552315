import React, { Fragment, FC } from 'react';
import { Col, Typography, Space } from 'antd';

import { StyledTitle, StyledHide, TitleContainer, HideButton, StyledVisible } from './styles';
const { Text } = Typography;

interface IProps {
  isOwner: boolean;
  toggleBirthday: () => void;
  birthDateHidden: boolean;
  birthDateParsed: string;
  birthDate: string | null;
}

const BirthDay: FC<IProps> = ({ birthDateHidden, birthDateParsed, isOwner, toggleBirthday, birthDate }) => {
  if (!birthDate || (!isOwner && birthDateHidden)) {
    return null;
  }

  return (
    <Col span={6}>
      <Space direction="vertical">
        <TitleContainer>
          <StyledTitle disabled>Birthday</StyledTitle>
          {isOwner && (
            <HideButton onClick={toggleBirthday}>
              {!birthDateHidden ? (
                <Fragment>
                  <StyledVisible />
                  Hide
                </Fragment>
              ) : (
                <Fragment>
                  <StyledHide />
                  Show
                </Fragment>
              )}
            </HideButton>
          )}
        </TitleContainer>
        <Text>{birthDateParsed}</Text>
      </Space>
    </Col>
  );
};

export default BirthDay;
