import { cancel, cancelled, call, fork, put, select, take, delay } from 'redux-saga/effects';
import moment from 'moment-timezone';
import apolloClient from 'app/apolloClient';
import { LOGIN_MUTATION, REFRESH_AUTH_TOKEN_MUTATION, LOGIN_BY_EMAIL_MUTATION } from 'modules/auth/gql/mutation';
import { GET_CDN_COOKIE } from 'modules/auth/gql/query';
import appRoutes from 'app/routes';
import userManager from 'app/oidc';
import { authRoutes } from 'modules/auth/constants';
import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESSFUL,
  ONELOGIN_TOKEN_RETRIEVE_FAILURE,
  ONELOGIN_TOKEN_RETRIEVE_SUCCESS,
  REFRESH_AUTH_TOKEN_FAILURE,
  IMPERSONALIZE_BY_EMAIL,
  COOKIES_RETRIEVE_REQUEST,
  COOKIES_RETRIEVE_SUCCESS,
} from './auth.actionTypes';
import {
  loginSuccessful,
  logoutSuccessful,
  loginFailure,
  loginCancelled,
  logoutFailure,
  refreshTokenFailure,
  refreshTokenSuccess,
  cookiesFailure,
  cookiesSuccess,
  cookiesRequest,
} from './auth.actions';
import { userDataRetrieved } from '../user/user.actions';
import {
  ILoginSuccessfulResponse,
  ILoginByEmailSuccessfulResponse,
  ILoginSuccessful,
  IAuthState,
  IRefreshTokenSuccessfulResponse,
  IGetCdnCookieResponse,
} from './auth.types';
import { getTokenLifetimeLeft } from './auth.helper';
import { GET_USER_BY_ID } from 'modules/profile/gql/query';
import { UPDATE_LOCAL_TIME_ZONE } from 'modules/profile/gql/mutation';
import { setCookies } from 'utils/cookies';
import store from '../../store';

const loginGQL = (oneLoginToken: string) =>
  apolloClient.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      accessToken: oneLoginToken,
    },
  });

const impersonalizeGQL = (email: string) =>
  apolloClient.mutate({
    mutation: LOGIN_BY_EMAIL_MUTATION,
    variables: { email },
  });

const refreshTokenGQL = (refreshToken: string) =>
  apolloClient.mutate({
    mutation: REFRESH_AUTH_TOKEN_MUTATION,
    variables: {
      refreshToken,
    },
  });

const getCookies = () => apolloClient.query({ query: GET_CDN_COOKIE });

const setLocalTimeZone = (userId: string, timeZone: string) => {
  apolloClient.mutate({
    mutation: UPDATE_LOCAL_TIME_ZONE,
    variables: {
      id: userId,
      timeZone,
    },
  });
};

const getUser = (id: string) => {
  return id
    ? apolloClient.query({
        query: GET_USER_BY_ID,
        variables: {
          id,
        },
      })
    : null;
};

const oidcOneLoginAuthWorker = async () => {
  try {
    await userManager.signinRedirect();
    console.log('User successfully retrieved');
  } catch (err) {
    console.log('User retrieve failure due to error: ', err.message);
    throw err;
  }
};

const authorize = function* (oneLoginToken: string, history: any) {
  try {
    const defaultRoute = store.getState().auth.defaultRoute;
    const tokenResponse: ILoginSuccessfulResponse = yield call(loginGQL, oneLoginToken);
    const tokenOutput: ILoginSuccessful = tokenResponse?.data?.loginSso;
    const userId = tokenOutput.user?.id;

    yield put(loginSuccessful(tokenOutput));

    const userData = yield call(getUser, userId || '');
    const userRetrieved = userData?.data?.user;

    if (userRetrieved) {
      yield put(userDataRetrieved(userRetrieved));
    }

    if (!userRetrieved?.timeZone) {
      const autoDetectedZone = moment.tz.guess(true);

      if (autoDetectedZone && userId) {
        yield call(setLocalTimeZone, userId, autoDetectedZone);
      }
    }

    history.push(defaultRoute || appRoutes.newsFeed);
  } catch (error) {
    yield put(loginFailure(error));
  } finally {
    if (yield cancelled()) {
      yield put(loginCancelled());
    }
  }
};

const impersonalize = function* (email: string, history: any) {
  try {
    const defaultRoute = store.getState().auth.defaultRoute;
    const tokenResponse: ILoginByEmailSuccessfulResponse = yield call(impersonalizeGQL, email);
    const tokenOutput: ILoginSuccessful = tokenResponse?.data?.loginByEmail;
    const userId = tokenOutput.user?.id;

    yield put(loginSuccessful(tokenOutput));

    const userData = yield call(getUser, userId || '');
    const userRetrieved = userData?.data?.user;

    if (userRetrieved) {
      yield put(userDataRetrieved(userRetrieved));
    }

    if (!userRetrieved?.timeZone) {
      const autoDetectedZone = moment.tz.guess(true);

      if (autoDetectedZone && userId) {
        yield call(setLocalTimeZone, userId, autoDetectedZone);
      }
    }

    history.push(defaultRoute || appRoutes.newsFeed);
  } catch (error) {
    yield put(loginFailure(error));
  } finally {
    if (yield cancelled()) {
      yield put(loginCancelled());
    }
  }
};

const logoutWorker = function* (history: any) {
  try {
    history.push(authRoutes.login);
    yield put(logoutSuccessful());
  } catch (err) {
    yield put(logoutFailure(err));
  }
};

export const watchLogout = function* () {
  while (true) {
    const { history } = yield take(LOGOUT_REQUEST);
    yield fork(logoutWorker, history);
  }
};

export const watchLogin = function* () {
  while (true) {
    yield take(LOGIN_REQUEST);
    yield call(oidcOneLoginAuthWorker);
  }
};

export const authFlowWorker = function* () {
  while (true) {
    const { token, history } = yield take(ONELOGIN_TOKEN_RETRIEVE_SUCCESS);
    const task = yield fork(authorize, token, history);
    yield take([LOGOUT_SUCCESSFUL, LOGIN_FAILURE, ONELOGIN_TOKEN_RETRIEVE_FAILURE]);
    yield cancel(task);
  }
};

export const watchImpersonalize = function* () {
  while (true) {
    const { email, history } = yield take(IMPERSONALIZE_BY_EMAIL);
    const task = yield fork(impersonalize, email, history);
    yield take([LOGOUT_SUCCESSFUL, LOGIN_FAILURE]);
    yield cancel(task);
  }
};

const refreshAuthToken = function* (refreshToken: string) {
  try {
    const tokenResponse: IRefreshTokenSuccessfulResponse = yield call(refreshTokenGQL, refreshToken);
    const tokenOutput: ILoginSuccessful = tokenResponse?.data?.refreshAuthToken;
    yield put(refreshTokenSuccess(tokenOutput));
  } catch (error) {
    yield put(refreshTokenFailure(error));
  }
};

export const refreshFlowWorker = function* () {
  const authSate: IAuthState = yield select((state) => state.auth);
  const { accessExpires, refreshExpires, refreshToken } = authSate;
  // if refresh token expires - logout user
  if (!refreshExpires || getTokenLifetimeLeft(refreshExpires) <= 0) {
    yield put(logoutSuccessful());
  }
  // if access token expires - trying to relogin user with refresh token
  const REFRESH_CHECK_DELAY = 1000 * 60 * 10;
  if (refreshToken && accessExpires && getTokenLifetimeLeft(accessExpires) <= REFRESH_CHECK_DELAY) {
    const task = yield fork(refreshAuthToken, refreshToken);
    yield take([REFRESH_AUTH_TOKEN_FAILURE]);
    yield cancel(task);
  }
};

export const watchRefreshFlow = function* () {
  while (true) {
    yield fork(refreshFlowWorker);
    // rerun check for token refresh every 5 minutes
    yield delay(1000 * 60 * 5);
  }
};

export const getCookiesWorker = function* () {
  try {
    const getCookiesRequest: IGetCdnCookieResponse = yield call(getCookies);
    const { cookies, domain, expires: expRes } = getCookiesRequest.data.getCdnCookie;
    const expires = new Date(expRes * 1000);
    if (!cookies || !domain) {
      yield put(cookiesFailure());
    }

    setCookies(cookies);
    yield put(cookiesSuccess(expires));
  } catch (e) {
    yield put(cookiesFailure(e));
  }
};

export const watchGetCookiesFlow = function* () {
  while (true) {
    yield take(COOKIES_RETRIEVE_REQUEST);
    yield fork(getCookiesWorker);
  }
};

export const watchSuccessFetchCookies = function* () {
  while (true) {
    const { expires } = yield take(COOKIES_RETRIEVE_SUCCESS);
    const delayTime: number = +new Date(expires) - Date.now();
    yield delay(delayTime);
    yield put(cookiesRequest());
  }
};
