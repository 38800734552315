import React, { FC, useRef, useState, useEffect, useMemo } from 'react';

import {
  StyledSlider,
  StyledSlide,
  OrgChartContainer,
  EmptySlide,
  Slides,
  CardContainer,
  MainSubOrgContainer,
  UserCardContainer,
  SubOrgSliderContainer,
  SubOrgSubjectContainer,
} from '../styles';
import { IUser, IOrganization, IEntityData } from '../OrgChart';
import OrgChartCard from './OrgChartCard';
import leftArrow from '../../../assets/arrowLeft.png';
import rightArrow from '../../../assets/arrowRight.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IFollowedAuthorsResult } from '../OrgChart';

interface IProps {
  data: IEntityData[];
  changeUserHandler: (id: string) => void;
  toProfileHandler: (id: string) => void;
  followPopoverData: {
    userId: string | null;
    cardId: string | null;
  };
  changeFollowPopoverData: (id: string | null, cardId: string | null) => void;
  followedUsers: IFollowedAuthorsResult;
  followHandler: (id: string) => void;
  unfollowHandler: (id: string) => void;
  user?: IUser;
  organization?: IOrganization;
  changeOrganizationHandler: (id: string) => void;
  reportsToData: IEntityData;
}

type DataElement = IEntityData | null;

const NextButton = ({ className, onClick, style }: any) => (
  <img className={className} onClick={onClick} style={style} src={rightArrow} />
);

const PrevButton = ({ className, onClick, style }: any) => (
  <img className={className} onClick={onClick} style={style} src={leftArrow} />
);

const Slider: FC<IProps> = ({
  data,
  changeUserHandler,
  toProfileHandler,
  followPopoverData,
  changeFollowPopoverData,
  followedUsers,
  followHandler,
  unfollowHandler,
  user,
  organization,
  changeOrganizationHandler,
  reportsToData,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [filteredData, setFilteredData] = useState<DataElement[]>([]);
  const sliderRef = useRef<HTMLDivElement>(null);
  const slider = useRef<any>(null);
  const slidesToShow = useMemo(() => (data.length < 5 ? 3 : 5), [data]);
  const settings = {
    dots: false,
    speed: 500,
    infinite: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    swipe: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: data.length < 5 ? 3 : 5,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: data.length < 3 ? data.length : 3,
        },
      },
    ],
  };

  const resizeHandler = () => setScreenWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    const cloneOfData: DataElement[] = [...data];
    if (screenWidth <= 1400) {
      cloneOfData.splice(1, 0, null);
    } else {
      if (data.length < 5) {
        cloneOfData.splice(1, 0, null);
      } else {
        cloneOfData.splice(2, 0, null);
      }
    }

    setFilteredData(() => cloneOfData);
  }, [data, screenWidth]);

  const shiftSlideHandler = (oldIndex: number, newIndex: number) => {
    const cloneOfData: DataElement[] = [...filteredData];
    const indexOfElement = cloneOfData.findIndex((elem) => !elem);
    cloneOfData.splice(indexOfElement, 1);
    if (newIndex > oldIndex) {
      cloneOfData.splice(indexOfElement + 1, 0, null);
    } else {
      cloneOfData.splice(indexOfElement - 1, 0, null);
    }
    setFilteredData(cloneOfData);
  };

  const goToManager = () => {
    if (reportsToData.user?.id) {
      changeUserHandler(reportsToData.user.id);
      return;
    }
    changeOrganizationHandler(reportsToData.organization?.id || '');
  };

  if (!filteredData) {
    return null;
  }

  return (
    <SubOrgSliderContainer slidesToShow={slidesToShow}>
      <MainSubOrgContainer ref={sliderRef}>
        <UserCardContainer>
          <SubOrgSubjectContainer>
            <OrgChartCard
              user={user}
              organization={organization}
              toProfileHandler={toProfileHandler}
              isHaveBottomLine={false}
              followPopoverData={followPopoverData}
              changeFollowPopoverData={changeFollowPopoverData}
              followedUsers={followedUsers}
              followHandler={followHandler}
              unfollowHandler={unfollowHandler}
              managerId={user?.manager?.id}
              topButtonClickHandler={goToManager}
              isSubjectCard={true}
            />
          </SubOrgSubjectContainer>
        </UserCardContainer>
        {filteredData.length <= 3 ? (
          <Slides>
            <OrgChartContainer elementsPerRow={3} style={filteredData.length === 1 ? { gridTemplateColumns: '1fr' } : {}}>
              {filteredData.map((elem, key) => {
                if (!elem) {
                  return (
                    <CardContainer key={key}>
                      <EmptySlide />
                    </CardContainer>
                  );
                }
                return (
                  <CardContainer key={key}>
                    <OrgChartCard
                      user={elem.user}
                      organization={elem.organization}
                      toProfileHandler={toProfileHandler}
                      isHaveBottomLine={false}
                      isOpacity={true}
                      followPopoverData={followPopoverData}
                      changeFollowPopoverData={changeFollowPopoverData}
                      followedUsers={followedUsers}
                      followHandler={followHandler}
                      unfollowHandler={unfollowHandler}
                      key={key}
                      managerId={elem?.user?.manager?.id || ''}
                    />
                  </CardContainer>
                );
              })}
            </OrgChartContainer>
          </Slides>
        ) : (
          <StyledSlider {...settings} ref={slider} beforeChange={shiftSlideHandler} key={user?.id}>
            {filteredData.map((elem, key) => {
              if (!elem) {
                return (
                  <CardContainer key={key}>
                    <EmptySlide />
                  </CardContainer>
                );
              }
              return (
                <StyledSlide id={`${key}`} key={key}>
                  <div>
                    <OrgChartCard
                      user={elem.user}
                      organization={elem.organization}
                      toProfileHandler={toProfileHandler}
                      isHaveBottomLine={false}
                      isOpacity={true}
                      followPopoverData={followPopoverData}
                      changeFollowPopoverData={changeFollowPopoverData}
                      followedUsers={followedUsers}
                      followHandler={followHandler}
                      unfollowHandler={unfollowHandler}
                      key={key}
                      managerId={elem?.user?.manager?.id || ''}
                    />
                  </div>
                </StyledSlide>
              );
            })}
          </StyledSlider>
        )}
      </MainSubOrgContainer>
    </SubOrgSliderContainer>
  );
};

export default Slider;
