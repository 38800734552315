import styled from 'styled-components';
import { Layout, Typography } from 'antd';

export const MainContainer = styled(Layout)`
  margin: 0 auto;
  background: #fff;
  min-height: calc(100vh - 56px);
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 20px 106px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  @media (max-width: 1000px) {
    padding: 20px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`;

export const StyledTitle = styled(Typography.Title)`
  margin: 0 !important;
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  h1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    color: #111111;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1000px) {
    h1 {
      font-size: 26px;
    }
  }
`;

export const SearchContainer = styled.div`
  margin-top: 14px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const MainSupportDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 150px;

  h2 {
    color: #111111;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const SupportDocuments = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin-top: 16px;
  width: 100%;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

export const SupportDocument = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1890ff;
  padding: 29px 27px;
  cursor: pointer;
  user-select: none;
`;
