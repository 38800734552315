import React, { FC, useEffect } from 'react';
import moment, { Moment, utc } from 'moment';
import { DatePicker } from 'antd';

import { StyledLabel, FormItem } from '../styles';
import { ReactComponent as Calendar } from 'modules/common/assets/images/calendar.svg';

export interface IProps {
  label?: string;
  value?: string | Moment | null;
  defaultValue?: string | Moment | null;
  disabledDate?: (current: Moment) => boolean;
  onChange: (value: Moment | string | null) => void;
  placeholder?: string;
  isBorderLabeled?: boolean;
  allowClear?: boolean;
  format?: string;
  style?: {
    [key: string]: string;
  };
  showTime?: boolean;
  useLocalTimezone?: boolean;
}

export const disablePreviousDate = (curDate) => {
  const dayInMs = 86400000;
  const dateToCheck = curDate.diff(Date.now());
  return dateToCheck + dayInMs < 0;
};

const Datepicker: FC<IProps> = ({
  value,
  onChange,
  placeholder,
  label,
  allowClear,
  disabledDate,
  format,
  style,
  showTime,
  defaultValue = null,
  useLocalTimezone = false,
}) => {
  const transformFunction = useLocalTimezone ? moment : utc;
  const defaultDate = defaultValue ? transformFunction(defaultValue) : null;
  useEffect(() => {
    defaultDate && onChange(defaultDate);
  }, []);

  return (
    <FormItem style={style}>
      {label ? <StyledLabel hasValue={!!value}>{label}</StyledLabel> : null}
      <DatePicker
        value={value ? transformFunction(value) : defaultDate}
        onChange={onChange}
        placeholder={placeholder || ''}
        suffixIcon={<Calendar />}
        allowClear={allowClear}
        disabledDate={disabledDate}
        format={format}
        style={{ width: '100%' }}
        showTime={showTime}
      />
    </FormItem>
  );
};

export default Datepicker;
