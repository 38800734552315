import styled from 'styled-components';
import { Layout } from 'antd';
import { ReactComponent as FooterIcon } from 'modules/common/assets/images/shared/footerLogo.svg';

export const Container = styled(Layout.Footer)`
  padding: 24px 106px;
  border-top: 1px solid #d7dadf;
  background: #f5f5f5;
`;

export const StyledFooterText = styled.span`
  font-weight: 500;
  color: #4a4a4a;
`;

export const StyledLogo = styled(FooterIcon)`
  position: absolute;
  top: -30px;
  right: 0;
`;

export const ColoredSpan = styled.span`
  color: #ff8600;
`;

export const MainContainer = styled.div`
  height: calc(100vh - 56px);
`;

export const MainContentContainer = styled.div`
  height: calc(100% - 71px);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafbfc;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
  color: #000000;
  margin: 65px 0 0 0;
  padding: 0;
`;

export const SubTitle = styled.p`
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  padding: 0;
  margin: 13px 0 0 0;

  span {
    font-weight: 600;
  }
`;

export const Button = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #1890ff;
  margin: 32px 0 0 0;
  padding: 0;
  cursor: pointer;
`;
