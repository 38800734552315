import React, { FC, useState, useMemo, useRef } from 'react';
import pluralize from 'pluralize';
import moment from 'moment';
import CustomScroll from 'react-custom-scroll';
import { useHistory } from 'react-router-dom';

import {
  StyledModal,
  MainContainer,
  StyledClose,
  Header,
  StyledAvatar,
  StyledElement,
  NameContainer,
  StyledImage,
  ElementsContainer,
  Line,
  CommentsContainer,
  MainCommentsContainer,
  CommentsInputContainer,
  Subtitle,
  CaseIcon,
  ShowMoreButton,
} from './styles';
import { IComment, INewsFeedPostModel } from 'modules/common/gql/models/newsFeedPost.model';
import { hrPostsTitle } from 'modules/news-feed/pages/Feed/constants';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { POST_TYPES } from 'modules/news-feed/pages/Feed/constants';
import Comments from '../../../Comments';
import CommentsInput from '../CommentsInput';
import { CommentsMainInputContainer, CommentsControlContainer } from '../PreviewModal/styles';
import { Point, CancelButton } from '../PreviewModal/styles';

interface IProps {
  data: INewsFeedPostModel;
  comments: IComment[];
  isOpen: boolean;
  onClose: () => void;
  userAvatar?: string;
  mentionsClickHandler: (e: any) => void;
  setChangedCommentRef: (id: string, ref: HTMLDivElement | null) => void;
  scrollTo: number | undefined;
  fetchMoreComments: () => void;
  showMoreCommentsCount: number;
  changeCommentForScroll: (id: string) => void;
}

const PreviewHrPostsModal: FC<IProps> = ({
  data,
  isOpen,
  onClose,
  userAvatar,
  mentionsClickHandler,
  setChangedCommentRef,
  scrollTo,
  comments,
  fetchMoreComments,
  showMoreCommentsCount,
  changeCommentForScroll,
}) => {
  const editorRef = useRef<any>(null);
  const [changedCommentId, setChangedCommentId] = useState<null | string>(null);
  const [replyCommentId, setReplyCommentId] = useState<null | string>(null);
  const history = useHistory();
  const { type, targetEmployees } = data;
  const isAnniversaryType = data.type === POST_TYPES.HR_WORK_ANNIVERSARY;
  const isPromotionType = data.type === POST_TYPES.HR_PROMOTION;

  const changedReplyComment = useMemo(() => comments.find((elem) => elem._id === replyCommentId), [comments, replyCommentId]);

  const editorFocus = () => {
    editorRef?.current?.editor?.focus();
  };

  const editCommentHandler = (id: string) => {
    setReplyCommentId(null);
    setChangedCommentId(id);
    editorFocus();
  };

  const cancelCommentHandler = () => {
    setChangedCommentId(null);
  };

  const replyCommentHandler = (id: string) => {
    setChangedCommentId(null);
    setReplyCommentId(id);
    editorFocus();
  };

  const cancelReplyHandler = () => {
    setReplyCommentId(null);
  };

  return (
    <StyledModal footer={null} visible={isOpen} onCancel={onClose} width={400}>
      <MainContainer>
        <Header>
          <p>{hrPostsTitle[type](targetEmployees?.length ?? 0)}</p>
          <StyledClose onClick={onClose} />
        </Header>
        <ElementsContainer>
          <CustomScroll heightRelativeToParent={'350px'}>
            {(targetEmployees || []).map((elem, index, employees) => {
              const { fullName, photoUrl, userId, positionBusinessTitle, hireDate } = elem;
              const tenure = useMemo(() => {
                if (!hireDate) {
                  return 0;
                }
                const hire = moment(hireDate);
                const now = moment(new Date());
                return now.diff(hire, 'years');
              }, [hireDate]);
              return (
                <StyledElement onClick={() => history.push(`/profile/${userId}`)}>
                  <StyledAvatar src={<StyledImage preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
                  <NameContainer>
                    <p>{fullName}</p>
                    {isAnniversaryType && !!tenure && (
                      <Subtitle>
                        <CaseIcon />
                        {`${pluralize('year', tenure, true)} at Astreya`}
                      </Subtitle>
                    )}
                    {isPromotionType && (
                      <Subtitle>
                        <CaseIcon />
                        {`Promoted to ${positionBusinessTitle}`}
                      </Subtitle>
                    )}
                    {!(employees.length - 1 === index) && <Line />}
                  </NameContainer>
                </StyledElement>
              );
            })}
          </CustomScroll>
        </ElementsContainer>
        <MainCommentsContainer isEditMode={Boolean(changedCommentId)}>
          {showMoreCommentsCount > 0 && (
            <ShowMoreButton onClick={fetchMoreComments}>Show {showMoreCommentsCount} comments</ShowMoreButton>
          )}
          {comments && comments.length > 0 && (
            <CustomScroll heightRelativeToParent={'300px'} scrollTo={scrollTo}>
              <CommentsContainer>
                <Comments
                  onReply={replyCommentHandler}
                  isOpened={true}
                  isFullPost={true}
                  editHandler={editCommentHandler}
                  postId={data._id}
                  isFullModal={true}
                  mentionsClickHandler={mentionsClickHandler}
                  setChangedCommentRef={setChangedCommentRef}
                  commentsData={comments}
                  changeCommentForScroll={changeCommentForScroll}
                />
              </CommentsContainer>
            </CustomScroll>
          )}
        </MainCommentsContainer>
      </MainContainer>
      <CommentsInputContainer>
        <CommentsMainInputContainer>
          {changedCommentId && (
            <CommentsControlContainer>
              <p>Editing comment</p> <Point /> <CancelButton onClick={cancelCommentHandler}>Cancel</CancelButton>
            </CommentsControlContainer>
          )}
          {replyCommentId && (
            <CommentsControlContainer>
              <p>
                Replying to
                <span>{changedReplyComment?.author.fullName}</span>
              </p>
              <Point /> <CancelButton onClick={cancelReplyHandler}>Cancel</CancelButton>
            </CommentsControlContainer>
          )}
          <CommentsInput
            postId={data._id}
            postType={data.type}
            changedCommentId={changedCommentId}
            comments={comments}
            cancelCommentHandler={cancelCommentHandler}
            replyCommentId={replyCommentId}
            cancelReplyHandler={cancelReplyHandler}
            userAvatar={userAvatar}
            editorRef={editorRef}
          />
        </CommentsMainInputContainer>
      </CommentsInputContainer>
    </StyledModal>
  );
};

export default PreviewHrPostsModal;
