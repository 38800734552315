import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import { Tabs, Avatar, Image, message } from 'antd';

import {
  StyledModal,
  StyledCloseIcon,
  TabTitle,
  TabTitleMain,
  StyledList,
  UserDescription,
  StyledSpace,
  FollowButton,
  ListItem,
} from './styles';
import { reactionsTypes } from 'modules/common/const';
import { IReactionModel, IUserProxy } from 'modules/common/gql/models/reaction.model';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { UNFOLLOW_USER, FOLLOW_USER } from 'modules/profile/gql/mutation';
import { IFollowStatus } from 'modules/common/gql/models/user.model';
import { emitFollowRequest } from 'utils/mixpanel';
import { UserState } from 'store/domains/user/user.types';
import { RootState } from 'store/rootReducer';
import { GET_NEWS_FEED_POST_REACTION } from 'modules/news-feed/gql/query';
import { GET_COMMENTS_REACTIONS } from 'modules/news-feed/gql/query';

const { TabPane } = Tabs;

export interface IProps {
  isVisible: boolean;
  onClose: () => void;
  postId: string;
  commentId?: string;
}

interface IUserListProps {
  list: IUserProxy[];
  followUser: (id: string) => void;
  unfollowUser: (id: string) => void;
  curUserId: string;
}

interface IReactionResult {
  newsFeedPostReactions: IReactionModel;
}

interface INewsCommentReactionsResult {
  newsFeedCommentReactions: IReactionModel;
}

const UserList: FC<IUserListProps> = memo(
  ({ list, followUser, unfollowUser, curUserId }) => (
    <StyledList>
      {list.map(({ fullName, userId, photoUrl, positionBusinessTitle, followedByMeStatus }) => {
        const path = `/profile/${userId}`;
        const isPending = followedByMeStatus === IFollowStatus.PENDING;
        const isFollowing = followedByMeStatus === IFollowStatus.FOLLOWING;
        const isCurUser = curUserId === userId;
        return (
          <ListItem key={userId}>
            <StyledSpace>
              <Link to={path}>
                <Avatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
              </Link>
              <UserDescription direction="vertical">
                <Link to={path}>
                  <b>{fullName}</b>
                </Link>
                <span>{positionBusinessTitle}</span>
              </UserDescription>
            </StyledSpace>
            {followedByMeStatus && !isCurUser && (
              <FollowButton
                isUnFollow={!isFollowing}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isPending) {
                    return;
                  }
                  !isFollowing ? followUser(userId) : unfollowUser(userId);
                }}
              >
                {isPending ? 'Pending' : !isFollowing ? 'Follow' : 'Unfollow'}
              </FollowButton>
            )}
          </ListItem>
        );
      })}
    </StyledList>
  ),
  isEqual,
);

const ViewReactionsModal: FC<IProps> = ({ isVisible, onClose, postId, commentId }) => {
  const { data: reactionsData, refetch: refetchReactions } = useQuery<IReactionResult>(GET_NEWS_FEED_POST_REACTION, {
    variables: { postId },
    skip: !postId || Boolean(postId && commentId),
    onError: (err: Error) => message.error(err),
  });
  const { data: commentsReactions, refetch: refetchCommentReactions } = useQuery<INewsCommentReactionsResult>(
    GET_COMMENTS_REACTIONS,
    {
      variables: { postId, commentId },
      skip: !postId || !commentId,
      onError: (err: Error) => message.error(err),
    },
  );
  const reactions = useMemo(() => {
    if (postId && commentId && commentsReactions) {
      return commentsReactions?.newsFeedCommentReactions;
    } else if (postId && !commentId && reactionsData) {
      return reactionsData?.newsFeedPostReactions;
    } else {
      return null;
    }
  }, [reactionsData, commentsReactions, commentId, postId]);
  const user: UserState = useSelector((state: RootState) => state?.user);
  const reactionLabels = Object.keys(reactionsTypes);
  const userInfo = reactions?.userInfo || [];
  const allUsersReactions: IUserProxy[] = userInfo.reduceRight(
    // @ts-ignore
    (accumulator, currentValue) => accumulator.concat(currentValue.userProxies),
    [],
  );
  const [followUser] = useMutation(FOLLOW_USER, {
    onCompleted: () => {
      if (commentId) {
        refetchCommentReactions();
      } else {
        refetchReactions();
      }
      emitFollowRequest({ action: 'follow' });
    },
    onError: () => {},
  });
  const [unfollowUser] = useMutation(UNFOLLOW_USER, {
    onCompleted: () => {
      if (commentId) {
        refetchCommentReactions();
      } else {
        refetchReactions();
      }
      emitFollowRequest({ action: 'unfollow' });
    },
    onError: () => {},
  });

  const followHandler = (id: string) => {
    followUser({
      variables: {
        id,
      },
    });
  };

  const unfollowHandler = (id: string) => {
    unfollowUser({
      variables: {
        id,
      },
    });
  };

  return (
    <StyledModal
      title="Reactions"
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      closeIcon={<StyledCloseIcon />}
      width={300}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab={<TabTitleMain>{`All ${allUsersReactions.length}`}</TabTitleMain>} key="all">
          <UserList
            followUser={followHandler}
            unfollowUser={unfollowHandler}
            list={allUsersReactions}
            curUserId={user.id || ''}
          />
        </TabPane>
        {reactionLabels.map((type) => {
          const usersWithReaction = userInfo.find((el) => el.reactionType === type)?.userProxies || [];
          const tab = (
            <TabTitle>
              <img height="15" src={reactionsTypes[type].src} /> {usersWithReaction.length}
            </TabTitle>
          );
          return (
            <TabPane disabled={!usersWithReaction.length} tab={tab} key={reactionsTypes[type].type}>
              <UserList
                followUser={followHandler}
                unfollowUser={unfollowHandler}
                list={usersWithReaction}
                curUserId={user.id || ''}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </StyledModal>
  );
};

export default ViewReactionsModal;
