import React, { FC } from 'react';
import { utc } from 'moment';

import {
  DateContainer,
  WorkAnniversaryItem,
  TimelineAvatar,
  TimeLine,
  Point,
  WorkAnniversaryInfoContainer,
  WorkAnniversaryText,
  YearContainer,
  StyledAnniversaryImage,
} from '../../styles';
import { ITimelineEventModel } from 'modules/common/gql/models/timelineEvent.model';
import { TIMELINE_EVENT_TYPES, COMPANY } from 'modules/common/const';
import anniversary from '../../assets/anniversary.png';
import anniversaryMobile from '../../assets/anniversaryMobile.png';
import portfolio from '../../assets/portfolio.svg';

interface IProps {
  data: ITimelineEventModel;
  isVisibleYear: boolean;
  screenWidth: number;
}

const WorkAnniversary: FC<IProps> = ({ data, isVisibleYear, screenWidth }) => {
  const { type, date, relatedEntity, name } = data;
  const title = type === TIMELINE_EVENT_TYPES.START_DATE ? `Start Date` : `Work Anniversary`;
  return (
    <WorkAnniversaryItem>
      {isVisibleYear && <YearContainer>{utc(date).format('YYYY')}</YearContainer>}
      <TimelineAvatar background={'#D1F4EB'}>
        <img src={portfolio} />
      </TimelineAvatar>
      <TimeLine>
        <DateContainer background={'#D1F4EB'} color={'#04AA77'}>
          {title} <Point /> {utc(date).format('DD MMMM')}
        </DateContainer>
        <WorkAnniversaryInfoContainer>
          <WorkAnniversaryText>
            {type === TIMELINE_EVENT_TYPES.START_DATE && (
              <>
                <h1>Joined the company</h1>
                <p>
                  {relatedEntity?.positionBusinessTitle} <span>@{COMPANY.name}</span>
                </p>
              </>
            )}
            {type === TIMELINE_EVENT_TYPES.WORK_ANNIVERSARY && (
              <>
                <h1>{name}</h1>
                <p>
                  Work Anniversary <span>@{COMPANY.name}</span>
                </p>
              </>
            )}
          </WorkAnniversaryText>
          <StyledAnniversaryImage src={screenWidth <= 1300 ? anniversaryMobile : anniversary} alt={'anniversary'} />
        </WorkAnniversaryInfoContainer>
      </TimeLine>
    </WorkAnniversaryItem>
  );
};

export default WorkAnniversary;
