import styled from 'styled-components';
import { Avatar, Skeleton } from 'antd';

export const StyledAvatar = styled(Avatar)`
  position: absolute;
  left: 13px;
  top: 12px;
  width: 25px;
  height: 25px;
  z-index: 999;
`;

interface IMainContainer {
  isEmpty?: boolean;
  isLoading?: boolean;
}

export const MainContainer = styled.div<IMainContainer>`
  width: 100%;
  position: relative;

  .tox {
    border: none !important;
    background: rgba(239, 240, 241, 0.4) !important;
    border-radius: 40px !important;

    .tox-edit-area__iframe {
      background: transparent;

      p {
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    .tox-edit-area {
      background: rgba(239, 240, 241, 0.4) !important;
      margin-left: 30px;
    }

    .tox-toolbar-overlord {
      position: absolute !important;
      right: 0 !important;
      top: 2px !important;
      background-color: transparent !important;
    }

    .tox-toolbar {
      background: transparent !important;
    }

    .tox-tbtn {
      background: transparent !important;
      cursor: pointer !important;
    }
  }

  ${({ isEmpty }) =>
    isEmpty &&
    `
  .tox {
    height: 40px !important;
    .tox-editor-container {
      margin-top: -4px;
    }
  }
  ${StyledAvatar} {
    top: 8px !important;
  }
  `}

  ${(isLoading) =>
    isLoading &&
    `
      height: 40px !important;
      overflow: hidden;
  `}
`;

export const Container = styled.div``;

export const RichEditorSkeleton = styled(Skeleton.Button)`
  position: absolute;
  z-index: 9999;
  background: #fff;
  width: 100% !important;
  height: 100% !important;
  border-radius: 40px !important;
`;
