import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      id
      fullName
      firstName
      lastName
      middleName
      email
      birthDate
      birthDateHidden
      timeZone
      photoUrl
      terminatedAt
      createdAt
      isActive
      isFollowedByMe
      isProfilePhotoAvatar
      avatarBgColor
      phones {
        phoneNumber
        phoneDeviceType
        primaryUsage
        usageType
        hidden
        id
      }
      addresses {
        formattedAddress
        countryCode
        postalCode
        municipality
        usageType
      }
      workingAddress {
        formattedAddress
        countryCode
        postalCode
        municipality
        usageType
      }
      skills {
        id
        name
        approved
      }
      hobbies {
        id
        name
        approved
      }
      trainingToUsers {
        id
        issueDate
        training {
          id
          name
          imageUrl
          approved
        }
      }
      certificationToUsers {
        id
        issueDate
        certification {
          id
          name
          imageUrl
          approved
        }
      }
      profileCompleteness {
        total
        aboutMe
        workingHours
        hobbies
        skills
      }
      employment {
        id
        active
        hireDate
        tenure
        employeeMission
        employeeDescription
        workingHoursStart
        workingHoursEnd
      }
      job {
        id
        positionTitle
        positionBusinessTitle
        primaryJob
        employeeType
        positionTimeType
        fullTimeEquivalentPercentage
        payRateType
      }
      costCenter {
        id
        name
      }
      manager {
        id
        lastName
        firstName
        job {
          positionTitle
        }
      }
      userVisit {
        userId
        visitDate
        daysCount
      }
      permissionIds
      followedByMeStatus
    }
  }
`;

export const GET_USER_REPORTS_TREE = gql`
  query getUserReportsTree($id: String!) {
    getUserReportsTree(userId: $id) {
      id
      fullName
      job {
        positionTitle
      }
    }
  }
`;

export const GET_ALL_SKILLS = gql`
  query getSkills($name: String) {
    skills(input: { where: { approved: true, name: $name } }, pagination: { limit: 0, offset: 0 }) {
      data {
        id
        name
      }
      pagination {
        total
      }
    }
  }
`;

export const GET_ALL_HOBBIES = gql`
  query getHobbies($name: String) {
    hobbies(input: { where: { approved: true, name: $name } }, pagination: { limit: 0, offset: 0 }) {
      data {
        id
        name
      }
      pagination {
        total
      }
    }
  }
`;

export const GET_ALL_CERTIFICATIONS = gql`
  query getCertifications {
    certifications(input: { where: { approved: true } }, pagination: { limit: 0, offset: 0 }) {
      data {
        id
        name
        imageUrl
      }
      pagination {
        total
      }
    }
  }
`;

export const GET_ALL_TRAININGS = gql`
  query getTrainings {
    trainings(input: { where: { approved: true } }, pagination: { limit: 0, offset: 0 }) {
      data {
        id
        name
        imageUrl
      }
      pagination {
        total
      }
    }
  }
`;

export const GET_ORG_CHART = gql`
  query userOrgChart($id: String!) {
    userOrgChart(id: $id) {
      reportsTo {
        id
        fullName
        photoUrl
      }
      directReports {
        id
        fullName
        photoUrl
        job {
          id
          positionBusinessTitle
        }
        addresses {
          id
          countryCode
          municipality
        }
        costCenter {
          name
        }
      }
    }
  }
`;

export const GET_MY_FOLLOWERS = gql`
  query getMyFollowers($limit: Int!, $nextCursor: String) {
    getMyFollowers(pagination: { limit: $limit, nextCursor: $nextCursor }) {
      pagination {
        nextCursor
        pageCount
        limit
      }
      data {
        status
        followerUser {
          id
          fullName
          firstName
          lastName
          photoUrl
          job {
            positionTitle
          }
        }
      }
    }
  }
`;

export const GET_MY_FOLLOWED_AUTHORS = gql`
  query getMyFollowedAuthors($limit: Int!, $nextCursor: String) {
    getMyFollowedAuthors(pagination: { limit: $limit, nextCursor: $nextCursor }) {
      pagination {
        nextCursor
        pageCount
        limit
      }
      data {
        status
        authorUser {
          id
          firstName
          lastName
          fullName
          photoUrl
          job {
            positionTitle
          }
        }
      }
    }
  }
`;

export const GET_USER_FOLLOWERS_COUNT = gql`
  query getUserFollowersCount($userId: String!) {
    getUserFollowersCount(userId: $userId) {
      followersCount
      followingsCount
    }
  }
`;

export const GET_EVENTS_BY_USER = gql`
  query timelineEventsByUser($userId: String!, $pagination: CursorPaginationInput!) {
    timelineEventsByUser(userId: $userId, pagination: $pagination) {
      pagination {
        nextCursor
        pageCount
        limit
      }
      data {
        id
        name
        type
        date
        relatedEntityId
        hidden
        createdAt
        updatedAt
        ... on TimelineThanksEventModel {
          relatedEntity {
            post {
              ... on NewsFeedThanksPostModel {
                text
                _id
              }
            }
            user {
              id
              photoUrl
              fullName
            }
          }
        }
        ... on TimelineRecognitionEventModel {
          relatedEntity {
            post {
              ... on NewsFeedRecognitionPostModel {
                badge
                text
                _id
              }
            }
            user {
              id
              photoUrl
              fullName
            }
          }
        }
        ... on TimelinePersonalEventModel {
          relatedEntity {
            name
            date
          }
        }
        ... on TimelineCertificationEventModel {
          relatedEntity {
            photoUrl
          }
        }
        ... on TimelineTrainingEventModel {
          relatedEntity {
            photoUrl
          }
        }
        ... on TimelineTransferEventModel {
          relatedEntity {
            locationFrom
            locationTo
          }
        }
        ... on TimelinePromotionEventModel {
          relatedEntity {
            positionBusinessTitle
            positionTitle
          }
        }
        ... on TimelineBirthDayEventModel {
          relatedEntity {
            birthday
            age
          }
        }
        ... on TimelineWorkAnniversaryEventModel {
          relatedEntity {
            anniversaryYear
          }
        }
        ... on TimelineStartDateEventModel {
          relatedEntity {
            id
            positionBusinessTitle
            positionTitle
          }
        }
      }
    }
  }
`;

export const GET_USER_BADGES = gql`
  query newsFeedUserBadges($userId: String!) {
    newsFeedUserBadges(userId: $userId) {
      _id
      iconFileName
      iconPngFileName
      name
      description
      createdAt
      updatedAt
      iconUrl
    }
  }
`;
