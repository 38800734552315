import styled from 'styled-components';
import { Layout, Row, Card, Collapse } from 'antd';

import { ReactComponent as FeaturedPostsIcon } from '../../pages/Feed/assets/featuredPostsIcon.svg';
import { ReactComponent as CaretCollapsed } from '../../pages/Feed/assets/caretCollapsed.svg';

const { Sider } = Layout;

export const FeedContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

export const MainContainer = styled.div`
  padding: 20px 20px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  min-height: calc(100vh - 56px);
  box-sizing: border-box;
  @media (min-width: 992px) {
    padding: 20px 106px;
  }
`;

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
  background: transparent;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSider = styled(Sider)`
  background: none;
`;

export const StyledRow = styled(Row)`
  padding-top: 10px;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  background: #fff;
  padding: 0 16px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 16px;
  box-shadow: 0px 2px 8px rgba(181, 188, 198, 0.219608);

  :first-child {
    margin-top: 0px;
  }
  .ant-card-head {
    padding: 0;
    border-color: #e0e0e0;
  }
  .ant-card-body {
    padding: 24px 0;
  }
`;

export const LeftSideContainer = styled.div`
  padding: 0 16px 0 0;
  @media (max-width: 1200px) {
    padding: 0;
    margin-top: 20px;
    width: 100%;
  }
`;
export const RightSideContainer = styled.div`
  padding: 0 0 0 16px;
  @media (max-width: 1200px) {
    padding: 0;
    margin-top: 40px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const StyledParagraph = styled.div`
  margin-top: 16px;
`;

export const Mention = styled.span`
  cursor: pointer;
  color: #1890ff;
  &:hover {
    text-decoration: underline;
  }
`;

export const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  margin-top: 40px;

  ${({ isLoadedAllData }: { isLoadedAllData?: boolean }) =>
    isLoadedAllData &&
    `
    height: 0;
    margin-top: 0;
  `};
`;

export const HeaderFeature = styled.div`
  color: #111111;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;

  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    word-break: break-word;
    max-width: 85%;
  }
`;

export const MainContainerHeaderFeature = styled(Collapse)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background: #ffffff;
  margin-top: 16px;
  &.ant-collapse > .ant-collapse-item {
    width: 100%;
  }
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0 !important;
    padding: 10px 16px;
  }
  &.ant-collapse > .ant-collapse-item.ant-collapse-item-active .ant-collapse-extra > svg {
    transform: rotate(180deg);
  }
`;

export const HideButton = styled.div`
  position: absolute;
  right: 0;
  font-size: 14px;
  line-height: 24px;
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
`;

export const StyledFeaturedPostsIcon = styled(FeaturedPostsIcon)`
  margin-right: 10px;
`;

export const CaretCollapsedIcon = styled(CaretCollapsed)`
  position: absolute;
  top: 18px;
  right: 0;
`;

export const CollapseItem = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
`;
