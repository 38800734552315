import styled from 'styled-components';

import { Image } from 'antd';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 8px 16px;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    margin: 0 !important;
    padding: 0;
  }

  p {
    margin: 0;
    padding: 0;
    color: #4b4b4b;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 1.2%;
  grid-template-columns: repeat(auto-fit, 15.6%);
  margin-top: 14px;
  justify-content: center;
`;

interface ICard {
  isDontHaveAvatar?: boolean;
}

export const Card = styled.div<ICard>`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 185px;
  position: relative;
  overflow: hidden;
  user-select: none;
  cursor: pointer;

  ${({ isDontHaveAvatar }) =>
    isDontHaveAvatar &&
    `
    background: #3882d9;
  `}

  p {
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    z-index: 999;
    word-break: break-word;
    width: 80px;
    text-align: center;
    margin: 0;
    margin-bottom: 5px;
  }

  .ant-image {
    position: initial !important;
  }
`;

export const Subtitle = styled.div`
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  z-index: 999;
  max-width: 95px;
  text-align: center;
  margin-bottom: 5px;
`;

export const CardWrapper = styled.div`
  background: linear-gradient(180deg, rgba(62, 62, 62, 0) 0%, rgba(46, 46, 46, 0.86) 100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  z-index: 999;

  p {
    word-break: initial;
  }
`;

export const CardLastWrapper = styled(CardWrapper)`
  background: linear-gradient(180deg, rgba(36, 36, 36, 0) 0%, #262626 100%, #c4c4c4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
  height: 100%;

  h2 {
    color: #ffffff;
    font-weight: 500;
    font-size: 25px;
    line-height: 16px;
    z-index: 1;
  }
`;

interface IStyledAvatar {
  $isAvatarPlaceholder: boolean;
  $isDontHaveAvatar?: boolean;
  $avatarBgColor: string | null;
}

export const StyledAvatar = styled(Image)<IStyledAvatar>`
  object-fit: ${(props) => (props.$isAvatarPlaceholder ? 'contain' : 'cover')};
  height: 100%;
  align-self: center;

  ${({ $avatarBgColor }) =>
    $avatarBgColor
      ? `
    background-color: ${$avatarBgColor};
    & .ant-image { width: 80% !important; margin: auto; }
    `
      : ''}

  ${({ $isDontHaveAvatar }) =>
    $isDontHaveAvatar &&
    `
    width: 56px !important;
    height: 66px !important;
    .ant-image {
      height: 66px !important;
    }
  `}
`;

export const MainHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  img {
    width: 22px;
  }
`;

interface IAvatarContainer {
  isHaveAvatar?: boolean;
  $avatarBgColor?: string | null;
}

export const AvatarContainer = styled.div<IAvatarContainer>`
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${({ $avatarBgColor }) => ($avatarBgColor ? `background-color: ${$avatarBgColor}` : '')}

  ${({ isHaveAvatar }) =>
    isHaveAvatar &&
    `
      align-items: flex-end;
      .ant-image {
        height: 100% !important;
      }
  `}
`;
