import styled from 'styled-components';

import { ReactComponent as ArrowRight } from '../../../../assets/images/supportList/arrowRight.svg';

export const ContentContainer = styled.div`
  width: 100%;
`;

export const Articles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

export const Article = styled.div`
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background: #ffffff;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;

  p {
    color: #111111;
    font-size: 14px;
    line-height: 24px;
    margin-top: 36px;
  }

  :first-child {
    margin-top: 0;
  }
`;

export const ArticleHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: #111111;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ViewArticleButton = styled.div`
  background: #1890ff;
  border-radius: 4px;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  user-select: none;
`;

export const StyledArrowRight = styled(ArrowRight)`
  margin-left: 12px;
`;

export const RequestSupportButton = styled.a`
  background: #1890ff;
  border-radius: 4px;
  padding: 4px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-top: 7px;
  color: #ffffff;

  :hover {
    color: #ffffff;
  }
`;
