import styled, { css } from 'styled-components';
import { Modal, Space } from 'antd';
import { ReactComponent as CloseIcon } from 'modules/common/assets/images/close.svg';

export const StyledModal = styled(Modal)`
  position: relative;
  min-width: 400px;
  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-tabs-tab {
    margin-right: 16px;
    margin-left: 0!important;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 25px;
  right: 25px;
`;

export const TabTitle = styled.span`
  font-size: 12px;
  color: #1890ff;
  img {
    margin-right: 8px;
  }
`;

export const TabTitleMain = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #1890ff;
`;

export const StyledList = styled.ul`
  height: 550px;
  overflow-y: auto;
  list-style: none;
  padding-left: 0;
  width: 100%;

  li {
    width: 100%;
  }
`;

export const StyledSpace = styled(Space)`
  width: 100%;
  .ant-space-item:last-child {
    width: 100%;
  }
`;

export const UserDescription = styled(Space)`
  position: relative;
  top: 6px;
  border-bottom: 1px solid #eff0f1;
  width: 100%;

  b {
    color: #111111;
  }

  span {
    position: relative;
    top: -10px;
    color: #6b778c;
    font-size: 12px;
  }
`;

export const FollowButton = styled.div`
  position: absolute;
  top: 0;
  right: 28px;
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-left: 8px;

  ${({ isUnFollow }: { isUnFollow?: boolean }) =>
    !isUnFollow &&
    css`
      color: #6b778c;
    `}
`;

export const ListItem = styled.li`
  position: relative;
  margin-top: 8px;
  &:first-child {
    margin-top: 0;
  }
`;
