import React, { Fragment, useMemo, useState, useCallback, useRef, useLayoutEffect, FunctionComponent, useEffect } from 'react';
import { message, Image as AntImage } from 'antd';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import CustomScroll from 'react-custom-scroll';
import qs from 'qs';

import {
  StyledPreviewModal,
  MainPreviewModalContainer,
  StyledSliderContainer,
  SideContainer,
  StyledSlider,
  StyledSlide,
  StyledLeftArrow,
  StyledRightArrow,
  UserContainer,
  UserNameContainer,
  Name,
  SubName,
  PreviewModalContainer,
  DescriptionContainer,
  CommentsContainer,
  MainReactionsContainer,
  ReactionContainer,
  ReactionsContainer,
  StyledButton,
  ButtonsContainer,
  StyledDropdown,
  StyledCloseIcon,
  StyledPreviewAvatar,
  PreviewModalImage,
  CommentsMainContainer,
  PreviewModalHeader,
  MainUserContainer,
  InnerWrapper,
  MainDescriptionContainer,
  CostCenterImage,
  ViewAllText,
  TargetEmployeesContainer,
  StyledLike,
} from '../../styled';
import {
  CardsContainer,
  Card,
  StyledImage,
  TargetLinks,
  TargetLink,
  IconContainer,
  RecognitionBody,
  RecognitionCard,
  MainCommentsContainer,
  CommentsScrollContainer,
  CommentInputContainer,
  CommentsControlContainer,
  CommentsMainInputContainer,
  CancelButton,
  Point,
  CommentsInputAndScrollContainer,
  TextScrollContainer,
  PdfMainContainer,
  StyledDownloadAttachment,
  PdfFile,
  PdfFileList,
  StyledAttachment,
  StyledAttachmentLink,
  ShowMoreButton,
} from './styles';
import { INewsFeedPostModel } from 'modules/common/gql/models/newsFeedPost.model';
import { reactionsTypes } from 'modules/common/const';
import ReactionsPopover from '../../../PostCard/components/ReactionsPopover';
import { SET_NEWS_FEED_POST_REACTION, UNSET_NEWS_FEED_POST_REACTION } from 'modules/news-feed/gql/mutation';
import { GET_NEWS_FEED_POST_BY_ID, GET_NEWS_FEED_POST_REACTIONS } from 'modules/news-feed/gql/query';
import { IReaction as IBestReactionModel } from '../../../PostCard/PostCard';
import { IReactionModel, IReaction } from 'modules/common/gql/models/reaction.model';
import { profileRoutes } from 'modules/profile/config/Profile.router';
import { EDITOR_CONFIG } from 'app/tinymce';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { company } from 'modules/news-feed/pages/Feed/constants';
import { POST_TYPES } from 'modules/news-feed/pages/Feed/constants';
import PreviewHrPostsModal from '../PreviewHrPostsModal';
import { GET_NEWS_FEED_ALL_BADGES } from 'modules/common/gql/query';
import { INewsFeedBadgeResult } from 'modules/news-feed/pages/Feed/components/MainContent/MainContent';
import ViewReactionsModal from '../../../modals/ViewReactionsModal';
import { ReactComponent as Comment } from '../../../../assets/comment.svg';
import Comments from '../../../Comments';
import CostCenterIcon from 'modules/news-feed/pages/Feed/assets/createPostModal/teamIcon.png';
import CommentsInput from '../CommentsInput';
import { ON_COMMENT_ADDED, ON_COMMENT_UPDATED, ON_COMMENT_DELETED } from '../../../../../../gql/subscription';
import { IComment } from 'modules/common/gql/models/newsFeedPost.model';
import { emitReactPost, mapPostTypes } from 'utils/mixpanel';
import { richTextToPlain } from 'utils/parsers';
import { getPostReactions } from '../../../Comments/utils';
import { getFileExtension } from 'modules/news-feed/pages/Feed/utils';
import PdfContainer from '../PdfContainer';
import { GET_ALL_COMMENTS_IDS_BY_ID, GET_COMMENTS_BY_ID } from 'modules/news-feed/gql/query';
import apolloClient from 'app/apolloClient';

export interface IPostsResult {
  newsFeedPostById: INewsFeedPostModel;
  onNewsFeedCommentUpdated: IComment;
  onNewsFeedCommentAdded: IComment;
  onNewsFeedCommentDeleted: IComment;
}

interface IReactionsResult {
  newsFeedPostReactions: IReactionModel;
}

interface IProps {
  onClose: () => void;
  userId: string | null;
  postId: string | null;
  changedInitialImageKey: number | null;
  photoUrl?: string;
}
interface IReactionData {
  background: string;
  image: FunctionComponent;
  type: string;
}

const NextButton = ({ className, onClick, style }: any) => (
  <StyledRightArrow className={className} onClick={onClick} style={style} />
);

const PrevButton = ({ className, onClick, style }: any) => (
  <StyledLeftArrow className={className} onClick={onClick} style={style} />
);

const PreviewModal = ({ onClose, changedInitialImageKey, userId: idOfCurrentUser, postId, photoUrl: userAvatar }: IProps) => {
  const [scrollToCommentScroll, setScrollToCommentScroll] = useState<number | undefined>(undefined);
  const changedCommentRef = useRef<any>(null);
  const [changedCommentForScroll, setChangedCommentForScroll] = useState<string | null>(null);
  const editorRef = useRef<any>(null);
  const [changedCommentId, setChangedCommentId] = useState<null | string>(null);
  const [replyCommentId, setReplyCommentId] = useState<null | string>(null);
  const [isVisibleReactionsModal, setIsVisibleReactionsModal] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const searchData = useMemo(() => qs.parse(location.search.slice(1)), [location.search]);
  const slider = useRef<any>(null);
  const onError = (err: Error) => message.error(err);
  const { data: badgesData } = useQuery<INewsFeedBadgeResult>(GET_NEWS_FEED_ALL_BADGES, {
    onError: (err: Error) => message.error(err),
  });
  const feedQuery = useQuery<IPostsResult>(GET_NEWS_FEED_POST_BY_ID, {
    variables: { postId },
    onError,
  });
  const { data: reactionsQuery, refetch: refetchReactions } = useQuery<IReactionsResult>(GET_NEWS_FEED_POST_REACTIONS, {
    variables: { postId },
    onError,
  });

  const reactions: IReaction = reactionsQuery?.newsFeedPostReactions?.reactions || {};
  const feedData: INewsFeedPostModel | undefined = feedQuery?.data?.newsFeedPostById;
  const {
    files,
    text,
    _id,
    publishingDate,
    author,
    targetEmployees = [],
    badge,
    costCenter,
    comments,
    type,
    mentions,
    mostRepliedComment,
    mostReactedComment,
    commentsCount,
    directCommentsCount,
  } = feedData || {};
  const { photoUrl, fullName: preferredName = '' } = author || {};

  const isThanksPostType = type === POST_TYPES.THANKS;
  const isRecognitionPostType = type === POST_TYPES.RECOGNITION;
  const changedReplyComment = useMemo(
    () => (comments?.data || []).find((elem) => elem._id === replyCommentId),
    [comments, replyCommentId],
  );

  const filteredComments = useMemo(() => {
    if (!comments) {
      return [];
    }

    let commentsClone = [...comments.data];

    if (mostReactedComment) {
      commentsClone = [mostReactedComment, ...commentsClone];
    }
    if (mostRepliedComment) {
      commentsClone = [mostRepliedComment, ...commentsClone];
    }

    const uniqueComments: IComment[] = [];

    commentsClone.forEach((elem) => {
      if (!uniqueComments.some((comment) => comment._id === elem._id)) {
        uniqueComments.push(elem);
      }
    });

    return uniqueComments;
  }, [mostReactedComment, mostRepliedComment, comments]);

  const showMoreCommentsCount = useMemo(
    () => (directCommentsCount || 0) - (comments?.data?.length || 0),
    [directCommentsCount, comments],
  );

  const isFullText = useMemo(() => Boolean(searchData.previewModalIsOpenedText) || false, [searchData]);
  const changedCommentIdFromSearch = useMemo(() => searchData.previewModalChangedComment, [searchData]);

  const fetchMandatoryComments = async () => {
    if (!changedCommentIdFromSearch) {
      return;
    }
    const allComments = await apolloClient.query({
      query: GET_ALL_COMMENTS_IDS_BY_ID,
      variables: {
        postId,
      },
    });
    const allCommentsData = allComments?.data?.newsFeedPostComments?.data || [];

    const changedCommentIndex = allCommentsData.findIndex((elem) => elem._id === changedCommentIdFromSearch);

    if (changedCommentIndex === -1) {
      return;
    }

    const comments = await apolloClient.query({
      query: GET_COMMENTS_BY_ID,
      variables: {
        limit: changedCommentIndex + 1,
        postId,
      },
    });

    feedQuery.updateQuery((prevResult) => {
      const prevResultClone = JSON.parse(JSON.stringify(prevResult));
      const prevComments = prevResultClone.newsFeedPostById.comments;
      prevComments.data = comments?.data?.newsFeedPostComments?.data;
      prevComments.pagination = comments?.data?.newsFeedPostComments?.pagination;
      return Object.assign({}, prevResultClone);
    });
  };

  useLayoutEffect(() => {
    if (changedCommentRef.current && changedCommentIdFromSearch) {
      const rect = changedCommentRef.current;
      setScrollToCommentScroll(rect.offsetTop - rect.parentElement.offsetTop);
    }
  }, [feedQuery, changedCommentRef, changedCommentIdFromSearch]);

  useLayoutEffect(() => {
    if (changedCommentRef.current && changedCommentForScroll) {
      const rect = changedCommentRef.current;
      setScrollToCommentScroll(rect.offsetTop - rect.parentElement.offsetTop + rect.offsetHeight - 150);
    }
  }, [feedQuery, changedCommentRef, changedCommentForScroll]);

  const setChangedCommentRef = (id: string, ref: HTMLDivElement | null) => {
    if ((id === changedCommentIdFromSearch || id === changedCommentForScroll) && ref) {
      changedCommentRef.current = ref;
    }
  };

  useEffect(() => {
    fetchMandatoryComments();
  }, [changedCommentIdFromSearch]);

  const setSliced = (isSliced: boolean) => {
    if (isSliced) {
      history.push({
        pathname: location.pathname,
        search: qs.stringify({
          ...searchData,
          previewModalIsOpenedText: undefined,
        }),
      });
      return;
    }
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...searchData,
        previewModalIsOpenedText: true,
      }),
    });
  };

  /* WORKING WITH SLIDER */
  const settingsForSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
  };

  const imagesControlHandler = (e) => {
    const { current } = slider;

    if (!current) {
      return null;
    }

    if (e.keyCode === 39) {
      current.slickNext();
    }

    if (e.keyCode === 37) {
      current.slickPrev();
    }
  };

  useLayoutEffect(() => {
    const { current } = slider;
    window.addEventListener('keydown', imagesControlHandler, true);
    if (current) {
      current.slickGoTo(changedInitialImageKey || 0);
    }

    return () => {
      window.removeEventListener('keydown', imagesControlHandler, true);
    };
  }, []);

  useEffect(() => {
    // COMMENT UPDATED SUBSCRIPTION
    feedQuery.subscribeToMore({
      document: ON_COMMENT_UPDATED,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedComment = subscriptionData?.data?.onNewsFeedCommentUpdated;
        const feedDataClone = JSON.parse(JSON.stringify(prev));

        if (!updatedComment) {
          return prev;
        }
        const { _id, parentCommentId } = updatedComment;

        const changedFeed = feedDataClone?.newsFeedPostById;

        if (changedFeed) {
          const changedCommentIndex = changedFeed.comments.data.findIndex((elem) => elem._id === _id);

          if (changedCommentIndex !== -1) {
            changedFeed.comments.data.splice(changedCommentIndex, 1, updatedComment);
          } else {
            const changedParentComment = changedFeed.comments.data.find((elem) => elem._id === parentCommentId);

            if (changedParentComment) {
              const changedCommentIndex = changedParentComment.comments.data.findIndex((elem) => elem._id === _id);

              changedParentComment.comments.data.splice(changedCommentIndex, 1, updatedComment);
            }
          }

          return Object.assign({}, feedDataClone);
        }

        return prev;
      },
    });

    // COMMENT ADD SUBSCRIPTION
    feedQuery.subscribeToMore({
      document: ON_COMMENT_ADDED,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedComment = subscriptionData?.data?.onNewsFeedCommentAdded;
        let feedDataClone = JSON.parse(JSON.stringify(prev));

        if (!updatedComment) {
          return prev;
        }
        const { parentCommentId } = updatedComment;

        const changedFeed = feedDataClone?.newsFeedPostById;

        if (changedFeed) {
          if (parentCommentId) {
            const changedParentComment = changedFeed.comments.data.find((elem) => elem._id === parentCommentId);
            const changedComment = changedParentComment.comments.data.find((elem) => elem._id === updatedComment._id);

            if (changedComment) {
              return prev;
            }

            if (changedParentComment) {
              changedParentComment.comments.data.push(updatedComment);
            }
          } else {
            const changedComment = changedFeed.comments.data.find((elem) => elem._id === updatedComment._id);

            if (changedComment) {
              return prev;
            }

            changedFeed.comments.data.push(updatedComment);
          }

          return Object.assign({}, feedDataClone);
        }

        return prev;
      },
    });

    // COMMENT DELETE SUBSCRIPTION
    feedQuery.subscribeToMore({
      document: ON_COMMENT_DELETED,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedComment = subscriptionData?.data?.onNewsFeedCommentDeleted;
        const feedDataClone = JSON.parse(JSON.stringify(prev));

        if (!updatedComment) {
          return prev;
        }
        const { _id, parentCommentId } = updatedComment;

        const changedFeed = feedDataClone?.newsFeedPostById;

        if (changedFeed) {
          const changedCommentIndex = changedFeed.comments.data.findIndex((elem) => elem._id === _id);

          if (changedCommentIndex !== -1) {
            changedFeed.comments.data.splice(changedCommentIndex, 1);
          } else {
            const changedParentComment = changedFeed.comments.data.find((elem) => elem._id === parentCommentId);

            if (changedParentComment) {
              const changedCommentIndex = changedParentComment.comments.data.findIndex((elem) => elem._id === _id);

              changedParentComment.comments.data.splice(changedCommentIndex, 1);
            }
          }

          return Object.assign({}, feedDataClone);
        }

        return prev;
      },
    });
  }, []);

  /* WORKING WITH REACTIONS */
  const [setReaction] = useMutation(SET_NEWS_FEED_POST_REACTION, {
    onCompleted: refetchReactions,
    onError: () => message.error('Error during setting reaction'),
  });

  const [unsetReaction] = useMutation(UNSET_NEWS_FEED_POST_REACTION, {
    onCompleted: refetchReactions,
    onError: () => message.error('Error during setting reaction'),
  });

  const postReactions: IBestReactionModel[] = useMemo(() => getPostReactions(reactions), [reactions]);
  const bestReactions = useMemo(
    () => postReactions.sort((firstElem, secondElem) => secondElem.count - firstElem.count).slice(0, 3),
    [postReactions],
  );
  const allReactions = useMemo(() => {
    return postReactions.reduce((acc, current) => acc + current.count, 0);
  }, [postReactions]);
  // eslint-disable-next-line no-unused-vars
  const [isVisibleOverlay, setIsVisibleOverlay] = useState(false);
  const closeReactionsDropdown = useCallback(() => setIsVisibleOverlay(false), [setIsVisibleOverlay]);
  const setReactionHandler = useCallback(
    (reactionType: string) => {
      setReaction({ variables: { postId: _id, type: reactionType } });
      emitReactPost({ postType: mapPostTypes(type), reactionType: reactionType.toLowerCase() });
    },
    [_id],
  );
  const unSetReactionHandler = useCallback(
    (type: string) => {
      unsetReaction({ variables: { postId: _id, type } });
    },
    [_id],
  );

  const changedReaction: IReactionData | null = useMemo(() => {
    if (reactions) {
      const key = Object.keys(reactions).find((elem) => reactions[elem]?.includes(idOfCurrentUser || ''));

      if (key) {
        const reaction = reactionsTypes[key];

        if (reaction) {
          return reaction;
        }
      }
      return null;
    }
  }, [reactions, idOfCurrentUser, feedData]);

  const targetEmployeesClickHandler = (id: string) => {
    onClose();
    history.push(`${profileRoutes.profile}${id}`);
  };

  const badgeElement = useMemo(() => {
    const badgeElement = (badgesData?.newsFeedAllBadges || []).find((elem) => elem._id === badge);
    return badgeElement || null;
  }, [badge, badgesData]);

  const parsedTargetEmployees = useMemo(() => {
    if (!targetEmployees?.length) {
      return [];
    }
    const targetEmployeesClone = [...targetEmployees];

    const index = targetEmployeesClone.findIndex((elem) => elem.userId === idOfCurrentUser);

    if (index !== -1) {
      const [deletedElement] = targetEmployeesClone.splice(index, 1);
      targetEmployeesClone.unshift(deletedElement);
    }

    return targetEmployeesClone;
  }, [targetEmployees]);

  const composeTargetEmployeesText = (verb: string) => {
    if (!parsedTargetEmployees?.length) {
      return '';
    }

    const targetEmployeesClone = [...parsedTargetEmployees];

    const recognizeForMessage = isRecognitionPostType ? ` for ` : '';
    return (
      <TargetLinks>
        {targetEmployeesClone.map((el, i) => {
          const isLastTarget = i === targetEmployeesClone.length - 1;
          return (
            <Fragment>
              {i > 0 && `${isLastTarget ? ' and' : ','} `}
              <TargetLink onClick={() => targetEmployeesClickHandler(el.userId)}>{el.fullName}</TargetLink>
            </Fragment>
          );
        })}
        <span>
          {targetEmployeesClone.length > 1 ? ` were ${verb}${recognizeForMessage}` : ` was ${verb}${recognizeForMessage}`}
        </span>
      </TargetLinks>
    );
  };

  const ChangedReactionImage = changedReaction?.image;

  const mentionsClickHandler = (e) => {
    const targetElement: any = e.target;
    if (targetElement?.className === EDITOR_CONFIG.class) {
      const userId = targetElement.getAttribute(EDITOR_CONFIG.dataIdentifier);
      history.push(`${profileRoutes.profile}${userId}`);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const editorFocus = () => {
    editorRef?.current?.editor?.focus();
  };

  const editCommentHandler = (id: string) => {
    setReplyCommentId(null);
    setChangedCommentId(id);
    editorFocus();
  };

  const cancelCommentHandler = () => {
    setChangedCommentId(null);
  };

  const replyCommentHandler = (id: string) => {
    setChangedCommentId(null);
    setReplyCommentId(id);
    editorFocus();
  };

  const cancelReplyHandler = () => {
    setReplyCommentId(null);
  };

  const toProfile = (userId: string) => {
    onClose();
    history.push(`/profile/${userId}`);
  };

  const addMentionsToText = (text) => {
    let res = text;

    if (mentions?.length) {
      mentions.forEach((m) => {
        res = res.replace(
          new RegExp(m.fullName, 'g'),
          `<span class="post_mention" contenteditable="false" data-mention-name="${m.fullName}" data-mention-id="${m.userId}">${m.fullName}</span>`,
        );
      });
    }

    return res;
  };

  const plainText = useMemo(() => richTextToPlain(text || ''), [text]);

  const slicedText = useMemo(() => {
    if (plainText.length > 80) {
      let subText = plainText.slice(0, 80);
      subText = addMentionsToText(subText);
      return `<span>${subText}...</span>`;
    }

    return plainText;
  }, [text]);

  const pdfFiles = useMemo(() => files?.filter((elem) => getFileExtension(elem?.fileName || 'png') === 'pdf') || [], [files]);

  const fetchMoreComments = async () => {
    const changedFeed = feedQuery?.data?.newsFeedPostById;

    if (!changedFeed || !changedFeed.comments.pagination.nextCursor) {
      return;
    }

    const comments = await apolloClient.query({
      query: GET_COMMENTS_BY_ID,
      variables: {
        limit: 10,
        nextCursor: changedFeed.comments.pagination.nextCursor,
        postId: _id,
      },
    });

    const fetchMorePromise = new Promise<boolean>((resolve) => {
      feedQuery.updateQuery((prevResult) => {
        const prevResultClone = JSON.parse(JSON.stringify(prevResult));
        const changedFeed = prevResultClone?.newsFeedPostById;

        if (!changedFeed || !comments.data) {
          return Object.assign({}, prevResultClone);
        }

        changedFeed.comments.data = [...changedFeed.comments.data, ...comments.data.newsFeedPostComments.data];
        changedFeed.comments.pagination = comments.data.newsFeedPostComments.pagination;
        return Object.assign({}, prevResultClone);
      });

      setTimeout(() => resolve(true), 400);
    });

    const res = await fetchMorePromise;

    if (res) {
      setScrollToCommentScroll(999999);
    }
  };

  const changeComment = (id: string) => {
    setChangedCommentForScroll(id);
  };

  if (!feedData) {
    return null;
  }

  if (
    feedData.type === POST_TYPES.HR_BIRTHDAY_WISHES ||
    feedData.type === POST_TYPES.HR_NEW_HIRES ||
    feedData.type === POST_TYPES.HR_PROMOTION ||
    feedData.type === POST_TYPES.HR_WORK_ANNIVERSARY
  ) {
    return (
      <PreviewHrPostsModal
        comments={filteredComments || []}
        data={feedData}
        isOpen={Boolean(feedData)}
        onClose={onClose}
        userAvatar={userAvatar}
        mentionsClickHandler={mentionsClickHandler}
        setChangedCommentRef={setChangedCommentRef}
        scrollTo={scrollToCommentScroll}
        fetchMoreComments={fetchMoreComments}
        showMoreCommentsCount={showMoreCommentsCount}
        changeCommentForScroll={changeComment}
      />
    );
  }

  return (
    <StyledPreviewModal
      visible={!!feedData}
      onCancel={onClose}
      footer={null}
      width={1200}
      doNotHaveImages={!files || files.length <= 0}
    >
      {postId && isVisibleReactionsModal && (
        <ViewReactionsModal
          isVisible={isVisibleReactionsModal}
          onClose={() => setIsVisibleReactionsModal(false)}
          postId={postId}
        />
      )}
      <MainPreviewModalContainer>
        {files && files.length > 0 && (
          <StyledSliderContainer className="hide-on-mobile">
            <StyledSlider {...settingsForSlider} ref={slider}>
              {files.map((elem, key) => {
                const extension = getFileExtension(elem?.fileName || 'png');

                if (extension === 'pdf') {
                  return (
                    <StyledSlide key={key}>
                      <PdfMainContainer>
                        <CustomScroll flex={1}>
                          <PdfContainer src={elem} autoScale />
                        </CustomScroll>
                      </PdfMainContainer>
                    </StyledSlide>
                  );
                }

                return (
                  <StyledSlide key={key}>
                    <PreviewModalImage src={elem.fileUrl} />
                  </StyledSlide>
                );
              })}
            </StyledSlider>
          </StyledSliderContainer>
        )}
        <SideContainer>
          <PreviewModalContainer style={!isFullText ? {} : { flex: 1 }}>
            {(feedData.type === POST_TYPES.COMPANY_NEWS || feedData.type === POST_TYPES.TEAM_NEWS) && (
              <PreviewModalHeader>
                {feedData.type === POST_TYPES.COMPANY_NEWS ? (
                  <Fragment>
                    <img src={company.logo} />
                    <p>Posted for {company.name}</p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <CostCenterImage src={CostCenterIcon} />
                    <p>Posted for {costCenter?.name} team</p>
                  </Fragment>
                )}
              </PreviewModalHeader>
            )}
            <InnerWrapper>
              <MainUserContainer>
                <UserContainer onClick={() => author && toProfile(author.id)}>
                  {!author ? (
                    <StyledPreviewAvatar src={company.logo} alt={'logo'} />
                  ) : (
                    <StyledPreviewAvatar
                      alt={'logo'}
                      src={<AntImage preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />}
                    />
                  )}
                  <UserNameContainer>
                    <Name level={5}>{!author ? company.name : preferredName}</Name>
                    <SubName>{moment(publishingDate).format('MMM DD, YYYY')}</SubName>
                  </UserNameContainer>
                </UserContainer>
              </MainUserContainer>
              <TextScrollContainer
                isSliced={!isFullText}
                isTeamNewsPost={feedData.type === POST_TYPES.TEAM_NEWS || feedData.type === POST_TYPES.COMPANY_NEWS}
              >
                <CustomScroll flex={'1'} freezePosition={!isFullText} scrollTo={!isFullText ? 0 : undefined}>
                  <MainDescriptionContainer>
                    {pdfFiles.length > 0 && (isThanksPostType || isRecognitionPostType) && (
                      <Fragment>
                        {(isFullText ? pdfFiles : pdfFiles.slice(0, 2)).map((elem) => {
                          return (
                            <PdfFile>
                              <StyledAttachment />
                              <StyledAttachmentLink href={elem.fileUrl} target={'_blank'}>
                                <p>{elem.fileName}</p>
                              </StyledAttachmentLink>
                              <a href={elem.fileUrl} download target={'_blank'}>
                                <StyledDownloadAttachment />
                              </a>
                            </PdfFile>
                          );
                        })}
                      </Fragment>
                    )}
                    {isThanksPostType && (
                      <TargetEmployeesContainer isSliced={!isFullText}>
                        <p>{composeTargetEmployeesText('thanked')}</p>
                      </TargetEmployeesContainer>
                    )}
                    {isRecognitionPostType && (
                      <TargetEmployeesContainer isSliced={!isFullText}>
                        <p>{composeTargetEmployeesText('recognized')}</p>
                      </TargetEmployeesContainer>
                    )}
                    {isFullText && badgeElement && (
                      <IconContainer>
                        <RecognitionBody>
                          <h2>{badgeElement?.name}</h2>
                          <RecognitionCard>
                            <img src={badgeElement?.iconUrl} />
                          </RecognitionCard>
                        </RecognitionBody>
                      </IconContainer>
                    )}
                    {isFullText && isThanksPostType && (
                      <CardsContainer $repeat={targetEmployees.length > 1 ? 2 : 1}>
                        {targetEmployees.map((elem, key) => {
                          const { photoUrl, userId } = elem;
                          return (
                            <Card key={key} onClick={() => targetEmployeesClickHandler(userId)}>
                              <StyledImage src={photoUrl || emptyAvatar} fallback={emptyAvatar} preview={false} />
                            </Card>
                          );
                        })}
                      </CardsContainer>
                    )}
                    {isFullText && isRecognitionPostType && (
                      <CardsContainer $repeat={targetEmployees.length > 1 ? 2 : 1}>
                        {targetEmployees.map((elem, key) => {
                          const { photoUrl, userId } = elem;
                          return (
                            <Card key={key} onClick={() => targetEmployeesClickHandler(userId)}>
                              <StyledImage src={photoUrl || emptyAvatar} fallback={emptyAvatar} preview={false} />
                            </Card>
                          );
                        })}
                      </CardsContainer>
                    )}
                    {isThanksPostType || isRecognitionPostType ? (
                      <Fragment>
                        {pdfFiles.length > 0 ? (
                          isFullText ? (
                            <DescriptionContainer
                              onClick={mentionsClickHandler}
                              isSliced={!isFullText}
                              dangerouslySetInnerHTML={{ __html: text || '' }}
                            />
                          ) : null
                        ) : (
                          <DescriptionContainer
                            onClick={mentionsClickHandler}
                            isSliced={!isFullText}
                            dangerouslySetInnerHTML={{ __html: text || '' }}
                          />
                        )}
                        {!isFullText && <ViewAllText onClick={() => setSliced(false)}>See more</ViewAllText>}
                      </Fragment>
                    ) : !isFullText ? (
                      <DescriptionContainer>
                        {pdfFiles.length > 0 && (
                          <PdfFileList>
                            {pdfFiles.slice(0, 2).map((elem) => {
                              return (
                                <PdfFile>
                                  <StyledAttachment />
                                  <StyledAttachmentLink href={elem.fileUrl} target={'_blank'}>
                                    <p>{elem.fileName}</p>
                                  </StyledAttachmentLink>
                                  <a href={elem.fileUrl} download target={'_blank'}>
                                    <StyledDownloadAttachment />
                                  </a>
                                </PdfFile>
                              );
                            })}
                          </PdfFileList>
                        )}
                        <div onClick={mentionsClickHandler}>
                          <span dangerouslySetInnerHTML={{ __html: slicedText || '' }} />
                        </div>
                        {plainText.length > 80 || pdfFiles.length > 2 ? (
                          <ViewAllText onClick={() => setSliced(false)}>See more</ViewAllText>
                        ) : null}
                      </DescriptionContainer>
                    ) : (
                      <Fragment>
                        <PdfFileList>
                          {pdfFiles.map((elem) => {
                            return (
                              <PdfFile>
                                <StyledAttachment />
                                <StyledAttachmentLink href={elem.fileUrl} target={'_blank'}>
                                  <p>{elem.fileName}</p>
                                </StyledAttachmentLink>
                                <a href={elem.fileUrl} download target={'_blank'}>
                                  <StyledDownloadAttachment />
                                </a>
                              </PdfFile>
                            );
                          })}
                        </PdfFileList>
                        <DescriptionContainer
                          onClick={mentionsClickHandler}
                          isSliced={!isFullText}
                          dangerouslySetInnerHTML={{ __html: text || '' }}
                        />
                      </Fragment>
                    )}
                  </MainDescriptionContainer>
                </CustomScroll>
              </TextScrollContainer>
            </InnerWrapper>
          </PreviewModalContainer>
          {files && files.length > 0 && (
            <StyledSliderContainer className="show-on-mobile">
              <StyledSlider {...settingsForSlider} ref={slider}>
                {files.map((elem, key) => {
                  const extension = getFileExtension(elem?.fileName || 'png');

                  if (extension === 'pdf') {
                    return (
                      <StyledSlide key={key}>
                        <PdfMainContainer>
                          <CustomScroll flex={1}>
                            <PdfContainer src={elem} autoScale />
                          </CustomScroll>
                        </PdfMainContainer>
                      </StyledSlide>
                    );
                  }

                  return (
                    <StyledSlide key={key}>
                      <PreviewModalImage src={elem.fileUrl} />
                    </StyledSlide>
                  );
                })}
              </StyledSlider>
            </StyledSliderContainer>
          )}
          <Fragment>
            <CommentsMainContainer>
              <CommentsContainer>
                <MainReactionsContainer onClick={() => setIsVisibleReactionsModal(true)}>
                  <ReactionsContainer>
                    {bestReactions &&
                      bestReactions.map((elem, i) => {
                        const { type } = elem;
                        const { image: ReactionSVG, background } = reactionsTypes[type] || {};
                        return (
                          <ReactionContainer key={`${i}_${type}`} style={{ background }}>
                            <ReactionSVG />
                          </ReactionContainer>
                        );
                      })}
                  </ReactionsContainer>
                  <p>{allReactions} reactions</p>
                </MainReactionsContainer>
                <p onClick={() => setSliced(true)}>{commentsCount || 0} comments</p>
              </CommentsContainer>
            </CommentsMainContainer>
            <ButtonsContainer isOpenedComments={!isFullText}>
              <StyledDropdown
                overlay={<ReactionsPopover setReaction={setReactionHandler} closeReactionsDropdown={closeReactionsDropdown} />}
                placement="topCenter"
                trigger={['hover']}
              >
                {ChangedReactionImage ? (
                  <StyledButton
                    isChangedReaction={true}
                    onClick={() => changedReaction?.type && unSetReactionHandler(changedReaction.type)}
                  >
                    <ChangedReactionImage />
                    <p>{changedReaction?.type.toLowerCase()}</p>
                  </StyledButton>
                ) : (
                  <StyledButton onClick={() => setReactionHandler('LIKE')}>
                    <StyledLike />
                    <p>Like</p>
                  </StyledButton>
                )}
              </StyledDropdown>
              <StyledButton
                onClick={() => {
                  setSliced(true);
                  editorFocus();
                }}
              >
                <Comment />
                <p>Comment</p>
              </StyledButton>
            </ButtonsContainer>
          </Fragment>
          {!isFullText && (
            <CommentsInputAndScrollContainer>
              <CommentsScrollContainer
                style={!isFullText ? ((comments?.data || []).length <= 0 ? { maxHeight: '100%', flex: 1 } : { flex: 1 }) : {}}
                isCompanyNewsType={
                  feedData.type !== POST_TYPES.COMPANY_NEWS &&
                  feedData.type !== POST_TYPES.TEAM_NEWS &&
                  (comments?.data || []).length > 0
                }
              >
                {showMoreCommentsCount > 0 && (
                  <ShowMoreButton onClick={fetchMoreComments}>Show {showMoreCommentsCount} comments</ShowMoreButton>
                )}
                {!isFullText && (
                  <CustomScroll flex={'1'} scrollTo={scrollToCommentScroll}>
                    <MainCommentsContainer isEditMode={Boolean(changedCommentId || replyCommentId)}>
                      <Comments
                        isOpened={!isFullText}
                        onReply={replyCommentHandler}
                        isFullPost={true}
                        editHandler={editCommentHandler}
                        postId={postId}
                        isFullModal={true}
                        mentionsClickHandler={mentionsClickHandler}
                        setChangedCommentRef={setChangedCommentRef}
                        commentsData={filteredComments || []}
                        changeCommentForScroll={changeComment}
                      />
                    </MainCommentsContainer>
                  </CustomScroll>
                )}
              </CommentsScrollContainer>
              <CommentInputContainer isHidden={isFullText}>
                <CommentsMainInputContainer>
                  {changedCommentId && (
                    <CommentsControlContainer>
                      <p>Editing comment</p> <Point /> <CancelButton onClick={cancelCommentHandler}>Cancel</CancelButton>
                    </CommentsControlContainer>
                  )}
                  {replyCommentId && (
                    <CommentsControlContainer>
                      <p>
                        Replying to
                        <span>{changedReplyComment?.author.fullName}</span>
                      </p>
                      <Point /> <CancelButton onClick={cancelReplyHandler}>Cancel</CancelButton>
                    </CommentsControlContainer>
                  )}
                  <CommentsInput
                    postId={feedData._id}
                    comments={comments?.data || []}
                    changedCommentId={changedCommentId}
                    cancelCommentHandler={cancelCommentHandler}
                    replyCommentId={replyCommentId}
                    cancelReplyHandler={cancelReplyHandler}
                    userAvatar={userAvatar}
                    editorRef={editorRef}
                    postType={feedData.type}
                  />
                </CommentsMainInputContainer>
              </CommentInputContainer>
            </CommentsInputAndScrollContainer>
          )}

          <StyledCloseIcon onClick={onClose} />
        </SideContainer>
      </MainPreviewModalContainer>
    </StyledPreviewModal>
  );
};

export default PreviewModal;
