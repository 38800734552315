import React from 'react';
import ReactDOM from 'react-dom';
import { stopReportingRuntimeErrors } from 'react-error-overlay';
import './vendor';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'development') {
  stopReportingRuntimeErrors(); // disables error overlays
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
