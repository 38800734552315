import styled from 'styled-components';
import { Avatar } from 'antd';

import { ReactComponent as TerminatedIcon } from '../../../../../assets/images/popovers/terminated.svg';
import { ReactComponent as InactiveIcon } from '../../../../../assets/images/popovers/inactive.svg';
import { ReactComponent as ActiveIcon } from '../../../../../assets/images/popovers/active.svg';
import { ReactComponent as EmptyArticle } from '../../../assets/globalSearch/emptyArticle.svg';

export const StyledTerminatedIcon = styled(TerminatedIcon)`
  margin-left: 10px;
`;

export const StyledInactiveIcon = styled(InactiveIcon)`
  margin-left: 10px;
`;

export const StyledActiveIcon = styled(ActiveIcon)`
  margin-left: 10px;
`;

export const MoreContainer = styled.div`
  border-bottom: 1px solid #eff0f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  padding-bottom: 3px;
  color: #6b778c;

  p {
    color: #6b778c;
    padding: 0;
    margin: 0;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const MoreMainContainer = styled.div``;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  user-select: none;
  cursor: pointer;

  h2 {
    color: #111111;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    margin-left: 8px;
  }
`;

export const PostContainer = styled(UserContainer)`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 24px 1fr;
`;

export const PostTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: pointer;
  max-width: 100%;

  h1 {
    color: #111111;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    padding: 0;
    margin: 0;
    margin-left: 8px;
  }
  & > h2 {
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #111 !important;
  }
  & > p {
    padding-left: 8px;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #111 !important;
    margin: 0;
    width: 100%;
    word-break: break-word;
    margin-top: -3px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

export const EmptyAvatarPost = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  background: #1890ff;
`;

export const PostText = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #111111;
  display: flex;
  word-break: break-word;
  max-width: 256px;

  span {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
    padding: 0;
    max-width: 200px;
    max-height: 30px;
    overflow: hidden;
  }
`;

export const ArticleContainer = styled.div`
  display: flex;
  margin-top: 12px;
  cursor: pointer;

  h2 {
    color: #111111;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    margin-left: 8px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledEmptyArticle = styled(EmptyArticle)``;

export const EmptyArticleBox = styled.div`
  background: #1890ff;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const ArticleImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

export const TargetLinks = styled.span`
  text-align: center;
  position: relative;
  margin-top: 12px;
`;

export const TargetLink = styled.span`
  cursor: pointer;
  color: #1890ff;
  position: relative;

  :hover {
    text-decoration: underline;
  }
`;
