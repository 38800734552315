import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip, Image } from 'antd';

import { POST_TYPES } from 'modules/common/const';
import { IUserData, IPost, IArticleData } from '../GlobalSearch';
import {
  MoreContainer as MoreTitleContainer,
  MoreMainContainer,
  UserContainer,
  StyledActiveIcon,
  StyledInactiveIcon,
  StyledTerminatedIcon,
  StyledAvatar,
  PostContainer,
  PostTextContainer,
  // ArticleContainer,
  // ArticleImage,
  // EmptyArticleBox,
  // StyledEmptyArticle,
  TargetLinks,
  TargetLink,
} from './styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { richTextToPlain } from 'utils/parsers';
import { emitSearchClick } from 'utils/mixpanel';
import appRoutes from 'app/routes';
import { IBadge } from '../GlobalSearch';

interface IProps {
  userData?: IUserData[];
  postData?: IPost[];
  articlesData?: IArticleData[];
  closeSearch: () => void;
  searchValue: string;
  openPost: (id: string) => void;
  currentUserId: string;
  badgesData: IBadge[];
}

const composeTargetEmployeesText = (
  post: IPost,
  idOfCurrentUser: string,
  pushHistory: (path: string) => void,
  badgesData: IBadge[],
) => {
  const { post_type, targeted_users, badge: badgeId } = post;
  let parsedTargetEmployees: any = [];
  if (targeted_users?.length) {
    const targetEmployeesClone = [...targeted_users];
    const index = targetEmployeesClone.findIndex((elem) => elem.user_id === idOfCurrentUser);
    if (index !== -1) {
      const [deletedElement] = targetEmployeesClone.splice(index, 1);
      targetEmployeesClone.unshift(deletedElement);
    }
    parsedTargetEmployees = targetEmployeesClone;
  }

  if (!parsedTargetEmployees?.length) {
    return '';
  }

  const isRecognitionType = post_type === POST_TYPES.RECOGNITION;
  const badge = badgesData.find((el) => el._id === badgeId);
  const verb = isRecognitionType ? `recognized for ${badge?.name}.` : 'thanked: ';
  return (
    <TargetLinks>
      {parsedTargetEmployees.map((el, i) => {
        let mentionText = '';
        const isLastTarget = i === parsedTargetEmployees.length - 1;
        const isMoreElement = i === 4;
        if (i >= 0 && i <= 3) {
          mentionText = el.full_name;
        } else if (isMoreElement) {
          const othersCount = parsedTargetEmployees.length - i;
          mentionText = `${othersCount} ${othersCount > 1 ? 'others' : 'other'}`;
        }
        return (
          <>
            {i > 0 && i < 3 && `${isLastTarget ? ' and' : ','} `}
            {isMoreElement ? ',' : ''}
            <TargetLink
              onClick={() => {
                const path = isMoreElement
                  ? `${appRoutes.newsFeed}?changedFeedId=${post.post_id}`
                  : `${appRoutes.profile}/${el.user_id}`;
                pushHistory(path);
              }}
            >
              {mentionText}
            </TargetLink>
          </>
        );
      })}
      <span>{parsedTargetEmployees.length > 1 ? ` were ${verb}` : ` was ${verb}`}</span>
    </TargetLinks>
  );
};

const MoreContainer: FC<IProps> = ({
  userData,
  postData,
  closeSearch,
  searchValue,
  openPost,
  // articlesData,
  currentUserId,
  badgesData,
}) => {
  const history = useHistory();
  const symbolsToSplit = 50;

  const pushHistory = (path: string) => {
    history.push(path);
    closeSearch();
  };

  // TEMPORARY COMMENTED
  // if (!userData?.length && !postData?.length && !articlesData) {
  if (!userData?.length && !postData?.length) {
    return null;
  }

  return (
    <MoreMainContainer>
      {/* {!!articlesData?.length && (
        <>
          <MoreTitleContainer>
            Articles
            <p onClick={() => pushHistory(`/profile/global-search?type=ARTICLE&search=${searchValue}`)}>More</p>
          </MoreTitleContainer>
          {articlesData.map((elem, key) => {
            const { title, image_url, article_id, slug } = elem;
            return (
              <ArticleContainer
                key={key}
                onClick={() => {
                  pushHistory(`/knowledge-base/${article_id}/${slug}`);
                  emitSearchClick({ searchType: 'article' });
                }}
              >
                {image_url ? (
                  <ArticleImage src={image_url} />
                ) : (
                  <EmptyArticleBox>
                    <StyledEmptyArticle />
                  </EmptyArticleBox>
                )}
                <h2>{title}</h2>
              </ArticleContainer>
            );
          })}
        </>
      )} */}
      {!!userData?.length && (
        <>
          <MoreTitleContainer>
            People
            <p onClick={() => pushHistory(`/profile/global-search?type=PEOPLE&search=${searchValue}`)}>More</p>
          </MoreTitleContainer>
          {userData.map((elem, key) => {
            const { user_id, is_active, terminated_at, photo_url, full_name: preferredName } = elem;
            return (
              <UserContainer
                key={key}
                onClick={(e) => {
                  if (terminated_at) {
                    e.preventDefault();
                    return;
                  }
                  emitSearchClick({ searchType: 'people' });
                  pushHistory(`/profile/${user_id}`);
                }}
              >
                <StyledAvatar
                  size={'small'}
                  src={<Image preview={false} src={photo_url || emptyAvatar} fallback={emptyAvatar} />}
                />
                <h2>{preferredName}</h2>
                {terminated_at ? (
                  <Tooltip
                    placement={'bottom'}
                    title={<span>Profile of {preferredName} is no longer available</span>}
                    overlayClassName={'styledTooltip'}
                  >
                    <StyledTerminatedIcon />
                  </Tooltip>
                ) : is_active ? (
                  <StyledActiveIcon />
                ) : (
                  <StyledInactiveIcon />
                )}
              </UserContainer>
            );
          })}
        </>
      )}
      {!!postData?.length && (
        <>
          <MoreTitleContainer>
            Post
            <p onClick={() => pushHistory(`/profile/global-search?type=POST&search=${searchValue}`)}>More</p>
          </MoreTitleContainer>
          {postData.map((elem: IPost) => {
            const {
              text,
              author: { full_name, photo_url },
              post_id,
              post_type,
            } = elem;
            const parsedText = richTextToPlain(text);
            const isThanksType = post_type === POST_TYPES.THANKS;
            const isRecognitionType = post_type === POST_TYPES.RECOGNITION;
            const additionalText =
              isThanksType || isRecognitionType ? composeTargetEmployeesText(elem, currentUserId, pushHistory, badgesData) : '';
            const mainText = parsedText.length >= symbolsToSplit ? `${parsedText.substring(0, symbolsToSplit)}...` : parsedText;

            return (
              <PostContainer
                key={post_id}
                onClick={() => {
                  emitSearchClick({ searchType: 'post' });
                  openPost(post_id);
                }}
              >
                <StyledAvatar
                  size={'small'}
                  src={<Image preview={false} src={photo_url || emptyAvatar} fallback={emptyAvatar} />}
                />
                <PostTextContainer>
                  {full_name && <h2>{full_name}</h2>}
                  {mainText && (
                    <p>
                      {additionalText}
                      {mainText}
                    </p>
                  )}
                </PostTextContainer>
              </PostContainer>
            );
          })}
        </>
      )}
    </MoreMainContainer>
  );
};

export default MoreContainer;
