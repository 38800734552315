import styled from 'styled-components';

import { Typography, Popover, Dropdown, Avatar } from 'antd';
import { ReactComponent as FeaturedPostsIcon } from '../../assets/featuredPostsIcon.svg';
import { ReactComponent as IconMenu } from '../../assets/menu.svg';
import { ReactComponent as ThanksClapIcon } from '../../assets/thanksClap.svg';
import { StyledCard } from '../../styles';
import { ReactComponent as Like } from '../../assets/like.svg';
import { ReactComponent as Attachment } from '../../assets/attachment.svg';

interface IEditable {
  $isEditable?: boolean;
}

export const Menu = styled(IconMenu)<IEditable>`
  cursor: pointer;
  visibility: hidden;
  ${({ $isEditable }) =>
    $isEditable
      ? `
    visibility: visible;
  `
      : ``}
`;

export const StyledAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  overflow: hidden;
`;

export const StyledDropdown = styled(Dropdown)`
  z-index: 0;
`;

export const Card = styled(StyledCard)`
  padding: 0;
  .ant-card-body {
    padding: 0;
  }
`;

interface IContainer {
  $withHeadline?: boolean;
}
export const Container = styled.div<IContainer>`
  padding: 16px 16px 12px 16px;
  ${({ $withHeadline }) => $withHeadline && `padding-top: 0;`}
`;

export const HeaderContainer = styled.div`
  padding: 16px 16px 0 16px;
`;

export const StyledFeaturedPostsIcon = styled(FeaturedPostsIcon)`
  margin-right: 8px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
`;

export const UserContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const UserNameContainer = styled.div`
  margin-left: 7px;
  display: flex;
  flex-direction: column;
`;

export const Name = styled(Typography.Title)`
  margin: 0 !important;
  color: #111 !important;
`;

export const NameContainer = styled.div`
  ${({ isChanged }: { isChanged?: boolean }) =>
    isChanged &&
    `
    ${Name} {
      color: #1890FF !important;
      text-decoration: underline;
      font-weight: 600;
    }
  `};
`;

export const SubName = styled(Typography.Text)`
  font-size: 12px;
  color: #4b4b4b;
`;

interface IPropsDescriptionContainer {
  isSliced?: boolean;
}

export const DescriptionContainer = styled.div<IPropsDescriptionContainer>`
  font-size: 14px;
  font-weight: 400;
  word-break: break-word;
  color: #111;
  ol {
    padding: 0 0 0 20px;
  }
`;

export const SliceButton = styled.div`
  display: inline-block;
  margin-left: 5px;
  color: #1890ff;
  font-size: 14px;
  line-height: 24px;
  user-select: none;
  cursor: pointer;
`;

export const MainDescriptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const HeadlineContainer = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  color: #111111;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MainReactionsContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
  }
`;

export const ReactionsContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ReactionContainer = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border: 3px solid #fff;
  padding: 0;
  margin-left: -5px;
  :first-child {
    margin: 0;
  }

  svg {
    width: auto;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid #e0e0e0;
  margin-top: 16px;
`;

export const StyledLike = styled(Like)`
  width: 16px;
  fill: #111111;
`;

interface IButton {
  isChangedReaction?: boolean;
}

export const Button = styled.div<IButton>`
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 8px;
  fill: #111111;

  p {
    font-size: 14px;
    color: #111111;
    padding: 0 0 0 10px;
    margin: 0;
  }

  :hover {
    ${StyledLike} {
      fill: #1890ff;
    }
    background: #ecf6ff;
    fill: #1890ff;
    p {
      color: #1890ff;
    }
  }

  ${({ isChangedReaction }) =>
    isChangedReaction &&
    `
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #8E72AF;
      text-transform: capitalize;

      :hover {
        p {
          color: #8E72AF;
        }
      }
    }
  `};
`;

export const SeeMoreButton = styled.div`
  color: #1890ff;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
  cursor: pointer;
`;

export const StyledPopover = styled(Popover)`
  .ant-popover-arrow {
    visibility: hidden;
  }
`;

export const ReactionsPopoverContainer = styled.div`
  background: #fff;
  padding: 10px;
  border-radius: 40px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px 17px !important;
`;

export const ReactionElement = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :first-child {
    margin-left: 0;
  }
`;

export const EditPopoverContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 0;
  width: 163px;
  box-sizing: border-box;
  z-index: 0;
  padding: 16px 0;
`;

export const EditPopoverElement = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 24px;
  color: #111111;
  align-items: center;
  cursor: pointer;
  padding: 6px 25px;
  transition: 0.3s;

  :hover {
    background: #ecf6ff;
  }

  img {
    margin-right: 16px;
  }
`;

export const FullSizePostImage = styled.img`
  width: 100%;
  cursor: pointer;
  min-height: 100%;
`;

interface IPostImage {
  $isScalable?: boolean;
}
export const SmallSizePostImage = styled.img<IPostImage>`
  width: 100%;
  cursor: pointer;
  ${({ $isScalable }) => ($isScalable ? 'transform: scale(2)' : '')}
`;

export const PostImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.5);
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const SmallSizeImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: hidden;
  position: relative;
`;

export const FullSizeImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  overflow: hidden;
  padding: 0 12px;
`;

export const MiddleSizeImagesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  cursor: pointer;
  padding: 0 12px;
`;

export const MainBigSizeContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  cursor: pointer;
  padding: 0 12px;
  overflow: hidden;
`;

export const SmallSizeImagesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 300px 200px;
  grid-gap: 8px;
  cursor: pointer;
`;

export const ImagesMainContainer = styled.div`
  width: 100%;
`;

export const StyledFollowPopover = styled(Popover)``;

export const ViewReactionsButton = styled.p`
  cursor: pointer;
`;

export const Header = styled.div`
  color: #111111;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
`;

export const MainContainerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 12px 0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 16px;
`;

export const HideButton = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 24px;
    color: #1890ff;
    margin: 0 0 0 8px;
    padding: 0;
    font-weight: 600;
  }
`;

export const BehalfContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 15px;
  }

  p {
    color: #6b778c;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 0 8px;
    padding: 0;
  }
`;

export const BehalfTeamNewsContainer = styled(BehalfContainer)`
  p {
    margin: 0;
  }
`;

export const BehalfHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 15px;
  }

  p {
    color: #111;
    margin: 0 0 0 8px;
    padding: 0;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const BehalfTeamNewsHeaderContainer = styled(BehalfHeaderContainer)`
  p {
    margin: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ThanksClap = styled(ThanksClapIcon)`
  position: relative;
`;

export const ThanksDisclaimer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .thanks_mention {
    cursor: pointer;
    color: #1890ff;
  }
  .thanks_mention:hover {
    text-decoration: underline;
  }
  span {
    margin-right: 5px;
  }
`;

export const StyledAnd = styled.p`
  margin: 0 5px 0 0;
  padding: 0;
`;

export const Comma = styled.p`
  margin: 0 5px 0 -5px !important;
`;

export const RecognitionBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;

  h2 {
    color: #4b4b4b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const RecognitionCard = styled.div`
  width: 136px;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  img {
    width: 100%;
  }
`;

export const CostCenterImage = styled.img`
  margin-right: 5px;
`;

export const CommentInputContainer = styled.div`
  width: 100%;
  padding: 10px 16px 16px 16px;

  .rcs-inner-handle {
    background-color: #e4e4e4;
  }
  .rcs-custom-scrollbar {
    width: 4px;
  }
`;

export const CommentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
`;

export const CommentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  p {
    font-size: 12px;
    color: #9597a1;
    margin: 0;
    cursor: pointer;
  }
`;

export const CommentsMainContainer = styled.div`
  max-height: 350px;
  overflow: auto;
  width: 100%;
`;

export const CommentsMainInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const SingledPdfContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow: hidden;
`;

export const MiddlePdfContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const PdfName = styled.a`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    color: #1890ff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }
`;

export const StyledAttachment = styled(Attachment)``;

export const FullSizePdfImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  max-height: 280px;
  display: flex;
  align-items: center;
`;

export const SmallSizePdfImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  max-height: 150px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const ShowMoreButton = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-top: 12px;
  cursor: pointer;
  user-select: none;
`;
