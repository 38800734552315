import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonContainer = styled.div`
  margin-top: 8px;
  button {
    border-radius: 4px;
    font-weight: 600;
  }
`;

export const StyledAcceptButton = styled(Button)``;

export const StyledDeclineButton = styled(Button)`
  color: #1890FF;
  border-color: #1890FF;
  margin-left: 5px;
`;