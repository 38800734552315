const appRoutes = {
  home: '/',
  auth: '/auth',
  profile: '/profile',
  newsFeed: '/news-feed',
  team: '/team',
  careers: '/careers',
  knowledgeBase: '/knowledge-base',
  backOffice: '/admin',
  error: '/error',
  support: '/support',
};

export default appRoutes;
