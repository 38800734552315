export const richTextToPlainWithMention = (fullName: string, text: string) => {
  let element = document.createElement('DIV');
  element.innerHTML = text;
  const textOfElement = element.textContent || element.innerText || '';

  return `<p>${textOfElement.replace(fullName, `<span>${fullName}</span>`)}</p>`;
};

export const richTextToPlain = (richText: string) => {
  if (!richText) {
    return '';
  }
  // Special case with <br>
  const textWithoutBreaks = richText.replaceAll('<br />', ' ');
  const filteredRichText = textWithoutBreaks.replaceAll(/<\!--.*?-->/g, '');
  let tmp = document.createElement('DIV');
  tmp.innerHTML = filteredRichText;
  return tmp.textContent || tmp.innerText || '';
};
