import React, { FC } from 'react';

import { HeaderCard, InputContainer, SearchButton, SearchInput, StyledSearchIcon } from './styles';

interface IProps {
  searchValue: string;
  setInputSearchValue: (value: string) => void;
  searchHandler: () => void;
  placeholder: string;
  isArticleType: boolean;
}

const GlobalSearchInput: FC<IProps> = ({ searchHandler, searchValue, setInputSearchValue, placeholder, isArticleType }) => {
  const enterPressHandler = (e) => {
    if (e.keyCode === 13) {
      searchHandler();
    }
  };

  return (
    <HeaderCard isArticleType={isArticleType}>
      <InputContainer>
        <SearchInput
          placeholder={placeholder}
          onChange={(e) => setInputSearchValue(e.target.value)}
          value={searchValue}
          onKeyDown={enterPressHandler}
        />
      </InputContainer>
      <SearchButton onClick={searchHandler}>
        <StyledSearchIcon />
        Search
      </SearchButton>
    </HeaderCard>
  );
};

export default GlobalSearchInput;
