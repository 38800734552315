import styled, { css } from 'styled-components';

export const SpinnerMainContainer = styled.div`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 18px;
  height: 18px;

  border: 2px solid #1890ff;
  border-top: 3px solid transparent;
  border-radius: 100%;

  position: absolute;
  top: calc(50% - 9px);
  bottom: 0;
  left: calc(50% - 9px);
  right: 0;
  margin: 0;

  animation: spin 1s infinite linear;

  ${({ width, height }: { width?: number; height?: number }) =>
    width &&
    height &&
    css`
      width: ${`${width}px`};
      height: ${`${height}px`};
      top: ${`calc(50% - ${width / 2 + 'px'})`};
      left: ${`calc(50% - ${height / 2 + 'px'})`};
    `}

  ${({ color }: { color?: string }) =>
    color &&
    css`
      border: 2px solid ${color};
      border-top: 3px solid transparent;
    `}
`;
