import styled, { css } from 'styled-components';
import { Modal, Avatar, Image, Input } from 'antd';
import { ReactComponent as Case } from 'modules/news-feed/pages/Feed/assets/hrPosts/case.svg';
import { ReactComponent as Close } from 'modules/news-feed/pages/Feed//assets/hrPosts/close.svg';

export const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: 4px;
  }

  .rcs-inner-handle {
    background-color: #e4e4e4;
  }
  .rcs-custom-scrollbar {
    width: 4px;
  }
  position: relative;
`;

export const MainContainer = styled.div`
  width: 400px;
  padding: 24px 16px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4b4b4b;
    padding: 0;
    margin: 0;
  }
`;

export const StyledClose = styled(Close)`
  cursor: pointer;
`;

export const StyledElement = styled.div`
  width: 90%;
  align-items: center;
  display: grid;
  grid-template-columns: 40px 1fr;
  cursor: pointer;
`;

export const StyledAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  margin-left: 16px;
  position: relative;
  height: 64px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    margin: 0;
    padding: 0;
  }
`;

export const Line = styled.div`
  background: #eff0f1;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const ElementsContainer = styled.div`
  margin-top: 36px;
  border-bottom: 1px solid #eff0f1;
`;

export const MainCommentsContainer = styled.div`
  width: 100%;
  padding-bottom: 45px;

  ${({ isEditMode }: { isEditMode?: boolean }) =>
    isEditMode &&
    css`
      padding-bottom: 80px;
    `}
`;

export const CommentsContainer = styled.div`
  width: 100%;
  padding: 0 20px 0 0;
`;

export const CommentsInputContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  background: #fff;
  z-index: 999;
  box-shadow: 0px -3px 13px rgba(0, 0, 0, 0.08);
`;

export const CommentInput = styled(Input)`
  background: rgba(239, 240, 241, 0.4);
  border-radius: 40px;
  border: none;

  input {
    color: #9da5ac;
    font-size: 14px;
    background: transparent;
  }
`;

export const Subtitle = styled.span`
  position: relative;
  margin-top: -7px;
`;

export const CaseIcon = styled(Case)`
  position: relative;
  margin-right: 6px;
`;

export const ShowMoreButton = styled.div`
  color: #1890ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-top: 12px;
  cursor: pointer;
  user-select: none;
  margin-left: 8px;
`;
