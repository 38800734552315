import React, { useMemo, useState, useRef, useLayoutEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { message, Image } from 'antd';
import CustomScroll from 'react-custom-scroll';

import {
  StyledClubView,
  MainContainer,
  Header,
  StyledTitle,
  ClubViewContainer,
  ClubViewMainContainer,
  StyledAvatar,
  AvatarContainer,
  StyledFollowPopover,
  EmptyClubViewMainContainer,
  EmptyClubViewContainer,
  StyledAvatarContainer,
  StyledTooltipContainer,
  ClubViewUsersContainer,
  UsersLoadingContainer,
  SpinnerContainer,
  UsersContainer,
  MainClubViewContainer,
  HeaderClubViewContainer,
} from './styles';
import { GET_FOLLOWED_USERS, GET_DEFAULT_CLUB_VIEW, GET_CLUB_VIEW_USERS } from '../../gql/query';
import { FOLLOW_AUTHOR, UNFOLLOW_AUTHOR } from '../../gql/mutation';
import SubHeader from './components/SubHeader';
import FollowPopover from 'modules/common/components/popovers/FollowPopover';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import emptyClubView from '../../assets/emptyClubView.png';
import { IFollowStatus } from 'modules/common/gql/models/user.model';
import Spinner from 'modules/common/components/Spinner';
import { emitFollowRequest } from 'utils/mixpanel';
import DefaultElement from './components/DefaultElement';

interface IAddress {
  id: string;
  countryCode: string;
  usageType: string;
  municipality: string;
}

export interface IUser {
  id: string;
  fullName: string;
  photoUrl?: string;
  job: {
    positionTitle: string;
    addresses: IAddress[];
  };
  workingAddress: IAddress;
  terminatedAt: string | null;
  isActive: boolean;
}

export interface IAppliedFilters {
  interestFilters: {
    name: string;
    id: string;
  }[];
  skillFilters: {
    name: string;
    id: string;
  }[];
  adressFilter: {
    city: string;
  };
  jobFilter: {
    title: string;
  };
  costCenterFilter: {
    name: string;
    id: string;
  };
}

export interface IClubViewElement {
  appliedFilters: IAppliedFilters;
  data: {
    data: IUser[];
  };
}

export interface IClubViewDataResult {
  getDefaultClubViewV2: {
    data: IClubViewElement[];
  };
}

export interface IAuthorUser {
  status: IFollowStatus;
  authorUser: {
    id: string;
  };
}

export interface IFollowedAuthorsResult {
  getMyFollowedAuthors: {
    data: IAuthorUser[];
  };
}

export interface IFilters {
  COST_CENTER: string[];
  TENURE: string[];
  CITY: string[];
  HOBBIES: string[];
  SKILLS: string[];
}

export const defaultFilters: IFilters = {
  COST_CENTER: [],
  TENURE: [],
  CITY: [],
  HOBBIES: [],
  SKILLS: [],
};

export type FiltersTypes = 'COST_CENTER' | 'TENURE' | 'CITY' | 'HOBBIES' | 'SKILLS';

export interface IFollowPopoverData {
  userId: string | null;
  clubName: string | null;
}

export type Filter = { [key: string]: any };

interface IClubViewUsersResult {
  getClubViewUsers: {
    data: IUser[];
    pagination: {
      total: number;
      limit: number;
      offset: number;
    };
  };
}

const ClubView = () => {
  const loadingDivRef = useRef<HTMLDivElement>(null);
  const [isFetchMoreBlock, setFetchMoreBlock] = useState(false);
  const history = useHistory();
  const [isOpenedFilterContainer, setOpenedFilteredContainer] = useState(false);
  const [followPopoverData, setFollowPopoverData] = useState<IFollowPopoverData>({
    userId: null,
    clubName: null,
  });

  const { search } = useLocation();

  const filtersData: Filter = useMemo(() => {
    const filters: Filter = {};
    const splitedSearchData = qs.parse(search.slice(1));

    Object.keys(splitedSearchData).map((elem) => {
      const value = splitedSearchData[elem];
      if (typeof elem === 'string' && (typeof value === 'string' || Array.isArray(value))) {
        filters[elem] = value;
      }
    });

    return filters;
  }, [search]);

  const filtersForQueries = useMemo(() => {
    const { interestIds, skillIds, city, jobTitle, costCenterId } = filtersData;

    return {
      interestIds: interestIds?.map((elem) => elem.split(':')[0]) || undefined,
      skillIds: skillIds?.map((elem) => elem.split(':')[0]) || undefined,
      city: city,
      jobTitle: jobTitle,
      costCenterId: costCenterId?.split(':')[0] || undefined,
    };
  }, [filtersData]);

  const isHaveFilters = useMemo(() => Object.keys(filtersData).length > 0, [filtersData]);

  const { data: defaultData, loading: defaultLoading } = useQuery<IClubViewDataResult>(GET_DEFAULT_CLUB_VIEW);
  const clubViewUsersQuery = useQuery<IClubViewUsersResult>(GET_CLUB_VIEW_USERS, {
    variables: {
      appliedFilters: filtersForQueries,
      limit: 96,
      offset: 0,
    },
    onError: () => {},
    fetchPolicy: 'cache-first',
  });

  const { data: clubViewUsers, loading: clubViewUsersLoading } = clubViewUsersQuery;

  const isUsersLoading = defaultLoading || clubViewUsersLoading;

  const { data: followedUsers } = useQuery<IFollowedAuthorsResult>(GET_FOLLOWED_USERS);

  const closeFollowPopover = () => {
    setFollowPopoverData(() => ({
      clubName: null,
      userId: null,
    }));
  };

  const [followUser] = useMutation(FOLLOW_AUTHOR, {
    onCompleted: () => {
      emitFollowRequest({ action: 'follow' });
    },
    onError: () => message.error('Error'),
  });

  const [unfollowUser] = useMutation(UNFOLLOW_AUTHOR, {
    onCompleted: () => {
      emitFollowRequest({ action: 'unfollow' });
    },
    onError: () => message.error('Error'),
  });

  const followHandler = (id: string) => {
    followUser({
      variables: {
        authorId: id,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWED_USERS,
        },
      ],
    });
  };

  const unfollowHandler = (id: string) => {
    unfollowUser({
      variables: {
        authorId: id,
      },
      refetchQueries: [
        {
          query: GET_FOLLOWED_USERS,
        },
      ],
    });
  };

  const isLoadedAllData = useMemo(
    () => clubViewUsers && clubViewUsers.getClubViewUsers.data.length >= clubViewUsers.getClubViewUsers.pagination.total,
    [clubViewUsers],
  );

  const fetchMoreHandler = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting || !clubViewUsers || isLoadedAllData || isFetchMoreBlock) {
      return;
    }

    const { getClubViewUsers } = clubViewUsers;
    const { pagination } = getClubViewUsers;
    const { offset } = pagination;
    setFetchMoreBlock(true);
    clubViewUsersQuery.fetchMore({
      variables: {
        offset: offset + 96,
        limit: 96,
        appliedFilters: filtersForQueries,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        setFetchMoreBlock(false);

        return Object.assign({}, previousResult, {
          getClubViewUsers: {
            ...previousResult.getClubViewUsers,
            data: [...previousResult.getClubViewUsers.data, ...fetchMoreResult.getClubViewUsers.data],
            pagination: fetchMoreResult.getClubViewUsers.pagination,
          },
        });
      },
    });
  };

  useLayoutEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(fetchMoreHandler, options);

    if (loadingDivRef.current) {
      observer.unobserve(loadingDivRef.current);
      observer.observe(loadingDivRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [loadingDivRef.current, clubViewUsers, isFetchMoreBlock, isHaveFilters, isUsersLoading]);

  const setVisibleSearchPopover = (isVisible: boolean) => {
    setOpenedFilteredContainer(isVisible);
  };

  const { data: defaultClubViewData } = defaultData?.getDefaultClubViewV2 || {};
  const { getClubViewUsers } = clubViewUsers || {};

  const parsedDefaultElements = useMemo(() => {
    const [
      cityTitleElement,
      cityProgramElement,
      titleProgramElement,
      skillElement,
      interestElement,
      titleElement,
      cityElement,
      programElement,
    ] = defaultClubViewData || [];
    const cityTitleElementCity = cityTitleElement?.appliedFilters?.adressFilter?.city;
    const cityTitleElementTitle = cityTitleElement?.appliedFilters?.jobFilter?.title;
    const cityProgramCity = cityProgramElement?.appliedFilters?.adressFilter?.city;
    const [cityProgramCostCenterId, cityProgramCostCenterName] = [
      cityProgramElement?.appliedFilters?.costCenterFilter?.id,
      cityProgramElement?.appliedFilters?.costCenterFilter?.name,
    ];
    const titleProgramTitle = titleProgramElement?.appliedFilters?.jobFilter?.title;
    const [titleProgramCostCenterId, titleProgramCostCenterName] = [
      titleProgramElement?.appliedFilters?.costCenterFilter?.id,
      titleProgramElement?.appliedFilters?.costCenterFilter?.name,
    ];
    const skillFilter =
      skillElement?.appliedFilters?.skillFilters?.length > 0
        ? skillElement?.appliedFilters?.skillFilters[0]
        : {
            name: '',
            id: '',
          };
    const [skillCostCenterId, skillCostCenterName] = [skillFilter?.id, skillFilter?.name];
    const interestFilter =
      interestElement?.appliedFilters?.interestFilters?.length > 0
        ? interestElement?.appliedFilters?.interestFilters[0]
        : {
            name: '',
            id: '',
          };
    const [interestCostCenterId, interestCostCenterName] = [interestFilter?.id, interestFilter?.name];
    const titleData = titleElement?.appliedFilters?.jobFilter?.title;
    const cityData = cityElement?.appliedFilters?.adressFilter?.city;
    const [programId, programName] = [
      programElement?.appliedFilters?.costCenterFilter?.id,
      programElement?.appliedFilters?.costCenterFilter?.name,
    ];

    return [
      {
        filters: {
          city: cityTitleElementCity,
          jobTitle: cityTitleElementTitle,
        },
        groupName: `${cityTitleElementCity} + ${cityTitleElementTitle}`,
        users: cityTitleElement?.data?.data,
      },
      {
        filters: {
          city: cityProgramCity,
          costCenterId: `${cityProgramCostCenterId}:${cityProgramCostCenterName}`,
        },
        groupName: `${cityProgramCity} + ${cityProgramCostCenterName}`,
        users: cityProgramElement?.data?.data,
      },
      {
        filters: {
          jobTitle: titleProgramTitle,
          costCenterId: `${titleProgramCostCenterId}:${titleProgramCostCenterName}`,
        },
        groupName: `${titleProgramTitle} + ${titleProgramCostCenterName}`,
        users: titleProgramElement?.data?.data,
      },
      {
        filters: {
          skillIds: [`${skillCostCenterId}:${skillCostCenterName}`],
        },
        groupName: `${skillCostCenterName}`,
        users: skillElement?.data?.data,
      },
      {
        filters: {
          interestIds: [`${interestCostCenterId}:${interestCostCenterName}`],
        },
        groupName: `${interestCostCenterName}`,
        users: interestElement?.data?.data,
      },
      {
        filters: { jobTitle: titleData },
        groupName: `${titleData}`,
        users: titleElement?.data?.data,
      },
      {
        filters: { city: cityData },
        groupName: `${cityData}`,
        users: cityElement?.data?.data,
      },
      {
        filters: {
          costCenterId: `${programId}:${programName}`,
        },
        groupName: `${programName}`,
        users: programElement?.data?.data,
      },
    ];
  }, [defaultClubViewData]);

  return (
    <StyledClubView isOpenedSearch={isOpenedFilterContainer}>
      <MainContainer>
        <Header>
          <StyledTitle level={3}>Club View</StyledTitle>
        </Header>
        <SubHeader
          filtersData={filtersData}
          isOpenedFilterContainer={isOpenedFilterContainer}
          setVisibleSearchPopover={setVisibleSearchPopover}
        />
        {isHaveFilters ? (
          !isUsersLoading ? (
            <UsersContainer>
              <CustomScroll flex={1} freezePosition={Boolean((getClubViewUsers?.pagination?.total || 0) < 96)}>
                <ClubViewUsersContainer>
                  {(getClubViewUsers?.data || []).map((elem, key) => {
                    const { id, photoUrl } = elem;

                    return (
                      <AvatarContainer
                        style={{ marginTop: 0 }}
                        key={id}
                        onClick={(e) => {
                          e.stopPropagation();
                          setFollowPopoverData({
                            userId: id,
                            clubName: `${id}-${key}`,
                          });
                        }}
                      >
                        <StyledFollowPopover
                          onVisibleChange={(val) => !val && closeFollowPopover()}
                          trigger={['click']}
                          content={
                            <FollowPopover
                              followHandler={followHandler}
                              unfollowHandler={unfollowHandler}
                              userData={elem}
                              followedUsers={followedUsers?.getMyFollowedAuthors.data || []}
                            />
                          }
                          visible={followPopoverData.userId === id && followPopoverData.clubName === `${id}-${key}`}
                          overlayClassName={'followPopover'}
                          placement={'topRight'}
                        >
                          <StyledAvatarContainer
                            isChanged={followPopoverData.userId === id && followPopoverData.clubName === `${id}-${key}`}
                          >
                            <StyledAvatar
                              key={id}
                              src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />}
                            />
                          </StyledAvatarContainer>
                        </StyledFollowPopover>
                      </AvatarContainer>
                    );
                  })}
                </ClubViewUsersContainer>
                <SpinnerContainer ref={loadingDivRef} isLoadedAllData={isLoadedAllData}>
                  {!isLoadedAllData && <Spinner />}
                </SpinnerContainer>
              </CustomScroll>
            </UsersContainer>
          ) : isUsersLoading ? (
            <UsersLoadingContainer>
              <Spinner width={35} height={35} />
            </UsersLoadingContainer>
          ) : (
            <EmptyClubViewMainContainer>
              <EmptyClubViewContainer>
                <img src={emptyClubView} />
                <p>We haven’t found anyone by your request</p>
              </EmptyClubViewContainer>
            </EmptyClubViewMainContainer>
          )
        ) : (
          <ClubViewMainContainer>
            {!isUsersLoading ? (
              <MainClubViewContainer>
                <HeaderClubViewContainer>
                  {parsedDefaultElements.map((elem, key) => {
                    const { filters, groupName } = elem;

                    return (
                      <StyledTooltipContainer key={key}>
                        <p
                          onClick={() => {
                            history.push({
                              pathname: '/team/club-view',
                              search: qs.stringify(filters),
                            });
                          }}
                        >
                          {groupName}
                        </p>
                      </StyledTooltipContainer>
                    );
                  })}
                </HeaderClubViewContainer>
                <ClubViewContainer>
                  {parsedDefaultElements.map((elem, key) => {
                    const { groupName, users } = elem;

                    return (
                      <DefaultElement
                        closeFollowPopover={closeFollowPopover}
                        followHandler={followHandler}
                        followPopoverData={followPopoverData}
                        followedUsers={followedUsers?.getMyFollowedAuthors.data || []}
                        groupName={groupName}
                        setFollowPopoverData={setFollowPopoverData}
                        unfollowHandler={unfollowHandler}
                        users={users}
                        key={key}
                      />
                    );
                  })}
                </ClubViewContainer>
              </MainClubViewContainer>
            ) : isUsersLoading ? (
              <UsersLoadingContainer>
                <Spinner width={35} height={35} />
              </UsersLoadingContainer>
            ) : (
              <EmptyClubViewMainContainer>
                <EmptyClubViewContainer>
                  <img src={emptyClubView} />
                  <p>We haven’t found anyone by your request</p>
                </EmptyClubViewContainer>
              </EmptyClubViewMainContainer>
            )}
          </ClubViewMainContainer>
        )}
      </MainContainer>
    </StyledClubView>
  );
};

export default ClubView;
