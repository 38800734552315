import React, { FC } from 'react';
import { StyledButton } from './styles';
import { ReactComponent as AddIcon } from 'modules/common/assets/images/profile/interests/addElement.svg';

export interface IProps {
  text: string,
  onClick: () => void,
}

const EmptyButton: FC<IProps> = ({ text, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <AddIcon /> {text}
    </StyledButton>
  );
}

export default EmptyButton;
