import styled from 'styled-components';
import { Input, Skeleton } from 'antd';

interface WithValue {
  hasValue?: boolean;
}

export const FormItem = styled.div`
  position: relative;
  text-align: left;
  margin-top: 24px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export const StyledLabel = styled.label<WithValue>`
  position: absolute;
  top: ${(props) => (props.hasValue ? '-7px' : '25px')};
  left: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #4a4a4a;
  background: #fff;
  z-index: 1;
  transition: all 0.5s;
  opacity: ${(props) => (props.hasValue ? '1' : '0')};
`;

export const RichEditorSkeleton = styled(Skeleton)`
  position: absolute;
  z-index: 99;
  background: #fff;
  width: 100%;
  height: 100%;
`;

export const EditorContainer = styled.div`
  position: relative;
  width: 100%;

  .tox {
    margin-top: 6px !important;
  }
`;
