import config from 'app/config';

interface ISetCookieOptions {
  secure?: boolean;
  expires?: Date | string;
  path?: string;
  domain?: string;
  'max-age'?: number;
}

const setCookie = (name, value, options: ISetCookieOptions) => {
  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }
  document.cookie = updatedCookie;
};

export const setCookies = (params) => {
  const keys = Object.keys(params);
  for (const key of keys) {
    setCookie(key, params[key], { domain: config.baseDomain, path: '/' });
  }
};
