import { gql } from '@apollo/client';

export const SEARCH_USERS_BY_NAME = gql`
  query searchUsers($offset: Int!, $limit: Int!, $nameLike: String!) {
    searchUsers(pagination: { offset: $offset, limit: $limit }, nameLike: $nameLike) {
      data {
        id
        photoUrl
        fullName
        job {
          positionBusinessTitle
        }
      }
    }
  }
`;

export const GLOBAL_SEARCH_USERS = gql`
  query searchUsersGlobal($text: String!, $limit: Int!, $offset: Int!) {
    searchUsersGlobal(text: $text, pagination: { limit: $limit, offset: $offset }) {
      data {
        user_id
        first_name
        last_name
        full_name
        terminated_at
        is_active
        cost_center
        job_title
        photo_url
      }
      pagination {
        total
        offset
      }
    }
  }
`;

export const GLOBAL_SEARCH_POSTS = gql`
  query searchNewsFeedPostsGlobal($text: String!, $limit: Int!, $offset: Int!) {
    searchNewsFeedPostsGlobal(text: $text, pagination: { limit: $limit, offset: $offset }) {
      data {
        post_id
        post_type
        publishing_date
        expire_at
        text
        headline
        files {
          fileId
          fileName
          fileUrl
          fileType
          previewImageUrl
        }
        plainText
        mentioned_users {
          user_id
          first_name
          last_name
          middle_name
          full_name
          photo_url
        }
        author {
          user_id
          first_name
          last_name
          middle_name
          full_name
          photo_url
        }
        targeted_users {
          user_id
          first_name
          last_name
          middle_name
          full_name
          photo_url
          terminated
        }
        badge
      }
      pagination {
        total
        offset
      }
    }
  }
`;

export const GLOBAL_SEARCH_ARTICLES = gql`
  query articlesBySearchFilter($searchText: String, $tags: [String!], $externalSectionId: Int, $limit: Int!, $offset: Int!) {
    articlesBySearchFilter(
      input: { searchText: $searchText, tags: $tags, externalSectionId: $externalSectionId }
      pagination: { limit: $limit, offset: $offset }
    ) {
      data {
        article_id
        external_id
        external_section_id
        original_external_section_id
        section_name
        title
        slug
        contents
        image_url
        tags
      }
      pagination {
        total
      }
    }
  }
`;

const NOTIFICATIONS_POST_MENTION_COMMON_FIELDS = `
  _id
  text
  type
`;

const NOTIFICATION_COMMENT_TEMPLATE = `
  commentUser {
    photoUrl
    fullName
  }
  postUser {
    id
    fullName
  }
  comment {
    text
    postId
    _id
  }
  postType
`;

const RELATED_ENTITY_POST = `
  post {
    ... on NewsFeedCompanyNewsPostModel {
      ${NOTIFICATIONS_POST_MENTION_COMMON_FIELDS}
    }
    ... on NewsFeedTeamNewsPostModel {
      ${NOTIFICATIONS_POST_MENTION_COMMON_FIELDS}
    }
    ... on NewsFeedHrBirthdayWishesPostModel {
      ${NOTIFICATIONS_POST_MENTION_COMMON_FIELDS}
    }
    ... on NewsFeedHrNewHiresPostModel {
      ${NOTIFICATIONS_POST_MENTION_COMMON_FIELDS}
    }
    ... on NewsFeedHrPromotionPostModel {
      ${NOTIFICATIONS_POST_MENTION_COMMON_FIELDS}
    }
    ... on NewsFeedHrWorkAnniversaryPostModel {
      ${NOTIFICATIONS_POST_MENTION_COMMON_FIELDS}
    }
    ... on NewsFeedIndividualPostModel {
      ${NOTIFICATIONS_POST_MENTION_COMMON_FIELDS}
    }
    ... on NewsFeedThanksPostModel {
      ${NOTIFICATIONS_POST_MENTION_COMMON_FIELDS}
    }
    ... on NewsFeedRecognitionPostModel {
      ${NOTIFICATIONS_POST_MENTION_COMMON_FIELDS}
    }
  }
`;

export const USER_NOTIFICATIONS = gql`
  query userNotifications($limit: Int!, $offset: Int!) {
    userNotifications(pagination: { limit: $limit, offset: $offset }) {
      data {
        id
        userId
        status
        relatedEntityType
        createdAt
        user {
          fullName
          photoUrl
          id
        }
        relatedEntity {
          ... on NotificationPostMentionedRelatedEntity {
              ${RELATED_ENTITY_POST}
              user {
                photoUrl
                fullName
              }
            }
          ... on NotificationPostCommentedRelatedEntity {
            comment {
              text
              postId
              _id
            }
            commentUser {
              photoUrl
              fullName
            }
          }
          ... on NotificationPostReactedRelatedEntity {
            ${RELATED_ENTITY_POST}
            reactionUser {
              photoUrl
              fullName
            }
            reactionType
          }
          ... on NotificationPostCommentMentionedRelatedEntity {
              ${NOTIFICATION_COMMENT_TEMPLATE}
          }
          ... on NotificationPostCommentRepliedRelatedEntity {
              ${NOTIFICATION_COMMENT_TEMPLATE}
          }
          ... on NotificationPostCommentReactedRelatedEntity {
              ${RELATED_ENTITY_POST}
              comment {
                text
                postId
                _id
              }
              postUser {
                id
                fullName
              }
              reactionType
              reactionUser {
                photoUrl
                fullName
              }
              postType
          }
          ... on NotificationFollowRequestRelatedEntity {
            user {
              id
              fullName
              photoUrl
            }
          }
          ... on NotificationFollowAcceptedRelatedEntity {
            user {
              id
              fullName
              photoUrl
            }
          }
          ... on NotificationPostBirthdayWishesRelatedEntity {
            post {
              ... on NewsFeedHrBirthdayWishesPostModel {
                targetEmployees {
                  userId
                }
                _id
              }
            }
          }
          ... on NotificationPostWorkAnniversaryRelatedEntity {
            post {
              ... on NewsFeedHrWorkAnniversaryPostModel {
                targetEmployees {
                  userId
                  hireDate
                }
                _id
              }
            }
          }
          ... on NotificationPostNewHireTargetedRelatedEntity {
            post {
              ... on NewsFeedHrNewHiresPostModel {
                targetEmployees {
                  userId
                }
                _id
              }
            }
          }
          ... on NotificationPostPromotionTargetedRelatedEntity {
            post {
              ... on NewsFeedHrPromotionPostModel {
                targetEmployees {
                  userId
                }
                _id
              }
            }
          }
          ... on NotificationPostThanksTargetedRelatedEntity {
            post {
              ... on NewsFeedThanksPostModel {
                plainText,
                targetEmployees {
                  userId
                }
                _id
              }
            }
            user {
              photoUrl,
              fullName
            }
          }
          ... on NotificationPostRecognitionTargetedRelatedEntity {
            post {
              ... on NewsFeedRecognitionPostModel {
                plainText,
                badge,
                targetEmployees {
                  userId
                }
                _id
              }
            }
            user {
              photoUrl,
              fullName
            }
          }
        }
      }
      pagination {
        total
        limit
        offset
      }
    }
  }
`;

export const USER_NOTIFICATIONS_COUNT = gql`
  query userNewNotificationsCount {
    userNewNotificationsCount
  }
`;

export const GET_NEWS_FEED_ALL_BADGES = gql`
  query newsFeedAllBadges {
    newsFeedAllBadges {
      _id
      name
      description
      iconUrl
    }
  }
`;

export const GET_THANKS_MESSAGE_SUGGESTIONS = gql`
  query newsFeedThanksMessageSuggestions {
    newsFeedThanksMessageSuggestions
  }
`;
