import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Space, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';

import Footer from 'modules/common/components/Footer';
import { About, ProfileInfo, Timeline } from './components';
import {
  MainContainer,
  HeaderRow,
  StyledSider,
  MainContent,
  StyledTabs,
  InnerContainer,
  StyledLayout,
  HeaderContainer,
  ProfileContainer,
} from './styles';
import {
  GET_MY_FOLLOWED_AUTHORS,
  GET_USER_REPORTS_TREE,
  GET_MY_FOLLOWERS,
  GET_USER_FOLLOWERS_COUNT,
} from 'modules/profile/gql/query';
import { namedFollowChangeSubsriptions } from 'modules/news-feed/gql/subscription';
import { UNFOLLOW_USER, FOLLOW_USER } from 'modules/profile/gql/mutation';
import withCheckPermission from 'modules/common/hocks/withCheckPermission';
import withReactionsForMail from 'modules/common/hocks/withReactionsForMail';
import { IUserModel } from 'modules/common/gql/models/user.model';
import { profileRoutes } from 'modules/profile/config/Profile.router';
import { IFollowStatus } from 'modules/common/gql/models/user.model';
import { GET_USER_BADGES } from 'modules/profile/gql/query';
import { INewsFeedBadgeModel } from 'modules/profile/gql/models/userBadges.model';
import { FollowersModal } from './components';
import BreadCrumbs from './components/BreadCrumbs';
import { emitViewProfile, IViewProfileSection, emitFollowRequest } from 'utils/mixpanel';
import useOrgChart from 'modules/common/gql/hooks/useOrgChartQuery';

const { TabPane } = Tabs;

export interface IFollowerUser {
  id: string;
  fullName: string;
  photoUrl: string;
  job: {
    positionTitle: string;
  };
}

export interface IOrgChart {
  reportsTo?: IUserModel;
  directReports: IUserModel[];
}

export interface IFollowerData {
  status: IFollowStatus;
  followerUser: IFollowerUser;
}

export interface IFollowedData {
  status: IFollowStatus;
  authorUser: IFollowerUser;
}

interface IPagination {
  nextCursor?: string;
  pageCount: number;
  limit: number;
}

export interface IFollowersResult {
  getMyFollowers: {
    status: IFollowStatus;
    data: IFollowerData[];
    pagination: IPagination;
  };
}

export interface IFollowedAuthorsResult {
  getMyFollowedAuthors: {
    data: IFollowedData[];
    pagination: IPagination;
  };
}

export interface IFollowStatusChangedResult {
  onNewsFeedFollowChange: {
    _id: string;
    followerId: string;
    authorId: string;
    status: IFollowStatus;
    followerUser: IFollowerUser;
    authorUser: IFollowerUser;
  };
}
export interface IFollowSubscriptionResult {
  subscriptionData: {
    data: IFollowStatusChangedResult;
  };
}

export interface IGetUserFollowersCountResult {
  getUserFollowersCount: {
    followersCount: number;
    followingsCount: number;
  };
}

export interface IUserReports {
  fullName: string;
  id: string;
  job: {
    positionTitle: string;
  };
}

export interface IUserReportsThree {
  getUserReportsTree: IUserReports[];
}

export interface IUserBadgesResponse {
  newsFeedUserBadges: INewsFeedBadgeModel[];
}

enum ProfileSections {
  ABOUT = 'about',
  TIMELINE = 'timeline',
}

interface IProps {
  checkPermission: (tab: string) => boolean | null;
  refetchUser: () => void;
  profileData: { user: any };
  observerData: IFollowerUser;
  isOwner: boolean;
  isFollowed: boolean;
  isEditableProfile: boolean;
}

export type OpenedFollowersPopover = 'FOLLOWED_AUTHORS' | 'FOLLOWERS' | null;

const Profile: React.FC<IProps> = ({ checkPermission, refetchUser, profileData, observerData, isOwner, isEditableProfile }) => {
  const profileUser = isOwner ? observerData : profileData?.user;
  const history = useHistory();
  const goToProfile = useCallback((id) => history.push(`${profileRoutes.profile}${id}`), [history]);
  const [openedFollowersPopover, setOpenedFollowersPopover] = useState<OpenedFollowersPopover>(null);
  const userId = profileUser.id;
  const onError = useCallback((err: Error) => message.error(err), []);
  const orgChartQuery = useOrgChart(userId);
  const { data: userReportsThree } = useQuery<IUserReportsThree>(GET_USER_REPORTS_TREE, {
    variables: { id: userId },
    skip: !userId,
    onError,
  });
  const { data: followCountData } = useQuery<IGetUserFollowersCountResult>(GET_USER_FOLLOWERS_COUNT, { variables: { userId } });
  const {
    data: followedAuthorsData,
    refetch: refetchFollowed,
    subscribeToMore,
    fetchMore: fetchMoreFollowed,
  } = useQuery<IFollowedAuthorsResult>(GET_MY_FOLLOWED_AUTHORS, {
    onError,
    variables: { limit: 15 },
  });
  const { data: followersData, fetchMore: fetchMoreFollowers } = useQuery<IFollowersResult>(GET_MY_FOLLOWERS, {
    onError,
    variables: { limit: 15 },
  });
  const { data: userBadges } = useQuery<IUserBadgesResponse>(GET_USER_BADGES, {
    onError,
    variables: { userId },
    skip: !userId,
  });
  const [followUser] = useMutation(FOLLOW_USER, {
    onCompleted: () => {
      emitFollowRequest({ action: 'follow' });
      refetchFollowed();
      refetchUser();
    },
    onError,
    variables: {
      id: userId,
    },
  });

  const [unfollowUser] = useMutation(UNFOLLOW_USER, {
    onCompleted: () => {
      emitFollowRequest({ action: 'unfollow' });
      refetchFollowed();
      refetchUser();
    },
    onError,
    variables: {
      id: userId,
    },
  });

  const subscribeToFollowedStatus = useCallback(() => {
    subscribeToMore({
      document: namedFollowChangeSubsriptions('PROFILE'),
      updateQuery: (prev, { subscriptionData }: IFollowSubscriptionResult) => {
        if (!subscriptionData?.data) {
          return prev;
        }
        const { authorUser, status } = subscriptionData?.data.onNewsFeedFollowChange;
        const newData = prev.getMyFollowedAuthors.data.map((el) => ({ ...el }));
        const changedUserIndex = newData.findIndex((el: any) => el.authorUser.id === authorUser.id);
        if (changedUserIndex >= 0) {
          newData[changedUserIndex].status = status;
        }
        return {
          getMyFollowedAuthors: {
            ...prev.getMyFollowedAuthors,
            data: newData,
          },
        };
      },
    });
  }, []);

  useEffect(() => {
    emitViewProfile({ section: ProfileSections.ABOUT });
  }, []);

  useEffect(() => {
    subscribeToFollowedStatus();
  }, [subscribeToFollowedStatus]);

  const onCloseFollowersPopover = () => {
    setOpenedFollowersPopover(() => null);
  };

  const openFollowersModalHandler = (name: OpenedFollowersPopover) => {
    setOpenedFollowersPopover(() => name);
  };

  if (!followedAuthorsData || !followersData || !userReportsThree || !followCountData) {
    return null;
  }

  return (
    <ProfileContainer>
      <MainContainer>
        <FollowersModal
          onClose={onCloseFollowersPopover}
          followedAuthorsData={followedAuthorsData}
          followersData={followersData}
          openedModal={openedFollowersPopover}
          fetchMoreFollowed={fetchMoreFollowed}
          fetchMoreFollowers={fetchMoreFollowers}
          refetchFollowed={() => refetchFollowed && refetchFollowed()}
          followedAuthorsCount={followCountData.getUserFollowersCount.followingsCount}
          followersCount={followCountData.getUserFollowersCount.followersCount}
        />
        <HeaderRow>
          <HeaderContainer>
            <h2>{isOwner ? 'My Profile' : `Employee Profile: ${profileUser?.firstName} ${profileUser?.lastName}`}</h2>
            <BreadCrumbs data={userReportsThree.getUserReportsTree} />
          </HeaderContainer>
        </HeaderRow>
        <StyledLayout>
          <StyledSider width={400}>
            <ProfileInfo
              user={profileUser}
              refetchUser={refetchUser}
              followedAuthors={followedAuthorsData?.getMyFollowedAuthors.data}
              followers={followersData.getMyFollowers.data}
              followedAuthorsCount={followCountData.getUserFollowersCount.followingsCount || 0}
              followersCount={followCountData.getUserFollowersCount.followersCount || 0}
              isOwner={isOwner}
              isEditableProfile={isEditableProfile}
              followUser={followUser}
              unfollowUser={unfollowUser}
              openFollowersModalHandler={openFollowersModalHandler}
              userBadges={userBadges?.newsFeedUserBadges}
            />
          </StyledSider>
          <MainContent>
            <InnerContainer>
              <Space direction="vertical">
                <StyledTabs
                  defaultActiveKey={ProfileSections.ABOUT}
                  onChange={(activeKey) => {
                    const key: IViewProfileSection =
                      activeKey === ProfileSections.ABOUT ? ProfileSections.ABOUT : ProfileSections.TIMELINE;
                    emitViewProfile({ section: key });
                  }}
                >
                  {checkPermission(ProfileSections.ABOUT) && (
                    <TabPane tab="About" key={ProfileSections.ABOUT}>
                      <About
                        goToProfile={goToProfile}
                        user={profileUser}
                        refetchUser={refetchUser}
                        orgChart={orgChartQuery?.userOrgChartMixed}
                        isOwner={isOwner}
                        isEditableProfile={isEditableProfile}
                      />
                    </TabPane>
                  )}
                  {checkPermission(ProfileSections.TIMELINE) &&
                    (isOwner || profileUser.followedByMeStatus === IFollowStatus.FOLLOWING) && (
                      <TabPane tab="Timeline" key={ProfileSections.TIMELINE}>
                        <Timeline user={profileUser} isOwner={isOwner} />
                      </TabPane>
                    )}
                </StyledTabs>
              </Space>
            </InnerContainer>
          </MainContent>
        </StyledLayout>
      </MainContainer>
      <Footer />
    </ProfileContainer>
  );
};

export default withReactionsForMail(withCheckPermission(Profile));
