import React, { FC, Fragment } from 'react';
import { Space, Typography, message } from 'antd';
import { useMutation } from '@apollo/client';

import { StyledInnerTitle, StyledCol, TitleContainer, StyledHide, HideButton, StyledVisible } from '../../styles';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import { PhoneUsages } from 'modules/common/gql/models/phone.model';
import { HomePhoneIcon, WorkPhoneIcon } from '../../styles';
import { TOGGLE_PHONE } from 'modules/profile/gql/mutation';

export interface IProps {
  user: IUserModel;
  refetchUser: () => void;
  isEditable: boolean;
  isOwner: boolean;
}

const Phones: FC<IProps> = ({ user, refetchUser, isEditable, isOwner }) => {
  const [toggleField] = useMutation(TOGGLE_PHONE, {
    onCompleted: refetchUser,
    onError: (err) => message.error(err),
  });

  const { phones } = user;

  const togglePhone = (isHidden: boolean, phoneId: string) => {
    toggleField({
      variables: {
        hidden: !isHidden,
        id: user.id,
        phoneId,
      },
    });
  };

  if (!phones || phones.length <= 0) {
    return null;
  }

  return (
    <StyledCol span={24}>
      <StyledInnerTitle disabled>Phone Numbers</StyledInnerTitle>
      <Space direction="vertical" style={{ width: '100%' }}>
        {phones.map((phone, key) => {
          let IconComponent: any = null;
          switch (phone.usageType) {
            case PhoneUsages.BUSINESS:
              IconComponent = WorkPhoneIcon;
              break;
            case PhoneUsages.HOME:
              IconComponent = HomePhoneIcon;
              break;
          }

          return (
            <TitleContainer key={key}>
              <Typography.Text key={phone.phoneNumber}>
                {`${phone.phoneNumber}`} {IconComponent && <IconComponent />}
              </Typography.Text>
              {isEditable && isOwner && (
                <HideButton onClick={() => togglePhone(phone.hidden, phone.id || '')}>
                  {phone.hidden ? (
                    <Fragment>
                      <StyledHide />
                      Show
                    </Fragment>
                  ) : (
                    <Fragment>
                      <StyledVisible />
                      Hide
                    </Fragment>
                  )}
                </HideButton>
              )}
            </TitleContainer>
          );
        })}
      </Space>
    </StyledCol>
  );
};

export default Phones;
