import { ReactComponent as SocialNews } from '../assets/socialNews.svg';
import { ReactComponent as Team } from '../assets/team.svg';
import { ReactComponent as Careers } from '../assets/bx-briefcase.svg';
import { ReactComponent as Growth } from '../assets/growth.svg';
import { ReactComponent as Support } from '../assets/support.svg';
import workDay from '../assets/menuElements/workDay.svg';
import sevenGeese from '../assets/menuElements/7Geese.svg';
import slack from '../assets/menuElements/slack.svg';
import smartRecruiters from '../assets/menuElements/smartRecruiters.svg';
import oneLogin from '../assets/menuElements/onelogin.svg';
import tripActions from '../assets/menuElements/tripActions.svg';
import peakon from '../assets/menuElements/peakon.svg';
import astreyaLearning from '../assets/menuElements/astreyaLearning.png';
import { NF_PERMISSIONS } from 'modules/auth/constants';

export const profileHeaderElements = [
  {
    value: 'Social',
    link: '/news-feed',
    image: SocialNews,
    isExternalLink: false,
  },
  {
    value: 'Team',
    image: Team,
    link: '/team',
    elements: [
      {
        value: 'Organizational Chart',
        link: '/team/org-chart',
      },
      {
        value: 'Club View',
        link: '/team/club-view',
      },
    ],
    isExternalLink: false,
  },
  {
    value: 'Careers',
    link: 'https://www.smartrecruiters.com/app/internal-mobility',
    image: Careers,
    isExternalLink: true,
  },
  {
    value: 'Growth',
    link: 'https://astreya.onelogin.com/trust/saml2/http-post/sso/3d5a06a7-5890-4ccb-80a3-94365153f948',
    image: Growth,
    isExternalLink: true,
  },
  // {
  //   value: 'CRM',
  //   link: '/crm',
  //   image: CRM,
  // },
  {
    value: 'Support',
    link: 'https://intercom.help/astreya-86bba0b5dd26/en/',
    image: Support,
    isExternalLink: true,
  },
];

export const appsMenuElements = [
  {
    link: 'https://wd5.myworkday.com/wday/authgwy/astreya/login.htmld',
    image: workDay,
    restrictPermission: null,
    mixpanelApplication: 'workday',
  },
  {
    link: 'https://astreya.onelogin.com/trust/saml2/http-post/sso/b26bdc67-6530-4953-aaae-63ed7054f7bd',
    image: sevenGeese,
    restrictPermission: null,
    mixpanelApplication: '7geese',
  },
  {
    link: 'https://astreya.enterprise.slack.com/',
    image: slack,
    restrictPermission: null,
    mixpanelApplication: 'slack',
  },
  {
    link: 'https://astreya.onelogin.com/trust/saml2/http-post/sso/cc228ccd-7ef6-4eaf-87b8-e4a432cae8dc',
    image: smartRecruiters,
    restrictPermission: null,
    mixpanelApplication: 'smartrecruiters',
  },
  {
    link: 'https://astreya.onelogin.com/',
    image: oneLogin,
    restrictPermission: null,
    mixpanelApplication: 'onelogin',
  },
  {
    link: 'https://crm.astreya.com/',
    text: 'CRM',
    restrictPermission: NF_PERMISSIONS.NAVIGATE_TO_CRM,
    mixpanelApplication: 'astreya crm',
  },
  {
    link: 'https://astreya.onelogin.com/trust/saml2/http-post/sso/3d5a06a7-5890-4ccb-80a3-94365153f948',
    image: astreyaLearning,
    restrictPermission: null,
    mixpanelApplication: 'astreya learning',
  },
  {
    link: 'https://astreya.onelogin.com/trust/saml2/http-post/sso/1b9d6675-4a47-4496-a7f5-291c9f2dd841',
    image: tripActions,
    restrictPermission: null,
    mixpanelApplication: 'tripactions',
  },
  {
    link: 'https://app.peakon.com/dashboard',
    image: peakon,
    restrictPermission: NF_PERMISSIONS.NAVIGATE_TO_PEAKON,
    mixpanelApplication: 'peakon',
  },
  {
    link: 'https://www.shumskyideas.com/astreyastore/home.cfm',
    text: 'Astreya Store',
    restrictPermission: null,
    mixpanelApplication: 'astreya store',
  },
];
