import styled from 'styled-components';
import { Modal, Button } from 'antd';

export const StyledModal = styled(Modal)`
  border-radius: 8px !important;
  .ant-modal-footer {
    border: none;
    border-radius: 0px 0px 8px 8px;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 0 24px 24px;
  }
`;

export const Footer = styled.div`
  background: #fff;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
`;

export const AddButton = styled(Button)`
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
`;

export const CancelButton = styled(Button)`
  font-weight: 600;
`;
