import styled from 'styled-components';
import { Tooltip } from 'antd';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
`;

interface IStyledCardProps {
  isChangedBadge?: boolean;
}

export const StyledCard = styled.div<IStyledCardProps>`
  display: flex;
  width: 65px;
  height: 65px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
  box-sizing: content-box;
  border: 3px solid transparent;

  img {
    width: 100%;
  }

  ${({ isChangedBadge }) =>
    isChangedBadge &&
    `
     border-color: #1890ff;
  `}
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 65px 1fr;
  margin-bottom: 34px;
`;

export const CloseContainer = styled.div`
  border: 1px solid #ffffff;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -5px;
  top: -5px;
  border-radius: 100%;
  cursor: pointer;
  background: #1890ff;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;

  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    padding: 0;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: #4b4b4b;
    padding: 0;
    margin: 0;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  positionb: relative;
`;
