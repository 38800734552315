import React, { FC, Fragment, useMemo } from 'react';
import { Image } from 'antd';
import moment from 'moment';

import {
  Avatar,
  InfoContainer,
  UserContainer,
  MessageContainer,
  MainInfoContainer,
  MentiontText,
  AvatarContainer,
  ClapContainer,
  StyledThanksClapIcon,
  UserText,
  TargetPostTextContainer,
} from '../../../component.styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { NOTIFICATION_ENTITY_TYPES } from '../../../constants';
import { IPostNotification, IBadge } from '../../../interfaces';

interface IProps {
  data: IPostNotification;
  badgesData: IBadge[];
}

const TargetedPost: FC<IProps> = ({ data, badgesData }) => {
  const { createdAt, relatedEntity, relatedEntityType } = data;

  const { photoUrl, fullName: preferredName } = relatedEntity?.user || {};
  const { plainText = '', badge, targetEmployees } = relatedEntity?.post || {};

  const badgeElement = useMemo(() => {
    const badgeElement = badgesData.find((elem) => elem._id === badge);
    return badgeElement;
  }, [badge, badgesData]);

  const otherUsersCount = useMemo(() => (targetEmployees?.length || 1) - 1, [targetEmployees]);

  if (!relatedEntity) {
    return null;
  }

  return (
    <UserContainer>
      <AvatarContainer>
        <Avatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
        {relatedEntityType === NOTIFICATION_ENTITY_TYPES.POST_RECOGNITION_TARGETED ? (
          <ClapContainer>
            <img src={badgeElement?.iconUrl} />
          </ClapContainer>
        ) : (
          <ClapContainer>
            <StyledThanksClapIcon />
          </ClapContainer>
        )}
      </AvatarContainer>
      <MainInfoContainer>
        <InfoContainer>
          {relatedEntityType === NOTIFICATION_ENTITY_TYPES.POST_RECOGNITION_TARGETED ? (
            <MessageContainer>
              <UserText>{preferredName}</UserText> recognized <MentiontText>you</MentiontText>{' '}
              {otherUsersCount > 0 && (
                <Fragment>
                  and <MentiontText>{otherUsersCount > 1 ? `${otherUsersCount} more people` : 'one more person'}</MentiontText>
                </Fragment>
              )}{' '}
              for <UserText>{badgeElement?.name}</UserText>
            </MessageContainer>
          ) : (
            <MessageContainer>
              <UserText>{preferredName}</UserText> thanked <MentiontText>you</MentiontText>{' '}
              {otherUsersCount > 0 && (
                <Fragment>
                  and <MentiontText>{otherUsersCount > 1 ? `${otherUsersCount} more people` : 'one more person'}</MentiontText>
                </Fragment>
              )}
            </MessageContainer>
          )}
          <MessageContainer>{moment(createdAt).format('MMM DD')}</MessageContainer>
        </InfoContainer>
        {plainText && plainText.length > 0 && <TargetPostTextContainer dangerouslySetInnerHTML={{ __html: plainText }} />}
      </MainInfoContainer>
    </UserContainer>
  );
};

export default TargetedPost;
