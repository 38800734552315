import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthRouter from 'modules/auth/config/Auth.router';
import ProfileRouter from 'modules/profile/config/Profile.router';
import NewsFeedRouter from 'modules/news-feed/config/Feed.router';
import appRoutes from './routes';
import HeaderRouter from 'modules/common/components/Header/config/Header.router';
// import Careers from 'modules/career/config/Career.router';
import Team from 'modules/team/config/Team.router';
import ErrorPage from 'modules/common/components/Error';
import KnowledgeBase from 'modules/knowledge-base/config/KnowledgeBase.router';
import Support from 'modules/support/config/Profile.router';

const Router = () => (
  <Fragment>
    <HeaderRouter />
    <Switch>
      <Route exact path={appRoutes.home}>
        <Redirect to={appRoutes.newsFeed} />
      </Route>
      <Route path={appRoutes.auth}>
        <AuthRouter />
      </Route>
      <Route path={appRoutes.profile}>
        <ProfileRouter />
      </Route>
      <Route path={appRoutes.team}>
        <Team />
      </Route>
      {/* <Route path={appRoutes.careers}>
        <Careers />
      </Route> */}
      <Route path={appRoutes.error}>
        <ErrorPage />
      </Route>
      <Route path={appRoutes.knowledgeBase}>
        <KnowledgeBase />
      </Route>
      <Route path={appRoutes.newsFeed}>
        <NewsFeedRouter />
      </Route>
      <Route path={appRoutes.support}>
        <Support />
      </Route>
      <Route path="*">
        <Redirect to={appRoutes.newsFeed} />
      </Route>
    </Switch>
  </Fragment>
);

export default Router;
