import React, { useState, useMemo, FC, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

import {
  MainSubHeaderContainer,
  SearchFiltersInput,
  StyledSearchPopover,
  InputContainer,
  FiltersContainer,
  LoadingContainer,
} from '../styles';
import { Filter as StyledFilter, StyledCloseFilter } from './SearchPopover/styles';
import SearchPopover from './SearchPopover';
import useDebounce from 'modules/common/hooks/useDebounce';
import { GET_FILTERS, GET_COUNT_CLUB_VIEW_USERS } from '../../../gql/query';
import { Filter } from '../ClubView';
import Spinner from 'modules/common/components/Spinner';
import useOutsideClick from 'modules/common/hooks/useOutsideClick';

export interface IOption {
  label: string;
  value: string;
}

export interface IInterestFilter {
  name: string;
  id: string;
}

export interface ICityFilter {
  city: string;
}

export interface ICostCenterFilter {
  name: string;
  id: string;
}

export interface IJobTitleFilter {
  title: string;
}

export interface ISkillFilter {
  name: string;
  id: string;
}

interface IClubViewFilter {
  getClubViewInterestsFilters: {
    data: IInterestFilter[];
  };
  getClubViewCitiesFilters: {
    data: ICityFilter[];
  };
  getClubViewCostCentersFilters: {
    data: ICostCenterFilter[];
  };
  getClubViewJobTitleFilters: {
    data: IJobTitleFilter[];
  };
  getClubViewSkillsFilters: {
    data: ISkillFilter[];
  };
}

interface IProps {
  filtersData: Filter;
  isOpenedFilterContainer: boolean;
  setVisibleSearchPopover: (isVisible: boolean) => void;
}

export type FilterTypes = 'interestIds' | 'skillIds' | 'city' | 'jobTitle' | 'costCenterId';

interface IResultCountClubViewUsers {
  countClubViewUsers: number;
}

const SubHeader: FC<IProps> = ({ filtersData, isOpenedFilterContainer, setVisibleSearchPopover }) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const searchValue = useDebounce(search, 500);
  const [filters, setFilters] = useState(filtersData);
  const popoverRef = useRef<any>(null);

  const pushFiltersToHistory = (filters: Filter) => {
    history.push({
      pathname: '/team/club-view',
      search: qs.stringify(filters),
    });
  };

  const onClose = () => {
    pushFiltersToHistory(filters);
    setVisibleSearchPopover(false);
  };

  useOutsideClick(popoverRef, onClose);

  useEffect(() => {
    setSearch('');
    setFilters(filtersData);
  }, [filtersData]);

  const filtersForQueries = useMemo(() => {
    const { interestIds, skillIds, city, jobTitle, costCenterId } = filters;

    return {
      interestIds: interestIds && interestIds.length > 0 ? interestIds.map((elem) => elem.split(':')[0]) : undefined,
      skillIds: skillIds && skillIds.length > 0 ? skillIds.map((elem) => elem.split(':')[0]) : undefined,
      city: city,
      jobTitle: jobTitle,
      costCenterId: costCenterId?.split(':')[0] || undefined,
    };
  }, [filters]);

  const { data: countData } = useQuery<IResultCountClubViewUsers>(GET_COUNT_CLUB_VIEW_USERS, {
    variables: {
      appliedFilters: filtersForQueries,
    },
  });

  const { data: allFilters, loading: filtersLoading } = useQuery<IClubViewFilter>(GET_FILTERS, {
    variables: {
      textFilter: searchValue,
      appliedFilters: filtersForQueries,
    },
  });

  const {
    getClubViewCitiesFilters,
    getClubViewCostCentersFilters,
    getClubViewInterestsFilters,
    getClubViewJobTitleFilters,
    getClubViewSkillsFilters,
  } = allFilters || {};
  const citiesData = getClubViewCitiesFilters?.data || [];
  const costCenterData = getClubViewCostCentersFilters?.data || [];
  const interestsData = getClubViewInterestsFilters?.data || [];
  const jobTitleData = getClubViewJobTitleFilters?.data || [];
  const skillsData = getClubViewSkillsFilters?.data || [];

  const deleteFilter = (value: string, group: FilterTypes, isToGlobal?: boolean) => {
    let changedFilters = filters[group];

    if (Array.isArray(changedFilters)) {
      if (!changedFilters.includes(value)) {
        return;
      }

      const indexOfTag = changedFilters.indexOf(value);
      changedFilters.splice(indexOfTag, 1);
    } else {
      changedFilters = undefined;
    }

    if (isToGlobal) {
      pushFiltersToHistory({
        ...filters,
        [group]: changedFilters,
      });
    } else {
      setFilters({
        ...filters,
        [group]: changedFilters,
      });
    }
  };

  const changeFilter = (value: string, group: FilterTypes) => {
    let changedFilters = filters[group];

    if (!changedFilters) {
      if (['interestIds', 'skillIds'].includes(group)) {
        setFilters({
          ...filters,
          [group]: [value],
        });
      } else {
        setFilters({
          ...filters,
          [group]: value,
        });
      }
    } else {
      if (Array.isArray(changedFilters)) {
        changedFilters.push(value);
      } else {
        changedFilters = value;
      }
      setFilters({
        ...filters,
        [group]: changedFilters,
      });
    }
    setSearch('');
  };

  return (
    <MainSubHeaderContainer>
      <InputContainer>
        <StyledSearchPopover
          trigger={['contextMenu']}
          autoAdjustOverflow={false}
          getPopupContainer={(node) => (popoverRef.current = node)}
          content={
            <SearchPopover
              cities={citiesData}
              costCenters={costCenterData}
              interests={interestsData}
              jobTitles={jobTitleData}
              skills={skillsData}
              queriesLoading={filtersLoading}
              changeFilter={changeFilter}
              filtersData={filters}
              deleteFilter={deleteFilter}
              countClubViewUsers={countData?.countClubViewUsers}
              onClose={onClose}
              searchValue={searchValue}
            />
          }
          visible={isOpenedFilterContainer}
          overlayClassName={'clubViewSearch'}
          placement={'bottom'}
        >
          <div>
            <SearchFiltersInput
              placeholder={'Interests, Skills, City, Titles'}
              onFocus={() => setVisibleSearchPopover(true)}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </StyledSearchPopover>
      </InputContainer>

      {filtersLoading ? (
        <FiltersContainer>
          <LoadingContainer>
            <Spinner width={35} height={35} />
          </LoadingContainer>
        </FiltersContainer>
      ) : (
        <FiltersContainer>
          {Object.keys(filtersData).map((key: any) => {
            const changedFilter = filtersData[key];
            const filtersArray = ['interestIds', 'skillIds', 'costCenterId'];
            if (Array.isArray(changedFilter)) {
              return changedFilter.map((filter) => {
                const filterValue = filtersArray.includes(key) ? filter.split(':')[1] || '' : filter;
                return (
                  <StyledFilter isChanged>
                    <p>{filterValue}</p> <StyledCloseFilter onClick={() => deleteFilter(filter, key, true)} />
                  </StyledFilter>
                );
              });
            } else {
              const filterValue = filtersArray.includes(key) ? changedFilter.split(':')[1] || '' : changedFilter;

              return (
                <StyledFilter isChanged>
                  <p>{filterValue}</p> <StyledCloseFilter onClick={() => deleteFilter(changedFilter, key, true)} />
                </StyledFilter>
              );
            }
          })}
        </FiltersContainer>
      )}
    </MainSubHeaderContainer>
  );
};

export default SubHeader;
