import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { MainContainer, ContentContainer, MainContentContainer, Title, SubTitle, Button } from './styles';
import Footer from 'modules/common/components/Footer';
import notFoundImage from 'modules/profile/assets/images/profileNotFound/notFound.svg';

interface IProps {
  fullName: string;
}

const ProfileNotFound: FC<IProps> = ({ fullName }) => {
  const history = useHistory();
  return (
    <MainContainer>
      <MainContentContainer>
        <ContentContainer>
          <img src={notFoundImage} />
          <Title>Profile is Not Available</Title>
          <SubTitle>
            Sorry, But <span>{fullName}</span> Profile is No Longer Available
          </SubTitle>
          <Button onClick={history.goBack}>Go Back</Button>
        </ContentContainer>
      </MainContentContainer>
        <Footer />
    </MainContainer>
  );
};

export default ProfileNotFound;
