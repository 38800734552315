import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { login } from 'store/domains/auth/auth.actions';

import { MainContainer, StyledIllustration, StyledTitle, StyledButton, StyledOneLoginIcon } from './styles';
import { ReactComponent as Logo } from 'modules/common/assets/images/login/logo.svg';

const Login = () => {
  const dispatch = useDispatch();
  const doLogin = useCallback(() => dispatch(login()), [dispatch]);

  return (
    <MainContainer>
      <Logo />
      <StyledIllustration />
      <StyledTitle level={3}>Welcome to Connect</StyledTitle>
      <StyledButton type="primary" size={'large'} onClick={doLogin}>
        <StyledOneLoginIcon />
        Sign in with OneLogin
      </StyledButton>
    </MainContainer>
  );
};

export default Login;
