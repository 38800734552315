import styled from 'styled-components';
import { ReactComponent as MoreThanksSuggestionsIcon } from 'modules/news-feed/pages/Feed/assets/createPostModal/moreThanksSuggestions.svg';
export const Suggestions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Suggestion = styled.span`
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
  position: relative;
  color: #1890ff;
  font-size: 12px;
  line-height: 16px;
  padding: 7.5px 14px;
  border: 1px solid #1890ff;
  border-radius: 20px;
  cursor: pointer;
  height: 33px;
`;

export const MoreSuggestionsIcon = styled(MoreThanksSuggestionsIcon)`
  position: relative;
  top: 1px;
  margin-left: 10px;
  margin-right: 10px;
`;
