import { IUserModel } from 'modules/common/gql/models/user.model';

export enum UserRoles {
  OWNER = 'OWNER',
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
}

export interface UserState {
  id: string | null;
  roles: any | null;
  permissionIds: string[] | null;
  role: UserRoles | null;
  error: string | null;
  userData: IUserModel | null;
}
