import axios from 'axios';
import config from './config';
import { get as getFromStorage } from 'utils/localStorage';

const AxiosInstance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const request = (info: any) => {
  const { url, method = 'GET', params, data, headers = { 'Content-Type': 'application/json' } } = info;
  const result = AxiosInstance({ url, method, params, data, headers });
  return result;
};

const AxiosGglInstance = axios.create({
  baseURL: config.graphqlUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const requestGgl = (info: any) => {
  const token = getFromStorage('tokenOutput');
  const tokenParsed = JSON.parse(token || '{}');
  const { accessToken = '' } = tokenParsed;
  const { url, method = 'POST', params, data, headers = { 'Authorization': `Bearer ${accessToken}` } } = info;
  const result = AxiosGglInstance({ url, method, params, data, headers });
  return result;
};

export default request;
