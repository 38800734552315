import React from 'react';
import { Switch } from 'react-router-dom';

import appRoutes from 'app/routes';
import PrivateRoute from 'modules/auth/components/PrivateRoute';
import KnowledgeBaseElement from '../pages/KnowledgeBaseElement';

const KnowledgeBaseRouter = () => (
  <Switch>
    <PrivateRoute exact path={`${appRoutes.knowledgeBase}/:id/:slug`} component={KnowledgeBaseElement} />
  </Switch>
);

export default KnowledgeBaseRouter;
