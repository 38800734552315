// export const toDataURL = (url) =>
//   fetch(url, {
//     credentials: 'include',
//     mode: 'no-cors',
//   })
//     .then((response) => response.blob())
//     .then(
//       (blob) =>
//         new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => resolve(reader.result);
//           reader.onerror = reject;
//           reader.readAsDataURL(blob);
//         }),
//     );

export const toDataURL = (url) => {
  let newImg = new Image(100, 100);
  newImg.src = url;
  newImg.crossOrigin = 'Anonymous';

  return new Promise((resolve) => {
    newImg.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = newImg.naturalHeight;
      canvas.width = newImg.naturalWidth;
      ctx?.drawImage(newImg, 0, 0);
      resolve(canvas.toDataURL('image/png'));
    };
  });
};

export const getAverageRGB = (imageEl) => {
  let blockSize = 5,
    defaultRGB = { r: 0, g: 0, b: 0 },
    canvas = document.createElement('canvas'),
    context = canvas.getContext && canvas.getContext('2d'),
    data,
    width,
    height,
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0;

  if (!context) {
    return defaultRGB;
  }

  height = canvas.height = imageEl.naturalHeight || imageEl.offsetHeight || imageEl.height;
  width = canvas.width = imageEl.naturalWidth || imageEl.offsetWidth || imageEl.width;

  context.drawImage(imageEl, 0, 0);

  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    return defaultRGB;
  }

  length = data.data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  rgb.r = ~~(rgb.r / count);
  rgb.g = ~~(rgb.g / count);
  rgb.b = ~~(rgb.b / count);

  return rgb;
};

export const getImage = (base64Image: string) => {
  let newImg = new Image();
  newImg.src = base64Image;

  return new Promise((resolve) => {
    newImg.onload = function () {
      resolve(newImg);
    };
  });
};

export const parseDaysToWeeks = (daysCount: number) => {
  if (daysCount >= 365) {
    const countYears = Math.round(daysCount / 365);
    return countYears > 1 ? `${countYears} years ago` : 'a year ago';
  } else if (daysCount >= 25) {
    const countMath = Math.round(daysCount / 30);
    return countMath > 1 ? `${countMath} months ago` : 'a month ago';
  } else if (daysCount >= 18) {
    return `3 weeks ago`;
  } else if (daysCount >= 12) {
    return `2 weeks ago`;
  } else if (daysCount >= 7) {
    return `a week ago`;
  } else {
    return daysCount === 1 ? 'a day ago' : `${daysCount} days ago`;
  }
};

export const getFileExtension = (fileName: string) => {
  const splittedFileName = fileName.split('.');

  return splittedFileName[splittedFileName.length - 1];
};

export const getSizeFile = (size: number) => +(size / (1024 * 1024)).toFixed(2);
