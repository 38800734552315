import React, { FC } from 'react';
import { Typography } from 'antd';
import { StyledInnerTitle, StyledCol } from '../../styles';
import { IUserModel } from 'modules/profile/gql/models/user.model';

export interface IProps {
  user: IUserModel;
}
const Location: FC<IProps> = ({ user }) => {
  return (
    <StyledCol span={24}>
      <StyledInnerTitle disabled>Email</StyledInnerTitle>
      <Typography.Text>{user.email}</Typography.Text>
    </StyledCol>
  );
};

export default Location;
