import { cancel, fork, put, take } from 'redux-saga/effects';
import { UserRoles } from './user.types';

import { FETCH_USER_META_REQUEST, FETCH_USER_META_SUCCESS, FETCH_USER_META_FAILURE } from './user.actionTypes';

const fetchMeta = function* (token: string) {
  try {
    yield put({ type: FETCH_USER_META_SUCCESS, role: UserRoles.OWNER });
  } catch (error) {
    yield put({ type: FETCH_USER_META_FAILURE, error });
  }
};

export const userFlowWorker = function* () {
  while (true) {
    const { token } = yield take(FETCH_USER_META_REQUEST);
    const task = yield fork(fetchMeta, token)
    yield take([FETCH_USER_META_FAILURE ]);
    yield cancel(task);
  }
};
