import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import router from 'app/routes';

class ErrorBoundary extends Component<RouteComponentProps, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: true };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log('Error boundary error: ', error);
    return { hasError: true };
  }

  componentDidCatch() {
    this.props.history.push(router.error);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
