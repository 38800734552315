import React, { FC, useRef, memo, useMemo } from 'react';

import { MainContainer, SpinnerContainer } from './styles';
import usePdf from 'modules/common/hooks/usePdf';
import { FilesType } from '../../MainContent';
import Spinner from 'modules/common/components/Spinner/Spinner';
interface IProps {
  src: FilesType;
  scale?: number;
  page?: number;
  autoScale?: boolean;
}

const Pdf: FC<IProps> = ({ src, scale, page, autoScale }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const fileUrl = useMemo(() => (src instanceof File ? URL.createObjectURL(src) : src.fileUrl), [src]);

  const [loading] = usePdf({
    file: fileUrl,
    page,
    parentContainerRef: parentRef,
    scale,
    autoScale,
    withCredentials: process.env.NODE_ENV === 'production' ? true : false,
  });

  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return <MainContainer ref={parentRef} />;
};

export default memo(Pdf);
