import React, { FC } from 'react';
import moment from 'moment';
import { Image } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  MainContainer,
  Avatar,
  InfoContainer,
  UserContainer,
  MessageContainer,
  MessageText,
  MainInfoContainer,
  ReadIndicator,
  UserText,
} from '../../component.styles';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { NOTIFICATION_TYPES } from '../../constants';
import { IFollowNotification } from '../../interfaces';

interface IProps {
  data: IFollowNotification;
  changeNotify: (id: string) => void;
  goToProfile: (id: string) => void;
}

const FollowNotification: FC<IProps> = ({ data, changeNotify, goToProfile }) => {
  const { createdAt, status, relatedEntity, id } = data;
  const history = useHistory();

  const { id: followerId, fullName, photoUrl } = relatedEntity?.user || {};

  if (!relatedEntity) {
    return null;
  }

  return (
    <MainContainer
      onClick={() => {
        changeNotify(id);
        goToProfile(followerId);
      }}
    >
      <UserContainer>
        <div onClick={() => history.push(`/profile/${followerId}`)}>
          <Avatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} />
        </div>
        <MainInfoContainer>
          <InfoContainer>
            <MessageContainer onClick={() => history.push(`/profile/${followerId}`)}>
              <UserText>{fullName}</UserText>
              <MessageText>accepted your follow request</MessageText>
            </MessageContainer>
            <MessageContainer>{moment(createdAt).format('MMM DD')}</MessageContainer>
          </InfoContainer>
        </MainInfoContainer>
      </UserContainer>
      {(status === NOTIFICATION_TYPES.SEEN || status === NOTIFICATION_TYPES.NEW) && <ReadIndicator />}
    </MainContainer>
  );
};

export default FollowNotification;
