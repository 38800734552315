import React, { useCallback, useState, useMemo, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Image, Popover } from 'antd';
import { useLazyQuery } from '@apollo/client';

import appRoutes from 'app/routes';
import { RootState } from 'store/rootReducer';
import { logout } from 'store/domains/auth/auth.actions';
import {
  HeaderContainer,
  MainContainer,
  ControlsContainer,
  MenuContainer,
  Menu,
  MenuElement,
  ControlElement,
  StyledPopover,
  StyledMenuTablet,
  StyledDrawer,
  MenuTablet,
  MenuTabletElement,
  LogoContainer,
  StyledMenuPopover,
  StyledLink,
  StyledNotificationsPopover,
  TabletExternalLink,
  MenuTabletSubElement,
  NotifyCountContainer,
  NotifyContainer,
  NoRedirectMenuElement,
  CaretDown,
  StyledLogo,
} from './styles';
import {
  MobileMenuHeader,
  MobileHeaderContainer,
  MobileDrawer,
  MobileMenu,
  MobileMenuElement,
  MobileMenuProfile,
  MobileProfileTitle,
  ArrowRight,
  MobileApps,
  AppsTitle,
  NotificationsDrawer,
  MobileMenuHeaderLink,
  MobileMenuIcon,
} from './styles.m';
import { ReactComponent as MenuIcon } from './assets/bxs-grid.svg';
import { ReactComponent as Notify } from './assets/bx-bell.svg';
import { ReactComponent as ClubViewMenu } from './assets/clubViewMenu.svg';
import { ReactComponent as SupportMenu } from './assets/supportMenu.svg';
import { ReactComponent as NotificationsMenu } from './assets/notificationsMenu.svg';
import { ReactComponent as SignOutMenu } from './assets/signOutMenu.svg';
import { UserState } from 'store/domains/user/user.types';
import ProfilePopover from './components/ProfilePopover/ProfilePopover';
import { profileHeaderElements, appsMenuElements } from './constants';
import TeamPopoverContent from './components/TeamPopover';
import MenuPopover from './components/MenuPopover';
import NotificationsPopover from './components/NotificationsPopover';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import GlobalSearch from './components/GlobalSearch';
import { ADMIN_ACCESS_PERMISSION } from 'modules/auth/constants';
import { USER_NOTIFICATIONS_COUNT } from 'modules/common/gql/query';
import { NOTIFICATION_COUNTER_UPDATED } from 'modules/common/gql/subscriptions';
import { Desktop, Mobile } from 'modules/common/hocks/breakpoints';
import { ReactComponent as Search } from './assets/bx-search.svg';

export interface INotificationsCountResult {
  userNewNotificationsCount: number;
  onNotificationsCounterUpdated: {
    userId: string;
    counter: number;
  };
}

const Header = () => {
  const [isOpenedDrawer, setOpenedDrawer] = useState(false);
  const [isOpenedMenuPopover, setOpenedMenuPopover] = useState(false);
  const [isOpenedNotifyPopover, setOpenedNotifyPopover] = useState(false);
  const [isOpenedUserPopover, setOpenedUserPopover] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const user: UserState = useSelector((state: RootState) => state?.user);
  const { id: userId, permissionIds, userData } = user;
  const isAdmin = useMemo(
    () => !!permissionIds?.length && permissionIds.some((p) => p === ADMIN_ACCESS_PERMISSION),
    [permissionIds],
  );

  const [getNotifications, { data: notificationsCountData, loading, subscribeToMore }] = useLazyQuery<INotificationsCountResult>(
    USER_NOTIFICATIONS_COUNT,
    {
      variables: { id: userId },
      fetchPolicy: 'network-only',
    },
  );

  const closeAndNavigate = (path: string) => {
    history.push(path);
    setOpenedDrawer(false);
  };

  const navigateOuterLink = (url: string) => {
    window.location.replace(url);
  };

  const closeUserPopover = () => {
    setOpenedUserPopover(false);
  };

  const onLogout = useCallback(() => {
    dispatch(logout(history));
  }, [history, dispatch]);

  const closeNotifyPopover = () => {
    setOpenedNotifyPopover(() => false);
  };

  useEffect(() => {
    if (userId && !notificationsCountData && !loading) {
      getNotifications();
    }
  }, [notificationsCountData, loading, userId]);

  useEffect(() => {
    // ADDING NOTIFICATIONS SUBSCRIPTION
    subscribeToMore &&
      subscribeToMore({
        document: NOTIFICATION_COUNTER_UPDATED,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData?.data) {
            return prev;
          }

          return Object.assign({}, prev, {
            userNewNotificationsCount: subscriptionData.data.onNotificationsCounterUpdated.counter,
          });
        },
      });
  }, [subscribeToMore]);

  const { firstName, lastName, middleName, fullName, photoUrl } = userData || {};
  const careersMenuItem = useMemo(() => profileHeaderElements.find((el) => el.value === 'Careers'), []);
  const teamMenuItem = useMemo(() => profileHeaderElements.find((el) => el.value === 'Team'), []);
  const socialMenuItem = useMemo(() => profileHeaderElements.find((el) => el.value === 'Social'), []);
  const growthMenuItem = useMemo(() => profileHeaderElements.find((el) => el.value === 'Growth'), []);
  return (
    <Fragment>
      <Desktop>
        <HeaderContainer>
          <StyledDrawer
            placement={'left'}
            closable={false}
            onClose={() => setOpenedDrawer(false)}
            visible={isOpenedDrawer}
            key={'left'}
            width={400}
          >
            <LogoContainer onClick={() => history.push(appRoutes.newsFeed)}>
              <StyledLogo />
            </LogoContainer>
            <MenuTablet>
              {profileHeaderElements.map((elem, key) => {
                const { image: Image, link, value, isExternalLink, elements } = elem;

                if (isExternalLink) {
                  return (
                    <TabletExternalLink href={link} target={'_blank'} key={key}>
                      <Image />
                      <p>{value}</p>
                    </TabletExternalLink>
                  );
                }
                if (elements) {
                  return (
                    <div key={key}>
                      <MenuTabletElement
                        key={key}
                        to={link}
                        activeStyle={{
                          fill: '#1890FF',
                          color: '#1890FF',
                          background: '#ECF6FF',
                        }}
                        onClick={() => setOpenedDrawer(false)}
                      >
                        <Image />
                        <p>{value}</p>
                      </MenuTabletElement>
                      {elements.map((elem) => {
                        const { link, value } = elem;

                        return (
                          <MenuTabletSubElement
                            key={link}
                            to={link}
                            onClick={() => setOpenedDrawer(false)}
                            activeStyle={{
                              color: '#1890FF',
                            }}
                          >
                            <p>{value}</p>
                          </MenuTabletSubElement>
                        );
                      })}
                    </div>
                  );
                }
                return (
                  <MenuTabletElement
                    key={key}
                    to={link}
                    activeStyle={{
                      fill: '#1890FF',
                      color: '#1890FF',
                      background: '#ECF6FF',
                    }}
                    onClick={() => setOpenedDrawer(false)}
                  >
                    <Image />
                    <p>{value}</p>
                  </MenuTabletElement>
                );
              })}
            </MenuTablet>
          </StyledDrawer>
          <MainContainer>
            <MenuContainer>
              <StyledMenuTablet onClick={() => setOpenedDrawer(true)} />
              <StyledLogo onClick={() => history.push(appRoutes.newsFeed)} />
              <Menu>
                {profileHeaderElements.map((elem, key) => {
                  const { image: Image, link, value, elements, isExternalLink } = elem;
                  const activeStyle = { fill: '#1890FF', color: '#1890FF' };

                  if (elements) {
                    const isActive = location.pathname.includes(link);
                    return (
                      <Popover
                        key={key}
                        overlayClassName={'teamPopover'}
                        content={<TeamPopoverContent elements={elements} pathname={location.pathname} />}
                        trigger={'hover'}
                        placement={'bottomLeft'}
                      >
                        <NoRedirectMenuElement $isActive={isActive} activeStyles={activeStyle}>
                          <Image />
                          <p>{value}</p>
                          <CaretDown />
                        </NoRedirectMenuElement>
                      </Popover>
                    );
                  }

                  if (isExternalLink) {
                    return (
                      <StyledLink href={link} target={'_blank'} key={key}>
                        <Image />
                        <p>{value}</p>
                      </StyledLink>
                    );
                  }

                  return (
                    <MenuElement key={key} to={link} activeStyle={activeStyle}>
                      <Image />
                      <p>{value}</p>
                    </MenuElement>
                  );
                })}
              </Menu>
            </MenuContainer>
            <ControlsContainer>
              <GlobalSearch />
              <StyledMenuPopover
                onVisibleChange={(val) => setOpenedMenuPopover(val)}
                trigger={['click']}
                content={<MenuPopover />}
                visible={isOpenedMenuPopover}
                overlayClassName={'menuHeaderPopover'}
                placement={'bottomRight'}
              >
                <ControlElement onClick={() => setOpenedMenuPopover(true)}>
                  <MenuIcon />
                </ControlElement>
              </StyledMenuPopover>
              <StyledNotificationsPopover
                onVisibleChange={(val) => setOpenedNotifyPopover(val)}
                trigger={['click']}
                content={
                  <NotificationsPopover
                    closeNotifyPopover={closeNotifyPopover}
                    notificationsCountData={notificationsCountData?.userNewNotificationsCount}
                    isOpenedNotifyPopover={isOpenedNotifyPopover}
                  />
                }
                visible={isOpenedNotifyPopover}
                overlayClassName={'notificationsPopover'}
                placement={'bottomRight'}
              >
                <ControlElement>
                  <NotifyContainer>
                    {notificationsCountData?.userNewNotificationsCount && notificationsCountData.userNewNotificationsCount > 0 ? (
                      <NotifyCountContainer>
                        {notificationsCountData.userNewNotificationsCount >= 99
                          ? '99+'
                          : notificationsCountData.userNewNotificationsCount}
                      </NotifyCountContainer>
                    ) : null}
                    <Notify />
                  </NotifyContainer>
                </ControlElement>
              </StyledNotificationsPopover>

              {userData && (
                <ControlElement>
                  <StyledPopover
                    content={
                      <ProfilePopover
                        firstName={firstName || ''}
                        lastName={lastName || ''}
                        middleName={middleName || ''}
                        fullName={fullName || ''}
                        photoUrl={photoUrl}
                        onLogout={onLogout}
                        isAdmin={isAdmin}
                        closeUserPopover={closeUserPopover}
                      />
                    }
                    trigger={'click'}
                    placement={'bottomRight'}
                    visible={isOpenedUserPopover}
                    onVisibleChange={(val) => !val && closeUserPopover()}
                  >
                    <div onClick={() => setOpenedUserPopover((isOpened) => !isOpened)}>
                      <Avatar
                        src={<Image preview={false} src={userData?.photoUrl || emptyAvatar} fallback={emptyAvatar} />}
                        size={'small'}
                      />
                    </div>
                  </StyledPopover>
                </ControlElement>
              )}
            </ControlsContainer>
          </MainContainer>
        </HeaderContainer>
      </Desktop>
      <Mobile>
        <MobileHeaderContainer>
          <MobileDrawer
            placement={'left'}
            closable={false}
            onClose={() => setOpenedDrawer(false)}
            visible={isOpenedDrawer}
            key={'left'}
            width={'100%'}
          >
            <h2>Menu</h2>
            <MobileMenuProfile>
              <Avatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} size="large" />
              <MobileProfileTitle>
                <div>{`${firstName} ${middleName || ''} ${lastName}`}</div>
                <span onClick={() => closeAndNavigate(appRoutes.profile)}>
                  View Profile <ArrowRight />
                </span>
              </MobileProfileTitle>
            </MobileMenuProfile>
            <MobileMenu>
              <MobileMenuElement key="notifications-menu" onClick={() => setOpenedNotifyPopover(!isOpenedNotifyPopover)}>
                <p>Notifications</p>
                <NotificationsDrawer
                  placement={'left'}
                  closable={false}
                  visible={isOpenedNotifyPopover}
                  key={'left'}
                  width={'100%'}
                >
                  <NotificationsPopover
                    closeNotifyPopover={() => {
                      closeNotifyPopover();
                      setOpenedDrawer(false);
                    }}
                    notificationsCountData={notificationsCountData?.userNewNotificationsCount}
                    isOpenedNotifyPopover={isOpenedNotifyPopover}
                  />
                </NotificationsDrawer>
                <NotificationsMenu />
              </MobileMenuElement>
              <MobileMenuElement key="team-menu" onClick={() => closeAndNavigate(`${appRoutes.team}/club-view`)}>
                <p>Club View</p>
                <ClubViewMenu />
              </MobileMenuElement>
              {!!growthMenuItem && (
                <MobileMenuElement key="growth-menu" onClick={() => navigateOuterLink(growthMenuItem.link)}>
                  <p>Growth</p>
                  <growthMenuItem.image
                    style={{
                      width: '27px',
                      height: '27px',
                      fill: 'rgb(17, 17, 17)',
                    }}
                  />
                </MobileMenuElement>
              )}
              <MobileMenuElement key="support-menu" onClick={() => closeAndNavigate(appRoutes.support)}>
                <p>Support</p>
                <SupportMenu />
              </MobileMenuElement>
              <MobileMenuElement
                key="sign-out-menu"
                onClick={() => {
                  setOpenedDrawer(false);
                  onLogout();
                }}
              >
                <p>Sign Out</p>
                <SignOutMenu />
              </MobileMenuElement>
              <AppsTitle>Apps</AppsTitle>
              <MobileApps>
                {appsMenuElements.map(({ link, image, text }, i) => {
                  return (
                    <li key={i} onClick={() => navigateOuterLink(link)} style={{ backgroundImage: `url(${image})` }}>
                      {text || ''}
                    </li>
                  );
                })}
              </MobileApps>
            </MobileMenu>
          </MobileDrawer>
          <MainContainer>
            <MobileMenuHeader>
              <MobileMenuIcon onClick={() => setOpenedDrawer(!isOpenedDrawer)} />
              {!!socialMenuItem && (
                <MobileMenuHeaderLink onClick={() => closeAndNavigate(socialMenuItem.link)}>
                  <socialMenuItem.image />
                </MobileMenuHeaderLink>
              )}
              {!!teamMenuItem && (
                <MobileMenuHeaderLink onClick={() => closeAndNavigate(teamMenuItem.link)}>
                  <teamMenuItem.image />
                </MobileMenuHeaderLink>
              )}
              {!!careersMenuItem && (
                <MobileMenuHeaderLink onClick={() => navigateOuterLink(careersMenuItem.link)}>
                  <careersMenuItem.image />
                </MobileMenuHeaderLink>
              )}
              <MobileMenuHeaderLink onClick={() => closeAndNavigate('/profile/global-search')}>
                <Search />
              </MobileMenuHeaderLink>
            </MobileMenuHeader>
          </MainContainer>
        </MobileHeaderContainer>
      </Mobile>
    </Fragment>
  );
};

export default React.memo(Header);
