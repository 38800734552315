import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 420px;

  .rcs-inner-handle {
    background-color: #e4e4e4;
  }
  .rcs-custom-scrollbar {
    width: 4px;
  }
  .rcs-inner-container {
    :first-child {
      div {
        margin-right: 0 !important;
      }
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid #eff0f1;
  padding: 16px;
  color: #111111;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const MainContent = styled.div`
  width: 100%;
  padding: 16px;
  padding-bottom: 150px;
`;

export const Headline = styled.div`
  color: #6b778c;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const EmptyContainer = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #111111;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin: 25px 0 0 0;
    padding: 0;
  }
`;

export const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;

  ${({ isLoadedAllData }: { isLoadedAllData?: boolean }) =>
    isLoadedAllData &&
    `
    height: 0;
  `};
`;

export const Placeholder = styled.div`
  overflow: auto;
  padding: 0 16px;
  position: relative;
`;

export const PlaceholderHead = styled.div`
  width: 56px;
  height: 24px;
  background: #eff0f1;
  filter: blur(1px);
  border-radius: 4px;
  margin-top: 14px;
`;

export const PlaceholderBody = styled.div`
  width: 344px;
  height: 112px;
  background: #fafafa;
  filter: blur(1px);
  border-radius: 4px;
  margin-top: 10px;
  &:last-child {
    margin-bottom: 50px;
  }
`;

export const Preloader = styled.div`
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  border: 5px solid transparent;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 5px solid #1890ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  z-index: 1;
`;
