import { requestGgl } from './request';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';
import { message } from 'antd';

// constants
export const PLUGIN_NAMES_MAP = {
  paste: 'paste',
  lists: 'lists',
  code: 'code',
  link: 'link',
  emoticons: 'emoticons',
  autolink: 'autolink'
};
export const MENTIONS_CONFIG_TRIGGER = '@';
export const EDITOR_MENTION_CLASS = 'post_mention';
export const ICON_CLASS = `${EDITOR_MENTION_CLASS}_icon`;
export const EDITOR_CONFIG = {
  itemType: 'profile',
  class: EDITOR_MENTION_CLASS,
  styles: `
    .${EDITOR_MENTION_CLASS} {
      color: #1890ff;
    }
    body {
      font-family:Inter,sans-serif; font-size:14px;
      font-weight: 300;
      color: rgba(0, 0, 0, 0.85); 
    }
  `,
  selector: `span.${EDITOR_MENTION_CLASS}`,
  toolbar: 'bold italic | bullist numlist outdent indent emoticons',
  toolbarWithoutPermissions: 'emoticons',
  plugins: Object.values(PLUGIN_NAMES_MAP),
  height: 200,
  dataIdentifier: 'data-mention-id',
  mentionTagUsername: 'data-mention-name',
  dataSelector: 'mentionId',
};

export const SEARCH_USERS_QUERY =
  '\
  query searchUsers($offset: Int!, $limit: Int!, $nameLike: String!) { \
    searchUsers(pagination: {offset: $offset, limit: $limit}, nameLike: $nameLike) {\
      data {\
        id\
        photoUrl\
        fullName\
        firstName\
        lastName\
        job {\
          positionBusinessTitle\
        }\
      }\
    }\
  }';
export const initialSearchConfig = {
  operationName: 'searchUsers',
  query: SEARCH_USERS_QUERY,
  limit: 8,
  offset: 0,
};

class TinyMCEConfig {
  editor: any = null;

  init(editor) {
    this.editor = editor;
  }

  getPlainTextIncludingMentionTags() {
    try {
      const htmlContent = this.editor.getContent();
      // excludes all tags except 'span' which includes mentions data
      return htmlContent.replace(new RegExp(`(<\/?(?:span[^>]*class="${EDITOR_MENTION_CLASS}"[^>]*>)[^>]*>)|<[^>]+>`, 'g'), '$1');
    } catch {
      return '';
    }
  }

  getPlainText() {
    try {
      const plainText = this?.editor?.getContent({ format: 'text' });
      // replaces multiple spaces, tabs, new lines, etc to one space only;
      return plainText?.replace(/\s\s+/g, ' ').trim() || '';
    } catch (e) {
      message.error(e.message);
      return '';
    }
  }

  registerAtMentions(postId) {
    const searchConfig = Boolean(postId) ? { ...initialSearchConfig, limit: 5 } : initialSearchConfig;

    this.editor.ui.autocompleters = {
      mentions: {
        getUsers: () => {
          const mentions = this.editor.$(`${EDITOR_CONFIG.selector}`).toArray();
          return mentions?.map((m) => m.dataset && m.dataset[`${EDITOR_CONFIG.dataSelector}`]) || [];
        },
      },
    };
    this.editor.ui.registry.addAutocompleter(postId ? `comment-mentions` : 'autocompleter-mentions', {
      ch: MENTIONS_CONFIG_TRIGGER, // the trigger character to open the autocompleter
      minChars: 0, // lower number means searching sooner - but more lookups as we go
      columns: 1, // must be 1 for text-based results
      highlight: [],
      fetch: (pattern) => {
        const suggestionPromise = requestGgl({
          data: {
            operationName: searchConfig.operationName,
            query: searchConfig.query,
            variables: { limit: searchConfig.limit, offset: searchConfig.offset, nameLike: pattern },
          },
        });
        return suggestionPromise.then((res) => {
          const users = res?.data?.data?.searchUsers?.data || [];
          // Preparing autocomplete options
          const parsedUsers = users.map((u, i) => {
            const { firstName, lastName, fullName, id, photoUrl, job } = u;
            const { positionBusinessTitle } = job;
            let text = `${firstName} ${lastName}`;
            if (fullName && fullName !== text) {
              text += ` (${fullName})`;
            }
            // text += `<div>sdsdsd</div>`;

            if (!Boolean(postId)) {
              return {
                text: text,
                value: `${fullName}|${id}`,
                icon: `<img id="${ICON_CLASS}_${id}" class="${ICON_CLASS}" src="${photoUrl || emptyAvatar}" />`,
              };
            }

            return {
              type: 'cardmenuitem',
              value: `${fullName}|${id}`,
              items: [
                {
                  type: 'cardcontainer',
                  direction: 'horizontal',
                  valign: 'top',
                  items: [
                    {
                      type: 'cardimage',
                      id: `${ICON_CLASS}_${id}`,
                      src: `${photoUrl || emptyAvatar}`,
                      alt: '',
                      classes: ['commentMentionAvatar'],
                    },
                    {
                      type: 'cardcontainer',
                      direction: 'vertical',
                      items: [
                        {
                          type: 'cardtext',
                          text,
                          classes: ['commentMentionFullname'],
                        },
                        {
                          type: 'cardtext',
                          text: positionBusinessTitle,
                          classes: ['commentMentionPositionTitle'],
                        },
                      ],
                    },
                  ],
                },
              ],
            };
          });

          return parsedUsers;
        });
      },
      onAction: (autocompleteApi, rng, value) => {
        // insert in to the editor
        let parts = value.split('|');
        let name = parts[0];
        let uuid = parts[1];
        // eslint-disable-next-line max-len
        const mention = `<span contenteditable="false" class="${EDITOR_CONFIG.class}" ${EDITOR_CONFIG.mentionTagUsername}="${name}" ${EDITOR_CONFIG.dataIdentifier}="${uuid}">${name}</span>`;
        this.editor.selection.setRng(rng);
        this.editor.insertContent(mention);
        autocompleteApi.hide();
      },
    });
  }
}

export default TinyMCEConfig;
