import React, { FC, useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';

import EmptyButton from '../EmptyButton';
import {
  StyledInnerTitle,
  WorkingHoursInputs,
  WorkingHoursInputContainer,
  StyledSelectorTimes,
  SplitLine,
  StyledArrow,
  WorkingHoursEditContainer,
  WorkingHoursControls,
  ControlButton,
  StyledText,
  StyledCol,
  StyledCancell,
  StyledConfirm,
} from '../../styles';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import { UPDATE_WORKING_HOURS } from 'modules/profile/gql/mutation';
import { getTimeList } from './utils';
import { emitProfileUpdated } from 'utils/mixpanel';

export interface IProps {
  user: IUserModel;
  refetchUser: () => void;
  isEditable: boolean;
}

const WorkingHours: FC<IProps> = ({ user, refetchUser, isEditable }) => {
  const [isEdit, setEdit] = useState(false);
  const { employment } = user || {};
  const { workingHoursStart, workingHoursEnd } = employment || {};
  const [updateWorkingHours, { loading: updateWorkingHoursLoading, called: updateWorkingHoursCalled }] = useMutation(
    UPDATE_WORKING_HOURS,
  );
  const startDefaultDate = moment('10:00:00', 'HH:mm:ss').format('LT');
  const endDefaultDate = moment('12:00:00', 'HH:mm:ss').format('LT');
  const [startTimeForUpdate, setStartTimeForUpdate] = useState(workingHoursStart || startDefaultDate);
  const [endTimeForUpdate, setEndTimeForUpdate] = useState(workingHoursEnd || endDefaultDate);
  const timeListOptions = useMemo(() => getTimeList(), []);
  const filteredStartTimeOptions = useMemo(
    () =>
      timeListOptions.filter((option) => {
        const momentDataOption = moment(option, 'HH:mm:ss');
        const momentDataEndTime = moment(endTimeForUpdate, 'HH:mm:ss');

        return momentDataEndTime.diff(momentDataOption) > 0;
      }),
    [endTimeForUpdate, timeListOptions],
  );
  const filteredEndTimeOptions = useMemo(
    () =>
      timeListOptions.filter((option) => {
        const momentDataOption = moment(option, 'HH:mm:ss');
        const momentDataStartTime = moment(startTimeForUpdate, 'HH:mm:ss');

        return momentDataStartTime.diff(momentDataOption) < 0;
      }),
    [startTimeForUpdate, timeListOptions],
  );

  useEffect(() => {
    if (!updateWorkingHoursLoading && updateWorkingHoursCalled) {
      emitProfileUpdated({ section: 'working hours' });
      refetchUser();
    }
  }, [updateWorkingHoursLoading, updateWorkingHoursCalled]);

  const onClose = () => {
    setEdit(() => false);
  };

  const saveWorkingHours = () => {
    updateWorkingHours({
      variables: {
        id: user.id,
        workingHoursStart: startTimeForUpdate,
        workingHoursEnd: endTimeForUpdate,
      },
    });
    onClose();
  };

  const cancelWorkingHours = () => {
    onClose();
    setStartTimeForUpdate(workingHoursStart || startDefaultDate);
    setEndTimeForUpdate(workingHoursEnd || endDefaultDate);
  };

  if (!isEditable && !workingHoursStart && !workingHoursEnd) {
    return null;
  }

  return (
    <StyledCol
      onClick={() => isEditable && !isEdit && setEdit(true)}
      $isEditable={isEditable && (!!workingHoursStart || !!workingHoursEnd) && !isEdit}
      span={24}
    >
      <StyledInnerTitle disabled>Working Hours</StyledInnerTitle>
      {isEdit && (
        <WorkingHoursEditContainer>
          <WorkingHoursInputs>
            <WorkingHoursInputContainer>
              <StyledArrow />
              <StyledSelectorTimes
                suffixIcon={null}
                value={startTimeForUpdate}
                onChange={(value) => setStartTimeForUpdate(`${value}`)}
              >
                {filteredStartTimeOptions.map((elem) => {
                  return (
                    <StyledSelectorTimes.Option value={elem}>{moment(elem, 'HH:mm:ss').format('LT')}</StyledSelectorTimes.Option>
                  );
                })}
              </StyledSelectorTimes>
            </WorkingHoursInputContainer>
            <SplitLine />
            <WorkingHoursInputContainer>
              <StyledArrow />
              <StyledSelectorTimes
                suffixIcon={null}
                value={endTimeForUpdate}
                onChange={(value) => setEndTimeForUpdate(`${value}`)}
              >
                {filteredEndTimeOptions.map((elem) => {
                  return (
                    <StyledSelectorTimes.Option value={elem}>{moment(elem, 'HH:mm:ss').format('LT')}</StyledSelectorTimes.Option>
                  );
                })}
              </StyledSelectorTimes>
            </WorkingHoursInputContainer>
          </WorkingHoursInputs>
          <WorkingHoursControls>
            <ControlButton onClick={saveWorkingHours}>
              <StyledConfirm />
            </ControlButton>
            <ControlButton onClick={cancelWorkingHours} isCanceButton>
              <StyledCancell />
            </ControlButton>
          </WorkingHoursControls>
        </WorkingHoursEditContainer>
      )}
      {workingHoursStart && workingHoursEnd && !isEdit && (
        <div>
          <StyledText>{`${moment(workingHoursStart, 'HH:mm:ss').format('LT')} - ${moment(workingHoursEnd, 'HH:mm:ss').format(
            'LT',
          )}`}</StyledText>
        </div>
      )}
      {isEditable && (!workingHoursStart || !workingHoursEnd) && !isEdit && (
        <EmptyButton text="Add Working Hours" onClick={() => setEdit(true)} />
      )}
    </StyledCol>
  );
};

export default WorkingHours;
