import React, { FC, useState, useMemo } from 'react';
import { Typography } from 'antd';
import moment from 'moment-timezone';
import { useMutation } from '@apollo/client';
import { message } from 'antd';

import { TimeEditContainer, TimeEditInput, TimeInputContainer, StyledArrow, StyledSelector, TimeControls } from './styles';
import { StyledInnerTitle, StyledCol, StyledCancell, StyledConfirm, ControlButton } from '../../styles';
import { IUserModel } from 'modules/profile/gql/models/user.model';
import EmptyButton from '../EmptyButton';
import { UPDATE_LOCAL_TIME_ZONE } from 'modules/profile/gql/mutation';
import { emitProfileUpdated } from 'utils/mixpanel';

interface IProps {
  user: IUserModel;
  refetchUser: () => void;
  isEditable: boolean;
}

const Time: FC<IProps> = ({ user, refetchUser, isEditable }) => {
  const [value, setValue] = useState<string | null>(user.timeZone);
  const [isEditMode, setEditMode] = useState(false);
  const options = useMemo(() => {
    const filteredNames = moment.tz
      .names()
      .filter((elem) => elem.includes('/') && !elem.toLowerCase().includes('gmt') && !elem.toLowerCase().includes('etc'));

    const parsedNames = filteredNames.map((elem) => {
      const utcOffset = moment.tz(moment(), elem).format('Z');
      const parsedElement = elem.replaceAll('/', ' - ').replaceAll('_', ' ');
      return {
        label: `(GMT${utcOffset}) ${parsedElement}`,
        value: elem,
      };
    });

    return parsedNames;
  }, []);

  const currentTime = useMemo(() => value && moment().tz(value).format('hh:mma'), [value]);

  const onClose = () => {
    setEditMode(false);
  };

  const [updateLocalTimeZone] = useMutation(UPDATE_LOCAL_TIME_ZONE, {
    onError: () => message.error('Error'),
    onCompleted: () => {
      emitProfileUpdated({ section: 'timezone' });
      onClose();
      refetchUser();
    },
  });

  const confirmLocalZone = () => {
    updateLocalTimeZone({
      variables: {
        id: user.id,
        timeZone: value,
      },
    });
  };

  if (!isEditable && !user.timeZone) {
    return null;
  }

  return (
    <StyledCol onClick={() => isEditable && !isEditMode && setEditMode(true)} $isEditable={isEditable && !isEditMode} span={24}>
      <StyledInnerTitle disabled>Time Zone</StyledInnerTitle>
      {isEditMode && (
        <TimeEditContainer>
          <TimeEditInput>
            <TimeInputContainer>
              <StyledArrow />
              <StyledSelector
                suffixIcon={null}
                filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                value={value || ''}
                onChange={(value) => setValue(`${value}`)}
              >
                {options.map((elem) => {
                  return <StyledSelector.Option value={elem.value}>{elem.label}</StyledSelector.Option>;
                })}
              </StyledSelector>
            </TimeInputContainer>
          </TimeEditInput>
          <TimeControls>
            <ControlButton onClick={() => confirmLocalZone()}>
              <StyledConfirm />
            </ControlButton>
            <ControlButton
              onClick={() => {
                onClose();
                setValue(() => user.timeZone);
              }}
              isCanceButton
            >
              <StyledCancell />
            </ControlButton>
          </TimeControls>
        </TimeEditContainer>
      )}
      {!isEditMode && currentTime && value ? (
        <div>
          <Typography.Text>{`(GMT${moment.tz(moment(), value).format('Z')}), ${currentTime}`}</Typography.Text>
        </div>
      ) : null}
      {isEditable && !value && !isEditMode && <EmptyButton text="Add Local Time" onClick={() => setEditMode(true)} />}
    </StyledCol>
  );
};

export default Time;
