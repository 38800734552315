import React, { FC, useCallback } from 'react';
import { Avatar, Image } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  MainPopoverContainer,
  ProfileInfoContainer,
  ProfileNameContainer,
  Name,
  PreferedName,
  SplitLine,
  MenuElementProfile,
  MenuProfile,
  ButtonLink,
} from '../../styles';
import appRoutes from 'app/routes';
import emptyAvatar from 'modules/common/assets/images/emptyAvatar.svg';

interface IProps {
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  photoUrl?: string;
  onLogout: () => void;
  isAdmin: boolean;
  closeUserPopover: () => void;
}

const ProfilePopover: FC<IProps> = ({
  firstName,
  fullName,
  lastName,
  photoUrl,
  middleName,
  onLogout,
  isAdmin,
  closeUserPopover,
}) => {
  const history = useHistory();
  const goToProfile = useCallback(() => {
    history.push(appRoutes.profile);
    closeUserPopover();
  }, [history, closeUserPopover]);
  const goToSettings = useCallback(() => {
    history.push(appRoutes.backOffice);
    window.location.reload();
  }, [history]);

  return (
    <MainPopoverContainer>
      <ProfileInfoContainer onClick={goToProfile}>
        <Avatar src={<Image preview={false} src={photoUrl || emptyAvatar} fallback={emptyAvatar} />} size="default" />
        <ProfileNameContainer>
          <ButtonLink type="link">
            <Name>
              {firstName} {middleName} {lastName}
            </Name>
          </ButtonLink>
          <PreferedName>{fullName}</PreferedName>
        </ProfileNameContainer>
      </ProfileInfoContainer>
      <SplitLine />
      <MenuProfile>
        {isAdmin && (
          <MenuElementProfile>
            <div onClick={goToSettings}>Settings</div>
          </MenuElementProfile>
        )}
        <MenuElementProfile>
          <div onClick={onLogout}>Sign Out </div>
        </MenuElementProfile>
      </MenuProfile>
    </MainPopoverContainer>
  );
};

export default ProfilePopover;
