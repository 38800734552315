import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { MainContainer, StyledRow, FeedContainer } from './styles';
import MainContent from './components/MainContent';
import { UserState } from 'store/domains/user/user.types';
import { INewsFeedPostModel } from 'modules/common/gql/models/newsFeedPost.model';
import { IUserForNewsFeed } from 'modules/common/gql/models/user.model';
import { RootState } from 'store/rootReducer';
import { IReactionModel } from 'modules/common/gql/models/reaction.model';
import { IComment } from 'modules/common/gql/models/newsFeedPost.model';

export interface IUserResult {
  user: IUserForNewsFeed;
}

export interface INewsFeedPostResult {
  newsFeedPosts: {
    data: INewsFeedPostModel[];
    pagination: {
      total: number;
      nextCursor: string;
    };
  };
  onNewsFeedPostAdded: INewsFeedPostModel;
  onNewsFeedPostDeleted: INewsFeedPostModel;
  onNewsFeedPostUpdated: INewsFeedPostModel;
  onNewsFeedCommentUpdated: IComment;
  onNewsFeedCommentAdded: IComment;
  onNewsFeedCommentDeleted: IComment;
}

export interface INewsFeedPostByIdResult {
  newsFeedPostById: INewsFeedPostModel;
  onNewsFeedCommentAdded: IComment;
  onNewsFeedCommentUpdated: IComment;
}

export interface IReactionsResult {
  newsFeedReactionsByPostIds: IReactionModel[];
  onNewsFeedReaction: IReactionModel;
}

export interface ICostCenter {
  id: string;
  name: string;
}

const Feed: FC = () => {
  const user: UserState = useSelector((state: RootState) => state?.user);
  const { userData } = user;

  if (!userData) {
    return null;
  }

  return (
    <FeedContainer>
      <MainContainer>
        <StyledRow>
          <MainContent user={userData} />
        </StyledRow>
      </MainContainer>
    </FeedContainer>
  );
};

export default Feed;
