import pluralize from 'pluralize';
import { IEditableData } from './components/MainContent/MainContent';
import logo from 'modules/news-feed/pages/Feed/assets/logo.png';
import { COMPANY } from 'modules/common/const';
import { NF_PERMISSIONS, NF_PERMISSIONS_TO_SKIP } from 'modules/auth/constants';

import fireIcon from './assets/hrPosts/fire.svg';
import medalIcon from './assets/hrPosts/medal.svg';
import partyIcon from './assets/hrPosts/party.svg';
import briefcaseIcon from './assets/hrPosts/briefcase.svg';

export enum POST_TYPES {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY_NEWS = 'COMPANY_NEWS',
  TEAM_NEWS = 'TEAM_NEWS',
  THANKS = 'THANKS',
  RECOGNITION = 'RECOGNITION',
  HR_NEW_HIRES = 'HR_NEW_HIRES',
  HR_BIRTHDAY_WISHES = 'HR_BIRTHDAY_WISHES',
  HR_PROMOTION = 'HR_PROMOTION',
  HR_WORK_ANNIVERSARY = 'HR_WORK_ANNIVERSARY',
}

export enum BADGE_TYPE {
  TEAM_WORK = 'TEAM_WORK',
  LEADERSHIP = 'LEADERSHIP',
  GROWTH = 'GROWTH',
  EXCELLENCE = 'EXCELLENCE',
  CUSTOMER_FOCUS = 'CUSTOMER_FOCUS',
  INNOVATION = 'INNOVATION',
}

export const placeholderEditor = {
  [POST_TYPES.RECOGNITION]: 'Write a statement of recognition',
  [POST_TYPES.THANKS]: 'Write a statement of gratitude',
};

export const postTypes = [
  {
    label: 'Individual',
    value: POST_TYPES.INDIVIDUAL,
    permission: NF_PERMISSIONS.MANAGE_INDIVIDUAL_POSTS,
    skip: NF_PERMISSIONS_TO_SKIP.some((p) => p === NF_PERMISSIONS.MANAGE_INDIVIDUAL_POSTS),
  },
  {
    label: 'Company News',
    value: POST_TYPES.COMPANY_NEWS,
    permission: NF_PERMISSIONS.MANAGE_COMPANY_NEWS,
    skip: NF_PERMISSIONS_TO_SKIP.some((p) => p === NF_PERMISSIONS.MANAGE_COMPANY_NEWS),
  },
  {
    label: 'Team News',
    value: POST_TYPES.TEAM_NEWS,
    permission: [NF_PERMISSIONS.MANAGE_TEAM_NEWS, NF_PERMISSIONS.MANAGE_ALL_TEAM_NEWS],
    skip: NF_PERMISSIONS_TO_SKIP.some((p) => p === NF_PERMISSIONS.MANAGE_TEAM_NEWS),
  },
  {
    label: 'Give Thanks',
    value: POST_TYPES.THANKS,
    permission: NF_PERMISSIONS.MANAGE_THANKS,
    skip: NF_PERMISSIONS_TO_SKIP.some((p) => p === NF_PERMISSIONS.MANAGE_THANKS),
  },
  {
    label: 'New Hires',
    value: POST_TYPES.HR_NEW_HIRES,
    permission: NF_PERMISSIONS.MANAGE_HR_NEW_HIRES,
    skip: NF_PERMISSIONS_TO_SKIP.some((p) => p === NF_PERMISSIONS.MANAGE_HR_NEW_HIRES),
  },
  {
    label: 'Birthday Wishes',
    value: POST_TYPES.HR_BIRTHDAY_WISHES,
    permission: NF_PERMISSIONS.MANAGE_HR_BIRTHDAY_WISHES,
    skip: NF_PERMISSIONS_TO_SKIP.some((p) => p === NF_PERMISSIONS.MANAGE_HR_BIRTHDAY_WISHES),
  },
  {
    label: 'Promotion',
    value: POST_TYPES.HR_PROMOTION,
    permission: NF_PERMISSIONS.MANAGE_HR_PROMOTION,
    skip: NF_PERMISSIONS_TO_SKIP.some((p) => p === NF_PERMISSIONS.MANAGE_HR_PROMOTION),
  },
  {
    label: 'Work Anniversary',
    value: POST_TYPES.HR_WORK_ANNIVERSARY,
    permission: NF_PERMISSIONS.MANAGE_HR_WORK_ANNIVERSARY,
    skip: NF_PERMISSIONS_TO_SKIP.some((p) => p === NF_PERMISSIONS.MANAGE_HR_WORK_ANNIVERSARY),
  },
  {
    label: 'Recognition',
    value: POST_TYPES.RECOGNITION,
    permission: null,
    skip: true,
  },
];

export const hrPostsIcons = {
  [POST_TYPES.HR_NEW_HIRES]: fireIcon,
  [POST_TYPES.HR_PROMOTION]: medalIcon,
  [POST_TYPES.HR_BIRTHDAY_WISHES]: partyIcon,
  [POST_TYPES.HR_WORK_ANNIVERSARY]: briefcaseIcon,
};

export const hrPostsTitle = {
  [POST_TYPES.HR_NEW_HIRES]: (amount: number) => pluralize('New Hire', amount, true),
  [POST_TYPES.HR_PROMOTION]: (amount: number) => pluralize('Promotion', amount, true),
  [POST_TYPES.HR_BIRTHDAY_WISHES]: (amount: number) => pluralize('Birthday', amount, true),
  [POST_TYPES.HR_WORK_ANNIVERSARY]: (amount: number) => pluralize('Work Anniversary', amount, true),
};

export const NEWS_FEED_POSTS_LIMIT = 10000;

export const company = { ...COMPANY, logo };

export const initialNewsFeedData: IEditableData = {
  _id: '',
  type: POST_TYPES.INDIVIDUAL,
  text: '',
  isFeatured: true,
  headline: '',
  featuredExpAt: null,
  publishAt: null,
  costCenterId: '',
  files: [],
  mentions: [],
  targetEmployees: [],
  tags: null,
  userId: '',
  commentsCount: 0,
  createdAt: '',
  updatedAt: '',
  badge: null,
};

export const MIN_WIDTH_IMAGE = 600;
export const MIN_HEIGHT_IMAGE = 200;

export const MAX_SIDE_POSTS_COUNT = 6;
