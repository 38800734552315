export const statusElements = [
  {
    label: 'About Me',
    value: 'aboutMe',
  },
  {
    label: 'Working hours',
    value: 'workingHours',
  },
  // {
  //   label: 'Certifications',
  //   value: 'certifications',
  // },
  // {
  //   label: 'Trainings',
  //   value: 'trainings',
  // },
  {
    label: 'Interests',
    value: 'hobbies',
  },
  {
    label: 'Skills',
    value: 'skills',
  },
];

export const modalViewDays = [5, 10, 15];
